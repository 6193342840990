import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Link } from 'react-router-dom';

const BackButton = ({ to = '', label = '' }) => {
    const { t } = useTranslation();
    return (
        <Link to={to || '/dashboard'}>
            <Button
                startIcon={
                    <ArrowBackIosNewIcon
                        style={{
                            fontSize: '12px',
                        }}
                    />
                }
                sx={{
                    mb: 3,
                    color: '#000',
                    fontWeight: '100',
                }}
                style={{
                    color: '#000',
                    fontWeight: '100',
                    textTransform: 'inherit',
                }}
            >
                {label || t('sidebar.dashboard')}
            </Button>
        </Link>
    );
};

export default BackButton;
