import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Checkbox,
    TableSortLabel,
} from '@mui/material';

export default function BankTableHead(props) {
    const { onSelectAllClick, headCells, order, orderBy, onRequestSort } =
        props;
    const { t } = useTranslation();
    const roleId = useSelector((state) => state.user.info.role_id);
    const emulation = useSelector((state) => state.user.emulation);

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => {
                    if (headCell.type === 'link') {
                        return (
                            <TableCell
                                key={headCell.id}
                                padding={
                                    headCell.disablePadding ? 'none' : 'normal'
                                }
                            >
                                <Typography>{t(headCell.label)}</Typography>
                            </TableCell>
                        );
                    }

                    if (headCell.type === 'statistics') {
                        return (
                            <TableCell
                                key={headCell.id}
                                align="left"
                                padding={
                                    headCell.disablePadding ? 'none' : 'normal'
                                }
                            >
                                <Typography>{t(headCell.label)}</Typography>
                            </TableCell>
                        );
                    }

                    if (headCell.type === 'img') {
                        return (
                            <TableCell
                                key={headCell.id}
                                align="left"
                                padding={
                                    headCell.disablePadding ? 'none' : 'normal'
                                }
                            >
                                <Typography>{t(headCell.label)}</Typography>
                            </TableCell>
                        );
                    }

                    if (headCell.type === 'image') {
                        return (
                            <TableCell
                                key={headCell.id}
                                align="left"
                                padding={
                                    headCell.disablePadding ? 'none' : 'normal'
                                }
                            >
                                <Typography>{t(headCell.label)}</Typography>
                            </TableCell>
                        );
                    }

                    if (headCell.type === 'checkbox') {
                        return (
                            <TableCell key={headCell.id} padding="checkbox">
                                <Checkbox
                                    color="primary"
                                    onChange={onSelectAllClick}
                                    inputProps={{
                                        'aria-label': 'select all items',
                                    }}
                                />
                            </TableCell>
                        );
                    }

                    if (headCell.type === 'options') {
                        if (
                            (roleId === 2 && emulation.length === 1) ||
                            (roleId === 3 && !emulation.length)
                        ) {
                            return (
                                <TableCell
                                    key={headCell.id}
                                    align="left"
                                    padding={
                                        headCell.disablePadding
                                            ? 'none'
                                            : 'normal'
                                    }
                                >
                                    <Typography>{t(headCell.label)}</Typography>
                                </TableCell>
                            );
                        }
                        return null;
                    }

                    if (headCell.type === 'displayOrder') {
                        if (
                            (roleId === 2 && emulation.length === 1) ||
                            (roleId === 3 && !emulation.length)
                        ) {
                            return (
                                <TableCell
                                    key={headCell.id}
                                    align="left"
                                    padding={
                                        headCell.disablePadding
                                            ? 'none'
                                            : 'normal'
                                    }
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.id}
                                        direction={
                                            orderBy === headCell.id
                                                ? order
                                                : 'asc'
                                        }
                                        onClick={createSortHandler(headCell.id)}
                                        sx={{
                                            '& .MuiTableSortLabel-icon': {
                                                opacity:
                                                    orderBy === headCell.id
                                                        ? 1
                                                        : 0,
                                                transition: 'opacity 0.3s',
                                            },
                                            '&:hover .MuiTableSortLabel-icon': {
                                                opacity: 0.5,
                                            },
                                        }}
                                    >
                                        <Typography>
                                            {t(headCell.label)}
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                            );
                        }
                        return null;
                    }

                    if (
                        (roleId === 2 && emulation.length === 1) ||
                        (roleId === 3 && !emulation.length) ||
                        headCell.id !== 'teacher'
                    ) {
                        return (
                            <TableCell
                                key={headCell.id}
                                align="left"
                                padding={
                                    headCell.disablePadding ? 'none' : 'normal'
                                }
                                sortDirection={
                                    orderBy === headCell.id ? order : false
                                }
                            >
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={
                                        orderBy === headCell.id ? order : 'asc'
                                    }
                                    onClick={createSortHandler(headCell.id)}
                                    sx={{
                                        '& .MuiTableSortLabel-icon': {
                                            opacity:
                                                orderBy === headCell.id ? 1 : 0,
                                            transition: 'opacity 0.3s',
                                        },
                                        '&:hover .MuiTableSortLabel-icon': {
                                            opacity: 0.5,
                                        },
                                    }}
                                >
                                    <Typography>{t(headCell.label)}</Typography>
                                </TableSortLabel>
                            </TableCell>
                        );
                    }
                    return null;
                })}
            </TableRow>
        </TableHead>
    );
}
