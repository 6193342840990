import React from 'react';
import { connect } from 'react-redux';
import './hw.css';
import TextField from '@material-ui/core/TextField';
import { bindActionCreators } from 'redux';
import {
    simple,
    brother,
    friend,
    friend_brother,
    multiplication,
    AllHWTopic,
} from '../../constants/hw';
import {
    setData,
    initTemplatesName,
    saveHW,
    saveHWName,
    copyLastDayHW,
} from '../../actions/hw';

import { Add } from '@material-ui/icons';
import {
    __GetData,
    __AddDataHWRow,
    _copyDataRow,
} from '../../actions/AsyncTable';
import ComponentTable from '../../components/ComponentTable';
import TabPanel from '../../components/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import NativeSelect from '@material-ui/core/NativeSelect';
import HomeworkService from './../../services/homework.service';
import ModelService from './../../services/models.service';
import { Delete } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { openRequestModal } from '../../actions/modals';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { deleteAllHW } from '../../actions/hw';
import { setLastTopic } from '../../actions/groups';

class HWSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateName: null,
        };
        this.source = {
            firstLoad: () => this.props.initTemplatesName(),
            getData: (props) => {
                return HomeworkService.HomeworkList(
                    false,
                    Object.assign(
                        {
                            topic: this.props.HW.topic,
                            templatesName:
                                this.props.HW.templatesName_select ||
                                this.props.HW.templatesName,
                            day: this.props.HW.day,
                            size: 9999,
                        },
                        { ...props }
                    )
                );
            },
            getModel: () => ModelService.HomeworkList(),
        };
    }

    componentDidMount() {
        this.props.setLastTopic([{ value: 'S2' }]);
    }

    makeToOption = (params, name) => {
        return params.map((item, key) => (
            <option key={key} value={item.value}>
                {item.label}
            </option>
        ));
    };
    changeSelectTemplates = (e) => {
        this.props.setData({
            [e.target.id]: !isNaN(+e.target.value)
                ? +e.target.value
                : e.target.value,
        });
        this.props.__GetData({
            getData: (props) =>
                HomeworkService.HomeworkList(
                    false,
                    Object.assign(
                        {
                            topic: this.props.HW.topic,
                            templatesName: this.props.HW.templatesName_select,
                            day: this.props.HW.day,
                            size: 9999,
                            ...{
                                // eslint-disable-next-line
                                [e.target.id == 'templatesName_select'
                                    ? 'templatesName'
                                    : e.target.id]: +e.target.value
                                    ? +e.target.value
                                    : e.target.value,
                            },
                        },
                        { ...props }
                    )
                ),
        });
    };

    makeToOptionFromArray = (params) =>
        params.map((element) =>
            element.map((item, key) => (
                <option key={key} value={item.value}>
                    {item.label}
                </option>
            ))
        );

    callBackDataHW = (e) => {
        let table =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;
        let arrToSave = [];
        const objToSave = {};
        for (let i in e) {
            const obj = JSON.parse(i);
            if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            } else if (obj && obj.temporarily) {
                if (!objToSave[obj.temporarily])
                    objToSave[obj.temporarily] = {};
                objToSave[obj.temporarily][obj.code] = e[i];
            }
        }
        for (let i in objToSave) {
            if (!isNaN(+i)) {
                arrToSave.push({
                    ...objToSave[i],
                    id: i,
                });
            } else {
                arrToSave.push({
                    ...objToSave[i],
                });
            }
        }
        const schemaGameValue = {
            Ded: '3',
            Flash: '5',
            FlashMinus: '6',
        };
        for (let item of arrToSave) {
            if (schemaGameValue[item.game_type])
                item.game_type = schemaGameValue[item.game_type];
            if (+item.game_type > 3 && item.base !== '0') item.base = '0';
        }
        if (table.length > arrToSave.length) {
            let numbers = table.length - arrToSave.length;
            for (let i = 0; i < numbers; i++) {
                arrToSave.push({
                    topic: '0',
                    digit: 1,
                    game_type: '3',
                    duration: 0,
                });
            }
        }
        arrToSave = this.checkArrayRange(arrToSave);
        this.props.saveHW(arrToSave);
    };

    checkArrayRange(array) {
        for (let item of array) {
            if (+item.game_type === 3 && +item.topic > 24) item.topic = '0';
            if (+item.game_type === 4 && +item.topic < 53) item.topic = '53';
            if (
                (+item.game_type === 5 && +item.topic < 12) ||
                (+item.game_type === 5 && +item.topic > 24)
            )
                item.topic = '12';
            if (
                (+item.game_type === 6 && +item.topic < 12) ||
                (+item.game_type === 6 && +item.topic > 24)
            )
                item.topic = '12';
        }
        return array;
    }
    changeSelect = (e) => {
        this.props.setData({
            [e.target.id]:
                (!isNaN(+e.target.value) && +e.target.value) || e.target.value,
        });
        const selectedTopicValue = AllHWTopic.filter(
            (el) => el.value === +e.target.value
        );
        if (!selectedTopicValue && selectedTopicValue.length) return;
        this.props.setLastTopic([
            {
                value: selectedTopicValue[0].label,
            },
        ]);
        this.props.__GetData({
            getData: (props) =>
                HomeworkService.HomeworkList(
                    false,
                    Object.assign(
                        {
                            topic: this.props.HW.topic,
                            templatesName: this.props.HW.templatesName_select,
                            day: this.props.HW.day,
                            size: 9999,
                            ...{
                                // eslint-disable-next-line
                                [e.target.id == 'templatesName_select'
                                    ? 'templatesName'
                                    : e.target.id]: +e.target.value
                                    ? +e.target.value
                                    : e.target.value,
                            },
                        },
                        { ...props }
                    )
                ),
        });
    };

    handleChange = (e, newValue) => {
        this.props.setData({ day: +newValue });
        this.props.__GetData({
            getData: (props) =>
                HomeworkService.HomeworkList(
                    false,
                    Object.assign(
                        {
                            topic: this.props.HW.topic,
                            templatesName: this.props.HW.templatesName_select,
                            day: newValue,
                            size: 9999,
                        },
                        { ...props }
                    )
                ),
        });
    };
    recordTemplateNameData = (e) => {
        this.setState({
            templateName: e.target.value,
        });
    };
    createTemplateName = () => {
        this.props.saveHWName(this.state.templateName).then((res) => {
            this.props.initTemplatesName();
        });
    };

    render() {
        const time =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;
        let averege_time = 0;
        if (time && time.length >= 1) {
            for (let item of time) {
                if (item.duration) averege_time += +item.duration;
            }
        }
        averege_time = averege_time
            ? `${Math.floor(averege_time / 60) < 10 ? '0' : ''}${Math.floor(
                  averege_time / 60
              )}:${averege_time % 60 < 10 ? 0 : ''}${averege_time % 60}`
            : 0;
        const { t } = this.props;
        const days = [1, 2, 3, 4, 5, 6];
        const value = this.props.HW.day;
        let actionsButton = [
            {
                title: t('HWSettings.remove_hw'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: 'deleteAll',
                            header: t('HWSettings.remove_hw'),
                            modaltype: 'DeleteHwModal',
                        },
                    }),
                Icon: Delete,
            },
            {
                title: t('HWSettings.copy_temp'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            template_id: this.props.HW.templatesName_select,
                            header: t('HWSettings.copy_temp'),
                            modaltype: 'CopyTemplateModal',
                        },
                    }),
                Icon: SwapVertIcon,
            },
            {
                title: t('table.add'),
                Icon: Add,
                onClick: () => this.props.__AddDataHWRow(),
            },
        ];
        if (this.props.HW && this.props.HW.day !== 0) {
            actionsButton.push({
                title: t('table.copy_last_day'),
                Icon: SettingsBackupRestoreIcon,
                onClick: () => this.props.copyLastDayHW(),
            });
        }
        return (
            <>
                <div className="top_main_block_setting">
                    <div className="top_setting">
                        <p>{t('HWSettings.topic')}</p>
                        <NativeSelect
                            id="topic"
                            onChange={this.changeSelect}
                            title="select topic"
                        >
                            {this.makeToOptionFromArray([
                                simple,
                                brother,
                                friend,
                                friend_brother,
                                multiplication,
                            ])}
                        </NativeSelect>
                    </div>

                    <div className="top_setting">
                        <p>{t('HWSettings.templates_name')}</p>
                        <NativeSelect
                            id="templatesName_select"
                            onChange={this.changeSelectTemplates}
                            title="select topic"
                        >
                            {this.makeToOption(
                                this.props.HW.templatesName || [],
                                'templatesName'
                            )}
                        </NativeSelect>
                    </div>
                    <div className="top_setting">
                        <p>{t('HWSettings.create_pattern')}</p>
                        <TextField
                            onChange={this.recordTemplateNameData}
                            name="templatesNameAdd"
                            type="text"
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        title={t('buttons.save')}
                                        onClick={this.createTemplateName}
                                    >
                                        <Add />
                                    </IconButton>
                                ),
                            }}
                            placeholder={t('HWSettings.enter_name')}
                        />
                    </div>
                    {averege_time !== 0 ? (
                        <div className="top_setting item">
                            <p>
                                <strong>
                                    {t('HWSettings.averege_time')}:{' '}
                                    <span>
                                        {averege_time}
                                        {t('HWSettings.minutes')}
                                    </span>
                                </strong>
                            </p>
                        </div>
                    ) : null}
                </div>
                <div className="tasks">
                    <AppBar position="static">
                        <Tabs
                            variant="scrollable"
                            value={value}
                            onChange={this.handleChange}
                        >
                            {days.map((item, key) => (
                                <Tab
                                    key={key}
                                    label={`${t('groups.day')} ${item}`}
                                />
                            ))}
                        </Tabs>
                    </AppBar>

                    <TabPanel index="0" key="0" value="0">
                        <ComponentTable
                            source={this.source}
                            typeTable="HW"
                            blockOptions={true}
                            selectable={true}
                            callBackDataHW={this.callBackDataHW}
                            actions={[
                                {
                                    title: t('HWSettings.copy'),
                                    onClick: (id) =>
                                        this.props._copyDataRow(id),
                                    Icon: PlaylistAddIcon,
                                },
                                {
                                    title: t('table.delete'),
                                    onClick: (id) =>
                                        this.props.openRequestModal({
                                            opened: true,
                                            data: {
                                                id: id,
                                                header: t('table.delete'),
                                                modaltype: 'DeleteHwModal',
                                            },
                                        }),
                                    Icon: Delete,
                                },
                            ]}
                            actionsButton={actionsButton}
                            toolbarvisible={true}
                        />
                    </TabPanel>
                </div>
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        HW: state.HW,
        AsyncTable: state.AsyncTable,
        form: state.form['from-tableComponent'],
    }),
    (dispatch) =>
        bindActionCreators(
            {
                setData,
                __GetData,
                initTemplatesName,
                __AddDataHWRow,
                saveHW,
                copyLastDayHW,
                saveHWName,
                openRequestModal,
                _copyDataRow,
                deleteAllHW,
                setLastTopic,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(HWSettings));
