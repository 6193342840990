import Types from "../constants/help";

const defaultState = {
  edit: null,
  show: null,
  permissions: null,
  choosen_page: null,
  langData: [],
  type: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.GET:
      return { ...state, ...action.help };
    default:
      return state;
  }
};
