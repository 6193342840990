import React from "react";
import i18n from "i18next";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import NativeSelect from "@material-ui/core/NativeSelect";
import store from "./../../../../../store";
// import { changeStudentGroup } from "./../../../../../actions/getUsers";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";

const processLang = (data) => {
  let save = null;
  if (data && Object.keys(data).length) {
    for (let item of Object.keys(i18n.store.data)) {
      if (data[item]) {
        save = { text: data[item], lang: item };
        break;
      }
    }
  }
  if (save && save.text) return `${save.text} (${save.lang})`;
  return `--${i18n.t("modals.no_transation")}--`;
};

const processString = (item, code) => {
  let lang = {
    ru: "0",
    ua: "1",
    en: "2",
  };
  if (typeof item == "string" && item.includes("|||")) {
    let text = item.split("|||")[lang[i18n.language]];
    if (!text) {
      for (let _item of item.split("|||")) {
        if (_item) text = _item;
      }
    }
    return text ? text.toString() : `--${i18n.t("modals.no_transation")}--`;
  }
  if (`${item}`.includes("{") && typeof JSON.parse(item) == "object") {
    const _item = JSON.parse(item);
    return _item[i18n.language] ? _item[i18n.language] : processLang(_item);
  }
  return item.toString();
};

const renderTextField = ({
  input,
  meta: { touched, error },
  i18n,
  ...custom
}) => {
  // eslint-disable-next-line
  const teachers =
    (store.getState().offices && store.getState().offices.list) || [];
  // const value = custom.data;
  // __InitDataHWRow({ [input.name]: custom.data })(store.dispatch, store.getState);
  // groups.unshift({ id: -1, teacher_id: -1, title: "choose_group"});
  return (
    <NativeSelect {...input} {...custom}>
      <option value="">{i18n.t("modals.choose_office")}</option>
      {teachers.map((item, key) => {
        return (
          <option key={key} value={item.id}>
            {`${processString(item.title, "school")}`}
          </option>
        );
      })}
    </NativeSelect>
  );
  // ChangeStudentGroup
};

export default ({ label, code }) => {
  const { i18n } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {i18n.t(label)}
        </Typography>
        <Field
          name="school"
          i18n={i18n}
          component={renderTextField}
          type="text"
        />
      </CardContent>
    </Card>
  );
};
