import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getOffices } from '../../actions/getOfficesList';
import { getOfficeGroups, clearOfficeGroups } from '../../actions/groups';
import { DAYS } from '../../constants/officeGroups';
import { officesGroupsAge } from '../../constants/group_constant';
import { AllHWTopic } from '../../constants/hw';
import { openRequestModal } from '../../actions/modals';
import './GroupsPage.css';

class ScheduleComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scheldule: false,
            autoclickcontrol: false,
            office: null,
        };
    }

    componentDidMount() {}

    componentWillUnmount() {
        this.props.clearOfficeGroups();
    }

    handleChange = () => {
        if (!this.state.scheldule) this.props.getOffices(false, { size: 9999 });
        this.setState({
            scheldule: !this.state.scheldule,
        });
    };

    handleClickAutocomplete = (data) => {
        this.setState({ [data]: true });
    };
    handleClickAway = (data) => {
        setTimeout(() => {
            this.setState({ [data]: false });
        }, 50);
    };

    handleAutocomplete = (newValue) => {
        this.setState({ office: newValue });
        if (newValue && newValue.id) {
            this.props.getOfficeGroups({ id: newValue.id, size: 9999 });
        }
    };

    processNextClass = (item) => {
        const schemaStatus = {
            1: 'yellow',
            2: 'light-gray',
            0: 'green',
            3: 'summer_intensive',
            5: 'cube',
            99: 'cube-inactive',
            regular: 'green',
        };
        if (+item.course_type === 1 && item.gather === 'true') {
            return 'cand_dark_yellow'; //ccc529
        }
        if (+item.course_type === 0 && item.gather === 'true') {
            return 'act_dark_green'; //85bb65
        }
        if (+item.course_type === 5 && item.gather === 'true') {
            return 'act_dark_blue'; //85bb65
        }
        if (+item.course_type === 99 && item.gather === 'true') {
            return 'act_dark_blue_second'; //85bb65
        }
        if (item.course_type === 'regular' && item.gather === 'true') {
            return 'act_dark_green'; //85bb65
        }
        return item.course_type ? schemaStatus[item.course_type] : '';
    };

    getTopic(item) {
        const topic = AllHWTopic.find((el) => +el.value === +item.topic);
        return topic && topic.label ? topic.label.replace(/\s/g, '') : '';
    }

    processSchedule() {
        const { t } = this.props;
        const groups = JSON.parse(
            JSON.stringify(this.props.groups.officeGroups)
        );
        let listedGroups = {};
        if (groups) {
            for (let item of groups) {
                if (+item.course_type === 5) {
                    const newData = JSON.parse(JSON.stringify(item));
                    newData.lesson_day = item.lesson_day_add;
                    newData.lesson_time = item.lesson_time_add;
                    newData.course_type = 99;
                    groups.push(newData);
                }
            }
            for (let item of groups) {
                if (!listedGroups[item.lesson_day]) {
                    listedGroups[item.lesson_day] = [item];
                } else {
                    listedGroups[item.lesson_day].push(item);
                }
            }
        }
        return (
            <ol className="officeGrops">
                {Object.keys(DAYS).map((el, index) => {
                    return (
                        <li key={index}>
                            <h3>{t(`groups.${DAYS[el]}`)}</h3>
                            <ul>
                                {Object.keys(listedGroups).length
                                    ? listedGroups[el] &&
                                      listedGroups[el]
                                          .sort(
                                              (a, b) =>
                                                  +new Date(
                                                      `2020-03-03 ${
                                                          a.lesson_time.split(
                                                              ':'
                                                          )[0]
                                                      }:${
                                                          a.lesson_time.split(
                                                              ':'
                                                          )[1]
                                                      }`
                                                  ) -
                                                  +new Date(
                                                      `2020-03-03 ${
                                                          b.lesson_time.split(
                                                              ':'
                                                          )[0]
                                                      }:${
                                                          b.lesson_time.split(
                                                              ':'
                                                          )[1]
                                                      }`
                                                  )
                                          )
                                          .map((item, i) => {
                                              return (
                                                  <li
                                                      className={this.processNextClass(
                                                          item
                                                      )}
                                                      key={index + i}
                                                      onClick={() =>
                                                          this.props.openRequestModal(
                                                              {
                                                                  opened: true,
                                                                  data: {
                                                                      id: item.id,
                                                                      header: t(
                                                                          'modals_name.edit'
                                                                      ),
                                                                      modaltype:
                                                                          'EditGroupModal',
                                                                  },
                                                              }
                                                          )
                                                      }
                                                  >
                                                      <div>
                                                          {item.teacher_id}
                                                      </div>
                                                      <div>{`${
                                                          item.lesson_time.split(
                                                              ':'
                                                          )[0]
                                                      }:${
                                                          item.lesson_time.split(
                                                              ':'
                                                          )[1]
                                                      }`}</div>
                                                      <div>
                                                          {item.numbers_student}
                                                      </div>
                                                      <div>
                                                          {t(
                                                              `${
                                                                  officesGroupsAge[
                                                                      +item.age
                                                                  ]
                                                              }`
                                                          )}
                                                      </div>
                                                      <div>
                                                          {this.getTopic(item)}
                                                      </div>
                                                  </li>
                                              );
                                          })
                                    : null}
                            </ul>
                        </li>
                    );
                })}
            </ol>
        );
    }

    processTranslation(item) {
        const { t, i18n } = this.props;
        const lang_schema = { ru: 0, ua: 1, en: 2, ro: 5, by: 0, ee: 2 };
        const arrayTitle = item.title.split('|||') || 'error name';
        let title = arrayTitle[lang_schema[i18n.language]];
        if (!title) {
            for (let el of arrayTitle) {
                if (el) {
                    title = `${el} (${t('modals.no_transation')})`;
                    break;
                }
            }
        }
        return title || t('modals.no_transation');
    }

    render() {
        const { t } = this.props;

        return (
            <Box
                component="div"
                className="animated fadeIn faster"
                style={{ marginBottom: '20px' }}
            >
                <Accordion expanded={this.state.scheldule}>
                    <AccordionSummary
                        onClick={() => this.handleChange()}
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography
                            component="div"
                            className="header_icon_title"
                        >
                            <span>
                                <EventNoteIcon />
                            </span>
                            <span>{t('groups.scheldule')}</span>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{ width: '100%' }}>
                            <ClickAwayListener
                                onClickAway={() =>
                                    this.handleClickAway('autoclickcontrol')
                                }
                            >
                                <Autocomplete
                                    open={this.state.autoclickcontrol}
                                    options={
                                        (this.props.offices &&
                                            this.props.offices.list) || [0]
                                    }
                                    getOptionLabel={(option) =>
                                        this.processTranslation(option) ||
                                        t('modals.no_transation')
                                    }
                                    style={{ width: '200px' }}
                                    value={this.state.office}
                                    onChange={(event, newValue) => {
                                        this.handleAutocomplete(newValue);
                                    }}
                                    loading={
                                        this.props.offices &&
                                        !this.props.offices.list
                                    }
                                    disabled={
                                        this.props.offices &&
                                        !this.props.offices.list
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('modals.choose_office')}
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {(this.props.offices &&
                                                            !this.props.offices
                                                                .list) ||
                                                        this.props.groups
                                                            .loading ? (
                                                            <CircularProgress
                                                                color="inherit"
                                                                size={20}
                                                            />
                                                        ) : null}
                                                        {
                                                            params.InputProps
                                                                .endAdornment
                                                        }
                                                    </React.Fragment>
                                                ),
                                            }}
                                            onClick={() => {
                                                if (
                                                    this.props.offices &&
                                                    this.props.offices.list
                                                ) {
                                                    this.handleClickAutocomplete(
                                                        'autoclickcontrol'
                                                    );
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </ClickAwayListener>
                            <Box component="div" style={{ width: '100%' }}>
                                {this.state.office
                                    ? this.processSchedule()
                                    : null}
                            </Box>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Box>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        offices: state.offices,
        groups: state.groups,
        AsyncTable: state.AsyncTable,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                getOffices,
                getOfficeGroups,
                clearOfficeGroups,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(ScheduleComponent));
