import React from "react";
import Typography from "@material-ui/core/Typography";
// import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import NativeSelect from "@material-ui/core/NativeSelect";
// import store from "./../../../../../store";
// import { changeStudentGroup } from "./../../../../../actions/getUsers";
import { useTranslation } from "react-i18next";

import { Field } from "redux-form";

const USER_STATUSES = [
  {
    id: "",
    title: "choose_status",
  },
  {
    id: 0,
    title: "ACTIVE",
  },
  {
    id: 1,
    title: "BLOCKED",
  },
  {
    id: 2,
    title: "RESERVED",
  },
  {
    id: 3,
    title: "ARCHIVED",
  },
  {
    id: 4,
    title: "ON_VACATION",
  },
  {
    id: 5,
    title: "NOT_CONFIRMED",
  },
  {
    id: 6,
    title: "CANDIDATE",
  },
  {
    id: 7,
    title: "GRADUATE",
  },
];

const renderTextField = ({
  input,
  meta: { touched, error },
  i18n,
  ...custom
}) => {
  return (
    <NativeSelect {...input} {...custom}>
      {USER_STATUSES.map((item, key) => {
        return (
          <option key={key} value={item.id}>
            {i18n.t("users." + item.title)}
          </option>
        );
      })}
    </NativeSelect>
  );
};

export default ({ label, code }) => {
  const { i18n } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {i18n.t(label)}
        </Typography>
        <Field
          name="status"
          i18n={i18n}
          component={renderTextField}
          type="text"
        />
      </CardContent>
    </Card>
  );
};
