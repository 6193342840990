export const simple = [
  {
    label: "S 2",
    value: 0,
  },
  {
    label: "S 3",
    value: 1,
  },
  {
    label: "S 4",
    value: 2,
  },
  {
    label: "S 5",
    value: 3,
  },
  {
    label: "S 6",
    value: 4,
  },
  {
    label: "S 7",
    value: 5,
  },
  {
    label: "S 8",
    value: 6,
  },
  {
    label: "S 9",
    value: 7,
  },
];
export const brother = [
  {
    label: "B 4",
    value: 8,
  },
  {
    label: "B 3",
    value: 9,
  },
  {
    label: "B 2",
    value: 10,
  },
  {
    label: "B 1",
    value: 11,
  },
];

export const friend = [
  {
    label: "F 9",
    value: 12,
  },

  {
    label: "F 8",
    value: 13,
  },

  {
    label: "F 7",
    value: 14,
  },

  {
    label: "F 6",
    value: 15,
  },

  {
    label: "F 5",
    value: 16,
  },

  {
    label: "F 4",
    value: 17,
  },

  {
    label: "F 3",
    value: 18,
  },

  {
    label: "F 2",
    value: 19,
  },

  {
    label: "F 1",
    value: 20,
  },
];

export const friend_brother = [
  {
    label: "FB 6",
    value: 21,
  },

  {
    label: "FB 7",
    value: 22,
  },

  {
    label: "FB 8",
    value: 23,
  },

  {
    label: "FB 9",
    value: 24,
  },
];

export const multiplication = [
  {
    label: "M 2",
    value: 53,
  },
  {
    label: "M 11",
    value: 61,
  },
  {
    label: "M 12",
    value: 62,
  },
  {
    label: "M 5",
    value: 56,
  },
  {
    label: "M 6",
    value: 57,
  },
  {
    label: "M 7",
    value: 58,
  },
  {
    label: "M 9",
    value: 60,
  },
  {
    label: "M 8",
    value: 59,
  },
  {
    label: "M 4",
    value: 55,
  },
  {
    label: "M 3",
    value: 54,
  },
];

export const AllHWTopic = [
  {
    label: "S 2",
    value: 0,
  },
  {
    label: "S 3",
    value: 1,
  },
  {
    label: "S 4",
    value: 2,
  },
  {
    label: "S 5",
    value: 3,
  },
  {
    label: "S 6",
    value: 4,
  },
  {
    label: "S 7",
    value: 5,
  },
  {
    label: "S 8",
    value: 6,
  },
  {
    label: "S 9",
    value: 7,
  },
  {
    label: "B 4",
    value: 8,
  },
  {
    label: "B 3",
    value: 9,
  },
  {
    label: "B 2",
    value: 10,
  },
  {
    label: "B 1",
    value: 11,
  },
  {
    label: "F 9",
    value: 12,
  },

  {
    label: "F 8",
    value: 13,
  },

  {
    label: "F 7",
    value: 14,
  },

  {
    label: "F 6",
    value: 15,
  },

  {
    label: "F 5",
    value: 16,
  },

  {
    label: "F 4",
    value: 17,
  },

  {
    label: "F 3",
    value: 18,
  },

  {
    label: "F 2",
    value: 19,
  },

  {
    label: "F 1",
    value: 20,
  },

  {
    label: "FB 6",
    value: 21,
  },

  {
    label: "FB 7",
    value: 22,
  },

  {
    label: "FB 8",
    value: 23,
  },

  {
    label: "FB 9",
    value: 24,
  },
  {
    label: "M 2",
    value: 53,
  },
  {
    label: "M 11",
    value: 61,
  },
  {
    label: "M 12",
    value: 62,
  },
  {
    label: "M 5",
    value: 56,
  },
  {
    label: "M 6",
    value: 57,
  },
  {
    label: "M 7",
    value: 58,
  },
  {
    label: "M 9",
    value: 60,
  },
  {
    label: "M 8",
    value: 59,
  },
  {
    label: "M 4",
    value: 55,
  },
  {
    label: "M 3",
    value: 54,
  },
];
