import React from "react";
import { withStyles } from "@material-ui/core/styles";
// import { SERVER_ROOT } from "./../../../config";

const styled = withStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 50,
        height: 50,
        marginTop: -14,
        marginBottom: -14,
        // marginTop: -6,
        // marginBottom: -6,
        marginLeft: "auto"
    },
    image: {
        maxWidth: "100%"
    }
}));
// this.props.value ? SERVER_ROOT + this.props.value : "/img/empty_image.png"
// nextProps.value ? SERVER_ROOT + nextProps.value : "/img/empty_image.png"
class Image extends React.Component {
    state = {
        value: 1
    };

    onError = error =>
        this.setState({
            value: "/img/empty_image.png"
        });

    componentWillReceiveProps(nextProps) {
        this.setState({
            value: 1
        });
    }

    render() {
        const { value } = this.state;
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <img alt="" src={value} onError={this.onError} className={classes.image} />
            </div>
        );
    }
}

export default styled(Image);
