import { Collapse, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import LtvService from '../services/ltv.service';
import { useTranslation } from 'react-i18next';
import store from '../store';
import { useSelector } from 'react-redux';

const LTV = () => {
    const { t } = useTranslation();
    const [ltv, setLtv] = useState(null);
    const user = useSelector((state) => state.user);

    const fetchData = async () => {
        const role_id = user.emulation.length
            ? user.emulation[user.emulation.length - 1].info.role_id
            : user.info.role_id;
        let res = null;
        if (role_id === 2 || role_id === 3) {
            res = await LtvService.GetLTV();
        }
        if (res && res.ltv) {
            setLtv(res.ltv.toLocaleString('en-US'));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Collapse in={ltv !== null}>
            <Typography
                gutterBottom
                component="h6"
                style={{ fontSize: '14px', marginBottom: '15px' }}
            >
                {t('payments.ltv')}:{' '}
                <span className={`cityPrice ok`}>{ltv}</span>
            </Typography>
        </Collapse>
    );
};

export default LTV;
