import Types from './../constants/discounts';

const defaultState = {
    list: [],
    price: null,
    discountAmount: null,
    loading: false,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.SINGLE:
            return { ...state, ...action.discounts };
        case Types.CLEAR_DISCOUNTS:
            return { ...defaultState };
        default:
            return state;
    }
};
