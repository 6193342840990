import React, { useMemo } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import PhoneInput from 'react-phone-number-input';
import store from './../../../../../store';
import { Input } from '@mui/material';
import { processCountryPhone } from '../../../../../actions/phone';
import { useTranslation } from 'react-i18next';

export default ({ input, type, action, meta: { touched, error } }) => {
    const { t } = useTranslation();
    const { info } = store.getState().user;
    const roleId = useMemo(() => {
        return info.role_id;
    }, []);
    const domainLast =
        document.location.hostname.split('.')[
            document.location.hostname.split('.').length - 1
        ];
    return (
        <FormControl
            error={Boolean(touched && error)}
            variant="outlined"
            fullWidth
        >
            <Input
                {...input}
                placeholder={t('modals.phone')}
                type={type}
                endAdornment={action}
            />

            {/* {roleId === 10 ? (
      ) : (
        <PhoneInput
          international
          countryCallingCodeEditable={true}
          defaultCountry={processCountryPhone()}
          limitMaxLength
          {...input}
        />
      )} */}

            {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};
