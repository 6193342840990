import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import GroupsService from '../../services/groups.service';
import SCHEMAS from '../../constants/modalSchemas';
import { openRequestModal } from '../../actions/modals';
import { TasksCalendar } from '../../actions/hw';
import { getSingleGroup, clearSingleGroup } from '../../actions/groups';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import HistoryIcon from '@material-ui/icons/History';
import { emulation } from '../../actions/user';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import { sendMailToAllStudents } from '../../actions/payments';
import TimelineIcon from '@material-ui/icons/Timeline';
import Paper from '@material-ui/core/Paper';
import PaymentIcon from '@material-ui/icons/Payment';
import {
    Delete,
    Edit,
    ExitToApp,
    Add,
    VideogameAsset,
    ChevronLeft,
    MonetizationOn,
} from '@material-ui/icons';

import { withTranslation } from 'react-i18next';

import GradientIcon from '@mui/icons-material/Gradient';
import './main.min.css';
import './main0.min.css';
import './style.css';

import ModelService from './../../services/models.service';
import ComponentTable from '../../components/ComponentTable';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { toast } from 'react-toastify';
import Skeleton from '@material-ui/lab/Skeleton';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import HomeworkService from './../../services/homework.service';
import { IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import EditIcon from '@material-ui/icons/Edit';
import { age, statuses } from '../../constants/group_constant';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BankCustomersService from '../../services/bank-customers.service';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { getCurrentCurrency } from '../../actions/currencies';
import { withWidth, isWidthDown } from '@material-ui/core';

const currency = getCurrentCurrency();
class GroupSinlePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            bankStudents: [],
            bankStudentsVip: [],
        };
    }
    componentDidMount() {
        this.props.clearSingleGroup();
        this.constructGroupData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.modalWindow.requestmodal.data &&
            prevProps.modalWindow.requestmodal.data.modaltype ===
                'EditGroupModal' &&
            !this.props.modalWindow.requestmodal.data
        ) {
            this.props.clearSingleGroup();
            this.constructGroupData();
        }
        const data =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;
        let vacant = [];
        if (data && data.length) {
            for (let item of data) {
                if (item.status === 6) vacant.push(item);
            }
            if (
                data.length === vacant.length &&
                this.props.HW.calendar_tasks &&
                this.props.HW.calendar_tasks.length
            ) {
                this.removeAllHw();
            }
        }
    }

    async removeAllHw() {
        await HomeworkService.anull(false, {
            group_id: this.props.match.params.id,
        });
        this.props.TasksCalendar(this.props.match.params.id);
    }
    constructGroupData = async () => {
        const calendar = await this.props.TasksCalendar(
            this.props.match.params.id
        );
        if (calendar && calendar.redirect) {
            this.props.history.push(calendar.redirect);
        }

        this.props.getSingleGroup(this.props.match.params.id);
        const bankStudents = await BankCustomersService.GetGroupActiveStudents({
            courseId: this.props.match.params.id,
        });
        this.setState({ bankStudents });

        const bankStudentsVip = await BankCustomersService.GetGroupVipStudents({
            courseId: this.props.match.params.id,
        });
        this.setState({ bankStudentsVip });
    };

    handleBack = () => {
        this.props.history.push('/groups');
    };
    handleOverlap = (id) => {
        const date = id.start;
        let time = `${date.getFullYear()}-${
            date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1)
                : date.getMonth() + 1
        }-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
        this.props.history.push(
            '/groups/' + this.props.match.params.id + '/hw/' + time
        );
    };

    getDate(value) {
        const notParsed = new Date(value);
        const d = new Date(
            +new Date(notParsed) + notParsed.getTimezoneOffset() * 1000 * 60
        );
        const _month =
            d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
        const _date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

        return `${d.getFullYear()}-${_month}-${_date}`;
    }

    eventRenderContent() {
        return <></>;
    }

    handleDateSelect = async (e) => {
        const { t } = this.props;
        let table_data =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;
        let days = parseInt(
            (+new Date(e.endStr) - +new Date(e.startStr)) / 1000 / 60 / 60 / 24
        );
        if (days > 14) return toast.info(t('groups.max_days'));
        if (!table_data.length) return toast.error(t('groups.empty'));
        this.props.openRequestModal({
            opened: true,
            data: {
                id: this.props.match.params.id,
                date: {
                    from: e.startStr,
                    to: this.getDate(+new Date(e.endStr) - 86400000),
                },
                days,
                group: this.props.single,
                note: this.props.single.note || '',
                header: t('groups.create_hw'),
                modaltype: 'AddNewHwModal',
            },
        });
    };
    render() {
        // Пушимо іконку для банку тільки для role_id === 1
        // const isUserIdOne = true;
        // console.log(isUserIdOne);

        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        const { t, i18n } = this.props;

        const actions = [
            {
                title: t('sidebar.discounts'),
                onClick: (id, user) => {
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: `${t('sidebar.discounts')} | ${
                                user.first_name
                            } ${user.last_name}`,
                            user_status: user.status,
                            modaltype: 'EditDiscountsModal',
                        },
                    });
                },
                Icon: MonetizationOn,
            },
            {
                title: t('bracelets.Bracelet Control'),
                bracelet: true,
                onClick: (id, user) => {
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: `${t('bracelets.Bracelet Control')} | ${
                                user.first_name
                            } ${user.last_name}`,
                            modaltype: 'EditStudentBracelet',
                        },
                    });
                },
                Icon: GradientIcon,
                outOfMenu: true,
            },

            {
                title: t('groups.assign_book'),
                onClick: (id, user) => {
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            // eslint-disable-next-line
                            header: `${t('groups.assign_book')} | ${
                                user.first_name
                            } ${user.last_name}`,
                            modaltype: 'AssignBookModal',
                        },
                    });
                },
                Icon: MenuBookIcon,
            },
            {
                title: t('groups.stat'),
                onClick: (id, user) => {
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            // eslint-disable-next-line
                            header: `${t('groups.stat')} | ${user.first_name} ${
                                user.last_name
                            }`,
                            modaltype: 'SpeedTableModal',
                        },
                    });
                },
                Icon: EqualizerIcon,
                outOfMenu: true,
            },
            {
                title: t('groups.ar_cards'),
                onClick: (id, user) => {
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: user.id,
                            // eslint-disable-next-line
                            header: `${t('groups.ar_cards')} | ${
                                user.first_name
                            } ${user.last_name}`,
                            modaltype: 'EditARCardsModal',
                        },
                    });
                },
                Icon: VideogameAsset,
                outOfMenu: true,
            },
            {
                title: t('modals_name.edit'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: t('modals_name.edit'),
                            modaltype: 'EditUserModal',
                            role_id: 4,
                        },
                    }),
                Icon: Edit,
            },

            {
                title: t('table.emulation'),
                onClick: (id) =>
                    this.props.emulation({
                        id,
                        path: window.location.pathname,
                    }),
                Icon: ExitToApp,
            },
        ];

        const topActionButtons = [
            {
                title: t('HWSettings.remove_hw'),
                Icon: Delete,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            header: t('HWSettings.remove_hw'),
                            modaltype: 'removeHWModal',
                            course_id: this.props.match.params.id,
                        },
                    }),
            },
            {
                title: i18n.t('groups.enter_message'),
                Icon: MailOutlineIcon,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: {
                                data: {
                                    group_id:
                                        this.props.single &&
                                        this.props.single.id,
                                },
                            },
                            note: this.props.single && this.props.single.note,
                            header: i18n.t('groups.enter_message'),
                            modaltype: 'SendMessageModal',
                        },
                    }),
            },
            {
                title: t('payments.send_bill'),
                Icon: PaymentIcon,
                onClick: () =>
                    this.props.sendMailToAllStudents({
                        group_id: this.props.match.params.id,
                    }),
            },
            {
                title: t('HWSettings.copy'),
                Icon: ControlPointDuplicateIcon,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            header: t('HWSettings.copy'),
                            modaltype: 'CopyHwGroupOtherModal',
                            course_id: this.props.match.params.id,
                            date: new Date(),
                        },
                    }),
            },

            {
                title: t('groups.HW_change_history'),
                Icon: TimelineIcon,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: this.props.match.params.id,
                            header: t('groups.HW_change_history'),
                            modaltype: 'GroupHistoryHw',
                        },
                    }),
            },
            {
                title: t('groups.group_status_history'),
                Icon: HistoryIcon,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: this.props.match.params.id,
                            header: t('groups.group_status_history'),
                            modaltype: 'GroupHistoryModal',
                        },
                    }),
            },
            {
                title: t('sorokoinsForHomework.title'),
                Icon: AttachMoneyIcon,
                // style: {color: 'error'},
                // className: 'red-mail-icon',
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: this.props.match.params.id,
                            header: t('sorokoinsForHomework.header'),
                            modaltype: 'SorokoinsForHomework',
                        },
                    }),
            },
            {
                title: t('activeTransactions.title'),
                Icon: CurrencyExchangeIcon,
                // style: {color: 'error'},
                // className: 'red-mail-icon',
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: this.props.match.params.id,
                            header: t('activeTransactions.title'),
                            modaltype: 'ActiveTransactions',
                        },
                    }),
            },
            {
                title: t('orderedProducts.title'),
                Icon: ShoppingCartIcon,
                // style: {color: 'error'},
                // className: 'red-mail-icon',
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: this.props.match.params.id,
                            header: t('orderedProducts.title'),
                            modaltype: 'OrderedProducts',
                        },
                    }),
            },
            {
                title: t('table.add_student'),
                Icon: Add,
                onClick: () =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            schema: SCHEMAS.addUser,
                            header: t('table.add_student'),
                            modaltype: 'AddStudentModal',
                            course_id: this.props.match.params.id,
                        },
                    }),
            },
        ];

        let table_data =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;
        if (table_data && !table_data.length) delete topActionButtons[0];
        if (role_id && role_id === 3) delete topActionButtons[0];
        const { width } = this.props;
        const mobile = isWidthDown('sm', width);

        return (
            <Fragment>
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={2}
                    mb={2}
                    flexWrap={mobile ? 'wrap' : 'unset'}
                >
                    <Button
                        onClick={this.handleBack}
                        startIcon={<ChevronLeft />}
                        // variant={'outlined'}
                    >
                        <Typography>
                            {this.props.single && this.props.single.title ? (
                                this.props.single.title.split('|')[0]
                            ) : (
                                <Skeleton variant="text" width="150px" />
                            )}
                        </Typography>
                    </Button>
                    <Typography style={{ width: 'auto' }}>
                        {this.props.single ? (
                            t(
                                statuses.find(
                                    (el) =>
                                        el.value ===
                                        Number(this.props.single.course_type)
                                ).label
                            )
                        ) : (
                            <Skeleton variant="text" width="100px" />
                        )}
                    </Typography>
                    <Typography style={{ width: 'auto' }}>
                        {this.props.single ? (
                            t(
                                age.find(
                                    (el) =>
                                        el.value ===
                                        Number(this.props.single.age)
                                ).label
                            )
                        ) : (
                            <Skeleton variant="text" width="50px" />
                        )}
                    </Typography>

                    <Typography style={{ width: 'auto' }}>
                        {this.props.single ? (
                            `${this.props.single.price.toLocaleString()} ${currency}`
                        ) : (
                            <Skeleton variant="text" width="100px" />
                        )}
                    </Typography>
                    <IconButton
                        onClick={() =>
                            this.props.openRequestModal({
                                opened: true,
                                data: {
                                    id: this.props.match.params.id,
                                    header: t('modals_name.edit'),
                                    modaltype: 'EditGroupModal',
                                },
                            })
                        }
                    >
                        <EditIcon fontSize={'small'} className="ArrowBack" />
                    </IconButton>
                </Stack>
                <ComponentTable
                    source={{
                        getData: (page, size, sort) =>
                            GroupsService.getPupilsOfGroups(
                                false,
                                {
                                    page: 0,
                                    size: 9999,
                                    orderBy: (sort && sort.orderBy) || null,
                                    order: (sort && sort.order) || null,
                                },
                                { group_id: this.props.match.params.id }
                            ),
                        getModel: () =>
                            ModelService.GroupsSingleList(false, false),
                    }}
                    blockOptions={true}
                    actions={actions}
                    selectable={true}
                    enableFilter={false}
                    title={t('table.users_list')}
                    enableSettings
                    toolbarvisible={true}
                    typeTable="singleGroupPage"
                    actionsButton={topActionButtons}
                    bankStudents={this.state.bankStudents}
                    bankStudentsVip={this.state.bankStudentsVip}
                />
                <br />
                {role_id !== 3 ? (
                    <Paper className="calendar_hw">
                        <FullCalendar
                            locale={
                                i18n.language === 'ua' ? 'uk' : i18n.language
                            }
                            defaultView="dayGridMonth"
                            plugins={[dayGridPlugin, interactionPlugin]}
                            selectable={true}
                            select={this.handleDateSelect}
                            selectOverlap={this.handleOverlap}
                            events={this.props.HW.calendar_tasks}
                            eventContent={this.eventRenderContent}
                            longPressDelay={500}
                        />
                        <Divider />
                    </Paper>
                ) : null}
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        HW: state.HW,
        AsyncTable: state.AsyncTable,
        single: state.groups && state.groups.single,
        modalWindow: state.modalWindow,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                emulation,
                TasksCalendar,
                sendMailToAllStudents,
                getSingleGroup,
                clearSingleGroup,
            },
            dispatch
        )
);
export default withTranslation()(widthConnect(withWidth()(GroupSinlePage)));
