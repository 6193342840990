import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import TypeWrapper from './TypeWrapper';
import i18n from 'i18next';
import store from './../../../store';

import BodyActionButtons from '../BodyActionButtons';
import MobileBody from './MobileBody';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import VerifiedIcon from '@mui/icons-material/Verified';
import usePhoneCheck from '../../../hooks/usePhoneCheck';

import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

const schemaStatus = {
    1: 'yellow',
    2: 'light-gray',
    0: 'green',
    3: 'summer_intensive',
    regular: 'green',
};

const processNextClass = (item) => {
    if (+item.course_type === 1 && item.gather === 'true') {
        return 'cand_dark_yellow'; //ccc529
    }
    if (+item.course_type === 0 && item.gather === 'true') {
        return 'act_dark_green'; //85bb65
    }
    if (item.course_type === 'regular' && item.gather === 'true') {
        return 'act_dark_green'; //85bb65
    }
    return item.course_type ? schemaStatus[item.course_type] : '';
};

const processPaymentsClass = (row) => {
    if (row.status_payments === 2) return 'payments_red'; //#ff00003b
    if (row.status_payments === 1) return 'payments_yellow'; //#ff00003b
    if (row.status_payments === 0) return 'payments_green'; //#ff00003b
    return '';
};

const processClassName = (typeTable, row) => {
    if (typeTable === 'payments') return processPaymentsClass(row);
    if (typeTable === 'single_hw') return processNextClass(row);
    if (typeTable === 'HW') {
        const isHWNew = typeof row.id === 'string' ? ' newHw' : '';
        let style = row.eabacus
            ? `sequance_type_eabacus${isHWNew}`
            : `sequance_type_mind${isHWNew}`;
        if (row.can_edit) return (style += ` cant_edit_row${isHWNew}`);
        return style;
    }
    return '';
};

const styled = withStyles(() => ({
    actions: {
        whiteSpace: 'nowrap',
        '& > *': {
            margin: -12,
            '&:not(:first-child)': {
                marginLeft: 12,
            },
        },
    },
    image: {
        maxWidth: '100%',
    },
}));

const Body = ({
    classes,
    fetching,
    isEmpty,
    model,
    data,
    rowsCount,
    actions = [],
    typeTable,
    payType,
    paySum,
    userData,
    goldUser,
    bankStudents,
    bankStudentsVip,
}) => {
    const mobile = usePhoneCheck();

    const roleId = useMemo(() => {
        const {
            user: {
                info: { role_id },
            },
        } = store.getState();
        return role_id;
    }, []);

    // ++++++++++++++++++++++++++++++++++++++

    // +++++++++++++++++++++++++++++++++++++

    if (mobile) {
        return (
            <MobileBody
                fetching={fetching}
                data={data}
                typeTable={typeTable}
                processClassName={processClassName}
                model={model}
                actions={actions}
                classes={classes}
                userData={userData}
                paySum={paySum}
                payType={payType}
                goldUser={goldUser}
                roleId={roleId}
                bankStudents={bankStudents}
                bankStudentsVip={bankStudentsVip}
            />
        );
    }
    return (
        <TableBody>
            {fetching &&
                Array(typeTable === 'relocate' ? 2 : rowsCount)
                    .fill(0)
                    .map((e, key) => (
                        <TableRow key={key}>
                            <TableCell>
                                <Skeleton />
                            </TableCell>
                        </TableRow>
                    ))}

            {!fetching &&
                !!data.length &&
                data.map((row, key) => {
                    let isStudentInBank = false;
                    let isStudentVip = false;
                    const singleGroupPage = typeTable === 'singleGroupPage';
                    const isHwFinished =
                        !goldUser &&
                        typeTable === 'HW' &&
                        row.played_date !== null;
                    if (
                        singleGroupPage &&
                        bankStudents &&
                        bankStudents.length
                    ) {
                        isStudentInBank = bankStudents.includes(row.id);
                    }
                    if (
                        singleGroupPage &&
                        bankStudentsVip &&
                        bankStudentsVip.length
                    ) {
                        isStudentVip = bankStudentsVip.includes(row.id);
                    }
                    return (
                        <TableRow
                            key={key}
                            className={`${processClassName(typeTable, row)}${
                                isHwFinished ? ' settingFinished' : ''
                            }`}
                            hover
                        >
                            {singleGroupPage && (
                                <TableCell
                                    key={`bank-status-${key}`}
                                    align={'left'}
                                >
                                    {isStudentInBank ? (
                                        <Tooltip
                                            title={
                                                'Студент зареєстрований у СороБанк'
                                            }
                                        >
                                            <VerifiedIcon
                                                style={{
                                                    color: isStudentVip
                                                        ? '#FF501C'
                                                        : '#2ca10a',
                                                }}
                                            />
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title={
                                                'Студент НЕ зареєстрований у СороБанк'
                                            }
                                        >
                                            <UnpublishedIcon />
                                        </Tooltip>
                                    )}
                                </TableCell>
                            )}

                            {model.map(({ code, type, label, raw }, index) => {
                                if (code === 'hw_age' && goldUser) return null;
                                return raw !== false ? (
                                    <TableCell
                                        key={index}
                                        align={
                                            index !== model.length
                                                ? 'left'
                                                : 'right'
                                        }
                                        style={{
                                            position:
                                                index === 0
                                                    ? 'relative'
                                                    : 'unset',
                                        }}
                                    >
                                        {isHwFinished && index === 0 ? (
                                            <Box
                                                style={{
                                                    position: 'absolute',
                                                    left: '0',
                                                    top: '0',
                                                    pointerEvents: 'all',
                                                    cursor: 'alias',
                                                }}
                                            >
                                                <Tooltip
                                                    title={i18n.t(
                                                        'table.hw_finished'
                                                    )}
                                                >
                                                    <InfoIcon />
                                                </Tooltip>
                                            </Box>
                                        ) : null}
                                        <TypeWrapper
                                            allcell={data}
                                            data={row}
                                            code={code}
                                            temporarily={row.temporarily}
                                            type={type}
                                            id={row.id}
                                            value={row[code]}
                                            label={label}
                                            roleId={roleId}
                                        />
                                    </TableCell>
                                ) : null;
                            })}
                            {typeTable === 'payments' && userData !== 5 && (
                                <>
                                    <TableCell align="right">
                                        <NativeSelect
                                            id="payment_type"
                                            style={{ minWidth: '100px' }}
                                            value={
                                                row.payment_type
                                                    ? row.payment_type
                                                    : row.pay_type === 'cash'
                                                    ? '0'
                                                    : '1'
                                            }
                                            onChange={(e) =>
                                                payType({
                                                    id: row.id,
                                                    payment_type:
                                                        e.target.value,
                                                })
                                            }
                                        >
                                            <option value="0">
                                                {i18n.t('payments.cash')}
                                            </option>
                                            <option value="1">
                                                {i18n.t('payments.cashless')}
                                            </option>
                                        </NativeSelect>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        onChange={(e) => {
                                            if (e.target.value < 0)
                                                return (e.target.value = '');
                                            paySum({
                                                id: row.id,
                                                payment_sum: e.target.value,
                                            });
                                        }}
                                    >
                                        <TextField
                                            name="payment_sum"
                                            style={{ maxWidth: '70px' }}
                                            type="number"
                                            defaultValue={row.payment_sum}
                                        />
                                    </TableCell>
                                </>
                            )}
                            <BodyActionButtons
                                classes={classes}
                                actions={actions}
                                row={row}
                            />
                        </TableRow>
                    );
                })}

            {!fetching && isEmpty && (
                <TableRow>
                    <TableCell>По вашему запросу ничего не найдено</TableCell>
                </TableRow>
            )}
        </TableBody>
    );
};

export default styled(Body);
