import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import './Sidebar.css';
import { withTranslation } from 'react-i18next';
import logo_ua from '../../resources/img/logo/ua.png';
import logo_ru from '../../resources/img/logo/ru.png';
import logo_en from '../../resources/img/logo/en.png';
import logo_ee from '../../resources/img/logo/ее.png';
import logo_ro from '../../resources/img/logo/ro.png';
import logo_by from '../../resources/img/logo/by.png';
import { NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appLinks } from '../../constants/navLinks';
import { Button, Tooltip, useMediaQuery } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const logo = {
    ru: logo_ru,
    ua: logo_ua,
    en: logo_en,
    ee: logo_ee,
    ro: logo_ro,
    by: logo_by,
};

const setCollapseState = (state) => {
    localStorage.setItem('isCollapsed', JSON.stringify(state));
};

const getMenuCollapseStorage = () => {
    const menuCollapseStorage = localStorage.getItem('isCollapsed');
    if (menuCollapseStorage) {
        try {
            return JSON.parse(menuCollapseStorage);
        } catch (error) {
            console.log(error);
            return false;
        }
    }
};

const mobile = window.innerWidth <= 768;

const Sidebar = (props) => {
    const { t, i18n } = props;
    const [isCollapsed, setIsCollapsed] = useState(getMenuCollapseStorage());

    const userPermissions = useMemo(() => {
        return [
            ...(props.user &&
            props.user.emulation &&
            props.user.emulation.length
                ? props.user.emulation[props.user.emulation.length - 1]
                      .permission
                : props.user.permission),
            ...['books-stat'],
        ];
    }, [props.user.emulation, props.user.permission]);

    const pathName = useMemo(() => {
        return props.router.location.pathname.split('/')[1];
    }, [props.router.location]);

    const checkPermission = useMemo(() => {
        return userPermissions.indexOf(pathName) !== -1;
    }, [userPermissions, pathName]);

    const permissionMenuItem = useMemo(() => {
        let userPermissions =
            props.user && props.user.emulation && props.user.emulation.length
                ? props.user.emulation[props.user.emulation.length - 1]
                      .permission
                : props.user.permission;
        let displayedItems = [];
        for (let i = 0; i < userPermissions.length; i++) {
            displayedItems[i] = appLinks[userPermissions[i]];
        }

        return displayedItems;
    }, [props.user.emulation, props.user.permission]);

    const menuCheckboxReloader = useCallback(() => {
        document.getElementById('menu__toggle').checked = false;
    }, []);

    const toggleMenu = () => {
        setIsCollapsed(!isCollapsed);
    };

    useEffect(() => {
        setCollapseState(isCollapsed);
    }, [isCollapsed]);

    useEffect(() => {
        if (mobile) {
            setIsCollapsed(false);
        }
    }, []);

    return checkPermission ? (
        <Fragment>
            <div
                className={`sidebar-wrapper ${isCollapsed ? 'collapsed' : ''}`}
            >
                <div className="hamburger-menu">
                    <input id="menu__toggle" type="checkbox" />
                    <label className="menu__btn" htmlFor="menu__toggle">
                        <span />
                    </label>
                    <div
                        className="black_back"
                        onClick={menuCheckboxReloader}
                    ></div>
                    <div
                        className={`sidebar-container menu__box ${
                            isCollapsed ? 'collapsed' : ''
                        }`}
                    >
                        <div className="header_logo">
                            <img src={logo[i18n.language]} alt="Soroban" />
                        </div>

                        <ul className="sidebar-list">
                            {(permissionMenuItem &&
                                permissionMenuItem.map((link, index) => {
                                    if (!link || !link.parent) {
                                        return '';
                                    }
                                    const linkContent = isCollapsed ? (
                                        <Tooltip
                                            enterDelay={500}
                                            placement="right"
                                            title={t(`${link.title}`)}
                                        >
                                            {link.icon}
                                        </Tooltip>
                                    ) : (
                                        <Fragment>
                                            {link.icon}
                                            {t(`${link.title}`)}
                                        </Fragment>
                                    );

                                    if (
                                        link &&
                                        link.parent &&
                                        link.parent.target
                                    ) {
                                        return (
                                            <a
                                                key={index}
                                                {...link.parent}
                                                onClick={menuCheckboxReloader}
                                            >
                                                <li>{linkContent}</li>
                                            </a>
                                        );
                                    }
                                    if (
                                        link &&
                                        link.parent &&
                                        link.parent.onClick
                                    ) {
                                        return (
                                            <div key={index} {...link.parent}>
                                                <li>{linkContent}</li>
                                            </div>
                                        );
                                    }
                                    return (
                                        <NavLink
                                            key={index}
                                            {...link.parent}
                                            onClick={menuCheckboxReloader}
                                        >
                                            <li>{linkContent}</li>
                                        </NavLink>
                                    );
                                })) ||
                                null}
                        </ul>
                        {!mobile ? (
                            <Button
                                onClick={toggleMenu}
                                style={{
                                    color: ' #606060',
                                    fontWeight: '800',
                                    fontSize: '13px',
                                    marginBottom: '10px',
                                    marginLeft: '13px',
                                    justifyContent: 'flex-start',
                                    borderRadius: '7px',
                                    padding: '10px',
                                }}
                                startIcon={
                                    isCollapsed ? (
                                        <ArrowRightIcon
                                            style={{ marginRight: '8px' }}
                                        />
                                    ) : (
                                        <ArrowLeftIcon
                                            style={{ marginRight: '8px' }}
                                        />
                                    )
                                }
                            >
                                {isCollapsed ? '' : t('Згорнути меню')}
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
        </Fragment>
    ) : (
        <Redirect activeClassName="is-active" to="/dashboard" />
    );
};

const widthConnect = connect(
    (state) => ({ user: state.user, router: state.router }),
    (dispatch) => bindActionCreators({}, dispatch)
);

export default withTranslation()(widthConnect(Sidebar));
