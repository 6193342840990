import Types from './../constants/user';
import AuthService from './../services/auth.service';
import UsersService from './../services/users.service';
import StudentsService from './../services/students.service';
import base64url from 'base64url';
import TypesAsyncTable from './../constants/AsyncTable';
import { reloadTable } from './AsyncTable';
import i18n from 'i18next';
import { closeRequestModal } from './modals';
import store from '../store';

import history from './../history';
import { __ClearAllBankData } from './bank';
import CurrenciesService from '../services/currencies.service';

import { STATUSES } from '../constants/statuses';
const showCurrentStatus = (status, { t, i18n }) => {
    const selectedStatus = STATUSES.find((item) => item.id === Number(status));
    return t(`users.${selectedStatus.title || ''}`) || '';
};

const tokenTransformToInfo = (token) => {
    const middleSplitToken = token.split('.')[1];
    return JSON.parse(base64url.decode(middleSplitToken));
};

async function countCreatedStudent() {
    return await StudentsService.CountCreatedStudent();
}

function getNearStudentsBirthday(data) {
    return async () => {
        return await StudentsService.BirthdayList(data);
    };
}

function getDiscountsCount() {
    return async () => {
        return await StudentsService.DiscountsCount();
    };
}

function issueArCard(args) {
    return async () => {
        return await UsersService.issueArCard(args);
    };
}

function deleteRequest(...args) {
    return (dispatch, getState) => {
        UsersService.RelocateReqestDelete(...args).then(() => {
            closeRequestModal()(dispatch, getState);
            reloadTable()(dispatch, getState);
        });
    };
}

function confirmTransfer(...args) {
    return (dispatch, getState) => {
        closeRequestModal()(dispatch, getState);
        UsersService.RelocateConfirm(...args).then(() => {
            reloadTable()(dispatch, getState);
        });
    };
}

function sendRelocateRequest(args) {
    return async (dispatch, getState) => {
        return await UsersService.RelocateSendRequest(args).then(() => {
            reloadTable()(dispatch, getState);
        });
    };
}

function setNewHwHeight(item = null) {
    return (dispatch, getState) => {
        setTimeout(() => {
            const { newHwHeight } = getState().user;
            const doc = document.querySelector('.base-container');
            doc.scrollTop =
                item !== null ? doc.scrollHeight + 200 : newHwHeight;
            dispatch({
                type: Types.SETPROFILE,
                user: { newHwHeight: item !== null ? item : null },
            });
        }, 100);
    };
}

function setLoader(item) {
    return (dispatch) => {
        dispatch({
            type: Types.SETPROFILE,
            user: { loader: item },
        });
    };
}

function getStatuses(...args) {
    return () => {
        return StudentsService.GetStudentsStatuses(...args);
    };
}

function GetArCards(...args) {
    return () => {
        return StudentsService.GetArCards(...args);
    };
}

function getSingleArCards(...args) {
    return () => {
        return StudentsService.GetSingleARCards(...args);
    };
}

function getArcards() {
    return () => UsersService.GetARCards();
}

function getStatysticsData(...data) {
    return async () => {
        return await UsersService.CallStatisticsData(...data);
    };
}

function getStatistics(...data) {
    return (dispatch) => {
        UsersService.GetStatisticsService(...data).then((response) => {
            try {
                dispatch({
                    type: Types.STATISTICS,
                    user: {
                        statistics: response,
                    },
                });
            } catch (err) {
                console.log(err);
            }
        });
    };
}

function removeStatistics() {
    return (dispatch, getState) => {
        const { user } = getState();
        try {
            dispatch({
                type: Types.STATISTICS,
                user: {
                    ...user,
                    statistics: {},
                },
            });
        } catch (err) {
            console.log(err);
        }
    };
}

function checkOldApi(data) {
    return async () => {
        try {
            return await AuthService.OldApiCheck(data);
        } catch (err) {
            console.log(err);
        }
    };
}

function logIn(data) {
    return async (dispatch, getState) => {
        const { user } = getState();
        try {
            const response = await AuthService.Login(data);
            dispatch({
                type: Types.LOGIN,
                user: {
                    ...user,
                    multiprofile: response.multiprofile,
                    transactionKey: response.transactionKey,
                    loginView: response.status,
                },
            });
            return response;
        } catch (err) {
            console.log(err);
        }
    };
}

function handleResetView(item) {
    return (dispatch) => {
        dispatch({
            type: Types.RESETVIEW,
            user: {
                resetPass: !item,
            },
        });
    };
}

function setUserTheme(theme) {
    return (dispatch) => {
        const { user } = store.getState();
        const data = {
            user: {
                ...user,
                theme: theme ? 'dark' : 'white',
            },
        };

        const storageNotVerified = localStorage.getItem('globalStore');
        const storage = storageNotVerified
            ? JSON.parse(storageNotVerified)
            : { user: {} };
        localStorage.setItem(
            'globalStore',
            JSON.stringify({ ...storage, ...data })
        );

        localStorage.setItem('theme', data.user.theme);
        dispatch({
            type: Types.SETPROFILE,
            ...data,
        });
    };
}

function setProfile({ id, balance }) {
    return (dispatch, getState) => {
        const { user } = getState();
        AuthService.SelectProfile({
            id: id,
            transactionKey: user.transactionKey,
        }).then(async (response) => {
            const dataInfoToken = tokenTransformToInfo(response.token) || {};
            const storageNotVerified = localStorage.getItem('globalStore');
            const storage = storageNotVerified
                ? JSON.parse(storageNotVerified)
                : { user: {} };
            const info = dataInfoToken.info;
            const country_id =
                dataInfoToken &&
                dataInfoToken.info &&
                dataInfoToken.info.country_id;
            let countryCurrency = null;
            if (country_id) {
                countryCurrency = await CurrenciesService.GetCurrency({
                    id: country_id,
                });
            }
            const lang =
                window.location.hostname.split('.')[
                    window.location.hostname.split('.').length - 1
                ];
            info.user_id = dataInfoToken.user_id;
            info.balance = balance;
            const themeColor = localStorage.getItem('theme');
            if (!themeColor) localStorage.setItem('theme', 'white');
            const data = {
                user: {
                    ...storage.user,
                    ...user,
                    access_token: response.token,
                    refresh_token: response.refresh_token,
                    loggedin: response.status,
                    transactionKey: user.transactionKey,
                    loginView: null,
                    multiprofile: [],
                    info: info,
                    permission: dataInfoToken.permission,
                    animation: 0,
                    theme: localStorage.getItem('theme'),
                    currency: countryCurrency,
                },
            };
            localStorage.setItem(
                'globalStore',
                JSON.stringify({ ...storage, ...data })
            );
            const schemaLangs = {
                com: 'en',
                md: 'ro',
            };
            if (!localStorage.getItem('lang'))
                localStorage.setItem(
                    'lang',
                    schemaLangs[lang] ? schemaLangs[lang] : lang
                );
            i18n.changeLanguage(localStorage.getItem('lang'));
            dispatch({
                type: Types.SETPROFILE,
                ...data,
            });
        });
    };
}

function setUserDevice(state) {
    return (dispatch) => {
        dispatch({
            type: Types.SET_DEVICE_WIDTH,
            payload: state,
        });
    };
}

function resume(response) {
    return (dispatch, getState) => {
        const { user } = getState();
        const { isEmulationTokenUpdateProcess } = user;
        const dataInfoToken = tokenTransformToInfo(response.token) || {};
        const storageNotVerified = localStorage.getItem('globalStore');
        const storage = storageNotVerified
            ? JSON.parse(storageNotVerified)
            : { user: {} };
        dataInfoToken.info.user_id = dataInfoToken.user_id;
        const data = {
            user: {
                ...storage.user,
                ...user,
                access_token: response.token,
                loggedin: response.status,
                transactionKey: null,
                loginView: null,
                multiprofile: [],
                info: dataInfoToken.info,
                permission: dataInfoToken.permission,
                isRefreshTokenUpdateProcess: false,
                isEmulationTokenUpdateProcess: isEmulationTokenUpdateProcess
                    ? false
                    : isEmulationTokenUpdateProcess,
            },
        };
        localStorage.setItem(
            'globalStore',
            JSON.stringify({ ...storage, ...data })
        );
        dispatch({
            type: Types.RESUME,
            ...data,
        });
    };
}
function emulation(data) {
    return (dispatch, getState) => {
        const { user } = getState();
        const path = data.path;
        return AuthService.Emulation(data).then((response) => {
            if (!response) return;
            const storageNotVerified = localStorage.getItem('globalStore');
            const storage = storageNotVerified
                ? JSON.parse(storageNotVerified)
                : { user: {} };
            const dataInfoToken = tokenTransformToInfo(response.token) || {};
            const info = dataInfoToken.info;
            info.user_id = dataInfoToken.user_id;

            let chechEmulationHave = null;
            for (let i in user.emulation) {
                if (user.emulation[i].info.role_id === info.role_id)
                    chechEmulationHave = i;
            }
            const data = {};

            if (chechEmulationHave !== null) {
                user.emulation[chechEmulationHave] = {
                    user_id: dataInfoToken.user_id,
                    token: response.token,
                    info: info,
                    permission: dataInfoToken.permission,
                };
                Object.assign(data, {
                    user: {
                        ...storage.user,
                        ...user,
                        emulation: user.emulation,
                        isEmulationTokenUpdateProcess: false,
                    },
                });
            } else {
                Object.assign(data, {
                    user: {
                        ...storage.user,
                        ...user,
                        emulation: [
                            ...user.emulation,
                            {
                                user_id: dataInfoToken.user_id,
                                token: response.token,
                                info: info,
                                permission: dataInfoToken.permission,
                                path,
                            },
                        ],
                        isEmulationTokenUpdateProcess: false,
                    },
                });
            }

            localStorage.setItem(
                'globalStore',
                JSON.stringify({ ...storage, ...data })
            );
            dispatch({
                type: Types.EMULATION,
                ...data,
            });
            return true;
        });
    };
}

function emulationBack() {
    return (dispatch, getState) => {
        const { user, AsyncTable } = getState();
        const path = user.emulation[user.emulation.length - 1].path;
        let user_id = user.emulation[user.emulation.length - 1].info.user_id;
        if (!!user.emulation.length) user_id = `${user_id}_emul`;
        let custom_filter = AsyncTable.table.custom_filter;
        const storageNotVerified = localStorage.getItem('globalStore');
        const storage = storageNotVerified
            ? JSON.parse(storageNotVerified)
            : { user: {} };
        const data = {
            user: {
                ...storage.user,
                ...user,
                emulation: [
                    ...user.emulation
                        // eslint-disable-next-line
                        .map((el, index) => {
                            if (index !== user.emulation.length - 1) return el;
                        })
                        .filter(Boolean),
                ],
            },
        };
        localStorage.setItem(
            'globalStore',
            JSON.stringify({ ...storage, ...data })
        );
        if (custom_filter && custom_filter[user_id]) {
            delete custom_filter[user_id];
            dispatch({
                type: TypesAsyncTable.DATA,
                payload: {
                    custom_filter,
                },
            });
        }
        dispatch({
            type: Types.EMULATION,
            ...data,
        });
        history.push(path || '/dashboard');
        reloadTable()(dispatch, getState);
    };
}

function logOut() {
    return (dispatch, getState) => {
        localStorage.setItem('globalStore', JSON.stringify({}));
        const { user, AsyncTable } = getState();
        let custom_filter = AsyncTable.table.custom_filter;
        const emulation = user.emulation.length;
        dispatch({
            type: Types.SETPROFILE,
            user: {
                animation: 1,
            },
        });
        if (custom_filter && emulation) {
            for (let item of user.emulation) {
                const user_id = item.info.user_id;
                if (custom_filter[`${user_id}_emul`])
                    delete custom_filter[`${user_id}_emul`];
            }
            dispatch({
                type: TypesAsyncTable.DATA,
                payload: {
                    custom_filter,
                },
            });
        }
        setTimeout(() => {
            dispatch({
                type: Types.LOGOUT,
            });
            dispatch(__ClearAllBankData());
        }, 300);
    };
}

export {
    logIn,
    logOut,
    handleResetView,
    setProfile,
    resume,
    emulation,
    emulationBack,
    getStatistics,
    removeStatistics,
    getArcards,
    getSingleArCards,
    getStatysticsData,
    getStatuses,
    GetArCards,
    setLoader,
    deleteRequest,
    confirmTransfer,
    sendRelocateRequest,
    issueArCard,
    getDiscountsCount,
    getNearStudentsBirthday,
    checkOldApi,
    countCreatedStudent,
    setNewHwHeight,
    setUserTheme,
    showCurrentStatus,
    setUserDevice,
};
