const EsorobanIcon = () => {
    return (
        <svg
            className="MuiSvgIcon-root menu-icon"
            data-name="Слой 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
        >
            <path
                className="cls-1"
                d="M277.13,113.06c0,31.75,49.11,49.9,83.18,49.9,19.64,0,36-.67,53-9,36.94-18.15,28.78-50.23-3.55-67.61C354.8,56.79,277.13,85,277.13,113.06Z"
            />
            <path
                className="cls-1"
                d="M265,271.86c0,32.41,52.6,48.39,78.64,48.39,27.77,0,81.67-.3,81.67-39.32,0-35.65-82-62.72-134.62-39.33C280.06,246.34,265,256.81,265,271.86Z"
            />
            <path
                className="cls-1"
                d="M55.54,285.47c0,32.08,56,48.39,89.23,48.39,66.69,0,99-45.62,40.88-75.66C142.13,235.72,55.54,244.65,55.54,285.47Z"
            />
            <path
                className="cls-1"
                d="M75.2,123.64c0,19.3,9.69,29.88,29,39.05,31,14.7,76,18.23,107.62,2.3C228.6,156.54,234,153.68,234,134.23c0-17.3-19.6-28.82-31.73-34.81C174.15,85.52,132,83.26,102,96,91.59,100.34,75.2,110.38,75.2,123.64Z"
            />
            <path
                className="cls-1"
                d="M52.71,409.75c0,27.79,54.69,48.4,83.18,48.4,20.22,0,35.14-1,52.68-9.33,23.57-11.16,33.56-32.52,14.31-51.61-20-19.84-54.25-26.78-83.63-26.78C96.42,370.43,52.71,382.82,52.71,409.75Z"
            />
            <path
                className="cls-1"
                d="M256,377.72c0,28.37,58,49.91,89.23,49.91,67.59,0,97.91-47.22,41.88-75.15-29.73-14.82-77.89-17.94-107.94-3C269,354.51,256,365.83,256,377.72Z"
            />
            <path
                className="cls-1"
                d="M38.91,181.11c0,9.46,75.1,46.89,132.43,46.89,90.63,0,107.32-18.15,176.58-18.15,24.06,0,47.05,2.46,66.92,6.93,13.33,3,28.86,8.07,39.75,14.5,5.35,3.16,6.33,4.54,12.18,7.3,10.68-14.19,7.59-19.33-7.44-28.15-36.3-21.29-85.79-29.32-133.48-29.32-54.08,0-89.34,19.66-142.62,19.66-37.76,0-77.41-.71-111.36-18.77-4.33-2.31-19.24-11.47-22.78-11.47C44.3,170.53,38.91,176.83,38.91,181.11Z"
            />
            <path
                className="cls-1"
                d="M316.17,456.33c-.29,4.14,2.05,16.45,3.23,18.26,3.44,5.29,16.66,4.46,24.27,4.46.22-9.9,3-24.38,3-34.79a117.06,117.06,0,0,0-28.73-3Z"
            />
            <path
                className="cls-1"
                d="M143.26,67.68a184.1,184.1,0,0,0,19.91,3.12c6,.42,9.3,2.22,10.28-13.76.33-5.53,1.77-19.73.25-21.21-2.15-2.1-16.91-2.93-21.36-2.93-4.19,6.25-4.63,4.56-6.7,14.48A89,89,0,0,0,143.26,67.68Z"
            />
        </svg>
    );
};

export default EsorobanIcon;
