import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    CircularProgress,
    IconButton,
    Input,
    TextField,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ExpandMore, Add, Delete, Save } from '@mui/icons-material';
import { getCurrenciesList } from '../../actions/currencies';

const CurrencyControl = () => {
    const [touched, setTouched] = useState(false);
    const [toggle, setToggle] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [currencies, setCurrencies] = useState([]);
    const [loading, setLoading] = useState(true);
    const handleChange = (value) => {
        if (!loaded) setLoaded(true);
        setToggle(toggle !== '' ? '' : value);
    };

    useEffect(() => {
        if (loaded) {
            fetchData();
        }
    }, [loaded]);

    const fetchData = async () => {
        setLoading(true);
        const res = await getCurrenciesList();
        setCurrencies(res.list);
        setLoading(false);
    };

    const handleChangeTextField = (index, update) => {
        return;
        if (!touched) setTouched(true);
        const result = JSON.parse(JSON.stringify(currencies));
        result[index] = update;
        setCurrencies(result);
    };

    const handleAdd = () => {
        setCurrencies((prev) => [
            ...prev,
            {
                code: '',
                key: prev.length + 1,
                num: '',
            },
        ]);
    };

    const handleSave = () => {};

    const handleDelete = (index) => {
        return;
        if (!touched) setTouched(true);
        const result = JSON.parse(JSON.stringify(currencies));
        delete result[index];
        setCurrencies(result.filter(Boolean));
    };

    return (
        <Accordion expanded={toggle === '1'} onChange={() => handleChange('1')}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography
                    style={{
                        margin: '0',
                    }}
                    gutterBottom
                    variant="body1"
                >
                    Керування валютами
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {loading ? <CircularProgress size={20} /> : null}
                    {!loading && currencies && currencies.length ? (
                        <>
                            {currencies.map((el, index) => {
                                return (
                                    <Box
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            marginBottom: '12px',
                                        }}
                                    >
                                        <TextField
                                            placeholder={'Назва валюти'}
                                            value={el.code}
                                            onChange={(e) =>
                                                handleChangeTextField(index, {
                                                    ...el,
                                                    code: e.target.value,
                                                })
                                            }
                                            style={{ marginRight: '8px' }}
                                            type={'text'}
                                        />
                                        <TextField
                                            label={'ISO Код'}
                                            value={el.num}
                                            style={{ marginRight: '8px' }}
                                            onChange={(e) =>
                                                handleChangeTextField(index, {
                                                    ...el,
                                                    num: e.target.value,
                                                })
                                            }
                                            type={'number'}
                                        />
                                        {/*<IconButton*/}
                                        {/*    onClick={() => handleDelete(index)}*/}
                                        {/*>*/}
                                        {/*    <Delete />*/}
                                        {/*</IconButton>*/}
                                    </Box>
                                );
                            })}
                        </>
                    ) : null}
                    {/*<Button*/}
                    {/*    style={{*/}
                    {/*        marginBottom: '12px',*/}
                    {/*    }}*/}
                    {/*    endIcon={<Add />}*/}
                    {/*    onClick={handleAdd}*/}
                    {/*>*/}
                    {/*    Додати*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    style={{*/}
                    {/*        marginBottom: '12px',*/}
                    {/*    }}*/}
                    {/*    variant={'contained'}*/}
                    {/*    endIcon={<Save />}*/}
                    {/*    onClick={handleSave}*/}
                    {/*    disabled={!touched}*/}
                    {/*>*/}
                    {/*    Зберегти*/}
                    {/*</Button>*/}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

export default CurrencyControl;
