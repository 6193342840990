import React from "react";
import i18n from "i18next";

const days_schema = {
  "0": "mon",
  "1": "tue",
  "2": "wed",
  "3": "thu",
  "4": "fri",
  "5": "sat",
  "6": "sun",
};

export default ({ data, id, value, code, temporarily }) => {
  return (
    <div
      style={{
        display: "block",
        whiteSpace: "nowrap",
        maxWidth: "30px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      title={i18n.t(`groups.min.${days_schema[value]}`)}
      children={i18n.t(`groups.min.${days_schema[value]}`)}
    />
  );
};
