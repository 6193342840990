import Types from '../constants/finance';
import TypesCities from '../constants/getList';
import TypesCountries from '../constants/countries';
import CitiesService from '../services/cities.service';
import CountriesList from '../services/countries.service';
import FinanceService from '../services/finance.service';

function getShortAnalytics(data) {
    return async () => {
        return await FinanceService.getShortFinanceData(data);
    };
}

function getBossShortAnalytics(data) {
    return async () => {
        return await FinanceService.getBossShortFinanceData(data);
    };
}

function getBossFinanceData(data) {
    return async () => {
        return await FinanceService.getBossFinanceData(data);
    };
}

function getCitiesFromSelect(...args) {
    return async function (dispatch, getState) {
        try {
            const response = await CitiesService.CitiesList(false, {
                size: 9999,
            });
            const { finance, cities } = getState();
            response.list.sort((a, b) => (a.last_name > b.last_name ? 1 : -1));
            dispatch({
                type: Types.CITY,
                finance: {
                    ...finance,
                    // countries
                    cities: response.list.map((el) => ({
                        title: el.title,
                        parent_id: el.parent_id,
                    })),
                },
            });
            dispatch({
                type: TypesCities.CITIES,
                cities: {
                    ...cities,
                    ...response,
                },
            });
            return response;
        } catch (e) {
            console.log(e);
        }
    };
}

function selectedCountries(id) {
    return async function (dispatch, getState) {
        try {
            const { finance } = getState();
            dispatch({
                type: Types.SELECT_COUNTRY,
                finance: {
                    ...finance,
                    selectes_country: id,
                },
            });
        } catch (e) {
            console.log(e);
        }
    };
}

function getCountryFromSelect(...args) {
    return async function (dispatch, getState) {
        try {
            const { finance } = getState();
            const response = await CountriesList.CountriesList(false, {
                size: 9999,
            });
            response.list.sort((a, b) => (a.last_name > b.last_name ? 1 : -1));
            dispatch({
                type: Types.COUNTRY,
                finance: {
                    ...finance,
                    countries: response.list.map((el) => ({
                        title: el.title,
                        id: el.id,
                    })),
                },
            });
            dispatch({
                type: TypesCountries.GETCOUNTRIES,
                countries: response,
            });
            getCitiesFromSelect(...args)(dispatch, getState);
            return response;
        } catch (e) {
            console.log(e);
        }
    };
}

export {
    getCitiesFromSelect,
    selectedCountries,
    getCountryFromSelect,
    getShortAnalytics,
    getBossShortAnalytics,
    getBossFinanceData,
};
