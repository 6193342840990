import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { closeRequestModal, openRequestModal } from '../../actions/modals';
import { bindActionCreators } from 'redux';
import ComponentTable from '../../components/ComponentTable';

import { getUsers } from '../../actions/getUsers';
import { getGroupsFromSelect } from '../../actions/groups';
import StudentsService from './../../services/students.service';
import { Edit, ExitToApp, MonetizationOn } from '@material-ui/icons';
import { emulation } from '../../actions/user';
import ModelService from './../../services/models.service';
import { withTranslation } from 'react-i18next';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { getTeachersFromSelect } from '../../actions/groups';
import IconButton from '@material-ui/core/IconButton';
import MenuBookIcon from '@material-ui/icons/MenuBook';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { apiService } from '../../services/requestV2.service';

class StudentsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
        };
    }

    componentDidMount() {
        this.props.getTeachersFromSelect(false, { size: 9999 });
    }

    componentWillUnmount() {
        apiService.cancelAllRequests();
    }

    handleBack() {
        this.props.history.push('/dashboard');
    }
    render() {
        // Перевірка id користувача
        const { user } = this.props;
        // console.log(user.info.role_id)
        // const isUserIdOne = user.info.role_id === 1;
        // const isUserIdOne = true;

        const { t } = this.props;
        const table =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;

        const actions = [
            {
                title: t('modals_name.edit'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            role_id: 5,
                            header: t('modals_name.edit'),
                            modaltype: 'EditUserModal',
                        },
                    }),
                Icon: Edit,
            },
            {
                title: t('sidebar.discounts'),
                onClick: (id) => {
                    const user = table.find((el) => el.id === id);
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: `${t('sidebar.discounts')} | ${
                                user.first_name
                            } ${user.last_name}`,
                            user_status: user.status,
                            modaltype: 'EditDiscountsModal',
                        },
                    });
                },
                Icon: MonetizationOn,
            },
            {
                title: 'SOROBANK',
                onClick: (id) => {
                    this.props.history.push(`/students/bank/${id}/student`);
                },
                Icon: AttachMoneyIcon,
            },
            {
                title: t('groups.assign_book'),
                onClick: (id) => {
                    const user = table.find((el) => el.id === id);
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            // eslint-disable-next-line
                            header: `${t('groups.assign_book')} | ${
                                user.first_name
                            } ${user.last_name}`,
                            modaltype: 'AssignBookModal',
                        },
                    });
                },
                Icon: MenuBookIcon,
            },
            {
                // title: t('table.log_in_as'),
                title: t('table.emulation'),
                onClick: (id) =>
                    this.props.emulation({
                        id,
                        path: window.location.pathname,
                    }),
                Icon: ExitToApp,
            },
        ];

        // якщо id = 1, додамо іконку банку

        // if (isUserIdOne) {
        //     actions.push({
        //         title: 'bank SOROBAN',
        //         onClick: (id) => {
        //             console.log(id);
        //             this.props.history.push(`/students/bank/${id}`);

        //             // return this.props.openRequestModal({
        //             //   opened: true,
        //             //   data: {
        //             //     id: id,
        //             //     header: `${t("sidebar.discounts")} | ${user.first_name} ${
        //             //       user.last_name
        //             //     }`,
        //             //     user_status: user.status,
        //             //     modaltype: "EditDiscountsModal",
        //             //   },
        //             // });
        //         },
        //         Icon: AttachMoneyIcon,
        //     });
        // }

        const actionsNewStudents = [
            {
                title: t('modals_name.edit'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            role_id: 5,
                            header: t('modals_name.edit'),
                            modaltype: 'EditUserModal',
                        },
                    }),
                Icon: Edit,
            },
        ];
        const params = this.props.match.params.type;
        return (
            <Fragment>
                <h2 className="container-header">
                    {params === 'discounts' ||
                    params === 'birthdate' ||
                    params === 'day' ||
                    params === 'week' ||
                    this.state.filter ? (
                        <IconButton
                            aria-label="add to favorites"
                            className="delete_icon"
                            onClick={() => this.handleBack()}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                    ) : null}
                    {params === 'discounts' ||
                    params === 'birthdate' ||
                    params === 'day' ||
                    params === 'week'
                        ? `${t('sidebar.students')} | ${
                              params === 'discounts'
                                  ? t('sidebar.discounts')
                                  : params === 'day' || params === 'week'
                                  ? t('dashboard.new_students')
                                  : t('dashboard.birth_date')
                          }`
                        : t('sidebar.students')}
                </h2>
                {params === 'discounts' ? (
                    <ComponentTable
                        source={{
                            firstLoad: () =>
                                this.props.getGroupsFromSelect(false, {
                                    size: 9999,
                                }),
                            getData: (page, size, sort, filter) =>
                                StudentsService.DiscountList(false, {
                                    page: page || 0,
                                    size: size || 10,
                                    orderBy: (sort && sort.orderBy) || null,
                                    order: (sort && sort.order) || null,
                                    filter: filter,
                                }),
                            getModel: () =>
                                ModelService.StudentsDiscount(false, {
                                    role_id: 5,
                                }),
                        }}
                        actions={actions}
                        selectable={true}
                        enableFilter
                        title={t('sidebar.students')}
                        enableSettings
                        toolbarvisible={true}
                    />
                ) : null}
                {params === 'birthdate' ? (
                    <ComponentTable
                        source={{
                            firstLoad: () =>
                                this.props.getGroupsFromSelect(false, {
                                    size: 9999,
                                }),
                            getData: (page, size, sort, filter) =>
                                StudentsService.BirthdayList(false, {
                                    page: page || 0,
                                    size: size || 10,
                                    orderBy: (sort && sort.orderBy) || null,
                                    order: (sort && sort.order) || null,
                                    filter,
                                }),
                            getModel: () =>
                                ModelService.StudentsBirthday(false, {
                                    role_id: 5,
                                }),
                        }}
                        actions={actions}
                        selectable={true}
                        enableFilter
                        title={t('dashboard.birth_date')}
                        enableSettings
                        toolbarvisible={true}
                    />
                ) : null}
                {params === 'day' || params === 'week' ? (
                    <ComponentTable
                        source={{
                            firstLoad: () =>
                                this.props.getGroupsFromSelect(false, {
                                    size: 9999,
                                }),
                            getData: (page, size, sort, filter) =>
                                StudentsService.NewStudentsCreated(false, {
                                    page: page || 0,
                                    size: size || 10,
                                    orderBy: (sort && sort.orderBy) || null,
                                    order: (sort && sort.order) || null,
                                    filter,
                                    type: params,
                                }),
                            getModel: () =>
                                ModelService.CreatedStudents(false, {
                                    role_id: 5,
                                }),
                        }}
                        actions={actionsNewStudents}
                        selectable={true}
                        enableFilter
                        headerTable={
                            params === 'day'
                                ? t(`dashboard.per_day`)
                                : t(`dashboard.per_week`)
                        }
                        enableSettings
                        toolbarvisible={true}
                    />
                ) : null}
                {!params ? (
                    <ComponentTable
                        source={{
                            firstLoad: () =>
                                this.props.getGroupsFromSelect(false, {
                                    size: 9999,
                                }),
                            getData: (page, size, sort, filter) =>
                                StudentsService.StudentsList(false, {
                                    page: page || 0,
                                    size: size || 10,
                                    orderBy:
                                        (sort && sort.orderBy) || 'last_name',
                                    order: (sort && sort.order) || 'asc',
                                    filter: filter,
                                }),
                            getModel: () =>
                                ModelService.StudentsList(false, {
                                    role_id: 5,
                                }),
                        }}
                        actions={actions}
                        selectable={true}
                        enableFilter
                        typeTable="iconicStudents"
                        title={t('sidebar.students')}
                        enableSettings
                        toolbarvisible={true}
                    />
                ) : null}
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        modalWindow: state.modalWindow,
        users: state.users,
        AsyncTable: state.AsyncTable,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                closeRequestModal,
                getUsers,
                emulation,
                getTeachersFromSelect,
                getGroupsFromSelect,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(StudentsPage));
