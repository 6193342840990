// eslint-disable-next-line
import { ApiConnector, GET, POST, DELETE } from "./requestV2.service";

export default (() => {
  const API = ApiConnector("/models");
  return {
    UsersList: async (data, query) =>
      await API(GET, "/users", false, query).call(data),
    StudentsList: async (data, query) =>
      await API(GET, "/students", false, query).call(data),
    GroupsList: async (data, query) =>
      await API(GET, "/groups", false, query).call(data),
    GroupsSingleList: async (data, query) =>
      await API(GET, "/groups/single", false, query).call(data),
    EbacusList: async () => await API(GET, "/esoroban/abacus").call(false),
    StudentFromListListFranch: async () =>
      await API(GET, "/payment/franch").call(false),
    StudentFromListListTeacher: async () =>
      await API(GET, "/payment/teacher").call(false),
    HomeworkList: async (data) =>
      await API(GET, "/homework", false, data).call(false),
    CountriesList: async (data) =>
      await API(GET, "/countries", false).call(data),
    CitiesList: async (data) => await API(GET, "/cities", false).call(data),
    OfficesList: async (data) => await API(GET, "/offices", false).call(data),
    StudentsPayments: async (data) =>
      await API(GET, "/payment/student", false).call(data),
    DiscountsList: async (data) =>
      await API(GET, "/discounts", false).call(data),
    JournalModels: async (data) =>
      await API(GET, "/hwjournal", false).call(data),
    DebtModels: async (data) => await API(GET, "/debtreliev", false).call(data),
    StudentsDiscount: async (data) =>
      await API(GET, "/students/discount", false).call(data),
    StudentsBirthday: async (data) =>
      await API(GET, "/students/birth", false).call(data),
    CreatedStudents: async (data) =>
      await API(GET, "/students/created").call(data),
    Finance: async (data) => await API(GET, "/finance/city").call(data),
    CustomTasks: async (data) => await API(GET, "/custom-task").call(data),
    SupportModels: async (data) =>
      await API(GET, "/students/support").call(data),
    BooksAnalyticsModels: async (data) =>
      await API(GET, "/book/students").call(data),
  };
})();
