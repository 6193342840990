import React from 'react';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/NativeSelect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setProfile, logOut } from '../../actions/user';
import { getErrorNotification } from '../../actions/toast';
import { ArrowBack } from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import { STATUSES } from '../../constants/statuses';
import { withTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

class SelectProfileLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load: false,
            length: false,
            select: null,
        };
    }

    componentDidMount() {
        this.checkOneProfile();
    }
    handleProfile = (e) => {
        e.preventDefault();
        const { t } = this.props;
        const profile = this.props.user.multiprofile[this.state.select || 0];
        if (profile.role_id === 5 && +profile.status > 1) {
            const status = t(`users.${STATUSES[+profile.status].title}`);
            return getErrorNotification(`${t(`error.access_denied`)}${status}`);
        }
        this.setState({ load: true });
        this.props.setProfile(profile);
    };
    checkOneProfile() {
        const { t } = this.props;
        let item = this.props.user.multiprofile;

        if (item.length === 1) {
            if (item[0].role_id === 5 && +item[0].status > 1) {
                const status = t(`users.${STATUSES[+item[0].status].title}`);
                getErrorNotification(`${t(`error.access_denied`)}${status}`);
                return;
            }
            setTimeout(() => {
                this.setState({ length: true });
                setTimeout(() => this.props.setProfile(item[0]), 50);
            }, 1000);
        }
    }
    handleBack = () => {
        this.props.logOut();
    };
    render() {
        const { t, i18n } = this.props;
        const lang_schema = { ru: 0, ua: 1, en: 2, ro: 5, by: 0, ee: 2 };
        const handleSelect = (e) => {
            this.setState({
                select: e.target.value,
            });
        };
        return (
            <>
                <div
                    className={
                        this.state.length
                            ? 'main_container reset select animated fadeOutLeft faster'
                            : 'main_container reset select animated fadeInRight faster'
                    }
                    style={{ alignItems: 'flex-start' }}
                >
                    <Stack spacing={2} style={{ width: '100%' }}>
                        <ArrowBack
                            className="arrowBack"
                            onClick={this.handleBack}
                        />
                        <Select
                            name="roleSelect"
                            placeholder="Role"
                            onChange={handleSelect}
                            className="inputs"
                            fullWidth
                        >
                            {this.props.user.multiprofile &&
                            this.props.user.multiprofile.length
                                ? this.props.user.multiprofile.map(
                                      (profile, index) => {
                                          return (
                                              <option key={index} value={index}>
                                                  {profile.first_name}{' '}
                                                  {profile.last_name} -{' '}
                                                  {
                                                      profile.role.split('|||')[
                                                          lang_schema[
                                                              i18n.language
                                                          ]
                                                      ]
                                                  }
                                              </option>
                                          );
                                      }
                                  )
                                : null}
                        </Select>
                        <Button
                            onClick={this.handleProfile}
                            variant={'contained'}
                            color={'primary'}
                            fullWidth
                            disabled={
                                this.props.user.multiprofile &&
                                this.props.user.multiprofile.length === 1
                            }
                        >
                            {this.state.load ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : (
                                t('login.title')
                            )}
                        </Button>
                    </Stack>
                </div>
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({ user: state.user }),
    (dispatch) => bindActionCreators({ logOut, setProfile }, dispatch)
);

export default withTranslation()(widthConnect(SelectProfileLogin));
