import FopService from "../services/fop.service";

export function createFopBill(data) {
    return async (dispatch, getState) => {
      return await FopService.CreateFopBill(data)
    };
}

export function editFopBill(data) {
    return async (dispatch, getState) => {
      return await FopService.EditFopBill(data)
    };
}

export function getFopBillList(data) {
    return async (dispatch, getState) => {
      return await FopService.GetFopBillList(data)
    };
}

export function deleteFopBill(data) {
    return async (dispatch, getState) => {
      return await FopService.DeleteFopBill(data)
    };
}
