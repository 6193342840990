import Types from './../constants/modalWindows';
import TypesGroups from '../constants/groups';

function swap(item) {
    return (dispatch, getState) => {
        dispatch({
            type: Types.SET,
            modalWindow: {
                switch: item,
            },
        });
    };
}

function openModal() {
    return (dispatch, getState) => {
        const { modalWindow } = getState();

        dispatch({
            type: Types.OPEN,
            modalWindow: {
                ...modalWindow,
                usermenu: true,
            },
        });
    };
}

function closeModal() {
    return (dispatch, getState) => {
        const { modalWindow } = getState();

        dispatch({
            type: Types.CLOSE,
            modalWindow: {
                ...modalWindow,
                usermenu: false,
            },
        });
    };
}

function SetEdit(obj) {
    return (dispatch, getState) => {
        const { modalWindow } = getState();
        dispatch({
            type: Types.SET,
            modalWindow: {
                ...modalWindow,
                requestmodal: {
                    ...modalWindow.requestmodal,
                    data: {
                        ...modalWindow.requestmodal.data,
                        payload: {
                            ...modalWindow.requestmodal.data.payload,
                            ...obj,
                        },
                    },
                },
            },
        });
    };
}
function openRequestModal(data) {
    return (dispatch, getState) => {
        const { modalWindow } = getState();
        if (data.data.callback) {
            data.data.callback().then((response) => {
                data.data.payload = response;
                dispatch({
                    type: Types.OPEN,
                    modalWindow: {
                        ...modalWindow,
                        usermenu: false,
                        requestmodal: { ...data },
                    },
                });
            });
        } else
            dispatch({
                type: Types.OPEN,
                modalWindow: {
                    ...modalWindow,
                    requestmodal: { ...data },
                },
            });
    };
}

function closeRequestModal() {
    return (dispatch, getState) => {
        const { modalWindow } = getState();
        dispatch({
            type: Types.CLOSE,
            modalWindow: {
                ...modalWindow,
                requestmodal: false,
            },
        });
        
        // Обнулення стану priceError після закриття модального вікна
        dispatch({
            type: TypesGroups.SET_PRICE_ERROR, 
            payload: null,
        });
    };
}

export {
    openModal,
    closeModal,
    openRequestModal,
    closeRequestModal,
    SetEdit,
    swap,
};
