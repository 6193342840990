import React from 'react';
import { connect } from 'react-redux';

import {
    getCitiesFromSelect,
    getCountryFromSelect,
} from '../../actions/finance';
import Grid from '@material-ui/core/Grid';

import LinearProgress from '@material-ui/core/LinearProgress';
import './index.css';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import {
    getFinanceAnalitics,
    processFullReport,
} from '../../actions/analytics';
import ComponentTable from '../../components/ComponentTable';
import { reloadTable } from '../../actions/AsyncTable';
import Button from '@material-ui/core/Button';
import FinanceService from './../../services/finance.service';
import ModelService from './../../services/models.service';
import { openRequestModal } from '../../actions/modals';

// import { CSVLink } from "react-csv";
import Box from '@material-ui/core/Box';
import { DatePicker } from '@material-ui/pickers';
import { getOffices } from '../../actions/getOfficesList';
import { getTeachersFromSelect } from '../../actions/groups';
import DescriptionIcon from '@material-ui/icons/Description';
import { ExportToExcel } from './export';
import Paper from '@material-ui/core/Paper';

class FinancePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_report: false,
            download: [],
            data: [],
            selected: '',
            date: '',
            standart_date: '',
            top_loader: false,
            loader_timeout: null,
            small_report: [],
            dates: {},
            cities_name: null,
            short_date: null,
        };
    }

    processDate(item = false, short = false) {
        let d = item ? new Date(item) : new Date();

        const _min =
            d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
        const _hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
        const _month =
            d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
        const _date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

        const date = `${_date}.${_month}.${d.getFullYear()}_${_hours}:${_min}`;
        const standart_date = `${_month}.${_date}.${d.getFullYear()} 12:00`;
        this.setState({ standart_date });
        if (short) return `${_month}.${d.getFullYear()}`;
        if (item) return date;
        this.setState({ date, standart_date });
    }

    processCityName(item) {
        const arr = item.split('|||');
        for (let item of arr) {
            if (item) return item;
        }
    }

    downloadAllData(res) {
        const array = processFullReport(res);

        this.setState({
            short_date: this.processDate(
                false,
                this.state.dates.date ? this.state.dates.date : new Date()
            ),
            download: array,
            data: res,
            loading: false,
            loading_report: false,
        });
        setTimeout(() => {
            document.getElementById('downloadAll').click();
        }, 100);
    }

    processTitle(item) {
        let title;
        for (let el of item.title.split('|||')) {
            if (el) {
                title = el;
                break;
            }
        }
        return title;
    }

    async processFullReport(item) {
        const { t } = this.props;
        this.processDate();

        setTimeout(() => {
            this.props.openRequestModal({
                opened: true,
                data: {
                    type: item,
                    date: {
                        selected: this.state.dates,
                        standart: new Date(this.state.standart_date),
                    },
                    header:
                        item === 'small'
                            ? t('finance.get_report')
                            : t('finance.get_full_report'),
                    modaltype: 'FinanceSelectComponent',
                },
            });
        }, 100);
    }

    render() {
        const { t } = this.props;
        const source = {
            firstLoad: () =>
                this.props.getCountryFromSelect(false, { size: 9999 }),
            getData: (page, size, sort, filter) =>
                FinanceService.getAllListCity(false, {
                    date: this.state.dates.date || null,
                    page: page || 0,
                    size: size || 10,
                    orderBy: (sort && sort.orderBy) || null,
                    order: (sort && sort.order) || null,
                    filter,
                }),
            getModel: () => ModelService.Finance(false, {}),
        };

        const parceDateFrom = (d) => {
            const date = new Date(d);
            return +new Date(date.getFullYear(), date.getMonth(), 1);
        };
        const parceDateTo = (d) => {
            const date = new Date(d);
            return +new Date(date.getFullYear(), date.getMonth() + 1, -1);
        };
        const onChange = (dates) => {
            this.setState({
                dates: {
                    ...this.state.dates,
                    date: dates,
                },
            });
            this.props.reloadTable();
        };
        const actions = [
            {
                title: t('finance.getcsv'),
                onClick: async (id) => {
                    this.setState({ loading: true });
                    let dates = !!this.state.dates.date
                        ? {
                              from: parceDateFrom(this.state.dates.date),
                              to: parceDateTo(this.state.dates.date),
                          }
                        : null;
                    let data = await this.props.getFinanceAnalitics({
                        ...dates,
                        city: id,
                    });

                    if (!data) {
                        return this.setState({ loading: false });
                    }
                    const city = this.props.cities.find((el) => el.id === id);
                    this.setState({
                        cities_name: this.processTitle(city),
                        countries_name: this.processTitle(
                            this.props.countries.find(
                                (el) => el.id === city.parent_id
                            )
                        ),
                    });
                    let groups = {};
                    for (let item of data.list) {
                        const group_name =
                            item.group.split('|||')[0] || item.group;
                        if (
                            Object.keys(groups).find((el) => el === group_name)
                        ) {
                            groups[group_name].push(item);
                        } else {
                            Object.assign(groups, { [group_name]: [item] });
                        }
                    }
                    this.processDate();
                    this.downloadAllData(groups, 'downloadAll');
                },
                Icon: DescriptionIcon,
            },
        ];
        return (
            <>
                <h2 className="container-header">
                    {t('sidebar.finances_analitics')}{' '}
                </h2>
                <Paper>
                    <Box p={2} m={2}>
                        <div>
                            <DatePicker
                                disableFuture
                                className="datePicker_analytics"
                                autoOk
                                allowKeyboardControl={false}
                                animateYearScrolling
                                variant="inline"
                                minDate={new Date('2021-03-01')}
                                views={['year', 'month']}
                                inputVariant="outlined"
                                format="MM.yyyy"
                                value={this.state.dates.date}
                                onChange={(date) => onChange(date)}
                                label={t('table.date')}
                                style={{ marginRight: 20 }}
                            />
                        </div>
                    </Box>
                    <Box p={2} m={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    className="greenButton"
                                    style={{
                                        width: '250px',
                                        margin: 0,
                                        marginTop: '15px',
                                    }}
                                    onClick={() =>
                                        this.processFullReport('small')
                                    }
                                >
                                    {t('finance.get_report')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    className="greenButton"
                                    style={{
                                        width: 'auto',
                                        margin: 0,
                                        marginTop: '15px',
                                    }}
                                    onClick={() =>
                                        this.processFullReport('detail')
                                    }
                                >
                                    {t('finance.get_full_report')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box p={2} m={2}>
                        <ComponentTable
                            source={source}
                            actions={actions}
                            selectable={true}
                            enableFilter={true}
                            title="Список пользователей"
                            enableSettings
                            toolbarvisible={true}
                            actionsButton={[]}
                            cities_report
                        />
                    </Box>
                    <ExportToExcel
                        id="downloadAll"
                        formatData={this.state.download}
                        fileName={`${t('finance.simple_report')}_${
                            this.state.countries_name
                        }_${this.state.cities_name}_${this.state.short_date}`}
                    />
                </Paper>
                {this.state.loading ? <LinearProgress /> : null}
            </>
        );
    }
}
const widthConnect = connect(
    (state) => ({
        user: state.user,
        offices: state.offices,
        teachers: state.groups,
        query: state.finance && state.finance.query,
        cities: state.cities && state.cities.list,
        countries: state.finance && state.finance.countries,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                getOffices,
                reloadTable,
                getFinanceAnalitics,
                getCountryFromSelect,
                getCitiesFromSelect,
                getTeachersFromSelect,
                openRequestModal,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(FinancePage));
