import { SubmissionError } from "redux-form";

export default (data, dispatch, props) => {
  const { onAccept, filter, validate, values, rollUpFilterView } = props;
  const validationErrors = validate(values, props);

  if (!!Object.keys(validationErrors).length) {
    throw new SubmissionError(validationErrors);
  } else {
    rollUpFilterView();
    onAccept(filter.map((e) => ({ ...e, values: data[e.code] })));
  }
};
