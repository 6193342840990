import Types from '../constants/help';
import { closeRequestModal } from './modals';
import HelpService from '../services/help.service';
import RoleService from '../services/roles.service';
import { getErrorNotification } from './toast';
import i18n from 'i18next';

export function clearAllData() {
    return async (dispatch, getState) => {
        dispatch({
            type: Types.GET,
            help: {
                edit: null,
                show: null,
                permissions: null,
                type: 0,
                langData: [],
                choosen_page: null,
            },
        });
    };
}

export function addAutocompleteData(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: Types.GET,
            help: {
                ...getState().help,
                ...data,
            },
        });
    };
}

export function addChoosenPage(item) {
    return async (dispatch, getState) => {
        dispatch({
            type: Types.GET,
            help: {
                ...getState().help,
                choosen_page: item,
            },
        });
    };
}

export function getPermissions(data) {
    return async (dispatch, getState) => {
        const res = await RoleService.PermissionsList(data);
        const { help } = getState().help;
        if (res.permission) {
            delete res.permission['debt'];
            delete res.permission['esoroban'];
            delete res.permission['sitelink'];
            delete res.permission['sales'];
            delete res.permission['help'];
            delete res.permission['online_lesson'];
        }
        dispatch({
            type: Types.GET,
            help: {
                ...help,
                permissions: res.permission,
            },
        });
    };
}

export function deleteHelper(data) {
    return async (dispatch, getState) => {
        HelpService.DeleteHelper(data).then((res) => {
            if (res) {
                getAllHelpers()(dispatch, getState);
                closeRequestModal()(dispatch, getState);
            }
        });
    };
}

export function addHelper(data) {
    return async (dispatch, getState) => {
        const { help } = getState();
        if (!help.langData.length) {
            return getErrorNotification(i18n.t('error.langs_not_choosen'));
        }
        closeRequestModal()(dispatch, getState);
        const permission = help.permissions[data.permission];
        data.url = data.video === 'error' ? '' : data.video;
        // delete data.franch;
        // delete data.teacher;
        delete data.video;
        for (let langs of help.langData) {
            for (let item of permission) {
                data.role = item;
                data.lang = langs.value;
                if (item === 3 && data.franch) {
                    await HelpService.CreateHelper(data);
                } else if (item === 4 && data.teacher) {
                    await HelpService.CreateHelper(data);
                }
            }
        }

        getAllHelpers()(dispatch, getState);
    };
}

export function getAllHelpers() {
    return async (dispatch, getState) => {
        const res = await HelpService.GetAllHelpers({ size: 999 });
        let list = {};
        if (res) {
            res.list.sort((a, b) =>
                a.permission < b.permission
                    ? -1
                    : a.permission > b.permission
                    ? 1
                    : 0
            );
            for (let item of res.list) {
                if (!list[item.permission]) {
                    list[item.permission] = [item];
                } else {
                    list[item.permission].push(item);
                }
                list[item.permission].sort((a, b) => +a.id - +b.id);
            }

            const { help } = getState();
            dispatch({
                type: Types.GET,
                help: {
                    ...help,
                    edit: list,
                },
            });
            return list;
        }
    };
}

export function editHelper(data) {
    return async (dispatch, getState) => {
        closeRequestModal()(dispatch, getState);

        data.url = data.video;
        delete data.video;
        HelpService.EditHelper(data).then((res) => {
            if (res) {
                getAllHelpers()(dispatch, getState);
            }
        });
    };
}

export function editAllData(input, value) {
    return async (dispatch, getState) => {
        const { help } = getState();
        let edit = help.edit;
        edit[input.page][input.index][input.name] = value;
        dispatch({
            type: Types.GET,
            help: {
                ...help,
                edit,
            },
        });
    };
}

export function getRoleHelpers(data) {
    return async (dispatch, getState) => {
        const res = await HelpService.GetRoleHelper(data);
        let list = {};
        if (res) {
            res.list.sort((a, b) =>
                a.permission < b.permission
                    ? -1
                    : a.permission > b.permission
                    ? 1
                    : 0
            );
            for (let item of res.list) {
                if (!list[item.permission]) {
                    list[item.permission] = [item];
                } else {
                    list[item.permission].push(item);
                }
                list[item.permission].sort((a, b) => +a.id - +b.id);
            }
        }
        const { help } = getState();
        dispatch({
            type: Types.GET,
            help: {
                ...help,
                show: list,
            },
        });
    };
}
