const countDate = (value, min = false) => {
  if (!value) return "";
  if (+value) value = +value;
  let d = new Date(value);

  const _min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  const _hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  const _month =
    d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  const _date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  const minutes = `${_hours}:${_min}`;

  return `${_date}.${_month}.${d.getFullYear()} ${min ? minutes : ""}`;
};

const formatDateWithoutTimezone = (value, min = false) => {
  if (!value) return "";
  if (+value) value = +value;
  
  let d = new Date(value);

  const _min = d.getUTCMinutes() < 10 ? "0" + d.getUTCMinutes() : d.getUTCMinutes();
  const _hours = d.getUTCHours() < 10 ? "0" + d.getUTCHours() : d.getUTCHours();
  const _month =
    d.getUTCMonth() + 1 < 10 ? "0" + (d.getUTCMonth() + 1) : d.getUTCMonth() + 1;
  const _date = d.getUTCDate() < 10 ? "0" + d.getUTCDate() : d.getUTCDate();
  const minutes = `${_hours}:${_min}`;

  return `${_date}.${_month}.${d.getUTCFullYear()} ${min ? minutes : ""}`;
};


const calculateDiscountEndDate = (startDateStr, days, min = true) => {
  if (!startDateStr) return ""; 
  if (days === null) return null;

  const startDate = new Date(startDateStr); 
  const endDate = new Date(startDate); 
  endDate.setDate(startDate.getDate() + days); 

  return formatDateWithoutTimezone(endDate, min); 
};

export { countDate, formatDateWithoutTimezone, calculateDiscountEndDate };
