import React, { useCallback, useRef, useState } from "react";
import { toast } from "react-toastify";

const DevModeParent = ({ children }) => {
  const [counter, setCounter] = useState(0);
  const countRef = useRef();
  const handleDevMode = () => {
    setCounter((prev) => prev + 1);
    if (!countRef.current) {
      startTimer();
    }
    if (counter >= 10) {
      const devMode = localStorage.getItem("devMode");

      devMode
        ? toast.success("Dev Mode Deactivated!")
        : toast.info(`Dev Mode Active!`);
      devMode
        ? localStorage.removeItem("devMode")
        : localStorage.setItem("devMode", "true");
      clear();
    }
  };

  const clear = () => {
    setCounter(0);
    clearTimeout(countRef.current);
    countRef.current = null;
  };

  const startTimer = () => {
    countRef.current = setTimeout(() => {
      clear();
    }, 2000);
  };
  return (
    <div className={"header-logo"} onClick={() => handleDevMode()}>
      {children}
    </div>
  );
};

export default DevModeParent;
