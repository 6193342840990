export default {
    CITIES: "@_get_cities",
    OFFICES: "@_get_offices",

    GROUPS: "@_get_groups",
    FILTRATION: '@_table_filtration_data',
    ADDGROUP: "@add_new_group",
    EDITGROUP: "@edit_group",

};
