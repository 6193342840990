import React from 'react';

import Image from './Image';
import Price from './Price';
import String from './String';
import Number from './Number';
import Boolean from './Boolean';
import Var from './Var';
import ContactList from './ContactList';
import Schedule from './Schedule';
import Redirect from './Redirect';
import Date from './Date';
import NameList from './NameList';
import Html from './Html';
import StreetType from './StreetType';
import Role from './Role';
import Checkbox from './Checkbox';
import Topic from './Topic';
import Digits from './Digits';
import Lang from './Lang';
import LinkToSinlge from './LinkToSinlge';
import LessonDay from './lessonDay';

import DaysStatisticPlac from './DaysStatisticPlac';
import StatusHistory from './StatusHistory';
import LinkToSinlgeAndLang from './LinkToSinlgeAndLang';
import InputFromHw from './InputFromHW';
import CourseSelect from './CourseSelect';

// import CitiesSelect from "./CitiesSelect";
// import CountrySelect from "./CountrySelect";

import StatusSelect from './statusSelect';
import HW_game_type from './HW_game_type';
import Discounts from './Discounts';
import DiscountsAmount from './DiscountsAmount';
import Options from './options';
import Week from './week';
import JournalSelect from './journal_select';
import Counters from './counter';
import Minmax from './Minmax';
import BirthDate from './birthDate';
import StatusCourse from './statusCourse';
import HWAge from './HWAge';
import NewEqField from './newEqFields';

export default ({
    allcell,
    data,
    type,
    value,
    id,
    code,
    temporarily,
    label,
    roleId,
}) => {
    if (label === '№' && type === 'string') {
        return <Counters value={value} code={code} />;
    }

    switch (type) {
        case 'status_course':
            return (
                <StatusCourse
                    data={data}
                    value={value}
                    code={code}
                    temporarily={temporarily}
                />
            );
        case 'string':
        case 'numbers_student':
        case 'counterparty.name':
        case 'cities_select':
        case 'country_select':
        case '[city]':
        case 'city.id':
        case 'city.name':
        case 'orderStatus':
            return (
                <String
                    data={data}
                    value={value}
                    code={code}
                    temporarily={temporarily}
                />
            );
        case 'number':
            return <Number value={value} />;
        case 'minmax':
            return (
                <Minmax
                    id={id}
                    data={data}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                    label={label}
                />
            );
        case 'price':
            return <Price value={value} />;
        case 'lesson_day':
            return (
                <LessonDay
                    data={data}
                    value={value}
                    code={code}
                    temporarily={temporarily}
                />
            );
        case 'image':
            return <Image value={value} />;
        case 'boolean':
            return <Boolean value={value} />;
        case '[email]':
            return <ContactList value={value} prefix="mailto:" />;
        case '[phone]':
            return <ContactList value={value} prefix="tel:+" />;
        case '[url]':
            return (
                <ContactList
                    value={value}
                    target="_blank"
                    rel="noopener noreferrer"
                />
            );
        case 'workingTime':
            return <Schedule value={value} />;
        case 'redirect':
            return <Redirect value={value} />;
        case 'birth_date':
            return <BirthDate value={value} />;
        case 'date':
            return <Date value={value} code={code} />;
        case 'subdivision.id':
            return <NameList value={value} />;
        case 'html':
            return <Html value={value} />;
        case '[street_type]':
            return <StreetType value={value} />;
        case 'role':
            return <Role value={value} />;
        case 'checkbox':
            return <Checkbox id={id} code={code} value={value} />;
        case 'HW-topic':
            return (
                <Topic
                    all={allcell}
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'HW-digits':
            return (
                <Digits
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'lang':
            return <Lang value={value} />;
        case 'LinkToSinlge':
            return <LinkToSinlge id={id} value={value} item="123" />;
        case 'LinkToSinlgeAndLang':
            return <LinkToSinlgeAndLang id={id} value={value} />;
        case 'new_eq':
            return (
                <NewEqField
                    id={id}
                    code={code}
                    data={data}
                    temporarily={temporarily}
                    value={value}
                    label={label}
                />
            );
        case 'InputHW':
            return (
                <InputFromHw
                    id={id}
                    code={code}
                    data={data}
                    temporarily={temporarily}
                    value={value}
                    label={label}
                />
            );
        case 'HW_game_type':
            return (
                // eslint-disable-next-line
                <HW_game_type
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'course_select':
            return (
                <CourseSelect
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'status_select':
        case 'status_select_teacher':
            return (
                <StatusSelect
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                    type={type}
                    roleId={roleId}
                    data={data}
                />
            );
        case 'daysStatisticPlac':
            return (
                <DaysStatisticPlac
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'status_history':
            return (
                <StatusHistory
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'discounts':
            return (
                <Discounts
                    id={id}
                    data={data}
                    allcell={allcell}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'discounts-amount':
            return (
                <DiscountsAmount
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'hw_age':
            return (
                <HWAge
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'varibles':
            return (
                <Var
                    id={id}
                    all={allcell}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'optionsHW':
            return (
                <Options
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'week':
            return (
                <Week
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );
        case 'journal_select':
            return (
                <JournalSelect
                    id={id}
                    code={code}
                    temporarily={temporarily}
                    value={value}
                />
            );

        default:
            return <>{value}</>;
    }
};
