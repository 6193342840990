export const time = [
  10000,
  7000,
  5000,
  4000,
  3500,
  3000,
  2500,
  2000,
  1800,
  1600,
  1400,
  1200,
  1000,
  900,
  800,
  700,
  600,
  500,
  300,
];

export const count = [2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 15, 20, 30, 50, 70];
