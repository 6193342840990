import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import '../../layout/Dash/Dash.css';
import { withTranslation } from 'react-i18next';
import ComponentTable from '../ComponentTable';
import StudentsService from '../../services/students.service';
import ModelService from '../../services/models.service';
import { Edit } from '@material-ui/icons';
import { openRequestModal } from '../../actions/modals';
import BooksSearch from './BooksSearch';
import { Box, Stack } from '@mui/material';
import { useLocation, useRouteMatch } from 'react-router-dom';
import ParseMobilePhones from './ParceMobilePhones';

const DashSupportView = ({ t, openRequestModal, ...props }) => {
    const actions = [
        {
            title: t('modals_name.edit'),
            onClick: (id) =>
                openRequestModal({
                    opened: true,
                    data: {
                        id: id,
                        modaltype: 'EditUserModal',
                        header: t('modals_name.edit'),
                    },
                }),
            Icon: Edit,
        },
    ];
    const route = useLocation();
    const pathname = route.pathname;
    const hiddePart = pathname === '/dashboard/hidden';
    return (
        <Fragment>
            <Stack spacing={2}>
                {hiddePart ? (
                    <ParseMobilePhones />
                ) : (
                    <>
                        <BooksSearch />

                        <ComponentTable
                            source={{
                                getData: (page, size, sort, filter) =>
                                    StudentsService.SupportList(false, {
                                        page: page || 0,
                                        size,
                                        orderBy: (sort && sort.orderBy) || null,
                                        order: (sort && sort.order) || null,
                                        filter,
                                    }),
                                getModel: () =>
                                    ModelService.SupportModels(false),
                            }}
                            actions={actions}
                            enableFilter
                            fetchOnFilter
                            title={t('table.users_list')}
                            enableSettings
                            toolbarvisible={true}
                            headerTable={'Support'}
                        />
                    </>
                )}
            </Stack>
        </Fragment>
    );
};

const widthConnect = connect(
    (state) => ({
        user: state.user,
        cities: state.cities,
        offices: state.offices,
    }),
    (dispatch) => bindActionCreators({ openRequestModal }, dispatch)
);

export default withTranslation()(widthConnect(DashSupportView));
