// ----------------------------------------------------------------------

export default function Button() {
    return {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'dashed' },
                    style: {
                        textTransform: 'none',
                        border: `2px dashed `,
                    },
                },
                {
                    props: { variant: 'dashed', color: 'secondary' },
                    style: {
                        border: `4px dashed `,
                    },
                },
            ],
        },
    };
}
