import React from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Field, change } from "redux-form";
import { useTranslation } from "react-i18next";
import store from "./../../../../store";

let schemaGame = [
  {
    label: "no",
    value: false,
  },
  {
    label: "yes",
    value: true,
  },
];
let schemaGameBase = [
  {
    label: "no",
    value: 0,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
  {
    label: "400",
    value: 400,
  },
  {
    label: "500",
    value: 500,
  },
];

const renderTextField = ({
  input,
  meta: { touched, error },
  i18n,
  ...custom
}) => {
  delete custom.id;
  delete custom.all;
  delete custom.items;
  return (
    <NativeSelect {...input} {...custom} style={{ minWidth: "50px" }}>
      {schemaGame.map((item, key) => {
        return (
          <option key={key} value={item.value}>
            {i18n.t("table." + item.label)}
          </option>
        );
      })}
    </NativeSelect>
  );
};
const renderTextFieldBase = ({
  input,
  meta: { touched, error },
  i18n,
  id,
  ...custom
}) => {
  const all = custom.all.find((el) => +el.id === +id);
  const isSecondDigit = +custom.items.digits === 2;
  const setValueToTable = (value) => {
    let name = JSON.parse(input.name);
    name.code = "base";
    store.dispatch(change("from-tableComponent", JSON.stringify(name), value));
    input.value = JSON.stringify(value);
  };
  if (
    custom.items.code === "base" &&
    (+custom.items.topic <= 11 || +custom.items.digits > 2)
  ) {
    if (all && all.base) {
      setValueToTable(0);
    }
  }
  let list = schemaGameBase;
  if (isSecondDigit) {
    list = schemaGameBase.filter(
      (el) => el.value === 400 || el.value === 500 || el.value === 0
    );
  }
  if (isSecondDigit && +input.value !== 0 && +input.value < 400) {
    setValueToTable(400);
  }
  delete custom.id;
  delete custom.all;
  delete custom.items;
  if (custom.disabled) input.value = "0";
  return (
    <NativeSelect {...input} {...custom} style={{ minWidth: "50px" }}>
      {list.map((item, key) => {
        return (
          <option key={key} value={item.value}>
            {i18n.t("table." + item.label)}
          </option>
        );
      })}
    </NativeSelect>
  );
};
export default ({ all, value, code, id, temporarily }) => {
  let game_type = null;
  let topic = null;
  let digits = null;
  const form = store.getState().form["from-tableComponent"].values;

  if (code !== "eabacus")
    game_type =
      form[
        Object.keys(form).find(
          (el) =>
            JSON.parse(el).code === "game_type" && JSON.parse(el).id === id
        )
      ];
  if (code !== "eabacus")
    topic =
      form[
        Object.keys(form).find(
          (el) => JSON.parse(el).code === "topic" && JSON.parse(el).id === id
        )
      ];
  if (code !== "eabacus")
    digits =
      form[
        Object.keys(form).find(
          (el) => JSON.parse(el).code === "digit" && JSON.parse(el).id === id
        )
      ];

  let component = code !== "base" ? renderTextField : renderTextFieldBase;
  if ((code === "base" && +topic <= 11) || (code === "base" && +digits > 2)) {
    value = 0;
  }

  const { i18n } = useTranslation();
  return (
    <Field
      name={JSON.stringify({ code, id, temporarily })}
      disabled={
        game_type === "4" ||
        (+game_type === 5 && code === "base") ||
        (+game_type === 6 && code === "base") ||
        (code === "base" && +topic <= 11) ||
        (code === "base" && +digits > 2)
      }
      i18n={i18n}
      all={all}
      id={id}
      component={component}
      items={{ value, code, id, temporarily, game_type, topic, digits }}
      type="text"
    />
  );
};
