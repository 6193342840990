import Types from './../constants/bank';
import BankCustomersService from '../services/bank-customers.service';
import BankTransactionsService from '../services/bank-transactions.service';
import BankProductsService from '../services/bank-products.service';
import BankCartService from '../services/bank-cart.service';

function setBalance() {
    return async (dispatch) => {
        const res = await BankCustomersService.GetProfile();
        dispatch({
            type: Types.SET_BALANCE,
            payload: res.balance || 0,
        });
        return res;
    };
}

function setPendingTransactions() {
    return async (dispatch) => {
        const res = await BankTransactionsService.AllTransactions();
        dispatch({
            type: Types.SET_PENDING_TRANSACTIONS,
            payload: res || [],
        });
    };
}

function setPendingRootTransactions() {
    return async (dispatch) => {
        const res = await BankTransactionsService.AllRootTransactions();
        dispatch({
            type: Types.SET_PENDING_ROOT_TRANSACTIONS,
            payload: res || [],
        });
    };
}

function setPendingOrders() {
    return async (dispatch) => {
        const res = await BankTransactionsService.AllProducts();
        dispatch({
            type: Types.SET_PENDING_ORDERS,
            payload: res || [],
        });
    };
}

function setTeachersBalances() {
    return async (dispatch) => {
        const res = await BankCustomersService.GetInformationTeachersBalances();
        dispatch({
            type: Types.SET_TEACHERS_BALANCES,
            payload: res || [],
        });
    };
}

function setStudentsBalances() {
    return async (dispatch) => {
        const res = await BankCustomersService.GetInformationStudentsBalances();
        dispatch({
            type: Types.SET_STUDENTS_BALANCES,
            payload: res || [],
        });
    };
}

function setCitiesBalances() {
    return async (dispatch) => {
        const res = await BankCustomersService.GetInformationCitiesBalances();
        dispatch({
            type: Types.SET_CITIES_BALANCES,
            payload: res || [],
        });
    };
}

function setProductsAllFranch() {
    return async (dispatch) => {
        const res = await BankProductsService.GetProductsAllFranch();

        if (res && res.response && res.response.error) {
            dispatch({
                type: Types.SET_All_PRODUCTS,
                payload: [],
            });
            return;
        }

        dispatch({
            type: Types.SET_All_PRODUCTS,
            payload: res || [],
        });
    };
}

function setAllCartItems() {
    return async (dispatch) => {
        const res = await BankCartService.GetCartItems();

        if (res &&  res.response && res.response.error) {
            dispatch({
                type: Types.SET_All_CART_ITEMS,
                payload: [],
            });
            return;
        }
        dispatch({
            type: Types.SET_All_CART_ITEMS,
            payload: res || [],
        });
    };
}

function __ClearAllBankData() {
    return (dispatch) => {
        dispatch({
            type: Types.CLEAR_STATE,
        });
    };
}

function setToken(customer) {
    return (dispatch, getState) => {
        dispatch({
            type: Types.REGISTRATION,
            payload: customer,
        });

        const { bank } = getState();
        const data = {
            bank: {
                ...bank,
            },
        };

        const storageNotVerified = localStorage.getItem('globalStore');
        const storage = storageNotVerified
            ? JSON.parse(storageNotVerified)
            : { bank: {} };
        localStorage.setItem(
            'globalStore',
            JSON.stringify({ ...storage, ...data })
        );
    };
}


export {
    setToken,
    setBalance,
    setPendingTransactions,
    setPendingRootTransactions,
    setPendingOrders,
    setProductsAllFranch,
    __ClearAllBankData,
    setAllCartItems,
    setTeachersBalances,
    setStudentsBalances,
    setCitiesBalances,
};
