import Types from '../constants/getUsers';
import UsersService from '../services/users.service';
import { reloadTable } from './AsyncTable';
import { closeRequestModal } from './modals';

function changeStudentGroup(n, value) {
    return (dispatch, getState) => {
        const name = JSON.parse(n);
        // eslint-disable-next-line
        if (name && name.code == 'course_id' && name.id) {
            UsersService.ChangeStudentGroup(
                { course_id: value },
                { user_id: name.id }
            ).then(() => {
                reloadTable()(dispatch, getState);
            });
        }
    };
}

function changeStudentStatus(n, value) {
    return (dispatch, getState) => {
        const name = JSON.parse(n);
        // eslint-disable-next-line
        if (name && name.code == 'status' && name.id) {
            UsersService.ChangeStudentStatus(
                { status: value },
                { user_id: name.id }
            ).then(() => {
                reloadTable()(dispatch, getState);
            });
        }
    };
}

function removeUsers() {
    return (dispatch) => {
        dispatch({
            type: Types.USERS,
            id: 'franch',
            source: {
                franch: null,
            },
        });
    };
}

function getUsers(id, args) {
    return async (dispatch) => {
        const response = await UsersService.UsersList(...args);
        response.list.sort((a, b) => (a.first_name > b.first_name ? 1 : -1));
        dispatch({
            type: Types.USERS,
            id,
            source: response,
        });
        return response;
    };
}

async function getSingleUser(args) {
    return await UsersService.SingleUser(false, { id: args });
}

function editUserSingle(array, closeModal = true) {
    return async (dispatch, getState) => {
        if (closeModal) closeRequestModal()(dispatch, getState);
        let data = JSON.parse(JSON.stringify(array));
        if (!data.passhash) delete data.passhash;
        if (data.phone_second) {
            data.phone = `${data.phone.replace(
                /[^0-9]/g,
                ''
            )},${data.phone_second.replace(/[^0-9]/g, '')}`;
        } else {
            data.phone = `${data.phone.replace(/[^0-9]/g, '')}`;
        }
        const user_id = data.id;
        const field = [
            'email',
            'first_name',
            'last_name',
            'middle_name',
            'phone',
            'birth_date',
            'passhash',
            'id',
            'description',
            'address',
            'contact_person',
            'bracelet',
        ];
        if (typeof data.hw_age === 'string' && data.hw_age.length) {
            field.push('hw_age');
        }
        for (let key in data) {
            // eslint-disable-next-line
            if (field.indexOf(key) == -1) {
                delete data[key];
            }
            if (key === 'birth_date' && data[key] === '') delete data[key];
        }
        return await UsersService.EditUser(data, { user_id });
    };
}

function editUser(data) {
    return (dispatch, getState) => {
        try {
            const user_id = data.id;
            const field = [
                'email',
                'first_name',
                'last_name',
                'middle_name',
                'phone',
                'birth_date',
                'passhash',
                'id',
                'description',
                'address',
                'referer',
                'family_id',
                'family_approved',
                'referer_approved',
                'contact_person',
                'bracelet',
            ];
            for (let key in data) {
                // eslint-disable-next-line
                if (field.indexOf(key) == -1) {
                    delete data[key];
                }
                if (key === 'birth_date' && data[key] === '') delete data[key];
            }

            if (data.phone) data.phone = `${data.phone.replace(/[^0-9]/g, '')}`;

            UsersService.EditUser(data, { user_id }).then(() => {
                closeRequestModal()(dispatch, getState);
                reloadTable()(dispatch, getState);
            });
        } catch (error) {
            console.log(error);
        }
    };
}

function editUserSales(data) {
    return async () => {
        const user_id = data.id;
        const field = [
            'referer',
            'family_id',
            'family_approved',
            'referer_approved',
            'id',
        ];
        for (let key in data) {
            // eslint-disable-next-line
            if (field.indexOf(key) == -1) {
                delete data[key];
            }
            if (key === 'birth_date' && data[key] === '') delete data[key];
        }

        const res = await UsersService.EditUser(data, { user_id });
        if (res) {
        }
    };
}

function deleteUser(data) {
    return (dispatch, getState) => {
        UsersService.DeleteUser(data).then((response) => {
            if (response) {
                closeRequestModal()(dispatch, getState);

                reloadTable()(dispatch, getState);
            }
            dispatch({
                type: Types.DELETEUSER,
            });
        });
    };
}

function addUser(payload) {
    return (dispatch, getState) => {
        let data = JSON.parse(JSON.stringify(payload));
        data.phone = data.phone.replace(/[^0-9]/g, '');
        closeRequestModal()(dispatch, getState);
        if (typeof data.hw_age === 'string' && !data.hw_age.length) {
            delete data.hw_age;
        }
        UsersService.AddUser(data).then(() => {
            reloadTable()(dispatch, getState);
        });
    };
}

export {
    getUsers,
    getSingleUser,
    editUser,
    deleteUser,
    addUser,
    changeStudentGroup,
    changeStudentStatus,
    editUserSingle,
    editUserSales,
    removeUsers,
};
