import { ApiConnector, GET, POST, PUT } from './bank-request.service';

export default (() => {
    const API = ApiConnector('/transactions');
    return {
        // Это редактирование ( ТОЛЬКО ЕСЛИ ТРАНЩЗАКЦИЯ В ОЖИДАНИИ ПОДТВЕРЖДЕНИЯ )
        EditTransaction: async (data, noMessages = false) =>
            await API(PUT, '/').call(data, noMessages, true),
        // Все ожидающие транзакции учителя
        AllTransactions: async (query) =>
            await API(GET, `/teacher?courseId=${query || ''}`).call(),
        AllRootTransactions: async (query) => await API(GET, '/root').call(),
        // получить историю всех транзакций по товарам
        AllProducts: async (query) =>
            await API(GET, `/products?courseId=${query || ''}`).call(),
        // получить запросы на покупку товаров учителя ( от ученика )
        RequestProducts: async (query) =>
            await API(GET, '/request/products?customerId=${query}').call(),
        TransferToCard: async (data) =>
            await API(POST, '/transfer-to-card').call(data, true),
        // Поповнити свій рахунок живими монетами
        AccountDeposit: async (data, noMessage) =>
            await API(POST, '/charge').call(data, noMessage),
        // Зняти монети зі свого рахунку
        WithdrawalTransaction: async (data, noMessage) =>
            await API(POST, '/cash').call(data, noMessage),
        // (ПОДТВЕРДИТЬ ТРАНЗАКЦИ ЧТО ТОВАР ЧТО И ПРОСТО БАБКИ )
        ComfirmTransaction: async (data, noMessage = false) =>
            await API(POST, '/comfirm').call(data, false),
        // ОТКЛОНИТЬ ТРАНЗАКЦИЮ ( И ПОКУПКА ТОВАРА И ПРОСТО ТРАНЗАКЦИЯ НА БАБКИ )
        DeclineTransaction: async (data) =>
            await API(POST, '/decline').call(data),
        AcceptTransactions: async (data) =>
            await API(POST, '/transfer/course/custom').call(data),
        OrderSorocoins: async (data) => await API(POST, '/order').call(data),
        // Переказ СОРОКОІНІВ ПО ID
        TransactionById: async (data) => await API(POST, '/transfer').call(data),
    };
})();
