import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import NativeSelect from '@material-ui/core/NativeSelect';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import { statuses } from '../../../../../constants/group_constant';

const renderSelectField = ({
    input,
    meta: { touched, error },
    i18n,
    ...custom
}) => {
    const deleteStatus = statuses
        .filter(Boolean)
        .findIndex((el) => el.value === 7);
    if (deleteStatus !== -1) delete statuses[deleteStatus];
    return (
        <NativeSelect {...input} {...custom}>
            {statuses.filter(Boolean).map((item, key) => {
                return (
                    <option key={key} value={item.value}>
                        {i18n.t(item.label)}
                    </option>
                );
            })}
        </NativeSelect>
    );
    // ChangeStudentGroup
};

export default ({ label, code }) => {
    const { i18n } = useTranslation();
    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                    {i18n.t(label)}
                </Typography>
                <Field
                    name="course_type"
                    i18n={i18n}
                    component={renderSelectField}
                    type="text"
                />
            </CardContent>
        </Card>
    );
};
