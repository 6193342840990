import HomeworkService from "../services/homework.service";

function getAvailibleDates(query) {
  return async (dispatch, getState) => {
    return await HomeworkService.GetAvailibleDates(query);
  };
}

async function getMultStat(query) {
  const res = HomeworkService.GetMultStat(query);
  return res;
}

async function getNewEqPointStat(query) {
  return await HomeworkService.GetPointStat(query);
}

async function getNewEqProgressStat(query) {
  return await HomeworkService.GetHwProgress(query);
}

export { getAvailibleDates, getMultStat, getNewEqPointStat, getNewEqProgressStat };
