export const STATUSES = [
  {
    id: 0,
    title: "ACTIVE",
  },
  {
    id: 1,
    title: "BLOCKED",
  },
  {
    id: 2,
    title: "RESERVED",
  },
  {
    id: 3,
    title: "ARCHIVED",
  },
  {
    id: 4,
    title: "ON_VACATION",
  },
  {
    id: 5,
    title: "NOT_CONFIRMED",
  },
  {
    id: 6,
    title: "CANDIDATE",
  },
  {
    id: 7,
    title: "GRADUATE",
  },
];

export const USER_STATUSES = [
  {
    id: 0,
    title: "ACTIVE",
  },
  {
    id: 1,
    title: "BLOCKED",
  },
  {
    id: 2,
    title: "RESERVED",
  },
  {
    id: 3,
    title: "ARCHIVED",
  },
  {
    id: 4,
    title: "ON_VACATION",
  },
  {
    id: 5,
    title: "NOT_CONFIRMED",
  },
  {
    id: 6,
    title: "CANDIDATE",
  },
  {
    id: 7,
    title: "GRADUATE",
  },
];

export const CANDIDATE_STATUSES = [
  {
    id: 6,
    title: "CANDIDATE",
    disabled: true,
  },
  {
    id: 0,
    title: "ACTIVE",
  },
  {
    id: 3,
    title: "ARCHIVED",
  },
  {
    id: 2,
    title: "RESERVED",
  },
];

export const ACTIVE_STATUSES = [
  {
    id: 0,
    title: "ACTIVE",
    disabled: true,
  },
  {
    id: 1,
    title: "BLOCKED",
  },
  {
    id: 4,
    title: "ON_VACATION",
  },
  {
    id: 7,
    title: "GRADUATE",
  },
  {
    id: 3,
    title: "ARCHIVED",
  },
  {
    id: 2,
    title: "RESERVED",
  },
];

export const BLOCKED_STATUSES = [
  {
    id: 1,
    title: "BLOCKED",
    disabled: true,
  },
  {
    id: 0,
    title: "ACTIVE",
  },
  {
    id: 4,
    title: "ON_VACATION",
  },
  {
    id: 7,
    title: "GRADUATE",
  },
  {
    id: 3,
    title: "ARCHIVED",
  },
  {
    id: 2,
    title: "RESERVED",
  },
];

export const VACATION_STATUSES = [
  {
    id: 4,
    title: "ON_VACATION",
    disabled: true,
  },
  {
    id: 0,
    title: "ACTIVE",
  },
  {
    id: 1,
    title: "BLOCKED",
  },
  {
    id: 7,
    title: "GRADUATE",
  },
  {
    id: 3,
    title: "ARCHIVED",
  },
  {
    id: 2,
    title: "RESERVED",
  },
];

export const RESERVE_STATUSES = [
  {
    id: 2,
    title: "RESERVED",
    disabled: true,
  },
  {
    id: 3,
    title: "ARCHIVED",
  },
  {
    id: 6,
    title: "CANDIDATE",
  },
];

const ARCHIVE_STATUSES = [
  {
    id: 3,
    title: "ARCHIVED",
    disabled: true,
  },
  {
    id: 2,
    title: "RESERVED",
  },
  {
    id: 6,
    title: "CANDIDATE",
  },
];

export const GRADUATE_STATUSES = [
  {
    id: 7,
    title: "GRADUATE",
    disabled: true,
  },
  {
    id: 0,
    title: "ACTIVE",
  },
];

export const RESTRICTED_STATUSES_SCHEMA = {
  0: ACTIVE_STATUSES,
  1: BLOCKED_STATUSES,
  2: RESERVE_STATUSES,
  3: ARCHIVE_STATUSES,
  4: VACATION_STATUSES,
  5: USER_STATUSES, //not confirmed
  6: CANDIDATE_STATUSES,
  7: GRADUATE_STATUSES,
};

export const USER_STATUSES_TEACHER = [
  {
    id: 0,
    title: "ACTIVE",
  },
  {
    id: 1,
    title: "BLOCKED",
  },
];
