import React, { Fragment } from "react";
import "./index.css";
import { connect } from "react-redux";
import { openRequestModal } from "../../actions/modals";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import Paper from "@material-ui/core/Paper";
import {
  getAllHelpers,
  editHelper,
  deleteHelper,
  editAllData,
} from "../../actions/help";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

import NativeSelect from "@material-ui/core/NativeSelect";

class HelpPageEdit extends React.Component {
  state = {
    allHelpersKeys: null,
    role_id: this.props.user.emulation.length
      ? this.props.user.emulation[this.props.user.emulation.length - 1].info
          .role_id
      : this.props.user.info.role_id,
    lang_sort: "",
    role_sort: "",
    title_sort: "",
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.props.getAllHelpers({ size: 9999 });
  }

  handleEdit(index, page) {
    this.props.editHelper(this.props.help.edit[page][index]);
  }

  processAccordion() {
    const { t } = this.props;
    return Object.keys(this.props.help.edit).map((item, i) => {
      let helperItems = this.props.help.edit[item];
      if (this.state.role_sort) {
        helperItems = helperItems.filter(
          (arrayKey) => +arrayKey.role === +this.state.role_sort
        );
      }
      if (this.state.lang_sort) {
        helperItems = helperItems.filter(
          (arrayKey) => arrayKey.lang === this.state.lang_sort
        );
      }
      if (this.state.title_sort) {
        helperItems = helperItems.filter((arrayKey) =>
          arrayKey.title.includes(this.state.title_sort)
        );
      }
      return (
        <div className="helperContainer" key={i}>
          <Accordion key={i}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{`${t(`sidebar.${item}`)}`}</Typography>
            </AccordionSummary>
            <AccordionDetails className="seq_block">
              <Typography component="div">
                {helperItems.map((el, index) => {
                  return (
                    <div className="helperContainer item" key={index}>
                      <Paper>
                        <Typography
                          component="div"
                          className="helpItems_Container"
                        >
                          <div style={{ width: "80%", padding: "20px 0" }}>
                            {`${el.title} | ${
                              el.role === 3 ? "Франчайзи" : "Учитель"
                            } | ${el.lang}`}
                          </div>
                          <div>
                            <IconButton
                              onClick={() =>
                                this.props.openRequestModal({
                                  opened: true,
                                  data: {
                                    // eslint-disable-next-line
                                    items: el,
                                    header: t("modals_name.edit"),
                                    modaltype: "EditHelperModal",
                                  },
                                })
                              }
                              title={t("modals_name.edit")}
                              className="addHelperButton"
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                this.props.openRequestModal({
                                  opened: true,
                                  data: {
                                    // eslint-disable-next-line
                                    id: el.id,
                                    header: t("buttons.delete"),
                                    modaltype: "DeleteHelperModal",
                                  },
                                })
                              }
                              title={t("buttons.delete")}
                              className="addHelperButton"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </Typography>
                      </Paper>
                    </div>
                  );
                })}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });
  }
  render() {
    return (
      <Fragment>
        <IconButton
          onClick={(event) =>
            this.props.openRequestModal({
              opened: true,
              data: {
                // eslint-disable-next-line
                header: `Додати`,
                modaltype: "AddHelperModal",
              },
            })
          }
          className="addHelperButton"
        >
          <AddIcon />
        </IconButton>
        <Paper className="help_page_sort animated fadeIn">
          <h4> Сортировка </h4>
          <NativeSelect
            style={{ minWidth: "100px" }}
            value={this.state.role_sort}
            onChange={(e) => this.setState({ role_sort: e.target.value })}
          >
            <option value="">Роль</option>
            <option value="4">Учитель</option>
            <option value="3">Франч</option>
          </NativeSelect>
          <NativeSelect
            style={{ width: "100px" }}
            value={this.state.lang_sort}
            onChange={(e) => this.setState({ lang_sort: e.target.value })}
          >
            <option value="">Язык</option>
            <option value="ua">UA</option>
            <option value="ru">RU</option>
            <option value="by">BY</option>
            <option value="md">MD</option>
            <option value="ee">EE</option>
            <option value="com">COM</option>
          </NativeSelect>
          <TextField
            multiline
            label="Название видеоурока"
            style={{ width: "100%" }}
            value={this.state.title_sort}
            onChange={(e) => this.setState({ title_sort: e.target.value })}
          />
        </Paper>

        {this.props.help.edit ? (
          <>{this.processAccordion()}</>
        ) : (
          <>
            <Skeleton
              className="olympiad_loading"
              variant="rect"
              width={"100%"}
              height={40}
            />
            <Skeleton
              className="olympiad_loading"
              variant="rect"
              width={"100%"}
              height={40}
            />
            <Skeleton
              className="olympiad_loading"
              variant="rect"
              width={"100%"}
              height={40}
            />
          </>
        )}
      </Fragment>
    );
  }
}

const widthConnect = connect(
  (state) => ({ user: state.user, help: state.help }),
  (dispatch) =>
    bindActionCreators(
      {
        openRequestModal,
        getAllHelpers,
        editHelper,
        deleteHelper,
        editAllData,
      },
      dispatch
    )
);

export default withTranslation()(widthConnect(HelpPageEdit));
