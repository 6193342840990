import React, { useEffect, useState, useCallback } from 'react';
import CountriesService from './../../services/countries.service';
import { Button, Card, CircularProgress, TextField, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import i18n from 'i18next';
import Typography from '@material-ui/core/Typography';
import { getErrorNotification } from '../../actions/toast';

const MaxCyclesComponent = () => {
    const classes = useStyles();
    const [blockDay, setBlockDay] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentBlockDay, setCurrentBlockDay] = useState(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const res = await CountriesService.GetMaxCycles();
        if (!res || (res.max_cycles && typeof res.max_cycles !== 'number'))
            return;
        setLoading(false);
        setCurrentBlockDay(res.max_cycles);
        setBlockDay('');
        return true;
    }, [setLoading, setCurrentBlockDay, CountriesService]);
    useEffect(() => {
        fetchData();
    }, []);

    const handleDateChange = useCallback((e) => {
        setBlockDay(e.target.valueAsNumber);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (blockDay <= 0 || blockDay >= 15) {
            getErrorNotification(
                i18n.t('Кількість циклів не повинна перебільшувати 15')
            );
            return;
        }
        setLoading(true);
        await CountriesService.PostMaxCycles({ cycles: blockDay });
        await fetchData();
    }, [blockDay]);

    return (
        <Card className={classes.root}>
            <Box
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="95%"
            >
                <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ marginBottom: '10px' }}
                >
                    Максимальна кількість циклів
                </Typography>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        alignContent: 'space-between',
                    }}
                >
                    {currentBlockDay && currentBlockDay !== 0 ? (
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="h6"
                            style={{ fontSize: '14px', marginBottom: '15px' }}
                        >
                            {`Поточна  кількість циклів `}
                            <span className={`cityPrice ok`}>
                                {currentBlockDay}
                            </span>
                        </Typography>
                    ) : null}

                    <TextField
                        id="date"
                        placeholder={'Введіть макс. к-сть циклів '}
                        type="number"
                        onChange={handleDateChange}
                        value={blockDay}
                        disabled={loading}
                        fullWidth
                    />
                    <Button
                        style={{ margin: '15px 0px', marginBottom: '0px' }}
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={loading}
                        id={'block_day_disable'}
                        endIcon={
                            loading ? (
                                <CircularProgress size={12} color="inherit" />
                            ) : null
                        }
                    >
                        {i18n.t('buttons.save')}{' '}
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        marginRight: '7px',
        marginBottom: '7px',
    },
});

export default MaxCyclesComponent;
