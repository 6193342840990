import React from "react";

const streetTypes = ["Улица", "Проспект", "Бульвар", "Площадь", "Переулок"];

export default ({ value }) =>
    value ? (
        <div
            title={streetTypes[+value]}
            style={{
                display: "block",
                whiteSpace: "nowrap",
                maxWidth: 150,
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginLeft: "auto"
            }}
        >
            {streetTypes[+value]}
        </div>
    ) : (
        "-"
    );
