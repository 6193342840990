import Types from '../constants/books';
import API_ROOT from './../constants/urlAPI';
import BooksService from '../services/books.service';
import React from 'react';
import { jsPDF } from 'jspdf';
import { countDate } from './date';

function getBooks(...args) {
    return async (dispatch, getState) => {
        return await BooksService.getBooksService(args);
    };
}
async function getBooksColorsCount() {
    return await BooksService.getWarningColors();
}
async function getStudentBooks(args) {
    return await BooksService.getStudentBooks(args);
}

async function getSingleStudentBooks() {
    return await BooksService.getSingleStudentBooks();
}

async function getBooksAnalytics() {
    return await BooksService.getBooksAnalytics();
}

function setData(args) {
    return (dispatch) => {
        dispatch({
            type: Types.CHANGE,
            books: args,
        });
    };
}

function recordUid(data) {
    return async (dispatch, getState) => {
        dispatch({
            type: Types.CHANGE,
            books: {
                uid: data,
            },
        });
    };
}

function assignBook(...args) {
    return async () => {
        try {
            return await BooksService.assignBook(args[0]);
        } catch (error) {
            return Promise.reject(error);
        }
    };
}

function createCodeBooks(args) {
    return async (dispatch, getState) => {
        const response = await BooksService.createCodes({ ...args });
        window.open(API_ROOT + '/files?data=' + response.codes, '_blank');
        // dispatch({
        //   type: Types.CHANGE,
        //   books: {
        //       type_book: "K",
        //       count_book: 0
        //   }
        // });
    };
}

const generatePDF = (receivedQRs, type, count) => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth();

    const qrSize = 40; // Size of the QR code
    const margin = 10; // Margin around elements
    const maxPerRow = 3; // Maximum QR codes per row
    const maxPerPage = 15; // Maximum QR codes per page
    const textSpacing = 0; // Space between text and QR code
    const rowSpacing = 50; // Space between rows
    const linkSpacing = 5; // Space below the link
    const textMarginTop = 4; // Top margin for vertical text
    const textMarginLeft = -5; // Left margin for vertical text
    const title = `soroban_books_${type}(${count}) ${countDate(
        new Date(),
        true
    )}`;

    doc.setFont('helvetica');
    doc.setFontSize(12);
    doc.text(title, margin, margin);

    receivedQRs.forEach((qr, index) => {
        if (index % maxPerPage === 0 && index !== 0) {
            doc.addPage();
        }

        const row = Math.floor((index % maxPerPage) / maxPerRow);
        const col = (index % maxPerPage) % maxPerRow;
        const x = margin + col * ((pageWidth - margin) / maxPerRow);
        const y = 20 + row * rowSpacing;

        // Convert canvas to image data
        const imageData = document.getElementById(qr.id).toDataURL();

        // Set font settings for vertical text
        doc.setFont('helvetica');
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0); // Black color for text

        // Add vertical text with added margins
        const verticalText = type.split('').join('\n');
        const textX = x + textMarginLeft;
        const textY = y + textMarginTop;
        const qrX = textX + textSpacing + 15;
        doc.text(verticalText, textX, textY);

        // Add QR image
        doc.addImage(imageData, 'PNG', qrX, y, qrSize, qrSize);

        // Set font settings for clickable URL
        doc.setFontSize(5);
        doc.setTextColor(0, 0, 255); // Blue color for link

        // Add clickable URL below the QR code
        const linkX = qrX + (qrSize - doc.getTextWidth(qr.url));
        doc.textWithLink(qr.url, linkX, y + qrSize + linkSpacing, {
            url: qr.url,
        });
    });

    // Save the PDF
    doc.save(`${title}.pdf`);
};

export {
    generatePDF,
    setData,
    createCodeBooks,
    assignBook,
    recordUid,
    getBooks,
    // setDataQr,
    getStudentBooks,
    getSingleStudentBooks,
    getBooksAnalytics,
    getBooksColorsCount,
};
