import React from "react";

export default ({ value, prefix = "", ...props }) =>
    !value || !value.length ? (
        "-"
    ) : (
        <ul
            style={{
                listStyleType: "none",
                padding: 0,
                margin: "-8px 0"
            }}
        >
            {value.map((e, i) => (
                <li key={i}>
                    <a
                        href={prefix + e}
                        {...props}
                        title={e}
                        children={e}
                        style={{
                            maxWidth: 150,
                            overflow: "hidden",
                            display: "block",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            marginLeft: "auto"
                        }}
                    />
                </li>
            ))}
        </ul>
    );
