import React from "react";
import { useTranslation } from "react-i18next";
import {
  reverseSchema,
  schema_d,
} from "../../../ModalEditWindow/modalComponents/EditDiscountsModal";

export default ({ value }) => {
  const { i18n } = useTranslation();
  const discountValue = schema_d[reverseSchema[value]];
  return value ? (
    <div
      title={i18n.t(discountValue)}
      style={{
        display: "block",
        whiteSpace: "nowrap",
        maxWidth: 250,
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      children={i18n.t(discountValue)}
    />
  ) : (
    "-"
  );
};
