import React, { useState } from 'react';
import ComponentTable from '../../components/ComponentTable';
import PaymentsService from '../../services/payments.service';
import ModelService from '../../services/models.service';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Delete } from '@material-ui/icons';
import { connect } from 'react-redux';
import { processDebt } from '../../actions/payments';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Card from '@material-ui/core/Card';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import { reloadTable } from '../../actions/AsyncTable';
import ArchiveIcon from '@mui/icons-material/Archive';
import SchoolIcon from '@mui/icons-material/School';
const debtTypeSchema = [
    {
        label: 'payments.debt_relief',
        value: 0,
    },
    {
        label: 'payments.archive_debt',
        value: 1,
    },
    {
        label: 'users.GRADUATE',
        value: 2,
    },
];

const actionsDebt = (props, debtType) => {
    const { t } = props;
    return [
        {
            title: t('buttons.accept'),
            onClick: (id) => {
                props.handleDebtCount(props.debtCount - 1);
                return props.processDebt({ id, type: 'complete' }, debtType);
            },
            Icon: CheckCircleIcon,
        },
        {
            title: t('buttons.delete'),
            onClick: (id) => {
                props.handleDebtCount(props.debtCount - 1);
                return props.processDebt({ id, type: 'annuled' }, -1);
            },
            Icon: Delete,
        },
    ];
};

const DebtControl = (props) => {
    const [debtType, setDebtType] = useState(0);
    const { t } = props;

    const query = (page, size, sort, filter) => ({
        page: page || 0,
        size: size || 10,
        orderBy: (sort && sort.orderBy) || 'title',
        order: (sort && sort.order) || 'asc',
        filter,
    });

    const source = {
        getData: (page, size, sort, filter) => {
            let data;
            switch (debtType) {
                case 0:
                    data = PaymentsService.DebtList(
                        false,
                        query(page, size, sort, filter)
                    );
                    break;
                case 1:
                    data = PaymentsService.DebtListArchive(
                        false,
                        query(page, size, sort, filter)
                    );
                    break;
                case 2:
                    data = PaymentsService.DebtListGraduate(
                        false,
                        query(page, size, sort, filter)
                    );
                    break;
            }
            return data;
        },
        getModel: () => ModelService.DebtModels(false, { role_id: 5 }),
    };

    const processTooltipClick = (value) => {
        setDebtType(value);
        props.handleChange('debt');
    };

    return (
        <Accordion expanded={props.debt} className={'paymentsAccordion'}>
            <AccordionSummary
                onClick={() => {
                    props.handleChange('debt');
                }}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography component="div" className="header_icon_title">
                    {t('sidebar.debt')}
                </Typography>
                <Typography>
                    {props.debtCount ? (
                        <Tooltip title={`${t(`payments.debt_relief`)}`}>
                            <Badge
                                badgeContent={props.debtCount}
                                style={{ marginLeft: '20px' }}
                                color="primary"
                            >
                                <ConfirmationNumberIcon />
                            </Badge>
                        </Tooltip>
                    ) : null}

                    {props.debtReliefArchive ? (
                        <Tooltip
                            title={`${t(`payments.debt_relief`)}: ${t(
                                'payments.archive_debt'
                            )}`}
                            onClick={() => processTooltipClick(1)}
                        >
                            <Badge
                                badgeContent={props.debtReliefArchive}
                                style={{ marginLeft: '20px' }}
                                color="primary"
                            >
                                <ArchiveIcon />
                            </Badge>
                        </Tooltip>
                    ) : null}

                    {props.debtReliefGraduate ? (
                        <Tooltip
                            title={`${t(`payments.debt_relief`)}: ${t(
                                'users.GRADUATE'
                            )}`}
                            onClick={() => processTooltipClick(2)}
                        >
                            <Badge
                                badgeContent={props.debtReliefGraduate}
                                style={{ marginLeft: '20px' }}
                                color="primary"
                            >
                                <SchoolIcon />
                            </Badge>
                        </Tooltip>
                    ) : null}
                </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
                <Box width={'100%'} mb={2}>
                    <Grid container spacing={1}>
                        {debtTypeSchema.map((debtTypeItem, key) => (
                            <Grid item key={key}>
                                <Button
                                    variant={
                                        debtTypeItem.value === debtType
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    disabled={props.fetching}
                                    onClick={() => {
                                        setDebtType(debtTypeItem.value);
                                        props.reloadTable();
                                    }}
                                >
                                    {t(`${debtTypeItem.label}`)}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Typography component="div" style={{ width: '100%' }}>
                    <Card style={{ width: '100%' }}>
                        {props.debt ? (
                            <ComponentTable
                                source={source}
                                selectable={true}
                                title={t('sidebar.students')}
                                enableSettings
                                typeTable="relocate"
                                toolbarvisible={true}
                                actions={actionsDebt(props, debtType)}
                            />
                        ) : null}
                    </Card>
                </Typography>
            </AccordionDetails>
        </Accordion>
    );
};

const widthConnect = connect(
    (state) => ({
        fetching:
            state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.fetching,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                processDebt,
                reloadTable,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(DebtControl));
