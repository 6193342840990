import Types from '../constants/offices';
import OfficesService from '../services/offices.service';
import { getError } from './toast';

function getOffices(...args) {
    return (dispatch, getState) => {
        try {
            OfficesService.OfficesList(...args).then((response) => {
                dispatch({
                    type: Types.GETOFFICES,
                    offices: response,
                });
            });
        } catch (error) {
            getError(error);
        }
    };
}

export { getOffices };
