import React, { Fragment } from "react";
import "./index.css";
import { connect } from "react-redux";
import { openRequestModal } from "../../actions/modals";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { getRoleHelpers } from "../../actions/help";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
class HelpPageView extends React.Component {
  state = {
    allHelpersKeys: null,
    role_id: this.props.user.emulation.length
      ? this.props.user.emulation[this.props.user.emulation.length - 1].info
          .role_id
      : this.props.user.info.role_id,
  };

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const domainLast =
      document.location.hostname.split(".")[
        document.location.hostname.split(".").length - 1
      ];
    await this.props.getRoleHelpers({
      lang: domainLast,
      size: 9999,
    });
  }

  handleEdit(index, page) {
    this.props.editHelper(this.props.help.edit[page][index]);
  }

  processAccordion() {
    const { t } = this.props;
    return Object.keys(this.props.help.show).map((item, i) => {
      return (
        <div className="helperContainer" key={i}>
          <Accordion key={i}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{`${t(`sidebar.${item}`)}`}</Typography>
            </AccordionSummary>
            <AccordionDetails className="seq_block">
              <Typography component="div">
                {this.props.help.show[item].map((el, index) => {
                  return (
                    <div className="helperContainer item" key={index}>
                      <Accordion key={i}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>{`${el.title}`}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="seq_block">
                          <Typography
                            component="div"
                            className="helpItems_Container"
                          >
                            <div style={{ width: "100%", padding: "20px 0" }}>
                              <iframe
                                className="helpPageIframe"
                                width="100%"
                                src={el.url}
                                title="YouTube video player"
                              />
                            </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  );
                })}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      );
    });
  }
  render() {
    return (
      <Fragment>
        {this.props.help.show ? (
          <>{this.processAccordion()}</>
        ) : (
          <>
            <Skeleton
              className="olympiad_loading"
              variant="rect"
              width={"100%"}
              height={40}
            />
            <Skeleton
              className="olympiad_loading"
              variant="rect"
              width={"100%"}
              height={40}
            />
            <Skeleton
              className="olympiad_loading"
              variant="rect"
              width={"100%"}
              height={40}
            />
          </>
        )}
      </Fragment>
    );
  }
}

const widthConnect = connect(
  (state) => ({ user: state.user, help: state.help }),
  (dispatch) =>
    bindActionCreators(
      {
        openRequestModal,
        getRoleHelpers,
      },
      dispatch
    )
);

export default withTranslation()(widthConnect(HelpPageView));
