export default {
    GROUPS: "@_get_groups",
    FILTRATION: '@_table_filtration_data',
    ADDGROUP: "@add_new_group",
    EDITGROUP: "@edit_group",
    DELETEGROUP: "@delete_group",
    PLATELIST: "@get_list_to_plate",
    GETOFFICEGROUP: "@__get_office_group",
    SET_PRICE_ERROR: '@__set_price_error'
};
