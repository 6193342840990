import React from "react";

export default ({ value, code }) => {
  return(
    value ? (
      <div
        style={{
          display: "block",
          whiteSpace: "nowrap",
          maxWidth: "40px",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
        title={value.toString()}
        children={value.toString()}
      />
    ) : (
      "-"
    )
  );
}
