import {
    Button,
    Card,
    Chip,
    CircularProgress,
    Collapse,
    IconButton,
    Stack,
    TextareaAutosize,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import { ClearAll, Search } from '@material-ui/icons';
import { encodeFilter } from '../../actions/AsyncTable';
import StudentsService from '../../services/students.service';
import { USER_STATUSES } from '../../constants/statuses';
import { useTranslation } from 'react-i18next';
import { getSingleUser } from '../../actions/getUsers';

function extractPhoneNumbers(text) {
    // Регулярное выражение для поиска номеров телефонов
    const phoneRegex = /\+380\s?\d{2}\s?\d{3}\s?\d{4}/g;

    // Извлечение номеров телефонов
    const phoneNumbers = text.match(phoneRegex);

    // Если номера телефонов найдены, возвращаем их, иначе возвращаем пустой массив
    return phoneNumbers || [];
}

const getSingleUsersData = async (list) => {
    return await Promise.all(
        list.map(async (item) => {
            const res = await getSingleUser(item.id);
            console.log(res);
            return { id: res.id, description: res.description || '' };
        })
    );
};

const ParseMobilePhones = () => {
    const [phones, setPhones] = useState('');
    const [processedPhones, setProcessedPhones] = useState([]);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const { i18n } = useTranslation();

    const handleClear = () => {
        setLoading(false);
        setProcessedPhones([]);
        setResults([]);
        setPhones('');
    };
    const handleSearch = async () => {
        handleClear();
        const processedPhones = extractPhoneNumbers(phones);
        setProcessedPhones(processedPhones);

        if (processedPhones.length) {
            setLoading(true);
            // Используем Promise.all для параллельного выполнения всех запросов
            let results = await Promise.all(
                processedPhones.map(async (item) => {
                    const res = await StudentsService.SupportList(false, {
                        page: 0,
                        size: 50,
                        orderBy: null,
                        order: null,
                        filter: encodeFilter(
                            [
                                {
                                    code: 'phone',
                                    label: 'table.phone',
                                    type: 'string',
                                    values: [item],
                                },
                            ],
                            true
                        ),
                    });
                    return res.list.length ? res.list[0] : null;
                })
            );
            results = results.filter(Boolean);
            setLoading(false);
            if (results && results.length) {
                setResults(results);
                const descriptions = await getSingleUsersData(results);
                setResults(
                    results.map((el) => ({
                        ...el,
                        description: descriptions.find(
                            (item) => item.id === el.id
                        ).description,
                    }))
                );
            }
        }
    };

    return (
        <Card p={3}>
            <Stack spacing={3} p={3}>
                <TextareaAutosize
                    placeholder={'Номера телефонов'}
                    value={phones}
                    onChange={(e) => setPhones(e.target.value)}
                    style={{ padding: '20px' }}
                />
                <Collapse in={!!processedPhones.length}>
                    <Stack spacing={1}>
                        <Typography>Найденные номера телефонов:</Typography>
                        <Stack spacing={1} direction={'row'}>
                            {processedPhones.map((el, index) => (
                                <Chip label={el} key={index} />
                            ))}
                        </Stack>
                    </Stack>
                </Collapse>

                <Button
                    variant={'contained'}
                    endIcon={<Search />}
                    onClick={handleSearch}
                >
                    Поиск
                </Button>
                {(results && results.length) ||
                (processedPhones && processedPhones.length) ? (
                    <Button endIcon={<ClearAll />} onClick={handleClear}>
                        Очистить
                    </Button>
                ) : null}

                <Typography variant={'caption'}>
                    * поиск проводится на production api
                </Typography>
                {loading && <CircularProgress size={20} />}

                {results.length ? (
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            flexDirection: 'row',
                            marginRight: '10px',
                            boxShadow:
                                '0 0 2px 0 rgba(145, 158, 171, 0.24), 0 16px 32px -4px rgba(145, 158, 171, 0.24)',
                            padding: '20px',
                        }}
                    >
                        {results.map((el, index) => {
                            return (
                                <div
                                    style={{
                                        marginRight: '10px',
                                        marginBottom: '10px',
                                        padding: '10px',
                                        border: '1px solid #00000017',
                                        borderRadius: '7px',
                                    }}
                                >
                                    <div style={{ marginBottom: '5px' }}>
                                        {el.first_name && el.last_name ? (
                                            <Typography
                                                variant={'h4'}
                                                style={{
                                                    fontSize: '20px',
                                                    fontWeight: '700',
                                                }}
                                            >{`${el.first_name} ${el.last_name}`}</Typography>
                                        ) : null}
                                        {el.status ? (
                                            <Chip
                                                label={
                                                    <Typography
                                                        variant={'h5'}
                                                        style={{
                                                            fontSize: '20px',
                                                            fontWeight: '700',
                                                        }}
                                                    >{`${i18n.t(
                                                        `users.${
                                                            USER_STATUSES.find(
                                                                (item) =>
                                                                    item.id ===
                                                                    Number(
                                                                        el.status
                                                                    )
                                                            ).title
                                                        }`
                                                    )}`}</Typography>
                                                }
                                            />
                                        ) : null}
                                    </div>
                                    {el.phone &&
                                    el.email &&
                                    el.course &&
                                    el.teacher &&
                                    el.city ? (
                                        <Stack spacing={'5px'} key={index}>
                                            <Typography>{`${el.phone}`}</Typography>
                                            <Typography>{`${el.email}`}</Typography>
                                            <Typography>{`${el.course}`}</Typography>
                                            <Typography>{`${el.teacher}`}</Typography>
                                            <Typography>{`${
                                                el.city.split('|||')[0]
                                            }`}</Typography>
                                            <Collapse in={!!el.description}>
                                                <Typography
                                                    style={{
                                                        maxWidth: '220px',
                                                        fontWeight: '700',
                                                        margin: '10px',
                                                    }}
                                                >
                                                    <Typography as={'span'}>
                                                        Описание:{' '}
                                                    </Typography>
                                                    {`${el.description || ''}`}
                                                </Typography>
                                            </Collapse>
                                        </Stack>
                                    ) : null}
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </Stack>
        </Card>
    );
};

export default ParseMobilePhones;
