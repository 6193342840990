import { useSelector } from 'react-redux';

function useCheckUserId() {
    // const userId = useSelector((state) => state.user.info.role_id);

    // Перевірка, чи userId дорівнює 1
    // const isUserIdOne = userId === 1;

    return true;
}

export default useCheckUserId;
