import Types from '../constants/payments';
import PaymentsService from '../services/payments.service';
import { reloadTable } from './AsyncTable';
import { getSuccess, getError, getNotification } from './toast';
import i18next from 'i18next';
import { closeRequestModal } from './modals';

function createSoloPayment() {
    return async (dispatch, getState) => {
        return await PaymentsService.CreateSoloBill();
    };
}

function sendMailToAllStudents(data) {
    return (dispatch, getState) => {
        PaymentsService.SendMailToAllStudents(data).then((res) => {
            if (res) {
                getNotification(i18next.t('payments.bill_send_success'));
            }
        });
    };
}

async function unblockStudent(id) {
    const res = await PaymentsService.UnblockStudent({ student_id: id });
    return res;
}

function createPaymentBill(...data) {
    return (dispatch, getState) => {
        return PaymentsService.CreateBill(...data);
    };
}

function getCountedResult() {
    return (dispatch, getState) => {
        return PaymentsService.GetCountedResult();
    };
}
function getPaymentsInfoResult() {
    return (dispatch, getState) => {
        return PaymentsService.getPaymentsInfoResult();
    };
}

function processDebt(data, debtType) {
    return async (dispatch, getState) => {
        switch (debtType) {
            case -1:
            case 0:
                await PaymentsService.ProcessDebt(data);
                break;
            case 1:
                await PaymentsService.ProcessDebtArchive(data);
                break;
            case 2:
                await PaymentsService.ProcessDebtGraduate(data);
        }
        await reloadTable()(dispatch, getState);
    };
}

function getPayments(...args) {
    return (dispatch, getState) => {
        const { payments } = getState();
        try {
            PaymentsService.PaymentsList(...args).then((response) => {
                dispatch({
                    type: Types.PAYMENTS,
                    payments: {
                        ...payments,
                    },
                });
            });
        } catch (e) {
            getError(e);
        }
    };
}

function getPaymentsHistory(...args) {
    return async (dispatch, getState) => {
        try {
            const res = await PaymentsService.PaymentHistory(...args);
            dispatch({
                type: Types.PAYMENTS,
                payments: {
                    list: res.list,
                    status: false,
                },
            });
            return res.list;
        } catch (e) {
            getError(e);
        }
    };
}

function clearPaymentsHistory() {
    return (dispatch, getState) => {
        dispatch({
            type: Types.PAYMENTS,
            payments: {
                list: [],
                status: true,
            },
        });
    };
}

function sendPayments(id, item) {
    return async (dispatch, getState) => {
        const data = getState().AsyncTable.table.data;
        let item = data.find((el) => el.id === id);
        if (!item.payment_sum) return getError('Only zero');
        let sendData =
            item && item.payment_sum
                ? {
                      user_id: item.id,
                      status: 1,
                      pay_type: item.payment_type
                          ? +item.payment_type
                              ? 'online'
                              : 'cash'
                          : item.pay_type,
                      amount: item.payment_sum,
                  }
                : null;
        const { payments } = getState();
        if (sendData === null) return false;
        const response = await PaymentsService.SendPayment(sendData);
        dispatch({
            type: Types.SEND_PAY,
            payments: {
                ...payments,
                result: response,
            },
        });
        return response;
    };
}

async function sendUnblockPayment(data) {
    const res = await PaymentsService.SendPayment(data);
    return res;
}

function returnPayments(id) {
    return async (dispatch, getState) => {
        const data = getState().AsyncTable.table.data;
        let item = data.find((el) => el.id === id);
        let sendData =
            item && item.payment_sum
                ? {
                      user_id: item.id,
                      status: 1,
                      pay_type: item.payment_type
                          ? +item.payment_type
                              ? 'online'
                              : 'cash'
                          : item.pay_type,
                      amount: item.payment_sum,
                  }
                : null;
        const { payments } = getState();
        if (sendData === null) return;
        const response = await PaymentsService.ReturnPayment(sendData);
        dispatch({
            type: Types.RETURN_PAY,
            payments: {
                ...payments,
                result: response,
            },
        });
        return response;
    };
}

function sendPaymentsFranch(data) {
    return (dispatch, getState) => {
        PaymentsService.SendPaymentFranch(data).then((el) => {
            closeRequestModal()(dispatch, getState);
        });
    };
}

export {
    getPayments,
    sendPayments,
    returnPayments,
    sendPaymentsFranch,
    getPaymentsHistory,
    clearPaymentsHistory,
    createPaymentBill,
    processDebt,
    getCountedResult,
    getPaymentsInfoResult,
    sendMailToAllStudents,
    createSoloPayment,
    unblockStudent,
    sendUnblockPayment,
};
