import React from "react";
export default ({ value, code }) => {
  if (+value) value = +value;
  let d = new Date(value);

  const _min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  const _hours = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  const _month =
    d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  const _date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();

  const date = `${_date}.${_month}.${d.getFullYear()} `;
  const minutes = `${_hours}:${_min}`;
  const output = code === "course_start_date" ? date : date + minutes;
  return (
    <span style={{ whiteSpace: "nowrap" }}>{value ? output : "-"}</span>
  );
};
