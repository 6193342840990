import _ from "underscore";
import React from "react";
// import { dayFromNumberShort } from "./../../../common/utilites";

export default ({ value }) => {
    if (!value) return "-";
    let schedule = _.chain(value)
        .map(e => ({ ...e, time: [e.from, e.to].join("-").trim() }))
        .groupBy("time")
        .mapObject(
            (value, key) =>
                _.uniq([_.first(_.pluck(value, "day")), _.last(_.pluck(value, "day"))])
                    .map(e => {
                        // const dayString = dayFromNumberShort(e);
                        // return dayString.charAt(0).toUpperCase() + dayString.slice(1);
                        return 1;
                    })
                    .join("-") +
                ": " +
                key
        )
        .values()
        .value();

    return !schedule.length ? (
        "-"
    ) : (
        <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
            {schedule.map((e, i) => (
                <li key={i} style={{ whiteSpace: "nowrap" }}>
                    {e}
                </li>
            ))}
        </ul>
    );
};
