import React from "react";
import "./Login.css";
import "animate.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FormLogin from "./../../components/Login/Form";
import SelectProfileLogin from "./../../components/Login/Select";
import ResetPassLogin from "./../../components/Login/ResetPass";

class LoginContainer extends React.Component {
  render() {
    return this.props.user.resetPass ? (
      <ResetPassLogin />
    ) : !this.props.user.loginView ? (
      <FormLogin />
    ) : (
      <SelectProfileLogin />
    );
  }
}

const widthConnect = connect(
  (state) => ({ user: state.user }),
  (dispatch) => bindActionCreators({}, dispatch)
);

export default widthConnect(LoginContainer);
