import { ApiConnector, GET, POST, PUT } from "./request.service";

export default (() => {
    const API = ApiConnector("/hwjournal");
    return {
        JournalList: async data =>   await API(GET, "", false).call(data),
        CreateJournal: async data  => await API(POST, ``).call(data),
        SaveJournal: async (data, query) => await API(PUT, "", false, query).call(data)
    };
})();
