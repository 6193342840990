import React, { useState, useCallback, useEffect, useMemo } from 'react';
import CountriesService from './../../services/countries.service';
import { Card, CircularProgress, Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import i18n from 'i18next';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import './index.css';
import { PostFranchPrices } from '../../actions/cities';
import { getError } from '../../actions/toast';
import { getCurrentCurrency } from '../../actions/currencies';

const rolesSchema = {
    BOSS: CountriesService.SetBossPrices,
    FRANCH: PostFranchPrices,
};

const requestSchemas = {
    min_price_offline: rolesSchema.BOSS,
    min_price_offline_li: rolesSchema.BOSS,
    min_price_online: rolesSchema.BOSS,
    min_price_online_li: rolesSchema.BOSS,
    avg_price_online: rolesSchema.FRANCH,
    avg_price_li_online: rolesSchema.FRANCH,
};

const LowSubscribeComponent = ({
    value,
    isLoading = false,
    code,
    updatePrices,
}) => {
    const currency = useMemo(() => {
        return getCurrentCurrency();
    }, []);
    const { t } = useTranslation();
    const classes = useStyles();
    const [newValue, setNewValue] = useState('');
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(false);
    const [currentValue, setCurrentValue] = useState(value);

    const handleDateChange = useCallback((e) => {
        setNewValue(e.target.valueAsNumber);
    }, []);

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    const processRequest = useCallback(
        async ({ code, newValue }) => {
            const schema = requestSchemas[code];
            if (code === 'avg_price_online' || code === 'avg_price_li_online') {
                try {
                    await schema({
                        code,
                        data: { avg_price: newValue },
                    });
                } catch (e) {
                    return { error: true, ...e, code };
                }
                return;
            }
            await schema({ [code]: newValue });
        },
        [requestSchemas]
    );

    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setErrors((item) => {
            if (!item) return null;
            const newValue = structuredClone(item);
            delete newValue[code];
            if (typeof newValue === 'object' && !Object.keys(newValue).length) {
                return null;
            }
            return newValue.filter(Boolean);
        });
        setLoading(true);
        const res = await processRequest({ code, newValue });
        if (res && res.error && res.code && res.message) {
            setErrors({ [code]: getError(res, true) });
        }
        setLoading(false);
        setNewValue('');
        updatePrices();
    }, [newValue, code]);

    return (
        <Card
            className={classes.root}
            style={{
                minHeight: '265px',
                marginRight: '7px',
                marginBottom: '7px',
            }}
        >
            <Box
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="95%"
            >
                <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ marginBottom: '10px', minHeight: '64px' }}
                >
                    {t(`finance.${code}`)}
                </Typography>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        justifyContent: 'flex-start',
                        alignItems: 'baseline',
                        alignContent: 'space-between',
                    }}
                >
                    {currentValue && currentValue !== 0 ? (
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="h6"
                            style={{ fontSize: '14px', marginBottom: '15px' }}
                        >
                            {`${t('payments.current_price')}: `}
                            <span className={`cityPrice ok`}>
                                {currentValue} {currency}
                            </span>
                        </Typography>
                    ) : null}
                    <form onSubmit={handleSubmit}>
                        <TextField
                            id="date"
                            label={`${i18n.t(`table.price`)} ${currency}`}
                            type="number"
                            onChange={handleDateChange}
                            value={newValue}
                            disabled={isLoading || loading}
                            fullWidth
                            helperText={
                                (Boolean(errors) && errors[code]) || null
                            }
                            error={Boolean(errors) && Boolean(errors[code])}
                            variant="outlined"
                        />

                        <Button
                            style={{
                                margin: '15px 0px',
                                marginBottom: '0px',
                            }}
                            className={'bossButton'}
                            fullWidth
                            onClick={handleSubmit}
                            variant="contained"
                            disabled={isLoading || loading || !newValue}
                            id={'block_day_disable'}
                            endIcon={
                                loading ? (
                                    <CircularProgress
                                        size={12}
                                        color="inherit"
                                    />
                                ) : null
                            }
                        >
                            {i18n.t('buttons.save')}{' '}
                        </Button>
                    </form>
                </Box>
            </Box>
        </Card>
    );
};

const useStyles = makeStyles({
    root: {
        maxWidth: 325,
    },
});

export default LowSubscribeComponent;
