import { useEffect, useMemo, useState } from 'react';
import {
    Alert,
    Badge,
    Box,
    IconButton,
    Popper,
    Fade,
    Button,
    Typography,
    Stack,
    Collapse,
} from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import CheckIcon from '@mui/icons-material/Check';
import { useNotificationCenter } from 'react-toastify/addons/use-notification-center';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { countDate } from '../actions/date';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { bindActionCreators } from 'redux';
import usePhoneCheck from '../hooks/usePhoneCheck';

function NotificationCenter(props) {
    const { t } = useTranslation();
    const {
        notifications,
        clear,
        markAllAsRead,
        markAsRead,
        unreadCount,
        add,
        remove,
    } = useNotificationCenter({
        filter: (el) =>
            el.type === 'error' || el.type === 'warning' || el.type === 'info',
    });
    const [showUnreadOnly, setShowUnreadOnly] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isReady, setIsReady] = useState(false);
    const [notificationEditing, setNotificationsEditing] = useState(false);
    const [notificationsUpdated, setNotificationsUpdated] = useState(
        notifications && notifications.length
            ? notifications.map((el) => ({ ...el, showData: false }))
            : notifications
    );

    const user = useSelector((state) => state.user);

    const toggleNotificationData = async (id) => {
        setNotificationsEditing(true);
        const newObj = JSON.parse(JSON.stringify(notificationsUpdated));
        const itemIndex = newObj.findIndex((el) => el.id === id);
        const item = newObj[itemIndex];
        if (item && item.data && Object.keys(item.data).length) {
            item.showData = !item.showData;
            await setNotificationsUpdated(newObj);
        }
        setNotificationsEditing(false);
    };

    useEffect(() => {
        if (!notificationEditing) {
            setNotificationsUpdated(
                notifications.map((el) => ({
                    ...el,
                    showData: el.showData || false,
                }))
            );
        }
    }, [notifications]);

    const user_id = useMemo(() => {
        return user.emulation.length
            ? user.emulation[user.emulation.length - 1].info.user_id
            : user.info.user_id;
    }, [user]);
    const toggleNotificationCenter = (event) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };
    useEffect(() => {
        if (!isReady) {
            return;
        }

        const storeData = getStoreData();
        localStorage.setItem(
            'notifications',
            JSON.stringify({ ...storeData, [user_id]: notifications })
        );
    }, [notifications, isReady]);

    useEffect(() => {
        processNotificationStore();
    }, []);

    const processNotificationStore = async () => {
        const notificationsStore = getStoreData(user_id);
        if (notificationsStore && notificationsStore.length) {
            for (const item of notificationsStore) {
                await add(item);
            }
        }
        setIsReady(true);
    };

    const getStoreData = (user_id = false) => {
        const store = localStorage.getItem('notifications');
        try {
            if (store) {
                const allStore = JSON.parse(store);
                if (!user_id) return allStore;
                return allStore[user_id] || [];
            }
        } catch (e) {
            console.log(e);
        }
    };
    const noMessages =
        !notifications.length || (unreadCount === 0 && showUnreadOnly);

    const isMobile = usePhoneCheck();

    return (
        <>
            <IconButton size="large" onClick={toggleNotificationCenter}>
                <Badge badgeContent={unreadCount} color="primary">
                    <MailIcon color="action" />
                </Badge>
            </IconButton>
            <Popper
                open={isOpen}
                anchorEl={anchorEl}
                transition
                sx={{
                    width: isMobile ? '100%' : 'unset',
                }}
                className={`themeColor${props.theme.toUpperCase()}`}
            >
                {({ TransitionProps }) => (
                    <>
                        <Fade {...TransitionProps} timeout={350}>
                            <Box>
                                <Box
                                    sx={{
                                        background: '#666',
                                        padding: '8px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderRadius: isMobile
                                            ? '0px'
                                            : '8px 0px 0px 0px',
                                    }}
                                >
                                    <Typography
                                        variant="h5"
                                        style={{
                                            fontSize: '16px',
                                            padding: '5px 10px',
                                        }}
                                        color="#fff"
                                    >
                                        {t(
                                            'notifications.notifications_center'
                                        )}
                                    </Typography>
                                </Box>
                                <Stack
                                    sx={{
                                        height: '400px',
                                        width: isMobile
                                            ? '100%'
                                            : 'min(60ch, 100ch)',
                                        padding: '12px',
                                        background: '#f1f1f1',
                                        overflowY: 'auto',
                                    }}
                                    spacing={2}
                                    className="notificationsCenter messagesContainer"
                                >
                                    {noMessages && (
                                        <h4
                                            style={{
                                                fontSize: '14px',
                                                padding: '5px 10px',
                                                fontWeight: 300,
                                            }}
                                        >
                                            {t('notifications.queue_empty')}{' '}
                                            <span
                                                role="img"
                                                aria-label="dunno what to put"
                                            >
                                                🎉
                                            </span>
                                        </h4>
                                    )}
                                    {(showUnreadOnly
                                        ? notificationsUpdated.filter(
                                              (v) => !v.read
                                          )
                                        : notificationsUpdated
                                    ).map((notification) => {
                                        return (
                                            <Alert
                                                className="notificationItem"
                                                key={notification.id}
                                                severity={
                                                    notification.type || 'info'
                                                }
                                                action={
                                                    <>
                                                        <IconButton
                                                            aria-label="mark as read"
                                                            component="span"
                                                            disabled={
                                                                notification.read
                                                            }
                                                            onClick={() =>
                                                                markAsRead(
                                                                    notification.id
                                                                )
                                                            }
                                                        >
                                                            {(notification.read && (
                                                                <CheckIcon />
                                                            )) || (
                                                                <MarkChatReadIcon />
                                                            )}
                                                        </IconButton>
                                                        <IconButton
                                                            aria-label="remove notification"
                                                            component="span"
                                                            onClick={() =>
                                                                remove(
                                                                    notification.id
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                }
                                            >
                                                {notification.content}
                                                <br />
                                                {(!!(
                                                    notification.data &&
                                                    Object.keys(
                                                        notification.data
                                                    ).length
                                                ) && (
                                                    <Button
                                                        type={'text'}
                                                        className={
                                                            'MuiTypography-caption'
                                                        }
                                                        style={{
                                                            fontSize: '10px',
                                                            textDecoration:
                                                                'underline',
                                                            fontWeight: 300,
                                                            textTransform:
                                                                'capitalize',
                                                            color: '#242424c7',
                                                        }}
                                                        onClick={() =>
                                                            toggleNotificationData(
                                                                notification.id
                                                            )
                                                        }
                                                    >
                                                        {notification.showData
                                                            ? 'Сховати...'
                                                            : 'Детальніше...'}
                                                    </Button>
                                                )) ||
                                                    null}

                                                <Collapse
                                                    in={notification.showData}
                                                >
                                                    <Typography
                                                        variant={'caption'}
                                                        style={{
                                                            whiteSpace:
                                                                'pre-wrap',
                                                        }}
                                                    >
                                                        {JSON.stringify(
                                                            notification.data,
                                                            null,
                                                            2
                                                        )}
                                                    </Typography>
                                                </Collapse>

                                                <br />

                                                <Typography
                                                    variant={'caption'}
                                                    style={{
                                                        fontSize: '10px',
                                                    }}
                                                >
                                                    {countDate(
                                                        notification.createdAt,
                                                        true
                                                    )}
                                                </Typography>
                                            </Alert>
                                        );
                                    })}
                                </Stack>
                                <Box
                                    sx={{
                                        background: '#666',
                                        padding: '8px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        borderRadius: isMobile
                                            ? '0px'
                                            : '0px 0px 0px 8px',
                                    }}
                                >
                                    <Button
                                        disabled={noMessages}
                                        variant="text"
                                        onClick={clear}
                                        style={{
                                            borderRadius: '5px',
                                            padding: '5px 15px',
                                            backgroundColor: '#dc4d4d',
                                            color: '#fff',
                                            fontSize: '12px',
                                        }}
                                        endIcon={<ClearAllIcon />}
                                    >
                                        {t('notifications.clear_all')}
                                    </Button>

                                    <Button
                                        disabled={noMessages}
                                        variant="text"
                                        onClick={() => markAllAsRead()}
                                        style={{
                                            borderRadius: '5px',
                                            padding: '5px 15px',
                                            fontSize: '12px',
                                            color: '#fff',
                                        }}
                                        endIcon={<DoneAllIcon />}
                                    >
                                        {t('notifications.mark_as_read')}
                                    </Button>
                                </Box>
                            </Box>
                        </Fade>
                    </>
                )}
            </Popper>
            <div
                style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100vh',
                    backgroundColor: '#00000066',
                    zIndex: isOpen ? 9 : -10,
                    opacity: isOpen ? 1 : 0,
                    marginLeft: '0px',
                }}
                onClick={toggleNotificationCenter}
            ></div>
        </>
    );
}

const withConnect = connect(
    (state) => ({
        theme: state.user && state.user.theme,
    }),
    (dispatch) => bindActionCreators({}, dispatch)
);

export default withConnect(NotificationCenter);
