import Types from './../constants/bank';
const defaultState = {
    customer: {},
    market: [],
    cart: [],
    jwt: '',
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.REGISTRATION:
            return { ...state, ...action.payload };
        case Types.SET_BALANCE:
            return {
                ...state,
                customer: { ...state.customer, balance: action.payload },
            };
        case Types.SET_PENDING_TRANSACTIONS:
            return {
                ...state,
                customer: { ...state.customer, pendingTransactions: action.payload },
            };
        case Types.SET_PENDING_ROOT_TRANSACTIONS:
            return {
                ...state,
                customer: { ...state.customer, pendingRootTransactions: action.payload },
            };
        case Types.SET_PENDING_ORDERS:
            return {
                ...state,
                customer: { ...state.customer, pendingOrders: action.payload },
            };
        case Types.SET_TEACHERS_BALANCES:
            return {
                ...state,
                customer: { ...state.customer, teachersBalances: action.payload },
            };   
        case Types.SET_STUDENTS_BALANCES:
            return {
                ...state,
                customer: { ...state.customer, studentsBalances: action.payload },
            }; 
        case Types.SET_CITIES_BALANCES:
            return {
                ...state,
                customer: { ...state.customer, citiesBalances: action.payload },
            }; 
        case Types.SET_All_PRODUCTS:
            return {
                ...state,
                market: action.payload,
            };
        case Types.SET_All_CART_ITEMS: // отримати всі товари у кошику
            return {
                ...state,
                cart: action.payload,
            };
        case Types.CLEAR_STATE:
            return {
                ...defaultState,
            };
        default:
            return state;
    }
};

