import CurrenciesService from '../services/currencies.service';
import store from '../store';
const getCurrenciesList = async () => {
    return await CurrenciesService.CurrenciesList();
};

const getCurrentCurrency = () => {
    const user = store.getState().user;
    const currency = user && user.currency && user.currency.code;
    return currency || '';
};

export { getCurrenciesList, getCurrentCurrency };
