import { ApiConnector, GET, POST, PUT, DELETE } from './bank-request.service';

export default (() => {
    const API = ApiConnector('/cart');
    return {
        GetCartItems: async () => await API(GET, '').call(),
        AddItemToCart: async (data) => await API(POST, '').call(data),
        EditCartItemQuantit: async (data, noMessage = false) =>
            await API(PUT, '').call(data, noMessage, true),
        ConfirmProductsByIds: async (data, noMessage = false) =>
            await API(POST, '/confirm').call(data, noMessage),
        DeleteProductFromCart: async (id) => await API(DELETE, `/${id}`).call(),
    };
})();
