const languageArray = [
    'ru',
    'ua',
    'it',
    'pl',
    'by',
    'ro',
    'ee',
    'en',
    'de',
    'cz',
];
const LANGUAGES = {
    ru: 0,
    ua: 1,
    en: 2,
    it: 3,
    pl: 4,
    by: 5,
    ro: 6,
    de: 7,
    cz: 8,
};

const schemaLangs = {
    md: ['ru', 'ro'],
    ru: ['ru'],
    ua: ['ua', 'ru'],
    com: ['en'],
    ee: ['ru'],
    by: ['by', 'ru'],
    cz: ['en', 'cz'],
};

export { languageArray, LANGUAGES, schemaLangs };
