import React from 'react';
import background from './../../resources/img/background.svg';
import darkBackground from './../../resources/img/darkBackground.svg';

import { useSelector } from 'react-redux';
import ThemeColorSwitch from '../../components/PanelHeader/ThemeColorSwitch';
import 'react-toastify/dist/ReactToastify.css';

const AuthLayout = ({ children }) => {
    const { theme } = useSelector((state) => state.user);
    return (
        <React.Fragment>
            <div style={{ position: 'absolute', zIndex: 100 }}>
                <ThemeColorSwitch />
            </div>
            <div
                className={`back-container themeColor${theme.toUpperCase()}`}
                style={{
                    backgroundImage: `url(${
                        theme === 'white' ? background : darkBackground
                    })`,
                }}
            >
                {children}
            </div>{' '}
            {/*<ToastContainer*/}
            {/*    stacked*/}
            {/*    position="top-right"*/}
            {/*    autoClose={5000}*/}
            {/*    hideProgressBar={false}*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnVisibilityChange*/}
            {/*    draggable*/}
            {/*    pauseOnHover*/}
            {/*/>*/}
        </React.Fragment>
    );
};
export default AuthLayout;
