import Types from "./../constants/roles";

const defaultState = {
    list: "",
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.SUPERADMIN:
        case Types.BOSS:
        case Types.FRANCHISE:
        case Types.TEACHER:
        case Types.PUPIL:
        case Types.DEVELOPER:
        case Types.CALLCENTER:
            return { ...state, ...action.roles };
        default:
            return state;
    }
};
