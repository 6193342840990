import Types from './../constants/countries';

const defaultState = {
    list: null,
    count: 0,
    prices: {
        loading: true,
        data: null,
    },
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.GETCOUNTRIES:
            return { ...state, ...action.countries };
        default:
            return state;
    }
};
