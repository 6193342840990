import Types from "./../constants/getList";

const defaultState = {
    list: null,
    count: 0,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.OFFICES:
        case Types.GETOFFICES:
            return { ...state, ...action.offices };
        default:
            return state;
    }
};
