import React from 'react';
import PanelHeader from '../../components/PanelHeader/PanelHeader';
import Sidebar from '../../components/Sidebar';
import './Dash.css';
import store from './../../store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'react-toastify/dist/ReactToastify.css';

class DashLayout extends React.Component {
    render() {
        const types = {
            0: 'panel-back animated fadeIn faster',
            1: 'panel-back animated fadeOutRight faster',
        };
        return (
            <div
                className={`${types[store.getState().user.animation]}${
                    this.props.group_active &&
                    this.props.group_active.eq_option &&
                    this.props.group_active.eq_option === 2 &&
                    this.props.history.location.pathname.includes('groups') &&
                    !!Object.keys(this.props.match.params).length
                        ? ' new-eq'
                        : ''
                } themeColor${this.props.theme.toUpperCase()}`}
            >
                <PanelHeader {...this.props} />
                <div className="dash-main-container">
                    <Sidebar />
                    <div className="base-container">{this.props.children} </div>
                </div>
            </div>
        );
    }
}

const withConnect = connect(
    (state) => ({
        user: state.user,
        theme: state.user && state.user.theme,
        group_active: state.groups && state.groups.single,
    }),
    (dispatch) => bindActionCreators({}, dispatch)
);
export default withConnect(DashLayout);
