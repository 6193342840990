import { ApiConnector, GET, POST, PUT, DELETE } from "./bank-request.service";


export default (() => {
    const API = ApiConnector('/products');
    return {
        GetProductsAllFranch: async () => await API(GET, "/all/franch").call(),
        GetActiveProducts: async () => await API(GET, "").call(),
        GetProductById: async (id) => await API(GET, `/${id}`).call(),
        AddProduct: async (data) => await API(POST, "").call(data),
        EditProduct: async (data) => await API(PUT, "").call(data),
        EditProductQuantity: async (data) => await API(PUT, "/quantity").call(data),
        HideProductById: async (data) => await API(PUT, "/hide").call(data),
        DeleteProduct: async (id) => await API(DELETE, `/${id}`).call(),   
        AddItemToCart: async (data) => await API(POST, "/cart").call(data),
    };
})();
