import React, {Fragment} from "react";
import './Requests.css';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeRequestModal, openRequestModal} from "../../actions/modals";

import ComponentTable from "../../components/ComponentTable";

import SCHEMAS from "../../constants/modalSchemas";


class RequestsPage extends React.Component {




    render() {

        const headRows = [
            {field: 'id', title: '№'},
            {field: 'email_login', title: 'Логiн'},
            {field: 'first_name', title: 'Iм`я'},
            {field: 'last_name', title: 'Прiзвище' },
            {field: 'buttons', title: 'Дії'},
        ];

        let rows;

         // if (this.props.users.list) {
         //     rows = this.props.users.list;
         // } else {
             rows = [
                 {
                     id: null,
                     login: null,
                     first_name: null,
                     last_name: null,

                 }];
            // };

                 rows.map((item, key) => {

                 item.buttons = <button onClick={this.props.openRequestModal.bind(this,
                     {
                         opened: true,
                         data: {
                             id: item.id,
                             schema: SCHEMAS.editUser,
                             header: 'Редагувати',

                         }})}>open</button>;
                 return item;
             });



        return (
            <Fragment>
                <h2 className="container-header"> Заявки </h2>
                <Fragment>
                    <ComponentTable
                        rowData={rows}
                        headData={headRows}
                        headerTable="Заявки"
                        selectable={true}
                        toolbarvisible={true}/>
                    
                </Fragment>

            </Fragment>

        )
    }
}

const widthConnect = connect(state => ({ user: state.user, modalWindow:state.modalWindow}),
    dispatch => bindActionCreators({ openRequestModal, closeRequestModal }, dispatch));

export default widthConnect(RequestsPage);
