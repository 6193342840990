import React from 'react';
import { connect } from 'react-redux';
import './index.css';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import {
    __ProcessReportFile,
    getFranchAnalitics,
    getStatReport,
} from '../../actions/analytics';
import {
    getShortAnalytics,
    getBossShortAnalytics,
    getBossFinanceData,
} from '../../actions/finance';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { getOffices } from '../../actions/getOfficesList';
import { getTeachersFromSelect } from '../../actions/groups';
import { STATUSES } from '../../constants/statuses';
import { ExportToExcel } from './export';

import Paper from '@material-ui/core/Paper';
import FinanceService from '../../services/finance.service';
import LTV from '../../components/LTV';

class AnalyticsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_report: false,
            download: [],
            data: [],
            selected: '',
            date: '',
            top_loader: false,
            loader_timeout: null,
            office_report: [],
            teacher_report: [],
            status_report: [],
            dates: {},
            report_date: null,
            loading_detailed: false,
            loading_outflow: false,
            loading_kpi: false,
        };
    }

    componentDidMount() {
        this.getDataStat();
    }

    async getDataStat() {
        this.props.getOffices(false, { size: 9999 });
        this.props.getTeachersFromSelect(false, { size: 9999 });
    }

    async getDataReport() {
        this.setState({ loading_report: true, download: [], data: [] });
        const school = this.state.office_report.length
            ? this.state.office_report.map((el) => el.id).join()
            : this.props.offices.list.map((el) => el.id).join();
        const teacher = this.state.teacher_report.length
            ? this.state.teacher_report.map((el) => el.id).join()
            : (
                  this.props.teachers &&
                  this.props.teachers.teacher_select &&
                  this.props.teachers.teacher_select.teacher
              )
                  .map((el) => el.id)
                  .join();
        const status = this.state.status_report.length
            ? this.state.status_report.map((el) => el.id).join()
            : STATUSES.map((el) => el.id).join();
        const res = await getStatReport({ school, teacher, status });
        if (!res) {
            return this.setState({ loading_report: false });
        }
        await __ProcessReportFile(res);
        this.setState({ loading_report: false, download: [], data: [] });
    }

    getRoleId() {
        return this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
    }

    async getKPIReport() {
        const roleId = this.getRoleId();

        this.setState({ loading_kpi: true });
        let dates = !!this.state.dates.date
            ? { date: this.state.dates.date }
            : { date: new Date() };
        let data;
        if (roleId === 2) {
            data = await FinanceService.getKPIBoss(dates);
        } else if (roleId === 3) {
            data = await FinanceService.getKPITeacher(dates);
        }
        if (!data) {
            return this.setState({ loading_kpi: false });
        }
        await __ProcessReportFile(data);

        this.setState({ loading_kpi: false });
    }

    async getDataOutflowReport() {
        const roleId = this.getRoleId();
        this.setState({ loading_outflow: true });
        let dates = !!this.state.dates.date
            ? { date: this.state.dates.date }
            : { date: new Date() };
        let data;
        if (roleId === 2) {
            data = await FinanceService.getBossOutflow(dates);
        } else if (roleId === 3) {
            data = await FinanceService.getFranchOutflow(dates);
        }
        await __ProcessReportFile(data);

        this.setState({ loading_outflow: false });
    }

    async getDataSimple() {
        this.setState({ loading: true });
        let dates = !!this.state.dates.date
            ? { date: this.state.dates.date }
            : { date: new Date() };

        let data =
            this.getRoleId() === 2
                ? await this.props.getBossShortAnalytics(dates)
                : await this.props.getShortAnalytics(dates);
        if (!data) {
            return this.setState({ loading: false });
        }
        await __ProcessReportFile(data);
        this.setState({ loading: false });
    }

    async getData() {
        this.setState({ loading_detailed: true });
        let dates = !!this.state.dates.date
            ? { date: this.state.dates.date }
            : { date: new Date() };
        let data =
            this.getRoleId() === 2
                ? await this.props.getBossFinanceData(dates)
                : await this.props.getFranchAnalitics(dates);
        if (!data) {
            return this.setState({ loading_detailed: false });
        }
        await __ProcessReportFile(data);
        this.setState({ loading_detailed: false });
    }

    render() {
        const roleId = this.getRoleId();
        const { t } = this.props;
        const onChange = (dates) => {
            this.setState({
                dates: {
                    ...this.state.dates,
                    date: dates,
                },
            });
        };
        const handleClearDates = () => {
            this.setState({
                dates: {
                    from: null,
                    to: null,
                },
            });
        };
        return (
            <>
                <LTV />
                <h2 className="container-header">{t('sidebar.analytics')} </h2>
                <Paper>
                    <Box p={2} m={2}>
                        <h2 className="container-header">
                            {t('analytics.averege_stat')}
                        </h2>
                        <div>
                            <DatePicker
                                className="datePicker_analytics"
                                autoOk
                                disableFuture
                                allowKeyboardControl={false}
                                animateYearScrolling
                                variant="inline"
                                minDate={new Date('2021-03-01')}
                                views={['year', 'month']}
                                inputVariant="outlined"
                                format="MM.yyyy"
                                value={this.state.dates.date}
                                onChange={(date) => onChange(date)}
                            />
                            {!!this.state.dates.from ||
                            !!this.state.dates.to ? (
                                <IconButton
                                    onClick={handleClearDates}
                                    title={t('buttons.delete')}
                                    className="datePicker_analytics"
                                >
                                    <ClearIcon />
                                </IconButton>
                            ) : null}
                        </div>

                        <Box style={{ display: 'flex' }}>
                            <Box
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'flex-start',
                                    flexWrap: 'wrap',
                                }}
                            >
                                <Box component="div" m={1}>
                                    <Button
                                        variant="contained"
                                        className="greenButton"
                                        onClick={() =>
                                            !this.state.loading
                                                ? this.getDataSimple()
                                                : null
                                        }
                                        type="submit"
                                        disabled={this.state.loading}
                                        style={{ margin: 0, marginTop: '15px' }}
                                        endIcon={
                                            !this.state.loading ? null : (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            )
                                        }
                                    >
                                        {`${t('finance.get_report')} (xlsx)`}
                                    </Button>
                                </Box>

                                <Box component="div" m={1}>
                                    <Button
                                        variant="contained"
                                        className="greenButton"
                                        onClick={() =>
                                            !this.state.loading_detailed
                                                ? this.getData()
                                                : null
                                        }
                                        disabled={this.state.loading_detailed}
                                        style={{ margin: 0, marginTop: '15px' }}
                                        endIcon={
                                            !this.state
                                                .loading_detailed ? null : (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            )
                                        }
                                    >
                                        {`${t(
                                            'finance.get_full_report'
                                        )} (xlsx)`}
                                    </Button>
                                </Box>

                                {roleId === 3 || roleId === 2 ? (
                                    <>
                                        <Box component="div" m={1}>
                                            <Button
                                                variant="contained"
                                                className="greenButton"
                                                onClick={() =>
                                                    !this.state.loading_outflow
                                                        ? this.getDataOutflowReport()
                                                        : null
                                                }
                                                type="submit"
                                                disabled={
                                                    this.state.loading_outflow
                                                }
                                                style={{
                                                    margin: 0,
                                                    marginTop: '15px',
                                                }}
                                                endIcon={
                                                    !this.state
                                                        .loading_outflow ? null : (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    )
                                                }
                                            >
                                                OUTFLOW Report (xlsx)
                                            </Button>
                                        </Box>

                                        <Box component="div" m={1}>
                                            <Button
                                                variant="contained"
                                                className="greenButton"
                                                onClick={() =>
                                                    !this.state.loading_kpi
                                                        ? this.getKPIReport()
                                                        : null
                                                }
                                                type="submit"
                                                disabled={
                                                    this.state.loading_kpi
                                                }
                                                style={{
                                                    margin: 0,
                                                    marginTop: '15px',
                                                }}
                                                endIcon={
                                                    !this.state
                                                        .loading_kpi ? null : (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    )
                                                }
                                            >
                                                KPI Report (xlsx)
                                            </Button>
                                        </Box>
                                    </>
                                ) : null}
                            </Box>
                            <ExportToExcel
                                id="downloadShortAnalytic"
                                formatData={this.state.download}
                                fileName={`${t('finance.simple_report')}_${
                                    this.state.report_date
                                }`}
                            />
                            <ExportToExcel
                                id="downloadAll"
                                formatData={this.state.download}
                                fileName={`${t('finance.detailed_report')}_${
                                    this.state.report_date
                                }`}
                            />
                            <ExportToExcel
                                id="downloadOutflowReport"
                                formatData={this.state.download}
                                fileName={`OUTFLOW REPORT_${this.state.report_date}`}
                            />
                            <ExportToExcel
                                id="downloadKPIReport"
                                formatData={this.state.download}
                                fileName={`KPI REPORT_${this.state.report_date}`}
                            />
                        </Box>
                    </Box>
                </Paper>
                {roleId !== 2 ? (
                    <Paper>
                        <Box p={2} m={2}>
                            <h2 className="container-header">
                                {t('analytics.stat_report')}
                            </h2>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <Autocomplete
                                    options={this.props.offices.list || [0]}
                                    disabled={
                                        this.props.offices.list &&
                                        !this.props.offices.list.length
                                    }
                                    disableCloseOnSelect
                                    multiple
                                    getOptionLabel={(option) =>
                                        option.title.split('|||')[0] || ''
                                    }
                                    className="statReport"
                                    value={this.state.office_report}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            office_report: newValue,
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('sidebar.offices')}
                                            name="office_report"
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    options={
                                        (this.props.teachers &&
                                            this.props.teachers
                                                .teacher_select &&
                                            this.props.teachers.teacher_select
                                                .teacher) || [0]
                                    }
                                    disableCloseOnSelect
                                    multiple
                                    getOptionLabel={(option) =>
                                        `${option.first_name} ${option.last_name}` ||
                                        ''
                                    }
                                    className="statReport"
                                    value={this.state.teacher_report}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            teacher_report: newValue,
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('table.teacher')}
                                            name="teacher_report"
                                            variant="outlined"
                                        />
                                    )}
                                />
                                <Autocomplete
                                    options={STATUSES}
                                    multiple
                                    disableCloseOnSelect
                                    getOptionLabel={(option) =>
                                        t(`users.${option.title}`) || ''
                                    }
                                    className="statReport"
                                    value={this.state.status_report}
                                    onChange={(event, newValue) => {
                                        this.setState({
                                            status_report: newValue,
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('table.status')}
                                            name="status_report"
                                            variant="outlined"
                                        />
                                    )}
                                />

                                <Box component="div" m={1}>
                                    {this.state.loading_report ? (
                                        <Button
                                            variant="contained"
                                            className="greenButton"
                                            type="submit"
                                            disabled={this.state.loading_report}
                                            style={{
                                                width: '250px',
                                                margin: 0,
                                            }}
                                        >
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        </Button>
                                    ) : (
                                        <Button
                                            variant="contained"
                                            className="greenButton"
                                            onClick={() => this.getDataReport()}
                                            disabled={
                                                !!this.state.dates.from &&
                                                !!this.state.dates.to
                                                    ? false
                                                    : !!this.state.dates.from ||
                                                      !!this.state.dates.to
                                            }
                                            type="submit"
                                            style={{
                                                width: '250px',
                                                margin: 0,
                                            }}
                                        >
                                            {`${t(
                                                'buttons.download_csv'
                                            )} (xlsx)`}
                                        </Button>
                                    )}
                                </Box>
                            </div>
                            <ExportToExcel
                                id="downloadAllReport"
                                formatData={this.state.download}
                                fileName={`${this.state.date}_statistics_report`}
                            />
                        </Box>
                    </Paper>
                ) : null}
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        offices: state.offices,
        teachers: state.groups,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                getFranchAnalitics,
                getOffices,
                getTeachersFromSelect,
                getShortAnalytics,
                getBossShortAnalytics,
                getBossFinanceData,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(AnalyticsPage));
