import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BankCustomersService from '../../services/bank-customers.service';
import { setBalance, setToken } from '../../actions/bank';
import ToDepositSorocoins from './ToDepositSorocoins.jsx';
import { useTranslation } from 'react-i18next';

import { Paper, Box, TextField, Stack } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import UpdateIcon from '@mui/icons-material/Update';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import BackButton from '../../components/BalanceComponent/BackButton';


const styles = (theme) => ({
    flexContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
});

function StudentBankSorobanPage(props) {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [studentsBalance, setStudentsBalance] = useState('0');
    const [vip, setVip] = useState(false);
    const [registered, setRegistered] = useState(false);

    const { classes } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;
    const { id, role } = useParams();

    const getCheckExist = async () => {
        const res = await BankCustomersService.GetCheckExist();
        if (res && res.jwt) {
            dispatch(setToken(res));
            dispatch(setBalance());
        }
    };

    const fetchPaymentInfo = async () => {
        const res = await BankCustomersService.GetPaymentInfo(id);
        if (Object.keys(res).length) {
            setRegistered(true)
            setName(res.first_name);
            setSurname(res.last_name);
            setStudentsBalance(res.balance)
            setVip(res.isVip || false)
        } else {
            toast.error(t('userBankSorobanPage.setBankError'));
        }
    };

    const fetchBalance = async () => {
        dispatch(setBalance());
    };

    useEffect(() => {
        getCheckExist();
        fetchPaymentInfo();
        fetchBalance();
    }, []);

    function handleClick() {
        fetchBalance();
    }

    const handleChange = async (event) => {
        const newStatus = !vip; 
        try {
            await BankCustomersService.SetVipStatus({
                student_id: Number(id),
                status: newStatus,
            });

            setVip(newStatus);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {role === 'student'
                ? <BackButton to={`/students`} label={t('userBankSorobanPage.students')} />
                : <BackButton to={`/dashboard/balances/${role}`} label={t('userBankSorobanPage.balances')} />
            }
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography>SOROBANK</Typography>
                <Stack
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={1}
                >
                    <Tooltip
                        title={t('userBankSorobanPage.updateBalance')}
                        placement="bottom"
                    >
                        <IconButton
                            onClick={handleClick}
                            style={{
                                width: '40px',
                                height: '40px',
                                borderRadius: '20px',
                            }}
                            aria-label={t('userBankSorobanPage.updateBalance')}
                        >
                            <UpdateIcon style={{ color: '#58a315' }} />
                        </IconButton>
                    </Tooltip>
                    <div className={classes.flexContainer}>
                        <Typography style={{ marginRight: '12px' }}>
                            {t('userBankSorobanPage.yourBalance')}{' '}
                        </Typography>
                        <span style={{ color: '#44A340' }}>
                            {balance} SoroCoins
                        </span>
                    </div>
                </Stack>
            </Stack>
            <Paper elevation={0}>
                <Box m={2} p={2}>
                    <div className={classes.flexContainer}>
                        <Stack direction="row">
                        <Typography style={{marginRight: '40px'}}>
                            {name} {surname}
                        </Typography>
                            {registered === true &&
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={vip} onChange={handleChange} name="vip" />
                                    }
                                    label="VIP"
                                />
                            }
                            </Stack>
                        <Stack direction="row">
                            <Typography
                                align="right"
                                style={{ marginRight: '16px' }}
                            >
                                {t('userBankSorobanPage.balance')}
                                <span
                                    style={{
                                        display: 'inline',
                                        marginLeft: '5px',
                                        color: '#44A340',
                                        textAlign: 'right',
                                    }}
                                >
                                    {studentsBalance || 0} SoroCoins
                                </span>
                            </Typography>
                            <Typography align="right">id={id}</Typography>
                        </Stack>
                    </div>
                </Box>
            </Paper>
            <Paper elevation={0}>
                <div style={{ width: '360px' }}>
                    <ToDepositSorocoins setStudentsBalance={setStudentsBalance} />
                </div>
            </Paper>
        </>
    );
}

export default withStyles(styles)(StudentBankSorobanPage);
