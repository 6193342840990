const BOOKS = [
  {
    value: "K",
    label: "KIDS",
  },
  {
    value: "1",
    label: "BOOK 1",
  },
  {
    value: "2",
    label: "BOOK 2",
  },
  {
    value: "M",
    label: "MULTI",
  },
];

export default BOOKS;
