import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, Field, initialize } from 'redux-form';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { setBalance } from '../../actions/bank';
import { getError, getSuccess } from '../../actions/toast';
import { closeRequestModal } from '../../actions/modals';
import BankCustomersService from '../../services/bank-customers.service';
import BankTransactionsService from '../../services/bank-transactions.service';
import { useTranslation } from 'react-i18next';
import {
    TextField,
    Stack,
    Paper,
    Box,
    Typography,
    Collapse,
} from '@mui/material';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from 'copy-to-clipboard';
import renderTextFieldSorocoinsAmount from '../../components/FormComponents/TextFieldSorocoinsAmount';

const renderTextField = ({ input, meta, ...custom }) => (
    <TextField {...input} {...custom} />
);

const renderTextFieldCardNumber = ({ input, meta, ...custom }) => (
    <TextField
        {...input}
        {...custom}
        onChange={(e) => {
            const value = e.target.value;

            // Дозволити лише цифри
            const formattedValue = value.replace(/[^0-9]/g, '');

            // Обмежити до 16 символів
            if (formattedValue.length <= 16) {
                input.onChange(formattedValue);
            }
        }}
    />
);


function ToDepositSorocoins(props) {
    const [cardNumber, setCardNumber] = useState('');
    const [isInvalidCardNumber, setIsInvalidCardNumber] = useState(false);
    const [isInvalidBalance, setIsInvalidBalance] = useState(false);
    const [showCopiedText, setShowCopiedText] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.bank);
    const balance = user.customer.balance || 0;
    const authorizedUserCardNumber = useSelector(
        (state) => state.bank.customer.acc
    );

    const { id } = useParams();
    const isTransferFromModal = !!(props.data && props.data.id);
    const fetchPaymentInfo = async () => {
        const ID = id || (props.data && props.data.id);
        const res = await BankCustomersService.GetPaymentInfo(ID);

        if (Object.keys(res).length) {
            setCardNumber(res.cardNumber);
        } else {
            toast.error(t('userBankSorobanPage.setBankError'));
        }
    };

    const fetchDataProfile = async () => {
        const res = await BankCustomersService.GetProfile();
        setBalance(res.balance || 0);
    };

    useEffect(() => {
        fetchPaymentInfo();
        fetchDataProfile();
    }, []);

    React.useEffect(() => {
        props.initialize({
            cardNumber: isTransferFromModal ? '' : cardNumber || '',
            sorocoinsAmount: '',
            description: '',
        });
    }, [props.initialize, cardNumber]);

    const fetchBalance = async () => {
        dispatch(setBalance());
    };

    useEffect(() => {
        fetchBalance();
    }, []);

    const handleCopy = (text) => {
        copy(text.replaceAll(' ', ''));
        setShowCopiedText(true);
        setTimeout(() => setShowCopiedText(false), 3000);
    };

    const handleSubmit = async (formData) => {
        const enteredAmount = parseFloat(formData.sorocoinsAmount);
        const cleanValueСardNumber = formData.cardNumber
            .replace(/\s+/g, '')
            .trim();
        // Додаємо пробіли кожні 4 цифри
        const formattedValueСardNumber =
            cleanValueСardNumber.match(/.{1,4}/g)?.join(' ') || '';

        if (formattedValueСardNumber === authorizedUserCardNumber) {
            toast.error(t('toDepositSorocoins.transferToOwnCardUnavailable'));
            setIsInvalidCardNumber(true);
            return;
        } else {
            setIsInvalidCardNumber(false);
        }

        if (parseFloat(enteredAmount) <= parseFloat(balance)) {
            const normalizedAmount = formData.sorocoinsAmount.replace(',', '.');
            const data = {
                amount: parseFloat(normalizedAmount),
                cardNumber: formattedValueСardNumber,
                note: formData.description,
            };

            try {
                await BankTransactionsService.TransferToCard(data);
                setTimeout(fetchBalance, 50);
                setIsInvalidBalance(false);
                const ID = id || (props.data && props.data.id);
                const res = await BankCustomersService.GetPaymentInfo(ID);

                if (props.setStudentsBalance) {
                    props.setStudentsBalance(res.balance);
                }

                props.reset('toDepositSorocoinsForm');
                dispatch(closeRequestModal());
                getSuccess();
            } catch (error) {
                if (error.message === 'Card number does not exist') {
                    getError(error);
                    setIsInvalidCardNumber(true);
                }
            }
        } else {
            toast.error(t('toDepositSorocoins.notEnoughMoney'));
            setIsInvalidBalance(true);
        }
    };

    return (
        <>
            <Paper elevation={0}>
                <Box m={2} p={2}>
                    <form
                        onSubmit={props.handleSubmit(handleSubmit)}
                        autoComplete="off"
                    >
                        <Stack spacing={3}>
                            <Collapse
                                in={!!(isTransferFromModal && cardNumber)}
                            >
                                <Typography>
                                    {t('toDepositSorocoins.ownCardNumber')}
                                </Typography>
                                <Button
                                    endIcon={<ContentCopyIcon />}
                                    onClick={() =>
                                        handleCopy(authorizedUserCardNumber)
                                    }
                                    variant={'contained'}
                                    fullWidth
                                    style={{
                                        backgroundColor: '#58a315',
                                        color: '#fff',
                                        fontWeight: 700,
                                    }}
                                >
                                    {authorizedUserCardNumber}
                                </Button>
                                <Collapse in={showCopiedText}>
                                    <Typography
                                        variant="caption"
                                        style={{ color: '#58a315' }}
                                    >
                                        {t('toDepositSorocoins.copied')}
                                    </Typography>
                                </Collapse>
                            </Collapse>
                            <Typography>
                                {t('toDepositSorocoins.transferToTheAccount')}
                            </Typography>
                            <Field
                                name="cardNumber"
                                label={t('toDepositSorocoins.cardNumber')}
                                style={{
                                    color: '#00A10C',
                                    width: '100%',
                                }}
                                variant="outlined"
                                component={renderTextFieldCardNumber}
                                required
                                error={isInvalidCardNumber}
                            />

                            <Field
                                name="sorocoinsAmount"
                                label={t('toDepositSorocoins.sorocoinsAmount')}
                                style={{
                                    color: '#00A10C',
                                    width: '100%',
                                }}
                                variant="outlined"
                                component={renderTextFieldSorocoinsAmount}
                                required
                                error={isInvalidBalance}
                            />
                            <Field
                                name="description"
                                label={t('toDepositSorocoins.description')}
                                style={{
                                    color: '#00A10C',
                                    width: '100%',
                                }}
                                variant="outlined"
                                component={renderTextField}
                                required
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ width: '100%' }}
                                type="submit"
                            >
                                {t('toDepositSorocoins.transferSorocoins')}
                            </Button>
                        </Stack>
                    </form>
                </Box>
            </Paper>
        </>
    );
}

// Валідація форми
const validate = (values) => {
    const errors = {};

    if (!values.cardNumber) {
        errors.cardNumber = 'required field';
    }

    if (!values.description) {
        errors.sorocoinsAmount = 'required field';
    }

    if (!values.sorocoinsAmount) {
        errors.sorocoinsAmount = 'required field';
    }

    return errors;
};

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(
    reduxForm({
        form: 'ToDepositSorocoinsForm',
        validate,
    })(ToDepositSorocoins)
);
