const errorMessages = {
    "Cannot read property 'reduce' of undefined": null,
    'It is impossible to delete a group if there are students in it':
        'It is impossible to delete a group if there are students in it',
    'The final answer': 'The final answer',
    'The first operation cannot be multiplication':
        'The first operation cannot be multiplication',
    'results in an intermediate answer less than zero':
        'results in an intermediate answer less than zero',
    'Error request: This group does not belong to the user who is requesting':
        'This group does not belong to the user who is requesting',
    'Error request: Referred friend must be created later than whoever studies in Soroban':
        'later than whoever studies in Soroban',
    'Error request: the attached student is already in this family':
        'family discount',
    'The teacher has students': 'The teacher has students',
    'already in this family for confirmation':
        'already in this family for confirmation',
    'student is already in this family': 'student is already in this family',
    'already listed as a referral':
        'this student is already listed as a referral',
    "couldn't get school record not found": 'unable_to_login',
    "couldn't get school": "couldn't get school",
    'Invalid parameter phone': 'Invalid parameter phone',
    "couldn't get fop": "couldn't get fop",
    "couldn't get Uid book": "couldn't get Uid book",
    'than the attached apprentice':
        'the apprentice created earlier than the attached apprentice',
    'Uid was already attached to another student':
        'Uid was already attached to another student',
    ix_uq_textbook_student_id_topic: 'this_type_of_book_is_already_attached',
    'IS NOT EXISTS': 'uid IS NOT EXISTS',
    'ALREADY LINKED TO': 'uid ALREADY LINKED TO student_id',
    "couldn't create Uid:error: pq: NOT VALID;":
        'Uid was already attached to another student',
    '_RE_LINKED TO': '_RE_LINKED TO',
    'Error request: Your price is less than set by the boss of the country. Min price:':
        'Your price is less than set by the boss of the country price',
    'Error server: report is empty': 'report is empty',
    'ERROR: integer out of range': 'integer out of range',
};

export { errorMessages };
