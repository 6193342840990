import AnaliticsService from './../services/analytics.service';
import {
    cityReport,
    cityReportDetailed,
    cityReportDetailedCell,
    kpiReport,
    outflowReport,
} from '../constants/analytics';
import ReportService from '../services/report.service';

function processDate(item = false, short = false) {
    let d = item ? new Date(item) : short ? new Date(short) : new Date();
    const _min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    const _hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
    const _month =
        d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    const _date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

    const file_date = `${_date}.${_month}.${d.getFullYear()}_${_hours}:${_min}`;
    if (short) return `${_month}.${d.getFullYear()}`;
    if (item) return file_date;
    this.setState({ file_date });
}

function processCityName(item) {
    const arr = item.split('|||');
    for (let item of arr) {
        if (item) return item;
    }
}

function getFranchAnalitics(data) {
    return async () => {
        return await AnaliticsService.GelFranchData(data);
    };
}

function getFinanceAnalitics(data) {
    return async () => {
        return await AnaliticsService.GelFinanceData(data);
    };
}

function processFullReport(res) {
    const labels = cityReportDetailed.map((el) => el.label);
    let array = [labels];
    const groups = Object.keys(res);
    for (let i = 0; i < groups.length; i++) {
        for (let item of res[groups[i]]) {
            const rowData = cityReportDetailed.map((field) => {
                if (field.key === 'city') {
                    return processCityName(item.city);
                } else if (field.key === 'period') {
                    return processDate(item[field.key], true);
                } else {
                    return item[field.key];
                }
            });

            array.push(rowData);
        }
        array.push(cityReportDetailedCell);
    }
    return array;
}

function processShortReport(res) {
    const labels = cityReport.map((el) => el.label);
    let data = res;
    let array = [labels];
    if (res && res[0].city) {
        data = res.sort((a, b) => {
            const titleA = processCityName(a.city).toLowerCase();
            const titleB = processCityName(b.city).toLowerCase();
            return titleA.localeCompare(titleB, 'uk');
        });
    }
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const rowData = cityReport.map((field) => {
            if (field.key === 'city') {
                return processCityName(item.city);
            } else if (field.key === 'period') {
                return processDate(item[field.key], true);
            } else {
                return item[field.key];
            }
        });

        array.push(rowData);
    }
    return array;
}

function processOutflowReport(res) {
    const labels = outflowReport.map((el) => el.label);
    let data = res;
    let array = [labels];

    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const rowData = outflowReport.map((field) => {
            if (field.key === 'city') {
                return processCityName(item.city);
            } else if (field.key === 'period') {
                return processDate(item[field.key], true);
            } else {
                return item[field.key];
            }
        });

        array.push(rowData);
    }
    return array;
}

function processKPIReport(res) {
    const labels = kpiReport.map((el) => el.label);
    let data = res;
    let array = [labels];

    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        const rowData = kpiReport.map((field) => {
            if (field.key === 'city') {
                return processCityName(item.city);
            } else if (field.key === 'period') {
                return processDate(item[field.key], true);
            } else {
                return item[field.key];
            }
        });

        array.push(rowData);
    }
    return array;
}

async function getStatReport(data) {
    return await AnaliticsService.GetStatReport(data);
}

async function __ProcessReportFile(data) {
    if (!data) return;
    const fileName = data.fileName;
    const response = await ReportService.DownloadDocument({
        file: data.fileName,
    });
    response
        .blob()
        .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.download = fileName.endsWith('.xlsx')
                ? fileName
                : fileName + '.xlsx';
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(href);
        })
        .catch((e) => console.error('Error downloading Excel file: ', e));
}

export {
    getFranchAnalitics,
    getStatReport,
    getFinanceAnalitics,
    processFullReport,
    processShortReport,
    processOutflowReport,
    processKPIReport,
    __ProcessReportFile,
};
