import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { ChangeCityPrice } from '../../actions/cities';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';

import MuiAlert from '@material-ui/lab/Alert';
import { getCurrentCurrency } from '../../actions/currencies';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 325,
        marginRight: '7px',
        marginBottom: '7px',
        minHeight: '265px',
        minWidth: '325px',
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PaymentsMediaCard({ recivedPrice = null }) {
    const currency = useMemo(() => {
        return getCurrentCurrency();
    }, []);
    const classes = useStyles();
    const [price, setPrice] = React.useState(0);
    const [loading, setLoader] = React.useState(false);
    let domain =
        document.location.hostname.split('.')[
            document.location.hostname.split('.').length - 1
        ];
    const money_langs = {
        ua: 'грн',
        ru: 'руб',
        ee: 'eur',
        com: 'cad',
        ro: 'lei',
        by: 'руб',
    };
    const handleChange = (e) => {
        const value = e.target.value;
        setPrice(value);
    };
    const handleCityPriceChange = async (e) => {
        e.preventDefault();
        if (!price) return;
        setLoader(true);
        await ChangeCityPrice(+price <= 0 ? -1 : +price);
        setLoader(false);
        setPrice(0);
    };

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    return (
        <Card className={classes.root} elevation={0}>
            <Box
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="95%"
            >
                <Typography gutterBottom variant="h5" component="h2">
                    {i18n.t('payments.city_price')}
                </Typography>
                <Box>
                    <Typography
                        gutterBottom
                        variant="h6"
                        component="h6"
                        style={{ fontSize: '14px', marginBottom: '15px' }}
                    >
                        {`${i18n.t(`payments.current_price`)}: `}
                        <span
                            className={`cityPrice ${
                                +recivedPrice <= 0 ? 'not_shown' : 'ok'
                            }`}
                        >
                            {recivedPrice <= 0 ? 0 : recivedPrice} {currency}
                        </span>
                    </Typography>

                    {+recivedPrice <= 0 ? (
                        <Typography
                            gutterBottom
                            variant="subtitle2"
                            component="p"
                            className="red"
                            style={{ marginTop: '15px', color: '#f36630' }}
                        >
                            * {i18n.t('payments.not_shown')}
                        </Typography>
                    ) : null}
                    <form onSubmit={handleCityPriceChange}>
                        <CardActions
                            style={{
                                padding: '0px',
                                marginTop: '15px',
                                marginBottom: '15px',
                            }}
                        >
                            <TextField
                                fullWidth
                                type="number"
                                disabled={loading}
                                className="cityPriceChange"
                                value={price || ''}
                                onChange={handleChange}
                                name="price"
                                variant="outlined"
                                label={`${i18n.t(`table.price`)} ${currency}`}
                            />
                        </CardActions>
                        <Button
                            fullWidth
                            style={{
                                margin: '0!important',
                                marginTop: '20px!important',
                                color: '#fff',
                            }}
                            size="medium"
                            disabled={loading || !price}
                            className={'bossButton'}
                            onClick={handleCityPriceChange}
                        >
                            {loading ? (
                                <CircularProgress color="inherit" size={20} />
                            ) : (
                                i18n.t('buttons.save')
                            )}
                        </Button>
                    </form>

                    <Snackbar
                        open={open}
                        autoHideDuration={6000}
                        onClose={handleClose}
                    >
                        <Alert onClose={handleClose} severity="info">
                            {i18n.t('payments.alert_message')}
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
        </Card>
    );
}
