import React from 'react';
import { connect } from 'react-redux';
import './index.css';
import TextField from '@material-ui/core/TextField';
import { bindActionCreators } from 'redux';

import { Add } from '@material-ui/icons';
import {
    __AddDataJournal,
    _copyDataRow,
    __Destroy,
} from './../../actions/AsyncTable';
import ComponentTable from '../../components/ComponentTable';
import IconButton from '@material-ui/core/IconButton';
import NativeSelect from '@material-ui/core/NativeSelect';
import JournalService from './../../services/journal.service';
import ModelService from './../../services/models.service';
import { Delete } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import { openRequestModal } from './../../actions/modals';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { deleteAllHW, saveHW } from './../../actions/hw';
import {
    changeSelect,
    saveJournal,
    recordList,
    processData,
    addThemesArray,
    initTemplatesName,
    editJournal,
} from './../../actions/journal';
import {
    simple,
    brother,
    friend,
    friend_brother,
    multiplication,
} from './../../constants/hw';

class JournalPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateName: null,
        };
        this.props.__Destroy();
        this.props.initTemplatesName();
        this.processData();
    }
    componentWillUnmount() {
        this.props.__Destroy();
    }

    processData = async () => {
        let table_data =
            this.props.AsyncTable &&
            this.props.AsyncTable.table &&
            this.props.AsyncTable.table.data;

        if (!table_data) {
            return setTimeout(() => {
                this.processData();
            }, 200);
        } else {
            let data = {};
            if (table_data) {
                if (!table_data.length)
                    return setTimeout(() => {
                        this.processData();
                    }, 200);
                for (let i = 0; i < table_data.length; i++) {
                    if (!data[table_data[i].title]) {
                        data[table_data[i].title] = [];
                        data[table_data[i].title].push(table_data[i]);
                    }
                    if (
                        !data[table_data[i].title].find(
                            (el) => el.id === table_data[i].id
                        )
                    )
                        data[table_data[i].title].push(table_data[i]);
                }
            }
            this.props.recordList(Object.keys(data));
            this.props.processData(Object.keys(data)[0]);
            this.props.addThemesArray([
                simple,
                brother,
                friend,
                friend_brother,
                multiplication,
            ]);
        }
    };
    makeToOption = (params, name) => {
        return params.map((item, key) => (
            <option key={key} value={item.value}>
                {item.label}
            </option>
        ));
    };
    changeSelect = (e) => {
        this.props.changeSelect({
            [e.target.id]: !isNaN(+e.target.value)
                ? +e.target.value
                : e.target.value,
        });
        this.props.processData(e.target.value);
    };

    makeToOptionFromArray = (params) =>
        params.map((element) =>
            element.map((item, key) => (
                <option key={key} value={item.value}>
                    {item.label}
                </option>
            ))
        );

    makeToOption = (params, name) => {
        return params.map((item, key) => (
            <option key={key} value={item.value}>
                {item.label}
            </option>
        ));
    };

    initUpdate = () => {
        this.props.changeSelect({
            templatesName:
                this.props.HW && this.props.HW.templatesName[0].value,
        });
    };

    handleChange = (e, newValue) => {};

    createJournal = () => {
        if (this.state.templateName) {
            this.props.saveJournal(this.state.templateName).then((res) => {
                this.processData();
            });
        }
    };

    recordTemplateNameData = (e) => {
        this.setState({
            templateName: e.target.value,
        });
    };

    callBackDataHW = (e) => {
        const arrToSave = [];
        const objToSave = {};
        for (let i in e) {
            const obj = JSON.parse(i);
            if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            } else if (obj && obj.temporarily) {
                if (!objToSave[obj.temporarily])
                    objToSave[obj.temporarily] = {};
                objToSave[obj.temporarily][obj.code] = e[i];
            }
        }
        for (let i in objToSave) {
            if (!isNaN(+i)) {
                arrToSave.push({
                    ...objToSave[i],
                    id: i,
                });
            } else {
                arrToSave.push({
                    ...objToSave[i],
                });
            }
        }
        for (let item of arrToSave) {
            item.title = arrToSave[0].title;
        }

        this.props.editJournal(arrToSave);
    };

    render() {
        const { t } = this.props;
        let actionsButton = [
            {
                title: 'Добавить',
                Icon: Add,
                onClick: () => this.props.__AddDataJournal(),
            },
        ];
        return (
            <>
                <div className="top_main_block_setting">
                    {this.props.journal && this.props.journal.list ? (
                        <div className="top_setting">
                            <p>{t('journal.title')}</p>
                            <NativeSelect
                                id="topic"
                                onChange={this.changeSelect}
                                title="select topic"
                            >
                                {this.props.journal.list.map((item, key) => (
                                    <option key={key} value={item}>
                                        {item}
                                    </option>
                                ))}
                            </NativeSelect>
                        </div>
                    ) : null}

                    <div className="top_setting">
                        <p>{t('journal.create_journal')}</p>
                        <TextField
                            onChange={this.recordTemplateNameData}
                            name="templatesNameAdd"
                            type="text"
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        title="save"
                                        onClick={this.createJournal}
                                    >
                                        <Add />
                                    </IconButton>
                                ),
                            }}
                            placeholder={t('HWSettings.enter_name')}
                        />
                    </div>
                </div>
                <div className="tasks">
                    <ComponentTable
                        source={{
                            getData: () => JournalService.JournalList(),
                            getModel: () => ModelService.JournalModels(),
                            // getData: () => JournalService.JournalList(false, { user_id, size: 9999 })
                        }}
                        blockOptions={true}
                        typeTable="journal"
                        selectable={true}
                        callBackDataHW={this.callBackDataHW}
                        actions={[
                            {
                                title: t('HWSettings.copy'),
                                onClick: (id) => this.props._copyDataRow(id),
                                Icon: PlaylistAddIcon,
                            },
                            {
                                title: t('table.delete'),
                                onClick: (id) =>
                                    this.props.openRequestModal({
                                        opened: true,
                                        data: {
                                            id: id,
                                            header: t('table.delete'),
                                            modaltype: 'DeleteHwModal',
                                        },
                                    }),
                                Icon: Delete,
                            },
                        ]}
                        actionsButton={actionsButton}
                        toolbarvisible={true}
                    />
                </div>
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        journal: state.journal,
        HW: state.HW,
        AsyncTable: state.AsyncTable,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                __AddDataJournal,
                openRequestModal,
                _copyDataRow,
                deleteAllHW,
                changeSelect,
                saveJournal,
                recordList,
                processData,
                __Destroy,
                addThemesArray,
                initTemplatesName,
                saveHW,
                editJournal,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(JournalPage));
