import Types from "./../constants/journal";

const defaultState = {
  topic: [],
  templatesName: [],
  list: [],
  table: [],

};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.CHANGE_DATA:
          return { ...state, ...action.journal };
        default:
          return state;
    }
};
