import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';
import store from './../../store';
import StorefrontComponent from '../../components/StorefrontComponent';
import StudentStorefrontComponent from '../../components/StudentStorefrontComponent';
import BankCustomersService from '../../services/bank-customers.service';
import { openRequestModal } from '../../actions/modals';
import { useTranslation } from 'react-i18next';
// import i18next from 'i18next';
import {
    Stack,
    Paper,
    Typography,
    Button,
    Tooltip,
    IconButton,
    Box,
    Badge,
} from '@mui/material';


export default function MyTemplatesPage(props) {

    return (
        <>
            <div>Сторінка моїх шаблонів</div>
        </>
    );
}

