import React from "react";
import { Field } from "redux-form";

import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import AutocomplateView from "./AutocomplateView";

export default class AutocomplateArrayView extends React.Component {
  componentDidMount() {
    this.props.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.fields.name !== this.props.fields.name) {
      this.props.getData();
    }
  }

  render() {
    const {
      data,
      label,
      fields,
      meta: { error },
    } = this.props;
    return (
      <Card style={{ overflow: "initial" }}>
        <CardContent>
          <div style={{ display: "flex", marginBottom: 16 }}>
            <Typography gutterBottom variant="h6" component="h2">
              {label}
            </Typography>
            <div style={{ flex: 1 }} />
            <Tooltip placement="top" title="Добавить правило">
              <IconButton onClick={() => fields.push()}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Grid container spacing={3} justifyContent="flex-end">
            {fields.map((member, index) => (
              <Grid item xs={12} key={index}>
                <Field
                  name={member}
                  component={AutocomplateView}
                  data={data}
                  fullWidth
                  action={
                    <InputAdornment position="end">
                      <Tooltip placement="top" title="Удалить правило">
                        <IconButton onClick={() => fields.remove(index)}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
              </Grid>
            ))}
            {error && (
              <Grid item xs={12}>
                <Typography variant="body2" component="p" color="error">
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  }
}
