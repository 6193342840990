
export default {
    id: e => (!!e ?  e : ""),
    price: e => (!!e ?  e : ""),
    quantity: e => (!!e ?  e : ""),
    qty: e => (!!e ?  e : ""),
    name: e => (!!e ?  e : ""),
    aptekaname: e => (!!e ?  e : ""),
    edrpou: e => (!!e ?  e : ""),
    aptekaedrpou: e => (!!e ?  e : ""),
    num_of_contract: e => (!!e? e : ""),
    address_block: e => (e ?  e : ""),
    address_house: e => (e ?  e : ""),
    address_office: e => (e ?  e : ""),
    address_postcode: e => (e ?  e : ""),
    mfo: e => (e ?  e : ""),
    checking_account: e => (!!e ? e  : ""),
    email: e => (!!e ? e.replace(/[а-яА-ЯэЭъЪєЄёЁїЇіІ]/gi, "") : ""),
    aptekaemail: e => (!!e ? e : ""),
    first_name: e => e &&  e,
    last_name: e => e &&  e,
    second_name: e => e &&  e,
    position: e => e && e
};
