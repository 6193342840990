import * as React from 'react';
import { useState } from 'react';
import i18n from 'i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';

const AddsDataToTheMultTable = ({ data, type }) => {
    const stat = data.stat;
    let processedStatData = {};
    for (let item in stat) {
        if (!item.includes(type)) continue;
        const number = item.split(type)[1];

        processedStatData[number] = !processedStatData[number]
            ? { [item]: stat[item] }
            : { ...processedStatData[number], [item]: stat[item] };
    }
    // return <div>123</div>;
    return (
        <Box style={{ padding: '25px 0' }}>
            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            {['', 0, ...Object.keys(processedStatData)].map(
                                (el, index) => (
                                    <TableCell
                                        key={index}
                                        align="right"
                                        variant="head"
                                        style={{
                                            fontWeight: 600,
                                        }}
                                    >
                                        {el}
                                    </TableCell>
                                )
                            )}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row}
                                </TableCell>
                                {Object.values(processedStatData[row]).map(
                                    (el, i) => {
                                        return (
                                            <TableCell key={i} align="right">
                                                {!el ? (
                                                    '-'
                                                ) : (
                                                    <Tooltip
                                                        placement="top"
                                                        title={`${i}${type}${row}: ${el}`}
                                                    >
                                                        <span>{el}</span>
                                                    </Tooltip>
                                                )}
                                            </TableCell>
                                        );
                                    }
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AddsDataToTheMultTable;
