const COLORS = {
    WHITE: '#ffffff',
    YELLOW: 'rgb(225 208 1)',
    GREEN: '#00bf62',
    BROWN: '#824e41',
    RED: '#d0142c',
    ORANGE: '#ec6e00',
    BLUE: '#313e8d',
    PURPLE: '#773c8e',
    BLACK: '#000',
};

const LABELS = {
    WHITE: 'White',
    YELLOW: 'Yellow',
    GREEN: 'Green',
    BROWN: 'Brown',
    RED: 'Red',
    ORANGE: 'Orange',
    BLUE: 'Blue',
    PURPLE: 'Purple',
    BLACK: 'Black',
};

const VALUES = {
    WHITE: 0,
    YELLOW: 1,
    GREEN: 2,
    BROWN: 3,
    RED: 4,
    ORANGE: 5,
    BLUE: 6,
    PURPLE: 7,
    BLACK: 8,
};

const BRACELETS = [
    {
        color: COLORS.WHITE,
        label: LABELS.WHITE,
        value: VALUES.WHITE,
    },
    {
        color: COLORS.YELLOW,
        label: LABELS.YELLOW,
        value: VALUES.YELLOW,
    },
    {
        color: COLORS.ORANGE,
        label: LABELS.ORANGE,
        value: VALUES.ORANGE,
    },
    {
        color: COLORS.GREEN,
        label: LABELS.GREEN,
        value: VALUES.GREEN,
    },
    {
        color: COLORS.BLUE,
        label: LABELS.BLUE,
        value: VALUES.BLUE,
    },
    {
        color: COLORS.PURPLE,
        label: LABELS.PURPLE,
        value: VALUES.PURPLE,
    },
    {
        color: COLORS.RED,
        label: LABELS.RED,
        value: VALUES.RED,
    },
    {
        color: COLORS.BROWN,
        label: LABELS.BROWN,
        value: VALUES.BROWN,
    },
    {
        color: COLORS.BLACK,
        label: LABELS.BLACK,
        value: VALUES.BLACK,
    },
];

export { BRACELETS, COLORS, LABELS, VALUES };
