import React from "react";
import store from "./../../../../store";
import { Field } from "redux-form";
import NativeSelect from "@material-ui/core/NativeSelect";

const processErrors = (input, form) => {
  const array = {};
  const name = JSON.parse(input.name).code;
  const id = JSON.parse(input.name).id;
  for (let items of Object.keys(form)) {
    const item = JSON.parse(items);
    if (
      item.id === id &&
      (item.code === "min_time" ||
        item.code === "max_time" ||
        item.code === "start_time")
    ) {
      array[item.code] = form[items];
    }
  }
  const min = +array.min_time;
  const start = +array.start_time;
  const max = +array.max_time;
  if (start && min > start) {
    if (name === "min_time") return true;
  } else if (start && max < start) {
    if (name === "max_time") return false;
  } else if (!start && min > max) {
    return true;
  } else if (!start && max < min) {
    return true;
  }
  return false;
};

const renderSelectField = ({
  input,
  meta: { touched, error },
  i18n,
  data,
  ...custom
}) => {
  const formData = custom.formData;
  delete custom.formData;
  delete custom.tableId;
  const schema = [
    0,
    7000,
    5000,
    4000,
    3500,
    3000,
    2500,
    2000,
    1800,
    1600,
    1400,
    1200,
    1000,
    900,
    800,
    700,
    600,
    500,
    400,
    300,
  ];
  return (
    <NativeSelect {...input} {...custom}  style={{ width: "60px" }} error={processErrors(input, formData)}>
      {schema.map((item, key) => {
        return (
          <option key={key} value={item}>
            {`${item / 1000} c.`}
          </option>
        );
      })}
    </NativeSelect>
  );
  // ChangeStudentGroup
};

export default ({ value, code, id, temporarily, label }) => {
  const form = store.getState().form["from-tableComponent"].values;
  const game_type =
    form[
      Object.keys(form).find(
        (el) => JSON.parse(el).code === "game_type" && JSON.parse(el).id === id
      )
    ];
  return (
    <Field
      default={value}
      name={JSON.stringify({ code, id, temporarily })}
      component={renderSelectField}
      tableId={id}
      formData={form}
      disabled={+game_type === 4}
      type="text"
    />
  );
};
