import Types from "./../constants/getUsers";

const defaultState = {
    default: {
        list: [],
        count: 0
    },
    blocks: {}
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.SINGLEUSER:
            return {
                ...state, ...action.modalWindow
            };
        case Types.USERS:
            return {
                ...state,
                blocks: {
                    ...state.blocks,
                    [action.id]: { ...JSON.parse(JSON.stringify(state.default)), ...action.source }
                }
            };
        default:
            return state;
    }
};
