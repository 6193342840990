import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Stack } from "@mui/material";
import { openRequestModal } from "../../actions/modals";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import HelpPageEdit from "./edit";
import HelpPageView from "./view";
import { clearAllData } from "../../actions/help";
import CardPanel from "../../components/CardPanel";
import TeacherProImage from "../../resources/img/techer_pro.png";
class HelpPage extends React.Component {
  state = {
    roleHelpers: [],
    role_id: this.props.user.emulation.length
      ? this.props.user.emulation[this.props.user.emulation.length - 1].info
          .role_id
      : this.props.user.info.role_id,
  };

  componentWillUnmount() {
    this.props.clearAllData();
  }

  render() {
    return (
      <Fragment>
        <h2 className="container-header"> Help </h2>
        <Stack spacing={2}>
          {this.state.role_id === 1 ? <HelpPageEdit /> : <HelpPageView />}
          <CardPanel
            title="Teacher PRO"
            link={"https://1drv.ms/u/s!Ag4Sa53NJWbdka4Wf6EgUAwwfMuAvQ?e=mKsuX4"}
            image={TeacherProImage}
          />
        </Stack>
      </Fragment>
    );
  }
}

const widthConnect = connect(
  (state) => ({ user: state.user }),
  (dispatch) => bindActionCreators({ openRequestModal, clearAllData }, dispatch)
);

export default withTranslation()(widthConnect(HelpPage));
