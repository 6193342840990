import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import NativeSelect from '@material-ui/core/NativeSelect';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useMediaQuery } from '@mui/material';
import usePhoneCheck from '../../../hooks/usePhoneCheck';

export default ({
    model,
    userData,
    data,
    fetching,
    hasActions,
    order,
    orderBy,
    typeTable,
    __ChangeSort,
    t,
    goldUser,
}) => {
    const mobile = usePhoneCheck();

    const hideSorting = model
        .map((column, index) => {
            // eslint-disable-next-line
            return column && column.raw != false
                ? userData !== 5 &&
                  column.code !== 'key' &&
                  column.code !== 'course_id' &&
                  column.code !== 'status_history' &&
                  column.code !== 'price' &&
                  column.code !== 'paid' &&
                  typeTable !== 'HW'
                    ? true
                    : false
                : false;
        })
        .every((el) => el === false);
    if (mobile) {
        return fetching ? (
            <div className="mobile_sort">
                <Skeleton />
            </div>
        ) : !data.length || hideSorting ? (
            <></>
        ) : (
            <div className="mobile_sort">
                <NativeSelect
                    style={{ width: '80%' }}
                    value={orderBy ? orderBy : ''}
                    onChange={(e) => {
                        if (e.target.value) __ChangeSort(e.target.value);
                    }}
                >
                    <option value="">{t('table.sort')}</option>
                    {!fetching &&
                        !!data.length &&
                        model.map((column, index) => {
                            if (column.code === 'hw_age' && goldUser)
                                return null;
                            // eslint-disable-next-line
                            return column && column.raw != false ? (
                                userData !== 5 &&
                                column.code !== 'key' &&
                                column.code !== 'course_id' &&
                                column.code !== 'status_history' &&
                                column.code !== 'price' &&
                                column.code !== 'paid' &&
                                typeTable !== 'HW' ? (
                                    <option key={index} value={column.code}>
                                        {t(column.label)}
                                    </option>
                                ) : null
                            ) : null;
                        })}
                </NativeSelect>
                <span
                    className="modile_sort_select"
                    onClick={() => __ChangeSort(orderBy)}
                >
                    {order === 'asc' ? (
                        <ArrowUpwardIcon fontSize="small" />
                    ) : (
                        <ArrowDownwardIcon fontSize="small" />
                    )}
                </span>
            </div>
        );
    }
    const singleGroupPage = typeTable === 'singleGroupPage';

    return (
        <TableHead>
            <TableRow>
                {fetching && (
                    <TableCell>
                        <Skeleton />
                    </TableCell>
                )}
                {!fetching && !!data.length && singleGroupPage && (
                    <TableCell key={'head-sorobank'} align={'left'}>
                        SBK
                    </TableCell>
                )}
                {!fetching &&
                    !!data.length &&
                    model.map((column, index) => {
                        if (column.code === 'hw_age' && goldUser) return null;
                        // eslint-disable-next-line
                        return column && column.raw != false ? (
                            <TableCell
                                key={index}
                                align={
                                    index !== model.length ? 'left' : 'right'
                                }
                            >
                                {userData !== 5 &&
                                column.code !== 'key' &&
                                column.code !== 'course_id' &&
                                column.code !== 'status_history' &&
                                column.code !== 'price' &&
                                column.code !== 'paid' &&
                                typeTable !== 'HW' ? (
                                    <TableSortLabel
                                        direction={order}
                                        onClick={() =>
                                            __ChangeSort(column.code)
                                        }
                                        active={orderBy === column.code}
                                    >
                                        {t(column.label)}
                                    </TableSortLabel>
                                ) : typeTable !== 'HW' ? (
                                    <span>{t(column.label)}</span>
                                ) : (
                                    <div className="hwOverflow">
                                        {t(column.label)}
                                    </div>
                                )}
                            </TableCell>
                        ) : null;
                    })}
                {!fetching &&
                    !!data.length &&
                    // eslint-disable-next-line
                    typeTable == 'payments' &&
                    userData != 5 && (
                        <>
                            <TableCell align="right">
                                {t('table.payment_format')}
                            </TableCell>
                            <TableCell align="right">
                                {t('table.transaction_amount')}
                            </TableCell>
                        </>
                    )}
                {!fetching && !!data.length && hasActions && (
                    <TableCell align="left"></TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};
