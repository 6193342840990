import React from "react";
import Button from "@material-ui/core/Button";
import { handleResetView } from "../../actions/user";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MailOutline, ArrowBack } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Close, Check } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { withTranslation } from "react-i18next";

class ResetPassLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailvalid: false,
      email: ""
    };
  }

  handleBack = () => {
    this.props.handleResetView(1);
  };

  resetPass = () => {};

  handler = e => {
    e.preventDefault();
    let currentValue = e.target.value;
    let regex = /\w+@{1}\w+\.\w+/;
    this.setState({
      emailvalid: regex.test(currentValue),
      email: currentValue
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="main-container reset animated fadeInRight faster">
        <div className="main_container">
          <ArrowBack className="arrowBack" onClick={this.handleBack} />
          <h3>{t("login.password_recovery")}</h3>
          <TextField
            className="inputs"
            type="email"
            placeholder="Введіть email"
            value={this.state.email}
            onChange={this.handler}
            name="email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutline />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="e-mail validation">
                    {this.state.emailvalid ? <Check /> : <Close />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button onClick={this.resetPass} className="orangeBtn">
            {t("login.next")}
          </Button>
        </div>
      </div>
    );
  }
}

const widthConnect = connect(
  state => ({ user: state.user }),
  dispatch => bindActionCreators({ handleResetView }, dispatch)
);

export default withTranslation()(widthConnect(ResetPassLogin));
