import React, { useEffect, useState, useCallback } from 'react';
import CountriesService from './../../services/countries.service';
import {
    Button,
    Card,
    CircularProgress,
    TextField,
    Box,
    Chip,
    Tooltip,
    Collapse,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import i18n from 'i18next';
import Typography from '@material-ui/core/Typography';
import { getError, getErrorNotification } from '../../actions/toast';
import BooksService from '../../services/books.service';
import { Stack } from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { useTranslation } from 'react-i18next';
import { processBooks } from '../ModalEditWindow/modalComponents/AssignBookModal';
import './style.css';
const regex =
    /[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}/;

function formatObject(obj, indent = 0) {
    let result = '';

    for (const key in obj) {
        const value = obj[key];

        if (typeof value === 'object') {
            result += `${'  '.repeat(indent)}${key}: {\n${formatObject(
                value,
                indent + 1
            )}${'  '.repeat(indent)}}\n`;
        } else {
            result += `${'  '.repeat(indent)}${key}: ${value}\n`;
        }
    }

    return result;
}

function processTitle(item) {
    for (let el of item.split('|||')) {
        if (el) {
            return el;
        }
    }
}
const MaxCyclesComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [blockDay, setBlockDay] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [book, setBook] = useState('');
    const [bookData, setBookData] = useState(null);

    const handleSubmit = useCallback(async () => {
        if (error) return;
        setLoading(true);

        try {
            const res = await BooksService.searchBookSupport({ uid: book });
            if (res) {
                setBookData({
                    book: {
                        label: false,
                        raw: processBooks([res.book])[0],
                        tooltip: formatObject(res.book),
                    },
                    student: {
                        label:
                            res.student &&
                            `${res.student.first_name} ${res.student.last_name}`,
                        tooltip: formatObject(res.student),
                        name: t('olympiad.student'),
                    },
                    teacher: {
                        label:
                            res.teacher &&
                            `${res.teacher.first_name} ${res.teacher.last_name}`,
                        tooltip: formatObject(res.teacher),
                        name: t('table.teacher'),
                    },
                    group: {
                        label: res.group && processTitle(res.group.name),
                        tooltip: formatObject(res.group),
                        name: t('table.group'),
                    },
                    city: {
                        label: res.city && processTitle(res.city.title),
                        tooltip: formatObject(res.city),
                        name: t('table.city'),
                    },
                });
            }
        } catch (e) {
            console.log(e);
            const message = getError(e, true);
            if (message) {
                setError(message);
            }
        }
        setLoading(false);
    }, [error, book]);

    const handleBookChange = useCallback(
        (e) => {
            setError('');
            const value = e.target.value;
            if (!value && bookData !== null) setBookData(null);
            const isValidUid = value.match(regex);
            if (!isValidUid) setError('Невірний формат uid');
            setBook(value);
        },
        [bookData]
    );

    return (
        <Card style={{ maxWidth: '400px' }}>
            <Box
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="95%"
            >
                <Box>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{ marginBottom: '20px' }}
                    >
                        Пошук підручника за UID
                    </Typography>

                    <TextField
                        label={'Введіть uid для пошуку підручника'}
                        type="text"
                        onChange={handleBookChange}
                        value={book}
                        disabled={loading}
                        fullWidth
                        error={!!error}
                        helperText={!!error && error}
                        style={{ minHeight: '45px' }}
                    />
                </Box>

                <Collapse in={!!bookData}>
                    <Box>
                        {bookData &&
                            Object.keys(bookData).map((bookItem, index) => {
                                const title = bookData[bookItem].label;
                                const name = bookData[bookItem].name;
                                const raw = bookData[bookItem].raw;
                                return (
                                    <Tooltip title={bookData[bookItem].tooltip}>
                                        <Chip
                                            key={index}
                                            className={'booksChips'}
                                            label={
                                                raw
                                                    ? `${t(
                                                          `books.${raw.label}`
                                                      )}${
                                                          raw.number
                                                              ? ` ${raw.number}`
                                                              : ''
                                                      }`
                                                    : `${
                                                          name
                                                              ? `${name}: `
                                                              : ''
                                                      }${title}`
                                            }
                                            style={{
                                                marginTop: '5px',
                                                marginRight: '10px',
                                            }}
                                        />
                                    </Tooltip>
                                );
                            })}
                    </Box>
                </Collapse>
                <Box>
                    <Button
                        style={{ margin: '15px 0px' }}
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={loading || !!error || !book}
                        endIcon={
                            loading ? (
                                <CircularProgress
                                    style={{ marginLeft: '0px' }}
                                    size={12}
                                    color="inherit"
                                />
                            ) : null
                        }
                    >
                        <Typography>Пошук</Typography>
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
});

export default MaxCyclesComponent;
