import React, { Fragment } from 'react';
import './DashboardPage.css';
import { connect } from 'react-redux';
import { getCities } from '../../actions/cities';
import { bindActionCreators } from 'redux';
import Types from '../../constants/roles';
import DashFranchiseView from '../../components/DashFranchiseView';
import DashTeacherView from '../../components/DashTeacherView';
import DashBossView from '../../components/DashBossView';
import DashAdminView from '../../components/DashAdminView';
import DashStudentView from '../../components/DashStudentView';
import { withTranslation } from 'react-i18next';
import DashSupportView from '../../components/DashSupportView';
import { apiService } from '../../services/requestV2.service';

class DashboardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleView: null,
            role_id: null,
        };
        if (this.props.user.permission && this.props.user.permission.length)
            this.processPanel();
    }

    componentDidMount() {
        if (this.props.user.permission && this.props.user.permission.length)
            this.processPanel();
    }

    componentWillUnmount() {
        apiService.cancelAllRequests();
    }

    processPanel = () => {
        let userinfo =
            this.props.user &&
            this.props.user.emulation &&
            this.props.user.emulation.length
                ? this.props.user.emulation[
                      this.props.user.emulation.length - 1
                  ]
                : this.props.user;
        switch (userinfo.info.role.split('|||')[0]) {
            case Types.SUPERADMIN:
                return <DashAdminView />;
            case Types.BOSS:
                return <DashBossView />;
            case Types.FRANCHISE:
                return <DashFranchiseView {...this.props} />;
            case Types.TEACHER:
                return <DashTeacherView {...this.props} />;
            case Types.STUDENT:
                return <DashStudentView />;
            case Types.DEVELOPER:
                return <DashFranchiseView />;
            case Types.CALLCENTER:
                return <DashSupportView />;
            default:
                break;
        }
    };

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <h2 className="container-header"> {t('dashboard.title')} </h2>
                {this.processPanel()}
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({ user: state.user, cities: state.cities, roles: state.roles }),
    (dispatch) => bindActionCreators({ getCities }, dispatch)
);

export default withTranslation()(widthConnect(DashboardPage));
