import * as React from "react";
import { useMemo, useState } from "react";
import i18n from "i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { AllHWTopic } from "../../../constants/hw";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography";
import { Stack } from "@mui/material";

const AddsDataToTheMultTable = ({ data }) => {
  const [topic, setTopic] = useState("");
  const [game, setGame] = useState("");
  const [digit, setDigit] = useState("");
  const [eabacus, setEabacus] = useState("");
  const [base, setBase] = useState("");

  const stat = data.list;
  let processedStatData = stat.map((item) => [
    item.GameID,
    item.Topic,
    item.GameID.split("_")[1] || "",
    (item.GameID.includes("eab") && "yes") || "no",
    item.Act || "",
    item.Rate || "",
    item.GameID.includes("100")
      ? "100"
      : item.GameID.includes("50")
      ? "50"
      : "-",
  ]);
  const handleChange = (e) => {
    setTopic(e.target.value);
  };
  const handleChangeDigit = (e) => {
    setDigit(e.target.value);
  };
  const handleChangeGame = (e) => {
    setGame(e.target.value);
  };
  const handleChangeEab = (e) => {
    setEabacus(e.target.value);
  };
  const handleChangeBase = (e) => {
    setBase(e.target.value);
  };
  if (topic) {
    processedStatData = processedStatData.filter((el) => el[1] === topic);
  }
  if (game) {
    processedStatData = processedStatData.filter((el) => el[0] === game);
  }
  if (digit) {
    processedStatData = processedStatData.filter((el) => +el[2] === +digit);
  }
  if (eabacus) {
    processedStatData = processedStatData.filter((el) => el[3] === eabacus);
  }
  if (base) {
    processedStatData = processedStatData.filter((el) => el[6] === base);
  }

  const yesNo = useMemo(() => {
    return [
      { label: i18n.t("table.yes"), value: "yes" },
      { label: i18n.t("table.no"), value: "no" },
    ];
  }, []);

  const baseSchema = useMemo(() => {
    return [{ value: "100" }, { value: "50" }];
  }, []);

  return (
    <Box style={{ padding: "25px 0" }}>
      <Box style={{ padding: "25px 0" }}>
        <Box style={{ marginBottom: "10px" }}>
          <Typography component="h3">{i18n.t("groups.filter")}</Typography>
        </Box>
        <Stack spacing={[0, 0, 0, 2]} direction="row" flexWrap={"wrap"}>
          <FormControl style={{ marginBottom: "5px" }} variant="outlined">
            <InputLabel id="game">{i18n.t("table.game_type")}</InputLabel>

            <Select
              labelId="game"
              value={game}
              onChange={handleChangeGame}
              style={{
                width: 150,
                marginRight: 20,
              }}
              label={i18n.t("table.game_type")}
            >
              <MenuItem value="">{"-"}</MenuItem>;
              {["Ded", "Flash", "FlashMinus"].map((el, index) => (
                <MenuItem key={index} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginBottom: "5px" }} variant="outlined">
            <InputLabel id="topic">{i18n.t("groups.theme")}</InputLabel>

            <Select
              labelId="topic"
              value={topic}
              onChange={handleChange}
              style={{
                width: 150,
                marginRight: 20,
              }}
              label={i18n.t("groups.theme")}
            >
              <MenuItem value="">{"-"}</MenuItem>;
              {AllHWTopic.map((el, index) => (
                <MenuItem key={index} value={el.label.replace(/\s/g, "")}>
                  {el.label.replace(/\s/g, "")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginBottom: "5px" }} variant="outlined">
            <InputLabel id="digit">{i18n.t("table.digit")}</InputLabel>

            <Select
              labelId="digit"
              value={digit}
              onChange={handleChangeDigit}
              style={{
                width: 150,
                marginRight: 20,
              }}
              label={i18n.t("table.digit")}
            >
              <MenuItem value="">{"-"}</MenuItem>;
              {[1, 2, 3, 4].map((el, index) => (
                <MenuItem key={index} value={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ marginBottom: "5px" }} variant="outlined">
            <InputLabel id="eabacus">{i18n.t("table.abacus")}</InputLabel>

            <Select
              labelId="eabacus"
              value={eabacus}
              onChange={handleChangeEab}
              style={{
                width: 150,
                marginRight: 20,
              }}
              label={i18n.t("table.abacus")}
            >
              <MenuItem value="">{"-"}</MenuItem>
              {yesNo.map((el, index) => (
                <MenuItem key={index} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ marginBottom: "5px" }} variant="outlined">
            <InputLabel id="base">{i18n.t("table.base")}</InputLabel>

            <Select
              labelId="base"
              value={base}
              onChange={handleChangeBase}
              style={{
                width: 150,
                marginRight: 20,
              }}
              label={i18n.t("table.base")}
            >
              <MenuItem value="">{"-"}</MenuItem>
              {baseSchema.map((el, index) => (
                <MenuItem key={index} value={el.value}>
                  {el.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              {[
                i18n.t("table.game_type"),
                i18n.t("groups.theme"),
                i18n.t("table.digit"),
                i18n.t("table.abacus"),
                i18n.t("table.actions"),
                i18n.t("olympiad.speed"),
                i18n.t("table.base"),
              ].map((el, index) => (
                <TableCell
                  key={index}
                  align="left"
                  variant="head"
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {el}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {processedStatData.map((row, index) => (
              <TableRow key={index}>
                {row.map((item, index) => (
                  <TableCell
                    align="left"
                    key={index}
                    component="th"
                    scope="row"
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AddsDataToTheMultTable;
