import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import i18n from 'i18next';

export default function ImgMediaCard({ title, link, image }) {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardMedia
                component="img"
                alt="green iguana"
                height="140"
                image={image}
                style={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                    padding: '20px 0',
                }}
            />
            <CardContent style={{ textAlign: 'center' }}>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Button
                    style={{ padding: '5px 15px', borderRadius: '7px' }}
                    variant="contained"
                    onClick={() => window.open(`${link}`, '_blank')}
                >
                    {i18n.t('buttons.download_csv')}
                </Button>
            </CardContent>
        </Card>
    );
}
