import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { Accordion, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import i18n from 'i18next';
import BodyActionButtons from '../BodyActionButtons';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import TypeWrapper from './TypeWrapper';
import React, { useCallback } from 'react';
import { countDate } from '../../../actions/date';
import { AllHWTopic } from '../../../constants/hw';
import { STATUSES } from '../../../constants/statuses';
import store from '../../../store';
import VerifiedIcon from '@mui/icons-material/Verified';
import InfoIcon from '@mui/icons-material/Info';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import { Box, Tooltip } from '@mui/material';

const disabledSummaryCodes = ['key', 'id', 'can_edit'];

const schemaGame = [
    {
        label: 'Ded',
        value: '3',
    },
    {
        label: 'Flash +',
        value: '5',
    },
    {
        label: 'Flash -',
        value: '6',
    },
    {
        label: 'Mult',
        value: '4',
    },
];
const lang_schema = { ru: 0, ua: 1, en: 2, ro: 5, by: 0, ee: 2 };

const MobileBody = ({
    fetching,
    data,
    typeTable,
    processClassName,
    model,
    actions,
    classes,
    userData,
    paySum,
    payType,
    goldUser,
    roleId,
    bankStudents,
    bankStudentsVip,
}) => {
    const processData = useCallback(
        (item, code = '') => {
            try {
                if (typeof item === 'object') {
                    // ??????
                    const obj = item;
                    if (obj) item = obj;
                }
            } catch (e) {
                console.log(e);
            }
            if (item && typeof item === 'object' && !!item[i18n.language]) {
                return item[i18n.language];
            } else if (
                item &&
                typeof item === 'object' &&
                !item[i18n.language]
            ) {
                for (let el in item) {
                    if (item[el]) {
                        return item[el];
                    }
                }
            } else if (code === 'birth_date') {
                return countDate(item);
            } else if (code === 'topic') {
                return AllHWTopic.find((el) => +el.value === +item).label;
            } else if (code === 'game_type') {
                return schemaGame.find((el) => +el.value === +item).label;
            } else if (typeof item === 'string' && item.includes('|||')) {
                return item.split('|||')[lang_schema[i18n.language]];
            } else if (typeof item === 'number' && code === 'status') {
                return i18n.t(
                    `users.${STATUSES.find((el) => +el.id === +item).title}`
                );
            } else if (code === 'course_id') {
                const { groups } = store.getState();
                if (
                    groups.select &&
                    groups.select.list &&
                    groups.select.list.length
                ) {
                    const title = groups.select.list.find(
                        (el) => el.id === item
                    );
                    if (!title) return i18n.t('groups.no_group_link');
                    if (
                        title.title &&
                        typeof title.title === 'object' &&
                        !title.title[i18n.language]
                    ) {
                        for (let el in title.title) {
                            if (title.title[el]) {
                                return title.title[el];
                            }
                        }
                    } else if (
                        typeof title.title === 'object' &&
                        !!title.title[i18n.language]
                    ) {
                        return title.title[i18n.language];
                    } else if (typeof title.title === 'string') {
                        return title.title;
                    }
                }
            }
            return item;
        },
        [i18n.language]
    );


    return (
        <div className="mobile_table">
            {fetching &&
                Array(typeTable === 'relocate' ? 2 : 5)
                    .fill(0)
                    .map((e, key) => (
                        <div key={key} style={{ padding: '2px 0px' }}>
                            <Skeleton height={'50px'} borderRadius={'0px'} />
                        </div>
                    ))}

            {!fetching &&
                !!data.length &&
                data.map((row, key) => {
                    let isStudentInBank = false;
                    let isStudentVip = false;
                    const singleGroupPage = typeTable === 'singleGroupPage';
                    const isHwFinished =
                        !goldUser &&
                        typeTable === 'HW' &&
                        row.played_date !== null;
                    if (
                        singleGroupPage &&
                        bankStudents &&
                        bankStudents.length
                    ) {
                        isStudentInBank = bankStudents.includes(row.id);
                    }
                    if (
                        singleGroupPage &&
                        bankStudentsVip &&
                        bankStudentsVip.length
                    ) {
                        isStudentVip = bankStudentsVip.includes(row.id);
                    }
                    return (
                        <Accordion className={`mobileTableAccordion`} key={key}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                className={`summary_mobile ${processClassName(
                                    typeTable,
                                    row
                                )}${isHwFinished ? ' settingFinished' : ''}`}
                            >
                                <Typography
                                    className="accordionHeadText"
                                    align="left"
                                    component="div"
                                >
                                    {isHwFinished ? (
                                        <Box
                                            style={{
                                                position: 'absolute',
                                                left: '0',
                                                top: '0',
                                                pointerEvents: 'all',
                                                cursor: 'alias',
                                            }}
                                        >
                                            <Tooltip
                                                title={i18n.t(
                                                    'table.hw_finished'
                                                )}
                                            >
                                                <InfoIcon fontSize="small" />
                                            </Tooltip>
                                        </Box>
                                    ) : null}
                                    {model
                                        .filter(
                                            (el) =>
                                                !disabledSummaryCodes.includes(
                                                    el.code
                                                )
                                        )
                                        .slice(0, 3)
                                        .map((rowItem, index, arr) => {
                                            const itemText = `${i18n.t(
                                                rowItem.label
                                            )}: ${processData(
                                                row[rowItem.code],
                                                rowItem.code
                                            )}`;
                                            return index !== arr.length - 1
                                                ? itemText + ' • '
                                                : itemText;
                                        })}
                                    {typeTable === 'payments'
                                        ? `  •  ${i18n.t(
                                              'table.price'
                                          )}: ${processData(row['price'])}`
                                        : null}
                                    {typeTable === 'iconicStudents'
                                        ? `  •  ${i18n.t(
                                              'table.status'
                                          )}: ${processData(
                                              row['status'],
                                              'status'
                                          )}`
                                        : null}
                                    {row['days_stat'] &&
                                    typeTable === 'singleGroupPage'
                                        ? `  •  ${i18n.t(
                                              'table.days_stat'
                                          )}: ${processData(
                                              row['days_stat'].value
                                          )}`
                                        : null}
                                </Typography>
                            </AccordionSummary>
                            <BodyActionButtons
                                actions={actions}
                                row={row}
                                classes={classes}
                                isHwFinished={isHwFinished}
                            />
                            <div
                                className={`AsyncMobileTable ${processClassName(
                                    typeTable,
                                    row
                                )}${isHwFinished ? ' settingFinished' : ''}`}
                            >
                                {isHwFinished ? (
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            left: '0',
                                            top: '0',
                                            pointerEvents: 'all',
                                            cursor: 'alias',
                                        }}
                                    >
                                        <Tooltip title={i18n.t('table.hw_finished')}>
                                            <InfoIcon />
                                        </Tooltip>
                                    </Box>
                                ) : null}
                                {singleGroupPage && (
                                    <div className="mobile_view">
                                        {isStudentInBank ? (
                                            <>
                                                <span>
                                                    {`Студент зареєстрований у СороБанк:  `}{' '}
                                                </span>
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <VerifiedIcon
                                                        style={{
                                                            color: isStudentVip
                                                                ? '#FF501C'
                                                                : '#2ca10a',
                                                        }}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <span>
                                                    {`Студент НЕ зареєстрований у СороБанк:  `}{' '}
                                                </span>
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <UnpublishedIcon />
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}

                                {typeTable === 'payments' && userData !== 5 && (
                                    <>
                                        <div
                                            onChange={(e) => {
                                                if (e.target.value < 0)
                                                    return (e.target.value =
                                                        '');
                                                paySum({
                                                    id: row.id,
                                                    payment_sum: e.target.value,
                                                });
                                            }}
                                            className="mobile_view"
                                        >
                                            <span>{`${i18n.t(
                                                'payments.enter_payment_amount'
                                            )}:  `}</span>
                                            <TextField
                                                name="payment_sum"
                                                style={{ maxWidth: '70px' }}
                                                type="number"
                                                defaultValue={row.payment_sum}
                                            />
                                        </div>
                                        <div className="mobile_view">
                                            <span>{`${i18n.t(
                                                'payments.pay_type'
                                            )}:  `}</span>
                                            <NativeSelect
                                                id="payment_type"
                                                style={{ minWidth: '100px' }}
                                                value={
                                                    row.payment_type
                                                        ? row.payment_type
                                                        : row.pay_type ===
                                                          'cash'
                                                        ? '0'
                                                        : '1'
                                                }
                                                onChange={(e) =>
                                                    payType({
                                                        id: row.id,
                                                        payment_type:
                                                            e.target.value,
                                                    })
                                                }
                                            >
                                                <option value="0">
                                                    {i18n.t('payments.cash')}
                                                </option>
                                                <option value="1">
                                                    {i18n.t(
                                                        'payments.cashless'
                                                    )}
                                                </option>
                                            </NativeSelect>
                                        </div>
                                    </>
                                )}
                                {model.map(
                                    ({ code, type, label, raw }, index) => {
                                        if (code === 'hw_age' && goldUser)
                                            return null;
                                        return raw !== false ? (
                                            <div
                                                key={index}
                                                className="mobile_view"
                                            >
                                                <span>{`${i18n.t(
                                                    label
                                                )}:  `}</span>
                                                <div
                                                    style={{
                                                        display: 'inline-block',
                                                        marginLeft: '10px',
                                                    }}
                                                >
                                                    <TypeWrapper
                                                        allcell={data}
                                                        data={row}
                                                        code={code}
                                                        temporarily={
                                                            row.temporarily
                                                        }
                                                        type={type}
                                                        id={row.id}
                                                        value={row[code]}
                                                        label={label}
                                                        roleId={roleId}
                                                    />
                                                </div>
                                            </div>
                                        ) : null;
                                    }
                                )}
                            </div>
                        </Accordion>
                    );
                })}
        </div>
    );
};

export default MobileBody;
