import Types from "../constants/olympiad";

const defaultState = {
    list: null,
    champions: null,
    type: 0,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.OLYMP:
            return { ...state, ...action.olympiad };
        default:
            return state;
    }
};
