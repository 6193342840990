import React from "react";
import { AllHWTopic } from "./../../../../constants/hw";
import NativeSelect from "@material-ui/core/NativeSelect";
import store from "./../../../../store";
import { __InitDataHWRow } from "./../../../../actions/AsyncTable";
import i18n from 'i18next';
import { Field } from "redux-form";

const renderTextField = ({ input, meta: { touched, error }, ...custom }) => {
  __InitDataHWRow({ [input.name]: custom.data.value })(
    store.dispatch,
    store.getState
  );
  const value = custom.data;
  return (
    <NativeSelect
      defaultValue={value.value}
      {...input}
      {...custom}
      style={{ minWidth: "80px" }}
    >
      <option value="0">{i18n.t('journal.choose_theme')}</option>
      {AllHWTopic.map((item, key) => (
        <option key={key} value={item.value}>
          {item.label}
        </option>
      ))}
    </NativeSelect>
  );
};

const renderTemplateName = ({ input, meta: { touched, error }, ...custom }) => {
  __InitDataHWRow({ [input.name]: custom.data.value })(
    store.dispatch,
    store.getState
  );
  const value = custom.data;
  let templateName = store.getState().journal.templatesName;
  return (
    <NativeSelect
      defaultValue={value.value}
      {...input}
      {...custom}
      style={{ minWidth: "80px" }}
    >
      <option value="0">{i18n.t('journal.choose_theme')}</option>
      {templateName.length &&
        templateName.map((item, key) => (
          <option key={key} value={item.value}>
            {item.label}
          </option>
        ))}
    </NativeSelect>
  );
};

export default ({ value, code, id, temporarily }) => {
  return (
    <Field
      data={"0"}
      name={JSON.stringify({ code, id, temporarily })}
      component={
        code === "setting.template_name" ? renderTemplateName : renderTextField
      }
      type="text"
    />
  );
};
