import React from 'react';
import { FieldArray } from 'redux-form';
// import DateView from "./Fields/DateView";
import CourseSelect from './Fields/CourseSelect';
import CountrySelect from './Fields/CountrySelect';
import CitiesSelect from './Fields/CitiesSelect';
import OfficesSelect from './Fields/OfficesSelect';

import DaysSelect from './Fields/DaysSelect';
import StatusSelect from './Fields/StatusSelect';
import StringArrayView from './Fields/StringArrayView';
import TeacherSelect from './Fields/TeacherSelect';
import AutocomplateArrayView from './Fields/AutocomplateArrayView';
import { useTranslation } from 'react-i18next';
import StatusCourse from './Fields/statusCourse';
import TeacherSupport from './Fields/TeacherSupport';
export default ({ unique, item, index, ...props }) => {
    const { t } = useTranslation();
    switch (item.type) {
        case 'string':
        case 'number':
        case 'price':
        case 'redirect':
        case '[phone]':
        case '[email]':
        case 'lang':
        case 'course_input':
        case 'LinkToSinlgeAndLang':
            return (
                <FieldArray
                    name={item.code}
                    label={t(item.label)}
                    component={StringArrayView}
                    placeholder={t(item.label)}
                    unique={unique}
                    data={item}
                    index={index}
                />
            );
        // case "date":
        //   return <DateView {...item} />;
        case 'city.name':
        case 'city.id':
            return (
                <FieldArray
                    name={item.code}
                    label={t(item.label)}
                    getData={props.getCities}
                    data={props.cities}
                    component={AutocomplateArrayView}
                    placeholder={t(item.label)}
                />
            );
        case 'subdivision.name':
        case 'subdivision.id':
            return (
                <FieldArray
                    name={item.code}
                    label={t(item.label)}
                    getData={props.getSubdivisions}
                    data={props.subdivisions}
                    component={AutocomplateArrayView}
                    placeholder={t(item.label)}
                />
            );
        case 'counterparty.name':
        case 'counterparty.id':
            return (
                <FieldArray
                    name={item.code}
                    label={t(item.label)}
                    getData={props.getCounterparties}
                    data={props.counterparties}
                    component={AutocomplateArrayView}
                    placeholder={t(item.label)}
                />
            );
        case 'status_course':
            return <StatusCourse {...item} />;
        case 'lesson_day':
            return <DaysSelect {...item} />;
        case 'course_select':
            return <CourseSelect {...item} />;
        case 'country_select':
            return <CountrySelect {...item} />;
        case 'cities_select':
            return <CitiesSelect {...item} />;
        case 'offices_select':
            return <OfficesSelect {...item} />;
            case 'teachers_select':
            return <TeacherSelect {...item} />;
        case 'status_select':
            return <StatusSelect {...item} />;
        case 'teachers_input':
        // case 'teachers_select':
        //     return <TeacherSupport {...item} />;

        default:
            return null;
    }
};
