import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CitiesService from './../../services/cities.service';
import { Box, Button, Card, CircularProgress } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import i18n from 'i18next';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import { getCurrentCurrency } from '../../actions/currencies';

const BlockDayComponent = (props) => {
    const { t } = props;
    const classes = useStyles();
    const [blockDay, setBlockDay] = useState('');
    const [loading, setLoading] = useState(true);
    const [currentBlockDay, setCurrentBlockDay] = useState(null);

    const fetchData = useCallback(async () => {
        setLoading(true);
        const res = await CitiesService.GetBlockDay();
        if (!res || (res.block_day && typeof res.block_day !== 'number'))
            return;
        setLoading(false);
        setCurrentBlockDay(res.block_day);
        setBlockDay('');
        return true;
    }, [setLoading, setCurrentBlockDay, CitiesService]);

    useEffect(() => {
        fetchData();
    }, []);

    const blockDayRestrict = useMemo(() => {
        return localStorage.getItem('devMode');
    }, []);

    const handleDateChange = useCallback((e) => {
        setBlockDay(e.target.valueAsNumber);
    }, []);

    const handleSubmit = useCallback(async () => {
        if (blockDay <= 0 || blockDay >= 21) {
            // getErrorNotification();
            return;
        }
        setLoading(true);
        await CitiesService.ChangeBlockDay({ day: blockDay });
        await fetchData();
    }, [blockDay]);

    const inputError = useMemo(() => {
        if (typeof blockDay !== 'number') {
            return false;
        }
        return blockDay <= 0 || blockDay >= 21;
    }, [blockDay]);

    return (
        <Card className={classes.root}>
            <Box
                p={2}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={'100%'}
            >
                <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    style={{ marginBottom: '10px' }}
                >
                    {i18n.t('payments.block_day')}
                </Typography>
                <Box>
                    {currentBlockDay && currentBlockDay !== 0 ? (
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="h6"
                            style={{ fontSize: '14px', marginBottom: '15px' }}
                        >
                            {`${i18n.t('payments.current_block_day')} `}
                            <span className={`cityPrice ok`}>
                                {currentBlockDay}
                            </span>
                        </Typography>
                    ) : null}

                    <TextField
                        id="date"
                        placeholder={i18n.t('payments.block_day_placeholder')}
                        type="number"
                        onChange={handleDateChange}
                        value={blockDay}
                        disabled={loading}
                        fullWidth
                        error={inputError}
                        variant="outlined"
                        helperText={inputError && t('error.block_day_error')}
                    />
                    <Button
                        style={{ margin: '15px 0px' }}
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                        disabled={inputError || loading || !blockDay}
                        id={'block_day_disable'}
                    >
                        {i18n.t('buttons.save')}{' '}
                        <Box>
                            {loading ? (
                                <CircularProgress size={24} color="inherit" />
                            ) : null}
                        </Box>
                    </Button>
                </Box>
            </Box>
        </Card>
    );
};

const useStyles = makeStyles({
    root: {
        maxWidth: 325,
        marginRight: '7px',
        marginBottom: '7px',
    },
});

export default withTranslation()(BlockDayComponent);
