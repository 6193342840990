import React from "react";
export default ({ value }) => {
  if (+value) value = +value;
  let d = new Date(value);
  const _month = d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  const _date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();

  const date = `${_date}.${_month}.${d.getFullYear()} `;
  return (
    <span style={{ whiteSpace: "nowrap" }}>
      {value ? date : "-"}
    </span>
  )
};
