import React from "react";
import i18n from "i18next"

export default ({ value, code }) => {
  let type = {
    "cash": i18n.t("payments.cash"),
    "cashless": i18n.t("payments.cashless"),
    "online": i18n.t("payments.cashless")
  }
  const width = code === "key" ? "30px" : "150px"
  return(
    value ? (
      <div
        style={{
          display: "block",
          whiteSpace: "nowrap",
          maxWidth: width,
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
        title={code === "pay_type" ? type[value.toString()] : value.toString()}
        children={code === "pay_type" ? type[value.toString()] : value.toString()}
      />
    ) : (
      "-"
    )
  );
}
