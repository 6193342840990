import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Prompt } from 'react-router-dom';
import DashLayout from '../layout/Dash';
import { recordUid } from '../actions/books';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import store from './../store';
import { openRequestModal } from '../actions/modals';
import { withTranslation } from 'react-i18next';

class ProtectedRoute extends Component {
    render() {
        const handleBlockedRoute = (nextLocation) => {
            const { t } = this.props;
            this.props.openRequestModal({
                opened: true,
                data: {
                    header: t('sidebar.hw_settings'),
                    modaltype: 'PreventRouterModal',
                    nextLocation,
                },
            });
            return false;
        };
        const isAuthenticated = this.props.isAuthenticated;
        const Component = this.props.component;
        const path = this.props.path;
        const queryString = this.props.location.search;
        let uid = null;
        if (queryString && queryString.includes('book')) {
            uid = queryString.substr(queryString.indexOf('book=') + 5, 50);
            recordUid(uid)(store.dispatch, store.getState);
        }
        if (!isAuthenticated && uid)
            toast.info('Авторизируйтесь для продолжения привязки учебника');
        window.onbeforeunload = (e) => {
            if (
                !this.props.exitAccept &&
                path.includes('/groups/:id/hw/:date') &&
                (!!this.props.touched ||
                    !(this.props.tableCount === this.props.tableActual))
            ) {
                return 'breforeUnload';
            }
        };
        return isAuthenticated ? (
            <Route
                path={path}
                render={(props) => {
                    return (
                        <DashLayout {...props}>
                            <Component {...props} />
                            <Prompt
                                when={
                                    !this.props.exitAccept &&
                                    path.includes('/groups/:id/hw/:date') &&
                                    (!!this.props.touched ||
                                        !(
                                            this.props.tableCount ===
                                            this.props.tableActual
                                        ))
                                }
                                message={handleBlockedRoute}
                            />
                        </DashLayout>
                    );
                }}
            />
        ) : (
            <Redirect to="/login" />
        );
    }
}

const widthConnect = connect(
    (state) => ({
        isAuthenticated: state.user && state.user.loggedin,
        touched:
            state.form &&
            state.form['from-tableComponent'] &&
            state.form['from-tableComponent'].anyTouched,
        tableCount:
            state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.count,
        tableActual:
            state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.data &&
            state.AsyncTable.table.data.length,
        exitAccept: state.groups && state.groups.acceptExitHwChange,
    }),
    (dispatch) => bindActionCreators({ recordUid, openRequestModal }, dispatch)
);
export default withTranslation()(widthConnect(ProtectedRoute));
