import React, { Fragment } from "react";
import ComponentTable from "../../components/ComponentTable";

import "./Exercise.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { closeRequestModal, openRequestModal } from "../../actions/modals";

class ExercisePage extends React.Component {
  render() {
    const headRows = [
      { field: "id", title: "Тип" },
      { field: "payment_date", title: "Налаштування" },
      { field: "expired", title: "Дата" },
      { field: "user_payment", title: "Статус" },
    ];

    let rows;

    // if (this.props.exercise.list) {
    //     rows = this.props.exercise.list;
    // } else {
    rows = [
      {
        type: null,
        settings: null,
        date: null,
        status: null,
      },
    ];
    rows.map((item, key) => {
      item.type = "Дед Соробан";
      item.date = "12.12.19";
      item.settings = "";
      item.status = "Виконано: 5";
      // item.buttons = <button onClick={this.props.openRequestModal.bind(this, {opened: true, data: {id: item.id}})}>open</button>;
      return item;
    });
    return (
      <Fragment>
        <h2 className="container-header"> Завдання </h2>
        <div className="teacher_exercise"></div>
        <ComponentTable
          rowData={rows}
          headData={headRows}
          headerTable={"Платежі"}
          selectable={true}
          toolbarvisible={true}
        />
      </Fragment>
    );
  }
}

const widthConnect = connect(
  (state) => ({ exercise: state.exercise, modalWindow: state.modalWindow }),
  (dispatch) =>
    bindActionCreators({ openRequestModal, closeRequestModal }, dispatch)
);

export default widthConnect(ExercisePage);
