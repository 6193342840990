import React from 'react';
import ReactDOM from 'react-dom';
import * as buffer from 'buffer';
import './layout/darkTheme.css';
window.Buffer = buffer.Buffer;
import ThemeConfig from './theme';

import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'react-phone-number-input/style.css';

import uaLocale from 'date-fns/locale/uk';
import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import eeLocale from 'date-fns/locale/et';
import roLocale from 'date-fns/locale/ro';

import { RootRoute } from './routes';
import * as serviceWorker from './serviceWorker';
import { ConnectedRouter } from 'connected-react-router';
import store from './store';
import history from './history';
import './index.css';
import './i18n.js';
import i18n from 'i18next';
import { ToastContainer } from 'react-toastify';

const localeMap = {
    ua: uaLocale,
    en: enLocale,
    ru: ruLocale,
    ee: eeLocale,
    ro: roLocale,
};

function App() {
    return (
        <ThemeConfig>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={localeMap[i18n.language]}
                    >
                        <RootRoute />
                    </MuiPickersUtilsProvider>
                </ConnectedRouter>
                <ToastContainer
                    stacked
                    position="top-center"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
            </Provider>
        </ThemeConfig>
    );
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
