import React from 'react';
import { connect } from 'react-redux';

import { getCities } from '../../actions/cities';
import { getUsers } from '../../actions/getUsers';
import { filterData } from '../../actions/groups';
import { getOffices } from '../../actions/offices';
import { bindActionCreators } from 'redux';
import './ChoosePlate.css';

class ChoosePlate extends React.Component {
    componentDidMount() {
        let query = {
            role_id: 4,
        };
        this.props.getCities(false, query);
        this.props.getUsers('teacher', [false, query]);
    }
    constructor(props) {
        super(props);
        this.state = {
            // country: 'default',
            city: 'default',
            office: 'default',
            teacher: '',
        };
    }

    handleClicker = (e) => {
        const filter_data = {
            city: this.props.groups.filtration.city,
            teacher: this.props.groups.filtration.teacher,
        };
        switch (e.target.name) {
            case 'city':
                filter_data.city = e.target.value;
                let city = {
                    city: e.target.value,
                };
                this.props.getOffices(false, city);
                this.setState({
                    city: e.target.value,
                });
                break;
            case 'office':
                filter_data.office = e.target.value;
                this.setState({
                    office: e.target.value,
                });
                break;
            case 'teacher':
                filter_data.teacher = e.target.value;
                this.setState({
                    teacher: e.target.value,
                });
                break;
            default:
                console.log('wrong select');
        }
        this.props.filterData(filter_data);
    };

    displayData() {
        this.props.filterData();
    }

    render() {
        /* eslint-disable no-unused-vars */
        const cities =
            this.props.cities && this.props.cities.list
                ? this.props.cities.list.map((item, index) => {
                      return (
                          <option key={index} value={item}>
                              {item}
                          </option>
                      );
                  })
                : [];
        const teacher =
            this.props.users &&
            this.props.users.blocks &&
            this.props.users.blocks.teacher &&
            this.props.users.blocks.teacher.list
                ? this.props.users.blocks.teacher.list.map((item, index) => {
                      return (
                          <option key={index} value={item.id}>
                              {item.first_name} {item.last_name}
                          </option>
                      );
                  })
                : [];
        const offices =
            this.props.offices && this.props.offices.list
                ? this.props.offices.list.map((item, index) => {
                      return (
                          <option key={index} value={item.title}>
                              {item.title}
                          </option>
                      );
                  })
                : [];

        return <div>ASDASDASDASDASD</div>;
    }
}
const widthConnect = connect(
    (state) => ({
        user: state.user,
        users: state.users,
        cities: state.cities,
        groups: state.groups,
        offices: state.offices,
    }),
    (dispatch) =>
        bindActionCreators(
            { getUsers, getCities, filterData, getOffices },
            dispatch
        )
);

export default widthConnect(ChoosePlate);
