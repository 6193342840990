//import PATTERNS from "./../../../common/patterns";

export default {
    edrpou: e => !!e && ![8, 10].includes(e.length) && "Допустимая длина 8 или 10 символов",
    aptekaedrpou: e => !!e && ![8, 10].includes(e.length) && "Допустимая длина 8 или 10 символов",
    address_postcode: e => !!e && e.length < 5 && "Минимальная длина 5 символов",
    mfo: e => !!e && e.length < 6 && "Минимальная длина 6 символов",
    // phone: e => !!e && !/^((\+380)+([0-9]){9})$/i.test(e) && "Не корректный формат телефона",
    //email: e => !!e && !PATTERNS.EMAIL.test(e) && "Не корректный формат Email",
    //aptekaemail: e => !!e && !PATTERNS.EMAIL.test(e) && "Не корректный формат Email"
};
