const booksSchema = {
  1: `textbook`,
  2: `textbook`,
  K: `textbook_kids`,
  M: `textbook_mult`,
  WITHOUT: "without_books",
  yellow: "yellow",
  red: "red",
  green: "green",
};
const colorsSchema = {
  yellow: "#f3ca00",
  red: "#ff3c3c",
  green: "green",
};

export { booksSchema, colorsSchema };
