import React from 'react';
import i18n from 'i18next';
import { Chip, Stack } from '@mui/material';
import store from '../../../store';
import { getSingleUser } from '../../../actions/getUsers';
import { closeRequestModal, openRequestModal } from '../../../actions/modals';

const openFamilyDiscountModal = async (user, currentId) => {
    if (user.student_id === currentId) {
        return;
    }
    const id = user.student_id;
    const student = await getSingleUser(id);
    closeRequestModal()(store.dispatch, store.getState);
    setTimeout(() => {
        openRequestModal({
            opened: true,
            data: {
                id: id,
                header: `${i18n.t('sidebar.discounts')} | ${user.name}`,
                user_status: student.status,
                modaltype: 'EditDiscountsModal',
            },
        })(store.dispatch, store.getState);
    }, 300);
};

const DiscountsOptions = ({ field, label, family = false, id }) => {
    if (!field || !field.length) return null;
    if (family) {
        return (
            <div className="itemDiscount" style={{ margin: '10px 0' }}>
                <p style={{ marginLeft: '5px' }}>{`${
                    label ? `${i18n.t(label)}: ` : ``
                }`}</p>
                {field.map((el, index) => (
                    <Chip
                        key={index}
                        style={{
                            marginRight: '5px',
                            marginBottom: '7px',
                        }}
                        onClick={() => openFamilyDiscountModal(el, id)}
                        label={`${el.name} | ${el.amount_rate}%`}
                    />
                ))}
            </div>
        );
    }
    return (
        <div className="itemDiscount">{`${
            label ? `${i18n.t(label)}` : ``
        }: ${field}`}</div>
    );
};
export default DiscountsOptions;
