import Types from "./../constants/getList";

const defaultState = {
  list: null,
  count: 0,
  cityPrice: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.CITIES:
    case Types.EDITCITY:
      return { ...state, ...action.cities };
    default:
      return state;
  }
};
