import Types from '../constants/journal';
import TypesAsyncTable from './../constants/AsyncTable';
import JournalService from './../services/journal.service';
import HomeworkService from './../services/homework.service';
import { reloadTable } from './AsyncTable';

async function getData() {
    return await JournalService.JournalList();
}

function editJournal(data) {
    return (dispatch, getState) => {
        JournalService.SaveJournal(data).then((el) => {
            reloadTable()(dispatch, getState);
        });
    };
}

function changeSelect(data) {
    return (dispatch, getState) => {
        const { journal } = getState();
        dispatch({
            type: Types.CHANGE_DATA,
            journal: {
                ...journal,
                topic: data.topic ? data.topic : '',
            },
        });
    };
}

function initTemplatesName() {
    return (dispatch, getState) => {
        HomeworkService.getTemplatesName(false).then((el) => {
            dispatch({
                type: Types.CHANGE_DATA,
                journal: {
                    templatesName: el.templatesName,
                },
            });
        });
    };
}

function clearJournal() {
    return (dispatch, getState) => {
        dispatch({
            type: Types.CHANGE_DATA,
            journal: {
                topic: [],
                templatesName: [],
                list: [],
                table: [],
            },
        });
    };
}

function addThemesArray(item) {
    return (dispatch, getState) => {
        const { journal } = getState();
        dispatch({
            type: Types.CHANGE_DATA,
            journal: {
                ...journal,
                topic: item,
            },
        });
    };
}

function processData(item) {
    return (dispatch, getState) => {
        const { journal } = getState();

        let table_data = journal.table;
        let result = [];
        for (let data of table_data) {
            if (data.title === item) result.push(data);
        }
        for (let i = 0; i < result.length; i++) {
            result[i].key = i + 1;
        }

        dispatch({
            type: TypesAsyncTable.DATA,
            payload: {
                data: result,
            },
        });
    };
}

function recordList(data) {
    return (dispatch, getState) => {
        const { journal } = getState();
        const { AsyncTable } = getState();
        dispatch({
            type: Types.CHANGE_DATA,
            journal: {
                ...journal,
                list: data,
                table: AsyncTable && AsyncTable.table && AsyncTable.table.data,
            },
        });
        processData(data[0]);
    };
}

function saveJournal(args) {
    return async function (dispatch, getState) {
        return await JournalService.CreateJournal({
            title: args,
            setting: [
                {
                    topic: 'S2',
                    templateName: '',
                },
            ],
        }).then((res) => {
            reloadTable()(dispatch, getState);
        });
    };
}

export {
    saveJournal,
    changeSelect,
    getData,
    recordList,
    processData,
    addThemesArray,
    initTemplatesName,
    clearJournal,
    editJournal,
};
