import Types from '../constants/cities';
import CitiesService from './../services/cities.service';
import { getError } from './toast';
import { closeRequestModal } from './modals';
import { reloadTable } from './AsyncTable';
import store from '../store';
import { combineLanguages } from './languages';

async function ChangeCityPrice(data) {
    const res = await CitiesService.ChangeCityPrice({ avg_price: +data });
    GetCurrentPrice()(store.dispatch, store.getState);
    return res;
}

async function PostFranchPrices({ code, data }) {
    let res;
    switch (code) {
        case 'avg_price_online':
            res = await CitiesService.PostFranchPriceOnline(data);
            break;
        case 'avg_price_li_online':
            res = await CitiesService.PostFranchPriceOnlineLi(data);
            break;
        default:
            break;
    }
    return res;
}

async function GetFranchPrices({ code }) {
    let res;
    switch (code) {
        case 'avg_price_online':
            res = await CitiesService.GetFranchPriceOnline();
            break;
        case 'avg_price_li_online':
            res = await CitiesService.GetFranchPriceOnlineLi();
            break;
        default:
            break;
    }
    return res;
}

function GetCurrentPrice() {
    return async (dispatch, getState) => {
        const res = await CitiesService.GetCurrentPrice();
        const { cities } = getState();
        if (res) {
            dispatch({
                type: Types.GETCITIES,
                cities: {
                    ...cities,
                    cityPrice: res.avg_price,
                },
            });
        }
        return res;
    };
}

async function ChangeCityLiPrice(data) {
    const res = await CitiesService.ChangeCityLiPrice({ avg_price: +data });
    GetCurrentLiPrice()(store.dispatch, store.getState);
    return res;
}

function GetCurrentLiPrice() {
    return async (dispatch, getState) => {
        const res = await CitiesService.GetCurrentLiPrice();
        const { cities } = getState();
        if (res) {
            dispatch({
                type: Types.GETCITIES,
                cities: {
                    ...cities,
                    liPrice: res.avg_proce_li,
                },
            });
        }
        return res
    };
}

function getCities(...args) {
    return async (dispatch, getState) => {
        try {
            const response = await CitiesService.CitiesList(...args);
            dispatch({
                type: Types.GETCITIES,
                cities: response,
            });
            return response;
        } catch (error) {
            // throw 'ERROR => GET CITIES!';
            getError(error);
        }
    };
}

function getCitiesStat(...args) {
    return async (dispatch, getState) => {
        try {
            return await CitiesService.GetCitiesStat(...args);
        } catch (e) {
            getError(e);
        }
    };
}

function addCity(data) {
    return (dispatch, getState) => {
        const request = {
            parent_id: data.parent_id,
            lang_def: {},
        };
        for (let index in data) {
            const [key, lang] = index.split('|');
            if (key && lang) {
                request[key][lang] = data[index];
            }
        }
        CitiesService.AddCity({ ...request, boss_id: data.boss_id }).then(
            (response) => {
                dispatch({
                    type: Types.ADDCITY,
                });
                closeRequestModal()(dispatch, getState);
                reloadTable()(dispatch, getState);
            }
        );
    };
}

function editCity(arr_data) {
    return (dispatch, getState) => {
        const data = structuredClone(arr_data);
        const toString = combineLanguages(arr_data.languages);

        for (let key in data) {
            // eslint-disable-next-line
            if (data[key] === '') delete data[key];
        }
        if (!data.ar) data.ar = false;
        if (!data.book) data.book = false;
        data.title = toString;
        CitiesService.EditCity(data).then((response) => {
            dispatch({
                type: Types.EDITCITY,
            });
            closeRequestModal()(dispatch, getState);
            reloadTable()(dispatch, getState);
        });
    };
}

function deleteCity(data) {
    return (dispatch, getState) => {
        CitiesService.DeleteCity(data).then((response) => {
            dispatch({
                type: Types.DELETECITY,
            });
            closeRequestModal()(dispatch, getState);
            reloadTable()(dispatch, getState);
        });
    };
}

function clearSingleCity() {
    return async (dispatch, getState) => {
        dispatch({
            type: Types.GETCITIES,
            cities: {
                single: null,
            },
        });
    };
}

function getSingleCity(...data) {
    return async (dispatch, getState) => {
        const res = await CitiesService.SingleCity(...data);
        dispatch({
            type: Types.GETCITIES,
            cities: {
                single: res,
            },
        });
        return res;
    };
}

export {
    getCities,
    addCity,
    editCity,
    getSingleCity,
    deleteCity,
    getCitiesStat,
    clearSingleCity,
    ChangeCityPrice,
    GetCurrentPrice,
    GetCurrentLiPrice,
    ChangeCityLiPrice,
    PostFranchPrices,
    GetFranchPrices,
};
