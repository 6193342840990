import ComponentTable from '../../components/ComponentTable';
import BooksService from '../../services/books.service';
import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { emulation } from '../../actions/user';
import { Badge, Button, Grid } from '@mui/material';
import BOOKS from '../../constants/qr_books';
import { reloadTable } from '../../actions/AsyncTable';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ModelService from '../../services/models.service';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { openRequestModal } from '../../actions/modals';
import { booksSchema } from '../../constants/booksConstant';
import { getGroupsFromSelect } from '../../actions/groups';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getBooksColorsCount, getBooks } from '../../actions/books';

const data = booksSchema;

const FranchBooksPage = (props) => {
    const history = useHistory();
    const { t } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const bookId = queryParams.get('id');
    const [activeBook, setActiveBook] = useState(bookId || 'K');
    const [countItems, setCountItems] = useState([]);

    const fetch = () => {
        props.getGroupsFromSelect(false, { size: 9999 });
        processBooks();
    };

    const processBooks = async () => {
        const getBooksResponse = await props.getBooks();
        let res = structuredClone(getBooksResponse);
        const colors = await getBooksColorsCount();

        res = { ...res, ...colors };
        const data = booksSchema;

        let items = Object.keys(res)
            .map((el, i) =>
                data[el]
                    ? {
                          count: res[Object.keys(res)[i]],
                          code: Object.keys(res)[i],
                      }
                    : null
            )
            .filter(Boolean);
        setCountItems(items);
    };
    useEffect(() => {
        fetch();
    }, []);

    const changeBookType = useCallback(
        (index) => {
            setActiveBook(index);
            props.reloadTable();
        },
        [setActiveBook, props.reloadTable]
    );

    const booksLayout = useMemo(() => {
        const BOOKS_FRANCH = structuredClone(BOOKS);
        BOOKS_FRANCH.unshift(
            {
                value: 'red',
                label: 'red',
                color: 'red',
            },
            {
                value: 'yellow',
                label: 'yellow',
                color: 'yellow',
            }
        );
        return BOOKS_FRANCH.map(({ label, value, color }, index) => {
            const count = countItems.find((el) => el.code === value);
            return (
                <Grid item key={index}>
                    <Badge
                        badgeContent={count ? count.count : null}
                        color="primary"
                        max={9999999}
                    >
                        <Button
                            onClick={() => changeBookType(value)}
                            variant={
                                activeBook === value ? 'contained' : 'outlined'
                            }
                            disabled={props.fetching}
                            className={color || ''}
                            style={{
                                margin: 0,
                            }}
                        >
                            {t(`books.${data[value]}`)}
                            {value === '1' || value === '2'
                                ? ` ${value}`
                                : null}
                        </Button>
                    </Badge>
                </Grid>
            );
        });
    }, [activeBook, props.fetching, countItems, t]);

    return (
        <Fragment>
            <IconButton
                aria-label="add to favorites"
                className="back_icon"
                onClick={() => history.push(`/dashboard`)}
            >
                <ArrowBackIcon />
            </IconButton>
            <Grid container spacing={1} style={{ marginBottom: '15px' }}>
                {booksLayout}
            </Grid>
            {props.groups ? (
                <ComponentTable
                    source={{
                        getData: (page, size, sort, filter) =>
                            activeBook === 'yellow' || activeBook === 'red'
                                ? BooksService.getColorsDetails(false, {
                                      page: page || 0,
                                      size: size || 10,
                                      orderBy: (sort && sort.orderBy) || null,
                                      order: (sort && sort.order) || null,
                                      filter,
                                      color: activeBook,
                                  })
                                : BooksService.getFranchBooks(false, {
                                      bookId: activeBook,
                                      page: page || 0,
                                      size: size || 10,
                                      orderBy: (sort && sort.orderBy) || null,
                                      order: (sort && sort.order) || null,
                                      filter,
                                  }),
                        getModel: () =>
                            ModelService.StudentsBirthday(false, {
                                role_id: 5,
                            }),
                    }}
                    actions={[
                        {
                            title: t('groups.assign_book'),
                            onClick: (id, user) => {
                                return props.openRequestModal({
                                    opened: true,
                                    data: {
                                        id: id,
                                        header: `${t('groups.assign_book')} | ${
                                            user.first_name
                                        } ${user.last_name}`,
                                        modaltype: 'AssignBookModal',
                                    },
                                });
                            },
                            Icon: MenuBookIcon,
                        },
                    ]}
                    toolbarvisible={true}
                    enableFilter={true}
                    typeTable="books_analytics"
                />
            ) : (
                <CircularProgress size={20} />
            )}
        </Fragment>
    );
};

const widthConnect = connect(
    (state) => ({
        user: state.user,
        cities: state.cities,
        offices: state.offices,
        fetching:
            state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.fetching,
        groups:
            state.groups &&
            state.groups.select &&
            state.groups.select.list &&
            state.groups.select.list.length,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                emulation,
                reloadTable,
                getGroupsFromSelect,
                getBooks,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(FranchBooksPage));
