export default {
    REGISTRATION: 'bank_registration',
    SET_BALANCE: '@set_balance',
    CLEAR_STATE: '@clear_state',
    SET_PENDING_TRANSACTIONS: '@set_pending_transactions',
    SET_PENDING_ROOT_TRANSACTIONS: '@set_pending_root_transactions',
    SET_PENDING_ORDERS: '@set_pending_orders',
    SET_All_PRODUCTS: '@set_all_products',
    SET_All_CART_ITEMS: '@set_all_cart_items',
    SET_TEACHERS_BALANCES: '@set_teachers_balances',
    SET_STUDENTS_BALANCES: '@set_students_balances',
    SET_CITIES_BALANCES: '@set_cities_balances',
};
