import React from "react";

export default ({ value }) =>
    value ? (
        <div
            dangerouslySetInnerHTML={{ __html: value }}
            style={{ maxHeight: 200, overflowY: "auto", textAlign: "initial" }}
        />
    ) : (
        "-"
    );
