import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import './Payments.css';

import {
    getFopBillList,
    createFopBill,
    editFopBill,
    deleteFopBill,
} from '../../actions/fop';
import { getErrorNotification } from './../../actions/toast';
import OfficesService from './../../services/offices.service';

import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { Delete } from '@material-ui/icons';
import SaveIcon from '@material-ui/icons/Save';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class FopBillComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fopList: [],
            offices: [],
            selected_offices: [],
            loading: true,
        };
    }

    componentWillUnmount() {
        if (this.evtSource) this.evtSource.close();
    }

    componentDidMount() {
        this.getFopBill();
    }

    async getFopBill() {
        const offices = await OfficesService.OfficesList(false, false);
        const res = await this.props.getFopBillList();
        let selected_offices = [];
        for (let item of res.list) {
            item.loading = false;
            for (let i of item.school) selected_offices.push(i);
        }
        let fopList = res.list;
        // eslint-disable-next-line
        this.state.fopList.filter(function (el) {
            if (!el.author_id) {
                res.list.push(el);
            }
        });
        if (res && offices && offices.list)
            this.setState({
                fopList,
                offices: offices.list,
                loading: false,
                selected_offices,
            });
    }

    countSelectedOffices(fopList) {
        let selected_offices = [];
        for (let item of fopList) {
            for (let i of item.school) selected_offices.push(i);
        }
        this.setState({ selected_offices, fopList });
    }

    handleChange = (e) => {
        const { event, index } = e;
        const fopList = this.state.fopList;
        fopList[index][event.target.name] = event.target.value;
        this.setState({ fopList });
    };

    handleChangeOffice = () => {};

    addFopBill() {
        let fopList = this.state.fopList;
        fopList.push({
            private_key: '',
            public_key: '',
            school: [],
            title: '',
            errorTitle: false,
        });
        this.setState({ fopList });
    }

    handleDeleteBill(index) {
        const fopList = this.state.fopList;
        // eslint-disable-next-line
        if (fopList[index].loading == undefined) {
            delete fopList[index];
            const filtered = fopList.filter(function (el) {
                return el != null;
            });
            this.countSelectedOffices(filtered);
            return;
        }
        fopList[index].loading = true;
        this.setState({ fopList });
        this.props.deleteFopBill({ id: fopList[index].id });
        this.getFopBill();
    }
    async createBill({ private_key, public_key, school, title }, index) {
        let offices = [];
        for (let item of school) {
            offices.push(item.id);
        }
        offices = JSON.stringify(offices);
        await this.props.createFopBill({
            private_key,
            public_key,
            title,
            offices,
        });
        const delte_added = this.state.fopList;
        delete delte_added[index];
        this.setState({ fopList: delte_added });
        this.getFopBill();
    }

    async handleSaveChanges(index) {
        let fopList = this.state.fopList;
        let offices_id = [];
        for (let item of fopList[index].school) {
            offices_id.push(item.id);
        }
        const data = {
            id: fopList[index].id,
            title: fopList[index].title,
            offices: JSON.stringify(offices_id),
            public_key: fopList[index].public_key,
            private_key: fopList[index].private_key,
        };
        if (
            !data.title ||
            !data.public_key ||
            !data.private_key ||
            !data.offices.length
        ) {
            fopList[index].errorTitle = !data.title;
            this.setState({ fopList });
            getErrorNotification('Заполните все поля.');
            return;
        }
        if (this.state.fopList[index].loading === undefined) {
            return this.createBill(this.state.fopList[index], index);
        }
        await this.props.editFopBill(data);
        fopList[index].loading = true;
        this.setState({ fopList, fopTitleError: false });
        this.getFopBill();
    }

    renderFopData() {
        const { t } = this.props;
        return this.state.fopList.map((item, index) => {
            return (
                <Card
                    key={index}
                    className={
                        item.loading
                            ? 'fop_data inactive'
                            : 'fop_data animated fadeIn faster'
                    }
                    style={{
                        minWidth: '310px',
                    }}
                >
                    {item.loading ? (
                        <div className="items_fop_bill_background">
                            <CircularProgress color="inherit" size={20} />
                        </div>
                    ) : null}
                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" className="avatar">
                                <LocalAtmIcon />
                            </Avatar>
                        }
                        title={
                            <TextField
                                name="title"
                                onChange={(e) =>
                                    this.handleChange({
                                        event: e,
                                        index: index,
                                    })
                                }
                                error={item.errorTitle}
                                label={t('offices.fop_title')}
                                value={item.title}
                                color="secondary"
                            />
                        }
                        action={
                            <CardActions disableSpacing>
                                <IconButton
                                    title={t('buttons.delete')}
                                    aria-label="add to favorites"
                                    className="delete_icon"
                                    onClick={() => this.handleDeleteBill(index)}
                                >
                                    <Delete />
                                </IconButton>
                                <IconButton
                                    title={t('buttons.save')}
                                    aria-label="share"
                                    className="save_icon"
                                    onClick={() =>
                                        this.handleSaveChanges(index)
                                    }
                                >
                                    <SaveIcon />
                                </IconButton>
                            </CardActions>
                        }
                    />
                    <CardContent>
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="div"
                        >
                            <Box component="div" m={2}>
                                <TextField
                                    style={{ widthL: '100%' }}
                                    name="private_key"
                                    onChange={(e) =>
                                        this.handleChange({
                                            event: e,
                                            index: index,
                                        })
                                    }
                                    label={t('offices.private_key')}
                                    variant="outlined"
                                    value={item.private_key}
                                />
                            </Box>
                            <Box component="div" m={2}>
                                <TextField
                                    style={{ width: '100%' }}
                                    name="public_key"
                                    onChange={(e) =>
                                        this.handleChange({
                                            event: e,
                                            index: index,
                                        })
                                    }
                                    label={t('offices.public_key')}
                                    variant="outlined"
                                    value={item.public_key}
                                />
                            </Box>
                            <Box component="div" m={2}>
                                <Autocomplete
                                    multiple
                                    options={this.state.offices}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) =>
                                        option.title.split('|||')[0]
                                    }
                                    value={item.school}
                                    onChange={(event, newValue) => {
                                        const data = [];
                                        const fopList = this.state.fopList;
                                        for (let item of newValue)
                                            data.push({
                                                id: item.id,
                                                fop_id: item.fop_id,
                                                title: item.title,
                                            });
                                        fopList[index].school = data;
                                        this.countSelectedOffices(fopList);
                                    }}
                                    getOptionDisabled={(option) =>
                                        !!this.state.selected_offices.find(
                                            (el) =>
                                                // eslint-disable-next-line
                                                el.title.split('|||')[0] ==
                                                option.title.split('|||')[0]
                                        )
                                    }
                                    renderOption={(option, { selected }) => {
                                        if (
                                            item.school.find(
                                                (el) =>
                                                    // eslint-disable-next-line
                                                    el.title.split('|||')[0] ==
                                                    option.title.split('|||')[0]
                                            )
                                        ) {
                                            selected = true;
                                        }
                                        return (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.title.split('|||')[0]}
                                            </React.Fragment>
                                        );
                                    }}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label={t('offices.title')}
                                            placeholder={t('modals.office')}
                                        />
                                    )}
                                />
                            </Box>
                        </Typography>
                    </CardContent>
                </Card>
            );
        });
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <CircularProgress color="inherit" size={20} />
                ) : (
                    <>
                        <div>
                            <IconButton
                                aria-label="share"
                                onClick={() => this.addFopBill()}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>

                        <div>
                            {this.state.fopList.length
                                ? this.renderFopData()
                                : null}
                        </div>
                    </>
                )}
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        payments: state.payments,
        modalWindow: state.modalWindow,
        user: state.user,
        AsyncTable: state.AsyncTable,
    }),
    (dispatch) =>
        bindActionCreators(
            { getFopBillList, createFopBill, editFopBill, deleteFopBill },
            dispatch
        )
);

export default withTranslation()(widthConnect(FopBillComponent));
