import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";

const styled = withStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    list: {
        listStyleType: "none",
        padding: 0,
        margin: 0
    },
    container: {
        display: "block",
        whiteSpace: "nowrap",
        maxWidth: 150,
        overflow: "hidden",
        textOverflow: "ellipsis",
        marginLeft: "auto"
    }
}));

const NameList = ({ classes, value }) => {
    const values = value && !!Object.values(value).length ? Object.values(value) : [];

    if (!values.length) return "-";

    return (
        <div className={classes.root}>
            <span className={classes.container} title={values[0]}>
                {values[0]}
            </span>
            {values.length > 1 && (
                <Tooltip
                    title={
                        <ul className={classes.list}>
                            {values.map((e, i) => (
                                <li key={i}>{e}</li>
                            ))}
                        </ul>
                    }
                    placement="left"
                >
                    <IconButton size="small">
                        <HelpIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default styled(NameList);
