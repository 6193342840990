import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import IconButton from '@material-ui/core/IconButton';
import DownloadingIcon from '@mui/icons-material/Downloading';
import DownloadIcon from '@mui/icons-material/Download';
import { Add } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { setData, createCodeBooks, generatePDF } from '../../actions/books';
import BooksService from '../../services/books.service';
import NativeSelect from '@material-ui/core/NativeSelect';
import { withTranslation } from 'react-i18next';
// import QRCodeGenerator from '../../components/QRCodeGenerator';
// import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { QRCodeCanvas } from 'qrcode.react';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { countDate } from '../../actions/date';
import BOOKS from '../../constants/qr_books';
import { jsPDF } from 'jspdf';

class BooksPage extends React.Component {
    state = {
        links: [],
        isQrFetch: false,
    };

    makeToOption = (params, name) => {
        return params.map((item, key) => (
            <option key={key} value={item.value}>
                {item.label}
            </option>
        ));
    };

    changeSelect = (e) => {
        this.props.setData({
            [e.target.id]: !isNaN(+e.target.value)
                ? +e.target.value
                : e.target.value,
        });
    };

    createCodes = () => {
        this.props.createCodeBooks(this.props.books);
    };

    createQrCodes = async () => {
        this.setState({ isQrFetch: true });
        const response = await BooksService.createCodes({
            ...this.props.books,
            type: 'JSON',
        });

        this.setState({
            links: response.arrayCodes.map((el) => ({
                url: el,
                id: el.split('/')[el.split('/').length - 1],
            })),
            isQrFetch: false,
        });
    };

    downloadPDF = () => {
        const { links } = this.state;
        const type = BOOKS.find(
            (el) => el.value === String(this.props.books.type_book)
        ).label;
        const count = this.props.books.count_book;
        generatePDF(links, type, count);
    };

    render() {
        const { t } = this.props;
        const type = BOOKS.find(
            (el) => el.value === String(this.props.books.type_book)
        ).label;
        const count = this.props.books.count_book;

        return (
            <Fragment>
                <h2 className="container-header">{t('codes.title')}</h2>

                <>
                    <div className="top_main_block_setting">
                        <div className="top_setting">
                            <p>{t('codes.type')}</p>
                            <NativeSelect
                                onChange={this.changeSelect}
                                id="type_book"
                                title="select topic"
                            >
                                {this.makeToOption(BOOKS, 'type')}
                            </NativeSelect>
                        </div>
                        <div className="top_setting">
                            <p>{t('codes.quantity')}</p>
                            <TextField
                                onChange={this.changeSelect}
                                name="count_book"
                                id="count_book"
                                type="text"
                                placeholder={t('codes.enterQuantity')}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            title="save"
                                            onClick={this.createCodes}
                                        >
                                            <Add />
                                        </IconButton>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                </>

                <h2 className="container-header">{t('codes.titleQr')}</h2>

                <>
                    <div className="top_main_block_setting">
                        <div className="top_setting">
                            <p>{t('codes.type')}</p>
                            <NativeSelect
                                onChange={(e) => {
                                    this.changeSelect(e);
                                    this.setState({ links: [] });
                                }}
                                id="type_book"
                                title="select topic"
                            >
                                {this.makeToOption(BOOKS, 'type')}
                            </NativeSelect>
                        </div>
                        <div className="top_setting">
                            <p>{t('codes.quantity')}</p>
                            <TextField
                                className="inputCountBook"
                                onChange={this.changeSelect}
                                name="count_book"
                                id="count_book"
                                type="number"
                                placeholder={t('codes.enterQuantity')}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            disabled={this.state.isQrFetch}
                                            title="save"
                                            onClick={this.createQrCodes}
                                        >
                                            {this.state.isQrFetch ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                <DownloadingIcon />
                                            )}
                                        </IconButton>
                                    ),
                                }}
                            />
                        </div>
                        <div className="top_setting">
                            {this.state.links.map((item) => {
                                return (
                                    <div
                                        key={`qr_${item.id}`}
                                        style={{ display: 'none' }}
                                    >
                                        <QRCodeCanvas
                                            id={item.id}
                                            value={item.url}
                                            size={300}
                                        />
                                    </div>
                                );
                            })}
                            <Stack spacing={2}>
                                {this.state.links.length ? (
                                    <Stack spacing={1}>
                                        <Typography variant="subtitle1">
                                            {t('codes.type')}:{' '}
                                            {
                                                BOOKS.find(
                                                    (el) =>
                                                        el.value ===
                                                        String(
                                                            this.props.books
                                                                .type_book
                                                        )
                                                ).label
                                            }
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {t('codes.bookCount')}:{' '}
                                            {this.props.books.count_book}
                                        </Typography>
                                    </Stack>
                                ) : null}
                                <Button
                                    disabled={!this.state.links.length}
                                    id="buttonQRCodeGenerator"
                                    variant="contained"
                                    onClick={this.downloadPDF}
                                    startIcon={<DownloadIcon />}
                                >
                                    {this.props.t('codes.download')}{' '}
                                </Button>
                            </Stack>
                        </div>
                    </div>
                </>
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({ user: state.user, books: state.books }),
    (dispatch) => bindActionCreators({ setData, createCodeBooks }, dispatch)
);

export default withTranslation()(widthConnect(BooksPage));
