import { languageArray } from '../constants/languages';

function separateToLanguages(inputString) {
    const languageValues = inputString.split('|||');
    const result = {};

    for (let i = 0; i < languageArray.length; i++) {
        result[languageArray[i]] = languageValues[i] || '';
    }

    return result;
}

function combineLanguages(languages) {
    const resultArray = [];

    for (const language of languageArray) {
        resultArray.push(languages[language] || '');
    }

    return resultArray.join('|||');
}

export { separateToLanguages, combineLanguages };
