import { TextField } from '@mui/material';

const renderTextFieldSorocoinsAmount = ({ input, meta, ...custom }) => (
    <TextField
        {...input}
        {...custom}
        type="number"
        inputProps={{ step: '0.01', min: '0' }}
        onChange={(e) => {
            const { value } = e.target;
            if (/^\d*([.,]\d{0,2})?$/.test(value)) {
                input.onChange(value.replace(/[^0-9\.\,\-+]/g, ''));
            }
        }}
        onKeyDown={(e) => {
            if (e.key === '-' || e.key === 'e' || e.key === '+')
                e.preventDefault();
        }}
    />
);

export default renderTextFieldSorocoinsAmount;
