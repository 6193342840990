import Types from "./../constants/finance";

const defaultState = {
  countries: null,
  cities: null,
  selectes_country: null,
  query: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.QUERY:
    case Types.COUNTRY:
    case Types.SELECT_COUNTRY:
    case Types.CITY:
      return { ...state, ...action.finance };
    default:
      return state;
  }
};
