import React from "react";
import TablePagination from "@material-ui/core/TablePagination";
import i18n from 'i18next';

export default ({ disabled, t, ...props }) => (
    <TablePagination
        component="div"
        labelRowsPerPage={t("table.items")}
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t("table.of")} ${count}`}
        {...props}
        nextIconButtonProps={{
          'title': i18n.t('table.next_page'),
          'disabled': props.page === Math.ceil(props.count / props.rowsPerPage) - 1 || disabled
        }}
        backIconButtonProps={{
          'title': i18n.t('table.prev_page'),
          'disabled': props.page === 0 || disabled
        }}
        SelectProps={{ disabled }}
    />
);
