import { Box } from '@mui/material';
import PaymentsMediaCard from './changeCityPrice';
import BlockDayComponent from './BlockDay';
import React, { useEffect, useState } from 'react';
import SummerIntencive from './changeLiPrice';
import LowSubscribeComponent from '../../components/DashBossView/LowSubscribeComponent';
import { GetFranchPrices } from '../../actions/cities';
import { Boy } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const pricesItems = ['avg_price_li_online', 'avg_price_online'];

const CityProperties = ({ recivedPrice, liPrice }) => {
    const { t } = useTranslation();
    const [prices, setPrices] = useState(null);
    const [loading, setLoading] = useState(false);
    const fetchData = async () => {
        setLoading(true);
        const avgPriceOnline = await GetFranchPrices({
            code: pricesItems[
                pricesItems.findIndex((el) => el === 'avg_price_online')
            ],
        });

        const avgPriceLiOnline = await GetFranchPrices({
            code: pricesItems[
                pricesItems.findIndex((el) => el === 'avg_price_li_online')
            ],
        });
        if (!avgPriceOnline || !avgPriceLiOnline) return;
        setPrices({
            avg_price_li_online: avgPriceLiOnline.avg_price_li_online,
            avg_price_online: avgPriceOnline.avg_price_online,
        });
        setLoading(false);
    };
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Box>
            <Typography
                style={{ margin: '40px 0px 10px 0px' }}
                variant="subtitle2"
                component="p"
            >
                *{t('payments.city_price_descr')}
            </Typography>
            <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
                {pricesItems.map((code, index) => {
                    return (
                        <LowSubscribeComponent
                            key={index}
                            value={!loading && prices ? prices[code] : 0}
                            isLoading={loading}
                            code={code}
                            updatePrices={() => fetchData()}
                        />
                    );
                })}
                <PaymentsMediaCard recivedPrice={recivedPrice} />
                <SummerIntencive recivedPrice={liPrice} />
                <BlockDayComponent />
            </Box>
        </Box>
    );
};

export default CityProperties;
