import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Toolbar, Typography, Stack, IconButton, Tooltip, CircularProgress, Button } from '@mui/material';
import { alpha } from '@mui/material/styles';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DeleteIcon from '@mui/icons-material/Delete';


export default function BankTableToolbar(props) {
    const {
        numSelected,
        onSuccessClick,
        onDeleteClick,
        loading,
        title,
        titleTooltipSuccess,
        titleTooltipDelete,
        sum,
        roundToTwoDecimals,
        selectedUserIds,
        rows
    } = props;
    const { t } = useTranslation();

    const [showUsers, setShowUsers] = useState(false);

    useEffect(() => {
        if (selectedUserIds && selectedUserIds.length === 0) {
            setShowUsers(false);
        }
    }, [selectedUserIds]);

    const selectedUsers = rows?.filter((user) => selectedUserIds.includes(user.rowId)) || [];
    

    return (
        <Toolbar
            sx={{
                mt: 2,
                mb: 2,
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(
                            theme.palette.primary.main,
                            theme.palette.action.activatedOpacity
                        ),
                }),
            }}
        >
            {numSelected > 0 ? (
                <Stack spacing={3} sx={{ width: "100%" }}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {sum !== undefined ? (
                                <>
                                    {title} {roundToTwoDecimals(sum)} SBK
                                </>
                            ) : (
                                <Button
                                    onClick={() => setShowUsers(!showUsers)}
                                    variant="outlined"
                                    sx={{
                                        border: '1px solid rgba(0, 171, 85, 0.3) !important',
                                        color: '#00AB55 !important',
                                        minWidth: '64px !important',
                                        padding: '5px 15px !important',
                                        borderRadius: '8px !important',
                                        backgroundColor: 'transparent !important',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 171, 85, 0.1) !important',
                                            borderColor: 'rgba(0, 171, 85, 0.5) !important'
                                        }
                                    }}
                                >
                                    {showUsers ? t('activeTransactions.hide') : `${numSelected} ${title}`}
                                </Button>
                            )}
                        </Typography>

                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            {loading ? <CircularProgress size={20} /> : null}
                            <Tooltip title={titleTooltipSuccess}>
                                <IconButton onClick={onSuccessClick} disabled={loading}>
                                    <DoneOutlineIcon />
                                </IconButton>
                            </Tooltip>
                            {onDeleteClick &&
                                <Tooltip title={titleTooltipDelete}>
                                    <IconButton onClick={onDeleteClick} disabled={loading}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Stack>
                    </Stack>
                    <>
                        {showUsers && (
                            <Stack spacing={1} sx={{ mt: 2 }}>
                                {selectedUsers.map((user) => (
                                    <Typography key={user.rowId}>
                                        {user.surname} {user.name}
                                    </Typography>
                                ))}
                            </Stack>
                        )}
                    </>
                </Stack>
            ) : null}
        </Toolbar>
    );
}

