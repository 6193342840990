import React, {Fragment} from 'react';

import ComponentTable from '../../components/ComponentTable';

import {connect} from "react-redux";
import {closeRequestModal, openRequestModal} from "../../actions/modals";
import {bindActionCreators} from "redux";



class PlannerPage extends React.Component{

    render() {

        const headRows = [
            {id: 'id', label: '№'},
            {id: 'task', label: 'Назва завдання'},
            {id: 'period', label: 'Періодичність запуску'},
            {id: 'host', label: 'Хост' },
            {id: 'Status',  label: 'Статус'},
            {id: 'button', label: 'Дії'}
        ];

        const rows = [
            {id: 1,
                task: 'Payments 1',
                period: '000,23',
                host: 'http://admin.soroboom.pl/',
                status: 'Запущено'},
            {id: 2,
                task: 'Payments 2',
                period: '000,23',
                host: 'http://admin.soroboom.pl/',
                status: 'Запущено'},
            {id: 3,
                task: 'Payments 3',
                period: '000,23',
                host: 'http://admin.soroboom.pl/',
                status: 'Запущено'},
            {id: 4,
                task: 'Payments 4',
                period: '000,23',
                host: 'http://admin.soroboom.pl/',
                status: 'Запущено'},

        ].map(item => {

            item.button = <button onClick={this.props.openRequestModal.bind(this, {opened: true, data: {id: item.id}})}>Редагувати</button>
            return item;
        });

        return (
            <Fragment>
                <h2 className="container-header"> Планувальник завдань </h2>
                <ComponentTable rowData={rows} headData={headRows} headerTable="Завдання" selectable={true}/>
                
            </Fragment>
        )



    }

}

const widthConnect = connect(state => ({ user: state.user, modalWindow:state.modalWindow}),
    dispatch => bindActionCreators({ openRequestModal, closeRequestModal }, dispatch));

export default widthConnect(PlannerPage);


