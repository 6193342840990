import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Collapse,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import { BRACELETS } from "../../../constants/bracelet";
import logo_ru from "../../../resources/img/logo/ru.png";
import logo_ua from "../../../resources/img/logo/ua.png";
import logo_en from "../../../resources/img/logo/en.png";
import logo_ee from "../../../resources/img/logo/ее.png";
import logo_ro from "../../../resources/img/logo/ro.png";
import logo_by from "../../../resources/img/logo/by.png";
import { editUserSingle, getSingleUser } from "../../../actions/getUsers";
import { connect, useDispatch } from "react-redux";
import { ArrowDropDown } from "@material-ui/icons";
import { bindActionCreators } from "redux";
import { closeRequestModal } from "../../../actions/modals";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { reloadTable } from "../../../actions/AsyncTable";
const logo = {
  ru: logo_ru,
  ua: logo_ua,
  en: logo_en,
  ee: logo_ee,
  ro: logo_ro,
  by: logo_by,
  de: logo_en,
};

const logoImgWidth = "60px";

const Bracelet = ({ selectBracelet, bracelet, logo, i18n, i, style = {} }) => {
  if (!bracelet) return null;
  return (
    <Button
      p={1}
      style={{
        width: "100%",

        backgroundColor: bracelet.color,
        display: "flex",
        justifyContent: "space-around",
        alignContent: "center",
        flexWrap: "wrap",
        borderRadius: "7px",
        padding: "14px",
        boxShadow:
          bracelet.label === "White"
            ? "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
            : "unset",
        ...style,
      }}
      onClick={() => (selectBracelet ? selectBracelet(bracelet) : null)}
    >
      <img
        width={logoImgWidth}
        alt={`SOROBAN bracelet logo 1 ${bracelet.label}`}
        src={logo[i18n.language]}
      />
      <img
        width={logoImgWidth}
        alt={`SOROBAN bracelet logo 2 ${bracelet.label}`}
        src={logo[i18n.language]}
      />
    </Button>
  );
};

const EditStudentBracelet = (props) => {
  const { t, i18n } = props;
  const [user, setUser] = useState("");
  const [listControl, setListControl] = useState(false);
  const [oldBracelet, setlOldBracelet] = useState("");
  const dispatch = useDispatch();

  const fetchUserProfile = async () => {
    const res = await getSingleUser(props.data.id);
    try {
      if (!res) return false;
      if (res && typeof res.bracelet === "string" && res.bracelet === "") {
        setListControl(true);
      }
      setUser(res);
      setlOldBracelet(res.bracelet);
      return true;
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchUserProfile();
  }, []);

  const selectBracelet = (bracelet) => {
    const newItem = structuredClone({
      ...user,
      bracelet: bracelet.label,
      selected: true,
    });
    setUser(newItem);
    setListControl(false);
  };

  const editUser = async () => {
    delete user.selected;
    await dispatch(editUserSingle({ ...user }, false));
    await fetchUserProfile();
    await dispatch(reloadTable());
    setListControl(false);
  };

  const selectedBracelet = user && user.bracelet;

  return (
    <Box>
      <Stack spacing={1}>
        <Collapse in={typeof oldBracelet === "string" && oldBracelet !== ""}>
          <Typography variant={"caption"} textAlign={"left"}>
            {t("bracelets.Current Bracelet")}
          </Typography>
        </Collapse>

        {typeof oldBracelet === "string" && oldBracelet !== "" ? (
          <Bracelet
            i18n={i18n}
            bracelet={BRACELETS.find((el) => el.label === oldBracelet)}
            logo={logo}
          />
        ) : null}

        <Collapse
          in={
            typeof oldBracelet === "string" &&
            oldBracelet !== "" &&
            user.selected
          }
          style={{ textAlign: "center" }}
        >
          <KeyboardDoubleArrowDownIcon />
          <Bracelet
            i18n={i18n}
            bracelet={BRACELETS.find((el) => el.label === selectedBracelet)}
            logo={logo}
          />
        </Collapse>

        <Collapse
          in={
            typeof oldBracelet === "string" &&
            oldBracelet === "" &&
            user.selected
          }
        >
          <Typography variant={"caption"} textAlign={"left"}>
            {t("bracelets.Apply Bracelet")}:
          </Typography>
          <Bracelet
            i18n={i18n}
            bracelet={BRACELETS.find((el) => el.label === selectedBracelet)}
            logo={logo}
          />
        </Collapse>

        <Collapse
          in={
            !user.selected &&
            typeof oldBracelet === "string" &&
            oldBracelet === ""
          }
        >
          <Typography variant={"caption"} textAlign={"left"}>
            {t("bracelets.Choose Bracelet")}
          </Typography>
        </Collapse>
        <Button
          onClick={() => setListControl((value) => !value)}
          style={{
            borderRadius: listControl ? "7px 7px 0px 0px" : "7px",
            boxShadow: "unset",
          }}
          endIcon={
            <ArrowDropDown
              style={{
                transform: listControl ? "rotate(180deg)" : "",
                transition: "0.2s",
              }}
            />
          }
          variant={"contained"}
        >
          {t("bracelets.Bracelets")}
        </Button>

        <Collapse in={listControl} style={{ margin: "0" }}>
          <Paper
            elevation={1}
            style={{
              padding: "0px 20px 20px 20px",
              backgroundColor: "#e0e0e0",
              borderRadius: "unset",
            }}
          >
            <Stack spacing={1}>
              {BRACELETS.filter(
                (el) =>
                  el.label !== selectedBracelet && el.label !== oldBracelet
              ).map((bracelet, i) => {
                return (
                  <Bracelet
                    key={i}
                    selectBracelet={selectBracelet}
                    i18n={i18n}
                    bracelet={bracelet}
                    logo={logo}
                  />
                );
              })}
            </Stack>
          </Paper>
        </Collapse>
      </Stack>

      <DialogActions>
        <Button
          className="request-button orangeButton"
          onClick={() => dispatch(props.closeRequestModal)}
        >
          {t("buttons.cancel")}
        </Button>
        <Button
          variant="contained"
          className="request-button greenButton"
          onClick={() => editUser()}
          disabled={!user.selected}
        >
          {t("buttons.apply")}
        </Button>
      </DialogActions>
    </Box>
  );
};

const widthConnect = connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ closeRequestModal }, dispatch)
);

export { Bracelet };

export default withTranslation()(widthConnect(EditStudentBracelet));
