import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import NativeSelect from '@material-ui/core/NativeSelect';
import store from './../../../../../store';
// import { changeStudentGroup } from "./../../../../../actions/getUsers";
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
const renderTextField = ({
    input,
    meta: { touched, error },
    i18n,
    ...custom
}) => {
    // eslint-disable-next-line
    const teachers =
        (store.getState().groups &&
            store.getState().groups.teacher_select &&
            store.getState().groups.teacher_select.teacher) ||
        [];
    // const value = custom.data;
    // __InitDataHWRow({ [input.name]: custom.data })(store.dispatch, store.getState);
    // groups.unshift({ id: -1, teacher_id: -1, title: "choose_group"});
    return (
        <NativeSelect {...input} {...custom}>
            <option value="">{i18n.t('filter.choose_teacher')}</option>
            {teachers.map((item, key) => {
                return (
                    <option key={key} value={item.id}>
                        {`${item.last_name} ${item.first_name}`}
                    </option>
                );
            })}
        </NativeSelect>
    );
    // ChangeStudentGroup
};

export default ({ label }) => {
    const { i18n } = useTranslation();
    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                    {i18n.t(label)}
                </Typography>
                <Field
                    name="teacher_id"
                    i18n={i18n}
                    component={renderTextField}
                    type="text"
                />
            </CardContent>
        </Card>
    );
};
