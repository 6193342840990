import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportToExcel = ({ formatData, fileName, id }) => {
    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (formatData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(formatData);

        const colWidths = formatData.reduce((acc, data) => {
            Object.keys(data).forEach((key) => {
                const cellValue = data[key];
                let cellWidth = cellValue ? cellValue.toString().length : 9;
                if (cellWidth > 30) {
                    cellWidth = 30;
                }
                if (!acc[key] || cellWidth > acc[key]) {
                    acc[key] = cellWidth;
                }
            });
            return acc;
        }, {});

        ws['!cols'] = Object.keys(colWidths).map((key) => {
            return { wch: colWidths[key] };
        });

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <button
            onClick={() => exportToCSV(formatData, fileName)}
            id={id}
            style={{ display: 'none' }}
        >
            Export
        </button>
    );
};
