import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import NativeSelect from "@material-ui/core/NativeSelect";
import store from "./../../../../../store";
// import { changeStudentGroup } from "./../../../../../actions/getUsers";
import { useTranslation } from "react-i18next";

import { Field } from "redux-form";

const renderTextField = ({ input, meta: { touched, error }, i18n, ...custom }) => {
    // eslint-disable-next-line
    const groups =
        (store.getState().groups && store.getState().groups.select && store.getState().groups.select.list) || [];

    // const value = custom.data;
    // __InitDataHWRow({ [input.name]: custom.data })(store.dispatch, store.getState);
    // groups.unshift({ id: -1, teacher_id: -1, title: "choose_group"});
    return (
        <NativeSelect {...input} {...custom}>
          <option value="">
              {i18n.t("groups.choose")}
          </option>
            {groups.map((item, key) => {
                let notTranslation = "";
                if (item.title && typeof item.title == "object"  && !item.title[i18n.language]) {
                    for (let i in item.title) {
                        if (!!item.title[i]) notTranslation = item.title[i] + " (no translation)";
                        break;
                    }
                }
                const title =
                    item.title && typeof item.title == "object"
                        ? item.title[i18n.language] ? item.title[i18n.language] : notTranslation
                        : item.title
                return (
                    <option key={key} value={item.id}>
                        {title === "choose_group" ? i18n.t("groups.choose") : title }
                    </option>
                );
            })}
        </NativeSelect>
    );
    // ChangeStudentGroup
};

export default ({ label, code }) => {
    const { i18n } = useTranslation();
    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant="h6" component="h2">
                    {i18n.t(label)}
                </Typography>
                <Field
                    name="course_id"
                    i18n={i18n}
                    component={renderTextField}
                    type="text"
                />
            </CardContent>
        </Card>
    );
};
