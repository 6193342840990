import Types from "./../constants/modalWindows";

const defaultState = {
  usermenu: false,
  languagemenu: false,
  requestmodal: {},
  switch: ""
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.OPEN:
    case Types.CLOSE:
    case Types.SET:
      return { ...state, ...action.modalWindow };
    default:
      return state;
  }
};
