import React from 'react';
import { connect } from 'react-redux';
import './index.css';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { openRequestModal } from './../../actions/modals';
import { confirmTransfer, sendRelocateRequest } from '../../actions/user';

import { reloadTable } from '../../actions/AsyncTable';
import ComponentTable from './../../components/ComponentTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import UsersService from './../../services/users.service';
import ModelService from './../../services/models.service';
import FlightLandIcon from '@material-ui/icons/FlightLand';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import base64url from 'base64url';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Delete } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CitiesService from './../../services/cities.service';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import StudentsService from './../../services/students.service';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { apiService } from '../../services/requestV2.service';

class RelocatePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            arrivals: true,
            depature: false,
            loading: false,
            cities: [0],
            geo_id: 0,
            first_name: '',
            last_name: '',
            search: false,
            students: [],
            selected: '-1',
            email: '',
            autoclickcontrol: false,
        };
    }

    handleTextField = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    componentDidMount() {
        this.getData();
    }

    componentWillUnmount() {
        apiService.cancelAllRequests();
    }

    async getData() {
        this.setState({ loading: true });
        const res = await CitiesService.RelocateCitiesAvaliable(false, {
            size: 9999,
        });
        if (!res) return;
        this.setState({ cities: res.list, loading: false });
    }

    encodeFilter(data) {
        const encodedFilter = data.reduce((result, item) => {
            return item.values
                ? { ...result, [item.code]: item.values }
                : result;
        }, {});
        return base64url.encode(
            encodeURIComponent(JSON.stringify(encodedFilter))
        );
    }

    async searchStudent() {
        this.setState({ search: true });
        let args = {
            first_name: [this.state.first_name],
            last_name: [this.state.last_name],
            email: [this.state.email],
        };
        const res = await StudentsService.StudentsList(false, {
            filter: base64url.encode(encodeURIComponent(JSON.stringify(args))),
        });
        this.setState({});
        this.setState({
            students: res.list,
            selected: res.list.length === 1 ? res.list[0].id : '-1',
            search: false,
        });
    }

    handleSelect = (e) => {
        this.setState({ selected: e.target.value });
    };

    handleClear = () => {
        this.setState({ selected: '-1', students: [] });
    };

    handleChange(item) {
        if (item === 'arrivals') this.setState({ depature: false });
        if (item === 'depature') this.setState({ arrivals: false });
        this.setState({ [item]: true });
    }

    async sendRequest() {
        this.setState({ loading: true });
        await this.props.sendRelocateRequest({
            geo_id: this.state.geo_id,
            id: this.state.selected,
        });
        this.setState({
            selected: '-1',
            students: [],
            first_name: '',
            last_name: '',
            email: '',
            loading: false,
        });
    }

    searchCityTranslate(item) {
        const { t } = this.props;
        let output = '';
        for (let data of Object.keys(item.title)) {
            if (item.title[data]) {
                output = item.title[data];
                break;
            }
        }
        if (!output) output = t('modals.no_transation');
        return output;
    }

    render() {
        const { t, i18n } = this.props;
        const arrivals = [
            {
                title: t('relocate.accept_transfer'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: t('relocate.confirm_title'),
                            modaltype: 'ConfirmTransferModal',
                        },
                    }),
                Icon: CheckCircleIcon,
            },
            {
                title: t('relocate.delete'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            text: 'relocate.arrival_delete',
                            header: t('relocate.delete'),
                            modaltype: 'DeleteModal',
                        },
                    }),
                Icon: Delete,
            },
        ];
        const depature = [
            {
                title: t('relocate.delete'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            text: 'relocate.delete_text',
                            header: t('relocate.delete'),
                            modaltype: 'DeleteModal',
                        },
                    }),
                Icon: Delete,
            },
        ];
        const handleClickAutocomplete = () => {
            this.setState({ autoclickcontrol: true });
        };
        const handleClickAway = () => {
            setTimeout(() => {
                this.setState({ autoclickcontrol: false });
            }, 50);
        };
        return (
            <>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="div"
                            className="header_icon_title"
                        >
                            {t('relocate.create')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div" style={{ width: '100%' }}>
                            <Card
                                style={{ width: '400px' }}
                                className="student_issue"
                            >
                                <CardContent>
                                    <br />
                                    <ClickAwayListener
                                        onClickAway={handleClickAway}
                                    >
                                        <Autocomplete
                                            id="select-city-relocate"
                                            options={this.state.cities || [0]}
                                            getOptionLabel={(option) => {
                                                if (!option) return '';
                                                return this.state.cities
                                                    ? option.title[
                                                          i18n.language
                                                      ]
                                                        ? option.title[
                                                              i18n.language
                                                          ]
                                                        : this.searchCityTranslate(
                                                              option
                                                          )
                                                    : option;
                                            }}
                                            open={this.state.autoclickcontrol}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    geo_id:
                                                        newValue !== null &&
                                                        Object.keys(newValue)
                                                            .length
                                                            ? newValue.id
                                                            : '',
                                                });
                                            }}
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    onClick={
                                                        handleClickAutocomplete
                                                    }
                                                    label={t(
                                                        'modals_name.add_city'
                                                    )}
                                                    variant="outlined"
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {this.state
                                                                    .loading ? (
                                                                    <CircularProgress
                                                                        color="inherit"
                                                                        size={
                                                                            20
                                                                        }
                                                                    />
                                                                ) : null}
                                                                {
                                                                    params
                                                                        .InputProps
                                                                        .endAdornment
                                                                }
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                    </ClickAwayListener>

                                    <br />
                                    {this.state.students.length ? (
                                        <div className="select_student">
                                            <FormControl>
                                                <Select
                                                    value={this.state.selected}
                                                    onChange={this.handleSelect}
                                                    variant="outlined"
                                                    style={{ width: '100%' }}
                                                >
                                                    {this.state.students
                                                        .length > 1 ? (
                                                        <MenuItem value="-1">
                                                            {t(
                                                                'sales.choose_student'
                                                            )}
                                                        </MenuItem>
                                                    ) : null}
                                                    {this.state.students.map(
                                                        (item, key) => {
                                                            return (
                                                                <MenuItem
                                                                    key={key}
                                                                    value={
                                                                        item.id
                                                                    }
                                                                >
                                                                    {`${item.first_name} ${item.last_name}`}
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <IconButton
                                                aria-label="delete"
                                                className="clearStudents"
                                                onClick={this.handleClear}
                                            >
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    ) : (
                                        <>
                                            <br />
                                            <TextField
                                                disabled={
                                                    this.state.search ||
                                                    !this.state.geo_id
                                                }
                                                type="text"
                                                variant="outlined"
                                                label={t('modals.first_name')}
                                                name="first_name"
                                                value={this.state.first_name}
                                                onChange={this.handleTextField}
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                disabled={
                                                    this.state.search ||
                                                    !this.state.geo_id
                                                }
                                                type="text"
                                                variant="outlined"
                                                label={t('modals.last_name')}
                                                name="last_name"
                                                value={this.state.last_name}
                                                onChange={this.handleTextField}
                                            />
                                            <br />
                                            <br />
                                            <TextField
                                                disabled={
                                                    this.state.search ||
                                                    !this.state.geo_id
                                                }
                                                type="text"
                                                variant="outlined"
                                                label={t('modals.email')}
                                                name="email"
                                                value={this.state.email}
                                                onChange={this.handleTextField}
                                            />
                                            <br />
                                            <Button
                                                variant="contained"
                                                className="greenButton"
                                                onClick={() =>
                                                    this.searchStudent()
                                                }
                                                disabled={
                                                    this.state.search ||
                                                    !this.state.geo_id ||
                                                    (!this.state.first_name &&
                                                        !this.state.last_name &&
                                                        !this.state.email)
                                                }
                                                style={{
                                                    width: '100%',
                                                    margin: 0,
                                                    marginTop: '15px',
                                                }}
                                            >
                                                {this.state.search ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : (
                                                    t('relocate.search')
                                                )}
                                            </Button>
                                        </>
                                    )}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant="contained"
                                        className="greenButton"
                                        type="submit"
                                        disabled={this.state.selected === '-1'}
                                        onClick={() => this.sendRequest()}
                                        style={{ width: '100%' }}
                                    >
                                        {this.state.loading ? (
                                            <CircularProgress
                                                color="inherit"
                                                size={20}
                                            />
                                        ) : (
                                            t('buttons.send')
                                        )}
                                    </Button>
                                </CardActions>
                            </Card>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <br />
                <Accordion
                    expanded={this.state.arrivals}
                    onClick={() => this.handleChange('arrivals')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="div"
                            className="header_icon_title"
                        >
                            <span>
                                <FlightLandIcon />
                            </span>
                            <span>{t('relocate.transfer_requests')}</span>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div" style={{ width: '100%' }}>
                            {this.state.arrivals ? (
                                <ComponentTable
                                    source={{
                                        getData: (page, size, sort, filter) =>
                                            UsersService.RelocateRecivedList(
                                                false,
                                                {
                                                    page: page || 0,
                                                    size: size || 10,
                                                    orderBy:
                                                        (sort &&
                                                            sort.orderBy) ||
                                                        'title',
                                                    order:
                                                        (sort && sort.order) ||
                                                        'asc',
                                                    filter,
                                                }
                                            ),
                                        getModel: () =>
                                            ModelService.UsersList(false, {
                                                role_id: 5,
                                            }),
                                    }}
                                    selectable={true}
                                    title={t('sidebar.students')}
                                    enableSettings
                                    typeTable="relocate"
                                    toolbarvisible={true}
                                    actions={arrivals}
                                />
                            ) : null}
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Accordion
                    expanded={this.state.depature}
                    onClick={() => this.handleChange('depature')}
                >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography
                            component="div"
                            className="header_icon_title"
                        >
                            <span>
                                <FlightTakeoffIcon />
                            </span>
                            <span>{t('relocate.incoming_transfer')}</span>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography component="div" style={{ width: '100%' }}>
                            {this.state.depature ? (
                                <ComponentTable
                                    source={{
                                        getData: (page, size, sort, filter) =>
                                            UsersService.RelocateSentList(
                                                false,
                                                {
                                                    page: page || 0,
                                                    size: size || 10,
                                                    orderBy:
                                                        (sort &&
                                                            sort.orderBy) ||
                                                        'title',
                                                    order:
                                                        (sort && sort.order) ||
                                                        'asc',
                                                    filter,
                                                }
                                            ),
                                        getModel: () =>
                                            ModelService.UsersList(false, {
                                                role_id: 5,
                                            }),
                                    }}
                                    selectable={true}
                                    title={t('sidebar.students')}
                                    enableSettings
                                    typeTable="relocate"
                                    toolbarvisible={true}
                                    actions={depature}
                                />
                            ) : null}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                confirmTransfer,
                sendRelocateRequest,
                reloadTable,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(RelocatePage));
