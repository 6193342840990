export const statuses = [
    {
        label: 'groups.choose_status',
        value: '',
        disabled: true,
    },
    {
        label: 'groups.usual',
        value: 0,
    },
    {
        label: 'groups.candidate',
        value: 1,
    },
    {
        label: 'groups.promo',
        value: 2,
    },
    {
        label: 'groups.summer_intensive',
        value: 3,
    },
    {
        label: 'groups.bingo',
        value: 4,
    },
    {
        label: 'groups.cube',
        value: 5,
    },
    // {
    //     label: 'modals.custom_note',
    //     value: 6,
    // },
    {
        label: 'groups.delete',
        value: 7,
    },
];

export const age = [
    {
        label: 'groups.age',
        value: '',
        disabled: true,
    },
    {
        label: 'groups.junior',
        value: 0,
    },
    {
        label: 'groups.senior',
        value: 1,
    },
    {
        label: 'groups.55+',
        value: 2,
    },
];

export const officesGroupsAge = {
    0: 'groups.junior',
    1: 'groups.senior',
    2: 'groups.55+',
};

export const days_current = [
    {
        label: 'modals.day_of_week',
        value: '',
        disabled: true,
    },
    {
        label: 'groups.mon',
        value: 0,
    },
    {
        label: 'groups.tue',
        value: 1,
    },
    {
        label: 'groups.wed',
        value: 2,
    },
    {
        label: 'groups.thu',
        value: 3,
    },
    {
        label: 'groups.fri',
        value: 4,
    },
    {
        label: 'groups.sat',
        value: 5,
    },
    {
        label: 'groups.sun',
        value: 6,
    },
];

export const custom_note = [
    {
        label: 'groups.choose_status',
        value: '',
        disabled: false,
    },
    {
        label: 'groups.online',
        value: 'Online',
    },
    {
        label: 'groups.offline',
        value: 'Offline',
    },
];
