import React from 'react';
import { TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


export default function DecimalInput(props) {
    const { id, quantity, onChange, isSelected, t, mobile } = props;

    const handleDecrease = () => {
        const currentQuantity = isNaN(parseFloat(quantity)) ? 0 : parseFloat(quantity);
        const newValue = Math.max(0, parseFloat((currentQuantity - 0.01).toFixed(2)));
        onChange(id, newValue);
    };

    const handleIncrease = () => {
        const currentQuantity = isNaN(parseFloat(quantity)) ? 0 : parseFloat(quantity);
        const newValue = parseFloat((currentQuantity + 0.01).toFixed(2));
        onChange(id, newValue);
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', width: '130px' }}>
            {mobile && (
                <IconButton
                    onClick={handleDecrease}
                    aria-label="decrease quantity"
                    style={{
                        width: '30%',
                        height: '100%',
                        borderRadius: '4px 0 0 4px',
                        padding: 0,
                    }}
                >
                    <RemoveIcon />
                </IconButton>
            )}
            <TextField
                id={`quantity-input-${id}`}
                value={quantity}
                type="number"
                color="warning"
                InputLabelProps={{
                    shrink: true,
                }}
                variant="standard"
                required
                error={isSelected(id) && (quantity === '' || quantity === null)}
                helperText={
                    isSelected(id) && (quantity === '' || quantity === null)
                        ? t('bankStudentsPage.fillField')
                        : ''
                }
                onChange={(e) => {
                    let value = e.target.value.replace(',', '.');
                    const regex = /^\d*\.?\d{0,2}$/;
                    if (!regex.test(value)) {
                        value = value.slice(0, -1);
                    }
                    onChange(id, value);
                }}
                onKeyDown={(e) => {
                    if (['-', '+', '=', '*', '/'].includes(e.key)) {
                        e.preventDefault();
                    }
                }}
                inputProps={{
                    min: 0,
                    step: 0.01,
                    style: {
                        textAlign: 'center',
                        height: '30px',
                        padding: 0,
                    },
                }}
                style={{
                    width: '100px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            />
            {mobile && (
                <IconButton
                    onClick={handleIncrease}
                    aria-label="increase quantity"
                    style={{
                        width: '30%',
                        height: '100%',
                        borderRadius: '0 4px 4px 0',
                        padding: 0,
                    }}
                >
                    <AddIcon />
                </IconButton>
            )}
        </div>
    );
}


