// * ↓↓ LIST OF APIs ↓↓
const PROD_API = 'https://api-bank.soroban.ua';
const TEST_API = 'https://api-bank-dev.soroban.ua';
const TEST_LOCAL_API = 'http://local.soroban.ua:8888/panel';
const STAGING_API = 'https://api-bank-staging.soroban.ua';

const HOSTNAME = window.location.hostname;

let URL = PROD_API;

const LOCAL_BACK = 0;
const PROD = 0;

//* ↓↓ API links process ↓↓
if (HOSTNAME.includes('local') || HOSTNAME.includes('192.168')) {
    URL = (PROD && PROD_API) || (LOCAL_BACK && TEST_LOCAL_API) || TEST_API;
} else if (HOSTNAME.includes('test')) {
    URL = TEST_API;
} else if (HOSTNAME.includes('staging')) {
    URL = STAGING_API;
}

export default URL;
