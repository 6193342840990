import Types from './../constants/user';
const defaultState = {
    lang: null,
    info: null,
    loggedin: false,
    transactionKey: null,
    multiprofile: [],
    access_token: null,
    loginView: false,
    resetPass: false,
    emulation: [],
    permission: [],
    statistics: null,
    animation: 0,
    loader: false,
    newHwHeight: null,
    theme: localStorage.getItem('theme') || 'white',
    isRefreshTokenUpdateProcess: false,
    isEmulationTokenUpdateProcess: false,
    device: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.LOGIN:
        case Types.LANG:
        case Types.SETPROFILE:
        case Types.RESETVIEW:
        case Types.EMULATION:
        case Types.STATISTICS:
        case Types.NEW_HW_HEIGHT:
        case Types.RESUME:
            return { ...state, ...action.user };
        case Types.LOGOUT:
            const theme = localStorage.getItem('theme');
            return { ...state, ...defaultState, theme: theme || 'white' };
        case Types.SET_DEVICE_WIDTH:
            return { ...state, device: action.payload };
        default:
            return state;
    }
};
