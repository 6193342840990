import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { logIn, handleResetView, checkOldApi } from '../../actions/user';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff, Check } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';

import { getError, getErrorNotification } from '../../actions/toast';

import './index.css';
import LanguageChange from '../LanguageChange';

import logo from '../../constants/logo';

class FormLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            pass: '',
            showPass: false,
            emailvalid: false,
            load: false,
        };
    }

    handleValue = (e) => {
        e.preventDefault();
        let currentValue = e.target.value;
        switch (e.target.name) {
            case 'email': {
                let regex = /\w+@{1}\w+\.\w+/;
                this.setState({
                    emailvalid: regex.test(currentValue),
                    email: currentValue,
                });
                break;
            }
            case 'pass': {
                this.setState({
                    pass: currentValue,
                });
                break;
            }
            default:
                break;
        }
    };
    handleClickShowPassword = () => {
        this.setState({
            showPass: !this.state.showPass,
        });
    };

    handleError = () => {
        getErrorNotification('Wrong email');
        this.setState({ load: false });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ load: true });
        if (this.state.emailvalid === false) return this.handleError();
        const oldApiCheck = await this.props.checkOldApi({
            log: this.state.email,
            pwd: btoa(this.state.pass.replace(/\s/g, '')),
        });
        if (oldApiCheck) {
            this.props
                .logIn({
                    email: this.state.email,
                    passwordHash: btoa(
                        unescape(encodeURIComponent(this.state.pass))
                    ),
                })
                .then((res) => {
                    if (!res) this.setState({ load: false });
                });
        }
    };

    clearAllValues() {
        this.setState({
            email: '',
            pass: '',
        });
    }
    render() {
        const { t, i18n } = this.props;
        return (
            <div className="main-container animated fadeInLeft faster">
                <div className="main_container">
                    <div className="main_logo">
                        <img
                            alt="Soroban Logo"
                            className="logo-pic animated fadeInLeft faster"
                            src={logo[i18n.language]}
                            onClick={() => this.clearAllValues()}
                            id="clear_all_login"
                        />
                        <LanguageChange />
                    </div>
                    <div className="main_form">
                        <div className="row">
                            <TextField
                                className="login_input"
                                type="email"
                                placeholder={t('table.email')}
                                value={this.state.email}
                                onChange={this.handleValue}
                                name="email"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                className="check"
                                                aria-label="e-mail validation"
                                            >
                                                {this.state.emailvalid ? (
                                                    <Check />
                                                ) : (
                                                    ''
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div className="row">
                            <TextField
                                className="login_input"
                                type={this.state.showPass ? 'text' : 'password'}
                                placeholder={t('modals.password')}
                                value={this.state.pass}
                                onChange={this.handleValue}
                                name="pass"
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    this.handleClickShowPassword
                                                }
                                            >
                                                {this.state.showPass ? (
                                                    <Visibility />
                                                ) : (
                                                    <VisibilityOff />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>

                        <div className="row">
                            <Button
                                onClick={this.handleSubmit}
                                disabled={!this.state.email || !this.state.pass}
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                            >
                                {this.state.load &&
                                !this.props.user.multiprofile.length ? (
                                    <CircularProgress
                                        size={24}
                                        color="inherit"
                                    />
                                ) : (
                                    t('login.title')
                                )}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const widthConnect = connect(
    (state) => ({ user: state.user }),
    (dispatch) =>
        bindActionCreators(
            { logIn, getError, handleResetView, checkOldApi },
            dispatch
        )
);

export default withTranslation()(widthConnect(FormLogin));
