import validationRules from "./validationRules";
import i18n from 'i18next';


export default (values, { filter }) => {
    const errors = {};
    for (let filterItem of filter) {
        switch (filterItem.type) {
            case "string":
            case "number":
            case "price":
            case "redirect":
            case "[phone]":
            case "[email]":
            case "city.id":
            case "city.name":
            case "subdivision.id":
            case "subdivision.name":
            case "counterparty.id":
            case "counterparty.name":
                if (!!values[filterItem.code] && !!values[filterItem.code].length) {
                    const filterItemStringErrors = [];

                    values[filterItem.code].forEach((value, index) => {
                        if (!value || !value.length) {
                            filterItemStringErrors[index] = i18n.t("table.fill_field");
                        }

                        if (validationRules[filterItem.code]) {
                            const singleValidationResult = validationRules[filterItem.code](value);

                            if (singleValidationResult) {
                                filterItemStringErrors[index] = singleValidationResult;
                            }
                        }
                    });

                    if (!!filterItemStringErrors.length) {
                        errors[filterItem.code] = filterItemStringErrors;
                    }
                } else {
                    errors[filterItem.code] = {
                        _error: i18n.t("filter.add_filter_rule")
                    };
                }
                break;
            case "date":
                if (!values[filterItem.code]) {
                    errors[filterItem.code] = {
                        from: i18n.t("table.fill_field"),
                        to: i18n.t("table.fill_field")
                    };
                }

                if (!!values[filterItem.code] && !values[filterItem.code].from) {
                    errors[filterItem.code] = {
                        ...errors[filterItem.code],
                        from: i18n.t("table.fill_field")
                    };
                }

                if (!!values[filterItem.code] && !values[filterItem.code].to) {
                    errors[filterItem.code] = {
                        ...errors[filterItem.code],
                        to: i18n.t("table.fill_field")
                    };
                }
                break;
            case "role":
            case "orderStatus":
                if (!values[filterItem.code]) {
                    errors[filterItem.code] = i18n.t("table.fill_field");
                }
                break;
            default:
                break;
        }
    }

    return errors;
};
