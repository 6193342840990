// eslint-disable-next-line
const defaultArray = [
  "email",
  "lastname",
  "nameNmiddle_name",
  "name",
  "pass",
  "phone",
  "birthdateNregistration",
  "birthdate",
  "registrationdate",
  "address",
  "description",
  "wifiNetworkView",
  "abacusAddView",
  "editAbacusView",
  "macAddress",
  "attachAbacus",
  "paymentAbacus",
  "wifiList",
  "wifiUserPass",
  "abacusalias",
  "language",
  "passNchatID",
  "chatID",
  "bossAddCountry",
  "bossAddCity",
  "filterUser",
  "addBtn",
  "saveBtn",
  "deleteNcancelBtn",
  "connectBtn",
  "addNcancelBtn",
  "addBtnNswitchToggle"
];

// const addUser = [
//     	'email',
//     	'lastname',
// 	'nameNmiddle_name',
//     	'pass',
// 	'phone',
// 	'birthdateNregistration',
// 	'address',
// 	'description',
// 	'addBtn'
// ];

// const editUser = [
// 	'email',
//     'lastname',
//     'nameNmiddle_name',
// 	'birthdateNregistration',
// 	'phone',
// 	'address',
// 	'description',
//     	'language',
// 	'language',
// 	'passNchatID',
// 	'addNcancelBtn',
//
// ];

// eslint-disable-next-line
const addStudent = [
  "username",
  "email",
  "lastname",
  "nameNmiddle_name",
  "name",
  "lastname",
  "phone",
  "birthdateNregistration",
  "address",
  "description",
  "addBtn"
];

// eslint-disable-next-line
const addManagerAbacus = [
  "lastname",
  "nameNmiddle_name",
  "email",
  "pass",
  "addBtn"
];

// const requestDelete = [
//     'deleteNcancelBtn',
// ];
// eslint-disable-next-line
const editManagerAbacus = [
  "lastname",
  "nameNmiddle_name",
  "email",
  "phone",
  "pass",
  "saveBtn"
];
// eslint-disable-next-line
const addEsorobanUser = [];

// const addAbacus = [
//     'macAddress'
// ];
// const editAbacus = [
//     'abacusalias',
//     'wifiList',
//     'wifiUserPass',
//     'addBtnNswitchToggle',
// ];
// eslint-disable-next-line
const reAttachAbacus = ["attachAbacus"];

// const paymentAbacus = [
//     'paymentAbacus',
// ];
// eslint-disable-next-line
const bossAddCountry = ["bossAddCountry"];
// eslint-disable-next-line
const bossAddCity = ["bossAddCity"];
// eslint-disable-next-line
const filterUser = ["filterUser"];

// export default {
//     defaultArray,
//     addUser,
//     editUser,
//     addStudent,
//     addManagerAbacus,
//     editManagerAbacus,
//     paymentAbacus,
//     editAbacus,
//     addAbacus,
//     reAttachAbacus,
//     requestDelete,
//     bossAddCountry,
//     bossAddCity,
//     filterUser,
// }

export default {};
