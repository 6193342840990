import React, { useCallback } from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import store from "./../../../../store";
import { changeStudentGroup } from "../../../../actions/getUsers";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Field } from "redux-form";
import { IconButton, Stack, Tooltip } from "@mui/material";
import MoveUpIcon from "@mui/icons-material/MoveUp";
const renderTextField = ({
  input,
  meta: { touched, error },
  i18n,
  ...custom
}) => {
  // eslint-disable-next-line
  const groups =
    (store.getState().groups &&
      store.getState().groups.select &&
      store.getState().groups.select.list) ||
    [];

  const group = groups.find((el) => +el.id === +input.value);
  return (
    <NativeSelect {...input} {...custom} style={{ minWidth: "200px" }}>
      {!group ? (
        <option value={-1}>{i18n.t("groups.no_group_link")}</option>
      ) : null}
      {groups.map((item, key) => {
        const title =
          item.title && typeof item.title === "object"
            ? item.title[i18n.language] ||
              Object.values(item.title).find(Boolean) + " (not translated)"
            : item.title;

        return (
          <option key={key} value={item.id}>
            {title}
          </option>
        );
      })}
    </NativeSelect>
  );
  // ChangeStudentGroup
};
export default ({ value, code, id }) => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const handleDoubleClick = useCallback((value) => {
    history.push(`/groups/${value}`);
  });
  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title={i18n.t("table.goToGroup")}>
        <IconButton
          onClick={() => {
            handleDoubleClick(value);
          }}
        >
          <MoveUpIcon />
        </IconButton>
      </Tooltip>
      <Field
        name={JSON.stringify({ code, id })}
        onChange={(event) => {
          if (event.target.value !== -1) {
            return changeStudentGroup(
              JSON.stringify({ code, id }),
              event.target.value
            )(store.dispatch, store.getState);
          }
        }}
        i18n={i18n}
        component={renderTextField}
        type="text"
      />
    </Stack>
  );
};
