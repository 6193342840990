export default {
  balloon: 1,
  gyroboard: 10,
  halloween: 11,
  holiday: 12,
  illusionist: 13,
  indian: 14,
  judge: 15,
  lamp: 16,
  mechanic: 17,
  operator: 18,
  photographer: 19,
  astronaut: 2,
  piggybank: 20,
  pinpong: 21,
  skateboard: 22,
  "soccer-player": 23,
  tourist: 24,
  viking: 25,
  blacksmith: 26,
  boxer: 27,
  cook: 28,
  flag: 29,
  cake: 3,
  painter: 30,
  solder: 31,
  staiker: 32,
  card_magican: 33,
  abacus: 34,
  aerostat: 35,
  basketball: 36,
  book: 37,
  brothers: 38,
  builder: 39,
  "chinese-lantern": 4,
  carpenter: 40,
  champion: 41,
  hand_fan: 42,
  hula_hoop: 43,
  jackhammer: 44,
  king: 45,
  like: 46,
  magic_carpet: 47,
  marshaller: 48,
  referee: 49,
  conductor: 5,
  rubiks_cube: 50,
  santa: 51,
  mexican: 52,
  phone: 53,
  pirate: 54,
  present: 55,
  sherlock: 56,
  shovel: 57,
  woodcarver: 58,
  skiing: 59,
  cowboy: 6,
  sled: 60,
  snowman: 61,
  spear: 62,
  thinker: 63,
  trombone: 64,
  "anim-hangglider": 65,
  moviegoer: 66,
  postman: 67,
  professor: 68,
  skippingrope: 69,
  dj: 7,
  analyst: 70,
  clown: 71,
  "cook-2": 72,
  kite: 73,
  soapbubbles: 74,
  umbrella: 75,
  vr: 76,
  dr: 77,
  superhero: 78,
  tea: 79,
  drummer: 8,
  accordion: 80,
  broom: 81,
  chemist: 82,
  cymbals: 83,
  police: 84,
  captain: 85,
  dumbbells: 86,
  flute: 87,
  laptop: 88,
  sailor: 89,
  gardener: 9,
  archer: 90,
  benchpress: 91,
};
