import React from 'react';
import i18n from 'i18next';
import { statuses } from '../../../../constants/group_constant';

export default ({ data, id, value, code, temporarily }) => {
    if (value === 'regular') value = '0';
    const status = statuses.find((el) => el.value === +value);
    let title = (status && i18n.t(status.label)) || '';
    // if (status.value === 6)
    //     title = `${i18n.t(status.label)}: ${data.customs_note}`;
    return value ? (
        <div
            style={{
                display: 'block',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            title={title}
            children={title}
        />
    ) : (
        ''
    );
};
