import Types from '../constants/offices';
import OfficesService from '../services/offices.service';
import { getError } from './toast';
import { closeRequestModal } from './modals';
import { reloadTable } from './AsyncTable';
import { combineLanguages } from './languages';

function deleteOffice(data) {
    return (dispatch, getState) => {
        closeRequestModal()(dispatch, getState);
        OfficesService.DeleteOffice(data).then((response) => {
            reloadTable()(dispatch, getState);
        });
    };
}

function getOffices(...args) {
    return (dispatch, getState) => {
        const { offices } = getState();
        try {
            OfficesService.OfficesList(...args).then((response) => {
                dispatch({
                    type: Types.GETOFFICES,
                    offices: {
                        ...offices,
                        ...response,
                    },
                });
            });
        } catch (error) {
            getError(error);
        }
    };
}

function addOffice(arr_data) {
    return (dispatch, getState) => {
        const data = structuredClone(arr_data);
        const toString = combineLanguages(arr_data.languages);
        const request = {
            parent_id: data.parent_id,
            lang_def: {},
            longitude: data.longitude || null,
            latitude: data.latitude || null,
        };
        for (let index in data) {
            const [key, lang] = index.split('|');
            if (key && lang) {
                request[key][lang] = data[index];
            }
        }
        request.lang_def = arr_data.languages;
        OfficesService.AddOffice(request).then((res) => {
            closeRequestModal()(dispatch, getState);
            reloadTable()(dispatch, getState);
        });
    };
}

function editOffice(data) {
    return (dispatch, getState) => {
        const lang_schema = {
            0: 'ru',
            1: 'ua',
            2: 'en',
            3: 'it',
            4: 'pl',
            5: 'by',
            6: 'md',
        };
        const request = {
            parent_id: data.parent_id,
            franchazy_id: data.franchazy_id,
            lang_def: {},
        };
        if (data.lang_ro) data.lang_md = data.lang_ro;
        delete data.lang_ro;

        for (let index in data) {
            const [key, lang] = index.split('|');
            if (key && lang) {
                request[key][lang] = data[index];
            }
        }
        let title = '';
        for (let i = 0; i < 7; i++) {
            const output = data[`lang_${lang_schema[i]}`];
            const _data =
                i === 6 ? (output ? output : '') : `${output ? output : ''}|||`;
            title += _data;
            delete data[`lang_${lang_schema[i]}`];
        }
        data.title = title;
        OfficesService.EditOffice(data).then((res) => {
            closeRequestModal()(dispatch, getState);
            reloadTable()(dispatch, getState);
        });
    };
}

async function getSingleOffice(...args) {
    return await OfficesService.SingleOffice(...args);
}

export { getOffices, addOffice, editOffice, getSingleOffice, deleteOffice };
