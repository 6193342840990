import Types from '../constants/countries';
import CountriesService from '../services/countries.service';
import { getError } from './toast';
import { closeRequestModal } from './modals';
import { reloadTable } from './AsyncTable';

function getCountries(...args) {
    return async (dispatch, getState) => {
        try {
            const response = await CountriesService.CountriesList(...args);
            dispatch({
                type: Types.GETCOUNTRIES,
                countries: response,
            });
            return response;
        } catch (error) {
            // throw 'ERROR => GET COUNTRIES!';
            getError(error);
        }
    };
}

function removeCountries() {
    return (dispatch, getState) => {
        dispatch({
            type: Types.GETCOUNTRIES,
            countries: {
                list: null,
            },
        });
    };
}

async function getSingleCountry(...args) {
    return await CountriesService.SingleCountry(...args);
}

function addCountry(data) {
    return (dispatch, getState) => {
        for (let key in data) {
            // eslint-disable-next-line
            if (data[key] == '') delete data[key];
        }
        CountriesService.AddCountry(data).then((response) => {
            dispatch({
                type: Types.ADDCOUNTRY,
            });
            closeRequestModal()(dispatch, getState);
            reloadTable()(dispatch, getState);
        });
    };
}

function editCountry(data) {
    return (dispatch, getState) => {
        for (let key in data) {
            // eslint-disable-next-line
            if (data[key] == '') delete data[key];
        }
        CountriesService.EditCountry(data).then((response) => {
            dispatch({
                type: Types.EDITCOUNTRY,
            });
            closeRequestModal()(dispatch, getState);
            reloadTable()(dispatch, getState);
        });
    };
}

function deleteCountry(data) {
    return (dispatch, getState) => {
        for (let key in data) {
            // eslint-disable-next-line
            if (data[key] == '') delete data[key];
        }
        CountriesService.DeleteCountry(data).then((response) => {
            dispatch({
                type: Types.DELETECOUNTRY,
            });
            closeRequestModal()(dispatch, getState);
            reloadTable()(dispatch, getState);
        });
    };
}

function getCountryPrices() {
    return (dispatch) => {
        dispatch({
            type: Types.GETCOUNTRIES,
            countries: {
                prices: { loading: true },
            },
        });
        CountriesService.GetBossPrices().then((response) => {
            dispatch({
                type: Types.GETCOUNTRIES,
                countries: {
                    prices: { data: { ...response }, loading: false },
                },
            });
        });
    };
}

// async function getBossList(args) {
//
//         return await UsersService.UsersList(false, {id : args})
//
// }

export {
    getCountries,
    addCountry,
    editCountry,
    getSingleCountry,
    deleteCountry,
    removeCountries,
    getCountryPrices,
};
