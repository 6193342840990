import Types from "./../constants/payments";

// const defaultState = {
//   payment_date: '2018-07-29',
//   expired: '2018-07-29',
//   user_payment: '123',
//   transaction_ammount: '111',
//   left_to_pay: "ASD"
// };

const defaultState = {
  list: [],
  status: true
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.SEND_PAY:
        case Types.PAYMENTS:
            return { ...state, ...action.payments };
        default:
            return state;
    }
};
