import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import ComponentTable from "../../components/ComponentTable";
import "./Payments.css";

import { discountStatus, deleteDiscount } from "../../actions/discounts";
import { openRequestModal } from "../../actions/modals";

import DiscountsService from "./../../services/discounts.service";
import ModelService from "./../../services/models.service";

import { Delete, MonetizationOn } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class DiscountsComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const actionsSales = [
      {
        title: t("sales.accept"),
        onClick: (id) => {
          this.props.discountStatus({ approved: true, id });
        },
        Icon: CheckCircleIcon,
        outOfMenu: true,
      },
      {
        title: t("sales.delete"),
        onClick: (id) => {
          this.props.deleteDiscount(false, { discount_id: id });
        },
        Icon: Delete,
        outOfMenu: true,
      },
      {
        title: t("sidebar.discounts"),
        onClick: (id, user) => {
          return this.props.openRequestModal({
            opened: true,
            data: {
              id: user.student_id,
              header: `${t("sidebar.discounts")} | ${user.user}`,
              user_status: user.status,
              modaltype: "EditDiscountsModal",
            },
          });
        },
        Icon: MonetizationOn,
      },
    ];
    return (
      <>
        <ComponentTable
          source={{
            getData: (page, size, sort, filter) =>
              DiscountsService.DiscountsList(false, {
                role_id: this.props.user.emulation.length
                  ? this.props.user.emulation[
                      this.props.user.emulation.length - 1
                    ].info.role_id
                  : this.props.user.info.role_id,
                page: page || 0,
                size: size || 10,
                orderBy: (sort && sort.orderBy) || null,
                order: (sort && sort.order) || null,
                filter,
              }),
            getModel: () => ModelService.DiscountsList(),
          }}
          actions={actionsSales}
          selectable={true}
          title={t("sales.title")}
          toolbarvisible={true}
          enableFilter={true}
        />
      </>
    );
  }
}

const widthConnect = connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openRequestModal,
        discountStatus,
        deleteDiscount,
      },
      dispatch
    )
);

export default withTranslation()(widthConnect(DiscountsComponent));
