import React, { useEffect, useState } from 'react';
import '../../layout/Dash/Dash.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import MaxCyclesComponent from './MaxCyclesComponent';
import { getCountryPrices } from '../../actions/countries';
import LowSubscribeComponent from './LowSubscribeComponent';
import { Box, Chip, Collapse, Typography } from '@mui/material';
import BalanceComponent from '../BalanceComponent';
import LtvService from '../../services/ltv.service';
import LTV from '../LTV';

const pricesItems = [
    'min_price_offline',
    'min_price_offline_li',
    'min_price_online',
    'min_price_online_li',
];

const DashBossView = (props) => {
    const loading = props.countriesPricesLoading;
    const prices = props.countriesPrices;
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        props.getCountryPrices();
    };
    return (
        <>
            <LTV />
            <BalanceComponent />
            <section className="dashSection">
                <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'}>
                    <MaxCyclesComponent />
                    {pricesItems.map((code, index) => {
                        return (
                            <LowSubscribeComponent
                                key={index}
                                value={!loading && prices ? prices[code] : 0}
                                isLoading={loading}
                                code={code}
                                updatePrices={() => fetchData()}
                            />
                        );
                    })}
                </Box>
            </section>
        </>
    );
};

const widthConnect = connect(
    (state) => ({
        users: state.users,
        user: state.user,
        cities: state.cities,
        countriesPrices: state.countries && state.countries.prices.data,
        countriesPricesLoading:
            state.countries && state.countries.prices.loading,
    }),
    (dispatch) => bindActionCreators({ getCountryPrices }, dispatch)
);

export default widthConnect(DashBossView);
