import React from "react";
import { useTranslation } from "react-i18next";

export default ({ value }) => {
    const { i18n } = useTranslation();
    return value ? (
        <div
            style={{
                display: "block",
                whiteSpace: "nowrap",
                maxWidth: 150,
                overflow: "hidden",
                textOverflow: "ellipsis"
            }}
            children={
                [i18n.t("sales.invalid"), i18n.t("sales.children_teacher"), i18n.t("sales.discounts_school"), i18n.t("sales.custom")][
                    value - 1
                ]
            }
        />
    ) : (
        "-"
    );
};
