import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import ComponentTable from '../../components/ComponentTable';
import './Payments.css';

import { openRequestModal } from '../../actions/modals';
import { sendPayments, returnPayments } from '../../actions/payments';
import { getNotification } from '../../actions/toast';

import { recountDiscount } from '../../actions/discounts';
import PaymentsService from './../../services/payments.service';
import ModelService from './../../services/models.service';

import {
    Edit,
    AttachMoney,
    MoneyOff,
    History,
    MonetizationOn,
} from '@material-ui/icons';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import {
    __UpdatePaymentsCurrentBalance,
    updateTableData,
} from '../../actions/AsyncTable';

const initState = {
    role_id: null,
};

const restrictedDebtCities = ['kiev', 'zp', 'nm', 'kh', 'od'];

class PaymentsComponent extends React.Component {
    constructor(props) {
        super(props);
        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        this.evtSource = null;
        initState.role_id = role_id;
        this.state = initState;
    }

    // componentWillUnmount() {
    //   if (this.evtSource) this.evtSource.close();
    // }

    componentDidMount() {
        // this.startParcingData();
    }

    updatePayments = (event, type) => {
        const { t } = this.props;
        if (type === 'updateDataPayments') {
            const res = JSON.parse(event.data);
            if (!res.length) return;
            let table_data =
                this.props.AsyncTable &&
                this.props.AsyncTable.table &&
                this.props.AsyncTable.table.data;
            for (let item of res) {
                const index = table_data.findIndex(
                    (el) => +el.id === +item.user_id
                );
                if (index !== -1) {
                    const user = table_data[index];
                    getNotification(
                        `${user.first_name} ${user.last_name} | ${t(
                            'payments.' + item.tran_type
                        )} | ${t('table.balance')}: ${item.balance} `
                    );
                    table_data[index].payment_sum = '0';
                    table_data[index].status_payments = 0;
                    table_data[index].balance = item.balance;
                }
            }
            this.props.updateTableData(table_data);
        }
    };

    getSelectedUserName(id, deployUser) {
        const table = structuredClone(this.props.tableData);
        const user = table.find((el) => el.id === id);
        if (deployUser) return user;

        return `${user.first_name} ${user.last_name}`;
    }

    processTablePaymentsStatus(id, status) {
        // status_payments === 2) return 'payments_red'; //#ff00003b
        // status_payments === 1) return 'payments_yellow'; //#ff00003b
        // status_payments === 0) return 'payments_green'; //#ff00003b
        const table = structuredClone(this.props.tableData);
        table.find((el) => +el.id === +id).status_payments = status;
        this.props.updateTableData(table);
    }

    render() {
        const { t } = this.props;
        const source = {};
        const actions = [];
        const user_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.user_id
            : this.props.user.info.user_id;
        const sourceFromRole = {
            getData: (page, size, sort, filter) => {
                if (this.state.role_id === 5)
                    return PaymentsService.PaymentHistory(false, {
                        user_id,
                        size: 9999,
                    });
                return PaymentsService.StudentFromListList(false, {
                    page: page || 0,
                    size: size || 10,
                    orderBy: (sort && sort.orderBy) || 'last_name',
                    order: (sort && sort.order) || 'asc',
                    filter,
                });
            },
            getModel: () =>
                this.state.role_id === 5
                    ? ModelService.StudentsPayments(false, {
                          role_id: this.state.role_id,
                      })
                    : this.state.role_id === 4
                    ? ModelService.StudentFromListListTeacher()
                    : ModelService.StudentFromListListFranch(),
        };
        let actionsFromRole = [
            {
                title: t('payments.add_amount'),
                onClick: async (id) => {
                    this.processTablePaymentsStatus(id, 1);
                    const res = await this.props.sendPayments(id);
                    if (!res) {
                        this.processTablePaymentsStatus(id, -1);
                        return;
                    }
                    if (res && typeof res.balance === 'number') {
                        this.props.__UpdatePaymentsCurrentBalance(
                            id,
                            res.balance
                        );
                    }
                },
                Icon: AttachMoney,
                outOfMenu: true,
                name: 'add_amount',
            },
            {
                title: t('payments.refund'),
                onClick: async (id) => {
                    const user = this.getSelectedUserName(id, true);
                    if (user.balance <= 0) return;
                    this.processTablePaymentsStatus(id, 1);
                    const res = await this.props.returnPayments(id);
                    if (!res) {
                        this.processTablePaymentsStatus(id, -1);
                        return;
                    }
                    if (res && res.balance) {
                        this.props.__UpdatePaymentsCurrentBalance(
                            id,
                            res.balance
                        );
                    }
                },
                Icon: MoneyOff,
                // outOfMenu: true,
                name: 'refund',
            },
            {
                title: t('payments.debt_write_off'),
                onClick: (id) => {
                    const name = this.getSelectedUserName(id, false);
                    const user = this.getSelectedUserName(id, true);
                    if (user.balance >= 0) return;
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id,
                            header: t('payments.debt_write_off') + ' | ' + name,
                            user: user,
                            modaltype: 'DebtOffModal',
                        },
                    });
                },
                outOfMenu: true,
                disabled: true,
                Icon: ConfirmationNumberIcon,
                key: 'debtModalKey',
            },
            {
                title: t('sidebar.discounts'),
                onClick: (id) => {
                    const user = this.getSelectedUserName(id, true);
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            header: `${t('sidebar.discounts')} | ${
                                user.first_name
                            } ${user.last_name}`,
                            user_status: user.status,
                            modaltype: 'EditDiscountsModal',
                        },
                    });
                },
                Icon: MonetizationOn,
            },
            {
                title: t('payments.history'),
                onClick: (id) => {
                    const name = this.getSelectedUserName(id, false);
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id,
                            header: t('payments.history') + ' | ' + name,
                            modaltype: 'PaymentHistoryModal',
                        },
                    });
                },
                Icon: History,
                outOfMenu: true,
            },
            {
                title: t('payments.recount_discount'),
                onClick: async (id) => {
                    await recountDiscount(id);
                },
                Icon: AutoModeIcon,
            },
            {
                title: t('payments.create_bill'),
                onClick: (id) => {
                    const name = this.getSelectedUserName(id, false);
                    const user = this.getSelectedUserName(id, true);
                    return this.props.openRequestModal({
                        opened: true,
                        data: {
                            id,
                            // eslint-disable-next-line
                            header: t('payments.create_bill') + ': ' + name,
                            modaltype: 'CreatePaymentBill',
                            item: user,
                        },
                    });
                },
                Icon: ReceiptIcon,
            },
            {
                title: t('payments.edit_user'),
                onClick: (id) =>
                    this.props.openRequestModal({
                        opened: true,
                        data: {
                            id: id,
                            role_id: 5,
                            header: t('modals_name.edit'),
                            modaltype: 'EditUserModal',
                        },
                    }),
                Icon: Edit,
            },
        ];
        if (
            restrictedDebtCities.includes(
                this.props.user &&
                    this.props.user.info &&
                    this.props.user.info.program_title
            )
        ) {
            actionsFromRole = actionsFromRole
                .filter((el) => !el.key)
                .filter(Boolean);
        }
        Object.assign(source, { ...sourceFromRole });
        if (this.state.role_id !== 5) actions.push(...actionsFromRole);
        return (
            <ComponentTable
                typeTable="payments"
                source={source}
                selectable={true}
                enableFilter={this.state.role_id !== 5}
                userData={this.state.role_id}
                actions={actions}
                title={t('payments.title')}
            />
        );
    }
}

const widthConnect = connect(
    (state) => ({
        payments: state.payments,
        modalWindow: state.modalWindow,
        user: state.user,
        AsyncTable: state.AsyncTable,
        tableData:
            state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.data,
        programTitle:
            state.user & state.user.info && state.user.info.program_title,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                sendPayments,
                returnPayments,
                updateTableData,
                __UpdatePaymentsCurrentBalance,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(PaymentsComponent));
