import React from "react";
import { connect } from "react-redux";
import "./index.css";
import { bindActionCreators } from "redux";
import { withTranslation } from "react-i18next";
import { openRequestModal } from "../../actions/modals";
import { confirmTransfer, sendRelocateRequest } from "../../actions/user";
import { reloadTable } from "../../actions/AsyncTable";
import ComponentTable from "./../../components/ComponentTable";
import PaymentsService from "./../../services/payments.service";
import ModelService from "./../../services/models.service";
import { Delete } from "@material-ui/icons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { processDebt } from "../../actions/payments";

class DebtComponent extends React.Component {
  render() {
    const { t } = this.props;
    const actions = [
      {
        title: t("buttons.accept"),
        onClick: (id) => this.props.processDebt({ id, type: "complete" }),
        Icon: CheckCircleIcon,
      },
      {
        title: t("buttons.delete"),
        onClick: (id) => this.props.processDebt({ id, type: "annuled" }),
        Icon: Delete,
      },
    ];
    return (
      <>
        <ComponentTable
          source={{
            getData: (page, size, sort, filter) =>
              PaymentsService.DebtList(false, {
                page: page || 0,
                size: size || 10,
                orderBy: (sort && sort.orderBy) || "title",
                order: (sort && sort.order) || "asc",
                filter,
              }),
            getModel: () => ModelService.DebtModels(false, { role_id: 5 }),
          }}
          selectable={true}
          title={t("sidebar.students")}
          enableSettings
          typeTable="relocate"
          toolbarvisible={true}
          actions={actions}
        />
      </>
    );
  }
}

const widthConnect = connect(
  (state) => ({
    user: state.user,
  }),
  (dispatch) =>
    bindActionCreators(
      {
        openRequestModal,
        confirmTransfer,
        sendRelocateRequest,
        reloadTable,
        processDebt,
      },
      dispatch
    )
);

export default withTranslation()(widthConnect(DebtComponent));
