import React from 'react';
import i18n from 'i18next';

export default ({ value }) => {
    if (!value) return '-';
    let title = '',
        history = '';
    const USER_STATUSES = {
        0: 'ACTIVE',
        1: 'BLOCKED',
        2: 'RESERVED',
        3: 'ARCHIVED',
        4: 'ON_VACATION',
        5: 'NOT_CONFIRMED',
        6: 'CANDIDATE',
        7: 'GRADUATE',
    };
    for (let i in value.sort((a, b) => +new Date(b.date) - +new Date(a.date))) {
        const d = new Date(value[i].date);
        const _min =
            d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
        const _hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
        const _month =
            d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
        const _date = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();

        const date = `${_date}.${_month}.${d.getFullYear()} ${_hours}:${_min}`;

        history +=
            date +
            ' | ' +
            i18n.t('users.' + USER_STATUSES[value[i].status]) +
            '\n';
        if (+i === 0) title = date;
    }
    return value ? (
        <div
            style={{
                display: 'block',
                whiteSpace: 'nowrap',
                maxWidth: 250,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            title={history}
            children={title}
        />
    ) : (
        '-'
    );
};
