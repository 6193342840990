import * as React from 'react';
import { useCallback, useState } from 'react';
import i18n from 'i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';


const AddsDataToTheMultTable = ({ data }) => {
    const themes = [
        `${i18n.t('mult_table.themes')}`,
        'M2',
        'M11',
        'M12',
        'M5',
        'M6',
        'M7',
        'M9',
        'M8',
        'M4',
        'M3',
    ];
    const [factor, setFactor] = useState('st');

    const handleChange = (e) => {
        setFactor(e.target.value);
    };

    const operationValue = useCallback(() => {
        console.log(data)
        let operations = [];
        for (let i = 0; i <= 9; i++) {
            const value = [];
            for (let theme of themes) {
                if (theme === `${i18n.t('mult_table.themes')}`) continue;
                if (!data || !data[factor]) continue;
                const key = theme.split('M')[1];
                value.push(
                    data[factor].find((el) => {
                        const theme = el.op.split('x')[0];
                        const number = el.op.split('x')[1];
                        if (key === theme && i === +number) return el;
                    })
                );
            }
            if (value.filter(Boolean).length) {
                operations[i] = value;
            }
        }
        return operations;
    }, [data, factor]);

    return (
        <Box style={{ padding: '25px 0' }}>
            <FormControl sx={{ m: 1 }} variant="outlined">
                <Select
                    value={factor}
                    onChange={handleChange}
                    style={{
                        marginBottom: 24,
                        marginTop: 12,
                    }}
                >
                    <MenuItem value="st">{i18n.t('mult_table.st')}</MenuItem>
                    <MenuItem value="md">{i18n.t('mult_table.md')}</MenuItem>
                    <MenuItem value="end">{i18n.t('mult_table.end')}</MenuItem>
                </Select>
            </FormControl>

            <TableContainer component={Paper}>
                <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                >
                    <TableHead>
                        <TableRow>
                            {themes.map((el, index) => (
                                <TableCell
                                    key={index}
                                    align="right"
                                    variant="head"
                                    style={{
                                        fontWeight: 600,
                                    }}
                                >
                                    {el}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {Object.keys(operationValue).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {row}
                                </TableCell>
                                {operationValue[row].map((el, i) => (
                                    <TableCell key={i} align="right">
                                        {el.val}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AddsDataToTheMultTable;
