import base64url from 'base64url';
import Types from './../constants/AsyncTable';
import { getError } from './toast';
import { setNewHwHeight } from './user';
import uuid from 'uuid/v4';
import store from './../store';
import { detectPhone } from './phone';

export function __Init(source, fetchOnFilter, destroy) {
    return function (dispatch, getState) {
        __GetModel(source, fetchOnFilter, destroy)(dispatch, getState);
    };
}

export function __Destroy() {
    return function (dispatch) {
        dispatch({ type: Types.DESTROY });
    };
}

export function isTouched(item) {
    return function (dispatch) {
        dispatch({
            type: Types.DATA,
            payload: {
                isTouched: item,
            },
        });
    };
}

export function __RowsPerPage(source) {
    return function (dispatch, getState) {
        return function (event) {
            const value = +event.currentTarget.getAttribute('data-value');
            dispatch({
                type: Types.ROWS_PER_PAGE,
                payload: {
                    page: 0,
                    data: [],
                    rowsPerPage: value,
                },
            });
            localStorage.setItem('rowsPerPage', value);
            __GetData(source)(dispatch, getState);

            setTimeout(() => window.scrollTo(0, 0), 0);
        };
    };
}

export function __SetRowPerPage(item) {
    return function (dispatch) {
        dispatch({
            type: Types.ROWS_PER_PAGE,
            payload: {
                rowsPerPage: item,
            },
        });
    };
}

export function __ChangePage(source) {
    return function (dispatch, getState) {
        return function (event, page) {
            dispatch({
                type: Types.ROWS_PER_PAGE,
                payload: {
                    page,
                    data: [],
                },
            });

            __GetData(source)(dispatch, getState);

            setTimeout(() => window.scrollTo(0, 0), 0);
        };
    };
}
export function __ChangeSort(source, code) {
    return function (dispatch, getState) {
        const { orderBy, order } =
            getState().AsyncTable.table.sort ||
            getState().AsyncTable.default.sort;
        dispatch({
            type: Types.SORT,
            payload: {
                sort: {
                    orderBy: code,
                    // eslint-disable-next-line
                    order: orderBy == code && order == 'asc' ? 'desc' : 'asc',
                },
            },
        });

        __GetData(source)(dispatch, getState);
    };
}

export function __GetModel(source, fetchOnFilter, destroy) {
    return async function (dispatch, getState) {
        try {
            dispatch({
                type: Types.SETSOURCE,
                payload: {
                    flagUpdate: false,
                },
            });
            const model = await source.getModel();
            const { user } = store.getState();
            let { custom_filter } = getState().AsyncTable.table;
            let user_id = user.emulation.length
                ? user.emulation[user.emulation.length - 1].info.user_id
                : user.info.user_id;
            if (!!user.emulation.length) user_id = `${user_id}_emul`;
            let filter = [];
            const location = document.location.pathname;
            if (
                custom_filter &&
                custom_filter[user_id] &&
                custom_filter[user_id][location] &&
                custom_filter[user_id][location].length
            ) {
                for (let item of custom_filter[user_id][location]) {
                    if (!!item.values) filter.push(item);
                }
            }
            dispatch({
                type: Types.MODEL,
                payload: {
                    model: model.list,
                    modelDefault: model.list,
                    filter,
                },
            });

            __GetData(
                source,
                false,
                fetchOnFilter,
                destroy
            )(dispatch, getState);
        } catch (error) {
            getError(error);
            console.log(error);
        }
    };
}

export function __SetSource(source) {
    return function (dispatch) {
        dispatch({
            type: Types.SETSOURCE,
            payload: {
                source,
            },
        });
    };
}

export function updateTableData(data) {
    return function (dispatch) {
        dispatch({
            type: Types.DATA,
            payload: {
                data,
            },
        });
    };
}

export function __GetData(
    source,
    resetPagination = false,
    fetchOnFilter = false,
    destroy = false,
    rowsPerPage = 100
) {
    return async function (dispatch) {
        try {
            const {
                page,
                rowsPerPage,
                filter,
                sort,
                custom_filter,
                fetchOnFilter: fetchOnFilterStored,
            } = store.getState().AsyncTable.table;
            const { rowsPerPage: defaultRowsPerPage } =
                store.getState().AsyncTable.default;
            const onFilter = fetchOnFilterStored || fetchOnFilter;
            dispatch({
                type: Types.DATA,
                payload: {
                    data: [],
                    fetching: true,
                    flagUpdate: false,
                    ...(resetPagination ? { page: 0 } : {}),
                    custom_filter: custom_filter,
                    fetchOnFilter: onFilter,
                },
            });
            let firstLoadResponse;
            if (source.firstLoad) {
                firstLoadResponse = await source.firstLoad();
            }

            const disabledFetching =
                (!custom_filter || !filter.length) && fetchOnFilter;
            if (source.firstLoad && !firstLoadResponse) return;
            const response = disabledFetching
                ? { list: [], page: 0, count: 0 }
                : await source.getData(
                      page,
                      rowsPerPage || defaultRowsPerPage,
                      sort,
                      encodeFilter(filter, onFilter)
                  );

            const { fetching } = store.getState().AsyncTable.table;
            if (response && fetching) {
                const defaultTemplate =
                    response &&
                    response.list &&
                    response.list[0] &&
                    response.list[0].defaultTemplate
                        ? { defaultTemplate: true }
                        : response &&
                          response.list &&
                          response.list.length === 0
                        ? { defaultTemplate: true }
                        : {};
                dispatch({
                    type: Types.DATA,
                    payload: {
                        ...defaultTemplate,
                        data: response.list,
                        count: response.count,
                        fetching: false,
                        flagUpdate: false,
                        isEmpty: response.count === 0,
                        custom_filter: custom_filter,
                        expanded: disabledFetching,
                        fetchOnFilter: onFilter,
                    },
                });
            }
        } catch (error) {
            // throw 'ERROR => GET TABLE DATA!';
            console.log(error);

            if (error.message === 'signal is aborted without reason') {
                return;
            }
            getError(error);
        }
    };
}

export function __SilentTableUpdate(
    source,
    resetPagination = false,
    fetchOnFilter = false,
    destroy = false,
    rowsPerPage = 100
) {
    return async function (dispatch, getState) {
        const {
            page,
            rowsPerPage,
            filter,
            sort,
            custom_filter,
            fetchOnFilter: fetchOnFilterStored,
        } = store.getState().AsyncTable.table;
        const onFilter = fetchOnFilterStored || fetchOnFilter;

        const response = await source.getData(
            page,
            rowsPerPage,
            sort,
            encodeFilter(filter, onFilter)
        );

        const disabledFetching =
            (!custom_filter || !filter.length) && fetchOnFilter;
        if (response) {
            const defaultTemplate =
                response &&
                response.list &&
                response.list[0] &&
                response.list[0].defaultTemplate
                    ? { defaultTemplate: true }
                    : response && response.list && response.list.length === 0
                    ? { defaultTemplate: true }
                    : {};
            dispatch({
                type: Types.DATA,
                payload: {
                    ...defaultTemplate,
                    data: response.list,
                    count: response.count,
                    fetching: false,
                    flagUpdate: false,
                    isEmpty: response.count === 0,
                    custom_filter: custom_filter,
                    expanded: disabledFetching,
                    fetchOnFilter: onFilter,
                },
            });
        }
    };
}

export function __ToogleSettings(currentTableId) {
    return function (dispatch, getState) {
        return function () {
            const currentTable = getState().AsyncTable.tables.find(
                (e) => e.id === currentTableId
            );

            dispatch({
                type: Types.TOOGLE_SETTINGS,
                currentTableId,
                payload: {
                    settingsOpen: !currentTable.settingsOpen,
                },
            });
        };
    };
}

export function reloadTable() {
    return async function (dispatch) {
        dispatch({
            type: Types.SETSOURCE,
            payload: {
                flagUpdate: true,
            },
        });
    };
}

export function __ToogleFilter(currentTableId) {
    return function (dispatch, getState) {
        return function () {
            const currentTable = getState().AsyncTable.tables.find(
                (e) => e.id === currentTableId
            );

            dispatch({
                type: Types.TOOGLE_FILTER,
                currentTableId,
                payload: {
                    filterOpen: !currentTable.filterOpen,
                },
            });
        };
    };
}

export function customFilterValue(data, no_location = false) {
    return function (dispatch, getState) {
        const location = document.location.pathname;
        const { user } = getState();
        const { custom_filter } = getState().AsyncTable.table;
        let user_id = user.emulation.length
            ? user.emulation[user.emulation.length - 1].info.user_id
            : user.info.user_id;
        if (!!user.emulation.length) user_id = `${user_id}_emul`;
        let filter = custom_filter ? custom_filter : [];
        if (no_location) {
            dispatch({
                type: Types.DATA,
                payload: {
                    custom_filter: data,
                },
            });
        } else {
            dispatch({
                type: Types.DATA,
                payload: {
                    custom_filter: {
                        ...filter,
                        [user_id]: {
                            ...filter[user_id],
                            [location]: data,
                        },
                    },
                },
            });
        }
    };
}

export function __SetFilterValues() {
    return function (dispatch, getState) {
        return function (filter) {
            customFilterValue(filter)(dispatch, getState);
            dispatch({
                type: Types.TOOGLE_FILTER,
                payload: {
                    filter,
                },
            });
        };
    };
}

export function __SetFilter() {
    return function (dispatch, getState) {
        return function (elFilter) {
            const { filter } = getState().AsyncTable.table;
            // eslint-disable-next-line
            if (filter.findIndex((el) => el.code == elFilter.code) != -1) {
                const fl = filter
                    // eslint-disable-next-line
                    .map((el) => (el.code != elFilter.code ? el : false))
                    .filter(Boolean);
                __SetFilterValues()(dispatch, getState)(fl);
            } else {
                __SetFilterValues()(dispatch, getState)([...filter, elFilter]);
            }
        };
    };
}

export function __ExpandFilterView() {
    return function (dispatch) {
        return function () {
            dispatch({
                type: Types.TOOGLE_FILTER_VIEW,
                payload: {
                    expanded: true,
                },
            });
        };
    };
}
export function __InitDataHWRow(e) {
    return function (dispatch, getState) {
        // return function() {
        dispatch({
            type: Types.INIT_DATA_HW_ROW,
            payload: e,
        });
        // };
    };
}

export function _copyDataRow(id) {
    return function (dispatch, getState) {
        const mobile = detectPhone();
        if (mobile) {
            const doc = document.querySelector('.base-container');
            setNewHwHeight(doc.scrollTop)(dispatch, getState);
        }
        let table = getState().AsyncTable.table.data;
        let arr = JSON.parse(JSON.stringify(table));
        let save = JSON.parse(JSON.stringify(arr.find((el) => el.id === id)));
        save.id = uuid();
        save.order = table.length + 1;
        table.push(save);
        dispatch({
            type: Types.ADD_DATA_HW_ROW,
            payload: table,
        });
    };
}

export function getDataFromTable(id, codeArr) {
    const form = store.getState().form['from-tableComponent'].values;
    const resObj = {};

    for (const code of codeArr) {
        const res =
            form[
                Object.keys(form).find(
                    (el) =>
                        JSON.parse(el).code === code && JSON.parse(el).id === id
                )
            ];
        resObj[code] = res;
    }

    return resObj;
}

export function __AddDataJournal() {
    return function (dispatch, getState) {
        // return function() {
        // const e = getState().form['from-tableComponent'].values;
        let table = getState().AsyncTable.table.data;
        let key = table[table.length - 1].key + 1;

        table.push({
            key: key,
            week: '0',
            setting: [{ topic: 'S2', templateName: table[0].title }],
        });
        dispatch({
            type: Types.ADD_DATA_HW_ROW,
            payload: table,
        });
        // };
    };
}
export function __AddDataHWRow() {
    return function (dispatch, getState) {
        const single = getState().groups && getState().groups.single;
        const user = getState().user;
        const role_id = user.emulation.length
            ? user.emulation[user.emulation.length - 1].info.role_id
            : user.info.role_id;
        const mobile = detectPhone();
        if (mobile) {
            const doc = document.querySelector('.base-container');
            setNewHwHeight(doc.scrollTop)(dispatch, getState);
        }
        const e = getState().form['from-tableComponent'].values;
        let table = getState().AsyncTable.table.data;
        const arrToSave = [];
        const objToSave = {};
        for (let i in e) {
            const obj = JSON.parse(i);
            if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            } else if (obj && obj.id) {
                if (!objToSave[obj.id]) objToSave[obj.id] = {};
                objToSave[obj.id][obj.code] = e[i];
            }
        }
        for (let i in objToSave) {
            if (!isNaN(+i)) {
                arrToSave.push({
                    ...objToSave[i],
                    id: i.length > 20 ? i : JSON.parse(i),
                });
            } else {
                arrToSave.push({
                    ...objToSave[i],
                    id: i.length > 20 ? i : JSON.parse(i),
                });
            }
        }
        const schemaGameValue = {
            Ded: '3',
            Flash: '5',
            FlashMinus: '6',
        };
        for (let item of arrToSave) {
            if (schemaGameValue[item.game_type])
                item.game_type = schemaGameValue[item.game_type];
        }
        if (table.length > arrToSave.length) {
            let numbers = table.length - arrToSave.length;
            for (let i = 0; i < numbers; i++) {
                arrToSave.push({
                    topic: '0',
                    digit: 1,
                    game_type: '3',
                    duration: 0,
                });
            }
        }
        arrToSave.sort((a, b) =>
            a.order > b.order ? (b.order > a.order ? 0 : 1) : -1
        );
        const order = arrToSave.length
            ? arrToSave[arrToSave.length - 1].order + 1
            : 1;
        const newEqData =
            role_id === 2 || single.eq_option === 2
                ? {
                      low_rate: 3500,
                      low_act: 4,
                      start_rate: 2500,
                      start_act: 5,
                      high_rate: 1600,
                      high_act: 7,
                  }
                : {};
        arrToSave.push({
            topic: '0',
            game_type: '3',
            digit: 1,
            id: uuid(),
            duration: 90,
            ...newEqData,
            order,
        });
        dispatch({
            type: Types.ADD_DATA_HW_ROW,
            payload: arrToSave,
        });
        // };
    };
}

export function __UpdatePaymentsCurrentBalance(id, value) {
    return function (dispatch, getState) {
        const state = getState();
        const table =
            state.AsyncTable &&
            state.AsyncTable.table &&
            state.AsyncTable.table.data;
        const data = structuredClone(table);

        const student = data.find((student) => student.id === id);
        if (!student) return;
        student.balance = value;
        student.status_payments = 0;

        dispatch({
            type: Types.DATA,
            payload: {
                data,
            },
        });
    };
}

export function __RollUpFilterView(currentTableId) {
    return function (dispatch) {
        return function () {
            dispatch({
                type: Types.TOOGLE_FILTER_VIEW,
                payload: {
                    expanded: false,
                },
            });
        };
    };
}

// function md5(data) {
//   return createHash("md5")
//     .update(JSON.stringify(data))
//     .digest("hex");
// }
//
export function encodeFilter(data, fetchOnFilter) {
    data = data || [];
    const encodedFilter = data.reduce((result, item) => {
        if (item.code === 'phone') {
            item.values = item.values.map((el) =>
                el.replaceAll(' ', '').replace('+', '')
            );
        }
        return item.values
            ? {
                  ...result,
                  [item.code]:
                      fetchOnFilter && Array.isArray(item.values)
                          ? item.values[0]
                          : item.values,
              }
            : result;
    }, {});
    return base64url.encode(encodeURIComponent(JSON.stringify(encodedFilter)));
}

export function __typePayments(obj) {
    return (dispatch, getState) => {
        // const { data } = getState().AsyncTable.table.data;
        dispatch({
            type: Types.PAY_TYPE,
            payload: obj,
        });
    };
}

export function __sumPayments(obj) {
    return (dispatch, getState) => {
        // const { data } = getState().AsyncTable.table.data;
        dispatch({
            type: Types.PAY_SUM,
            payload: obj,
        });
    };
}
