import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { openRequestModal } from "../../actions/modals";
import { bindActionCreators } from "redux";
import { CSVReader } from "react-papaparse";
import "./index.css";
import { getErrorNotification } from "../../actions/toast";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomTaskService from "../../services/customTasks.service";
import ModelService from "../../services/models.service";
import ComponentTable from "../../components/ComponentTable";
import Button from "@material-ui/core/Button";
import { reloadTable } from "../../actions/AsyncTable";
import { Delete, Edit } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import CircularProgress from "@material-ui/core/CircularProgress";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class MyTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      body: null,
      loading: false,
      isReset: true,
    };
  }

  processErrors(data, file) {
    const { t } = this.props;
    if (!file.name.includes(".csv")) {
      this.handleReset();
      this.setState({ loading: false, body: null });
      getErrorNotification(t("error.wrong_file_type"));
      return true;
    }

    if (data.length === 1) {
      this.handleReset();
      this.setState({ loading: false, body: null });
      getErrorNotification(t("error.wrong_file_format"));
      return true;
    }

    return false;
  }

  handleOnDrop = (data, file) => {
    if (this.processErrors(data, file)) return;
    let err = null;
    const { t } = this.props;
    this.setState({ body: null });
    let task;
    let body = {
      title: file.name.split(".csv")[0].substr(0, 100),
      seq: [],
    };
    const processErorrs = (items) => {
      items.filter(Boolean).map((el) => {
        const operation = el
          .replace(/[^-^+^;^x^х\d]/g, "")
          .replace("х", "x")
          .toLowerCase();
        if (!Number.isNaN(+operation[0])) {
          err = operation;
        }
        return operation;
      });
    };
    for (let i = 0; i < data.length - 1; i++) {
      let template = data[i].data;
      let eabacus = template.findIndex((el) => el.includes("eabacus"));
      let inline = template.findIndex((el) => el.includes("inline"));
      if (inline !== -1) delete template[inline];
      if (eabacus !== -1) delete template[eabacus];
      eabacus = eabacus !== -1;
      inline = inline !== -1;
      task = processErorrs(template);
      if (err) {
        this.handleReset();
        this.setState({ loading: false, body: null });
        return getErrorNotification(`${t("error.template_error")} ${err}`);
      }
      body.seq.push({
        task,
        eabacus,
        inline,
      });
    }
    this.setState({ body });
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  handleOnRemoveFile = (data) => {
    this.setState({ body: null });
  };

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "title" && value.length > 100) value = this.state.body.title;
    this.setState({
      body: {
        ...this.state.body,
        [name]: value,
      },
    });
  };

  CreateCustomTask = async () => {
    let res;
    this.setState({ loading: true });
    try {
      res = await CustomTaskService.CreateCustomTask(this.state.body);
    } catch (e) {
      this.setState({ loading: false, body: null });
    } finally {
      if (res) {
        this.props.reloadTable();
      }
      setTimeout(() => {
        this.handleReset();
        this.setState({ loading: false, body: null });
      }, 200);
    }
  };

  handleReset = () => {
    this.setState({ isReset: !this.state.isReset });
  };

  render() {
    const { t } = this.props;
    const actions = [
      {
        title: t("modals_name.info"),
        onClick: (id) =>
          this.props.openRequestModal({
            opened: true,
            data: {
              id: id,
              header: t("modals_name.info"),
              modaltype: "CustomTemplateInfo",
            },
          }),
        Icon: InfoIcon,
      },
      {
        title: t("buttons.delete"),
        onClick: (id) =>
          this.props.openRequestModal({
            opened: true,
            data: {
              id: id,
              header: t("buttons.delete"),
              type: "delete",
              modaltype: "UpdateCustomModal",
            },
          }),
        Icon: Delete,
      },
      {
        title: t("modals_name.edit"),
        onClick: (id) =>
          this.props.openRequestModal({
            opened: true,
            data: {
              id: id,
              header: t("modals_name.edit"),
              type: "edit",
              modaltype: "UpdateCustomModal",
            },
          }),
        Icon: Edit,
      },
    ];
    return (
      <Fragment>
        <h2 className="container-header">{t("templates.title")}</h2>
        <Box m={2}>
          <div>
            <div className="CSV_Custom">
              <CSVReader
                isReset={this.state.isReset}
                onDrop={this.handleOnDrop}
                onError={this.handleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={this.handleOnRemoveFile}
              >
                <span>{t("analytics.drag_and_drop")}</span>
              </CSVReader>
            </div>
            {this.state.body ? (
              <div className="CSV_Custom items_csv">
                <Box p={1}>
                  <TextField
                    style={{ width: "100%" }}
                    variant="outlined"
                    name="title"
                    multiline
                    label={t("table.template_name")}
                    value={this.state.body.title}
                    onChange={this.handleChange}
                  />
                </Box>
                <Box p={1}>
                  {this.state.body.eabacus ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked
                        />
                      }
                      label={t("dashboard.eabacus")}
                    />
                  ) : null}
                  {this.state.body.inline ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={true}
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked
                        />
                      }
                      label={t("table.inline")}
                    />
                  ) : null}
                </Box>
                <Box p={1}>
                  <span style={{ color: "#5e5e5e" }}>
                    {t("analytics.detected_operations")}:{" "}
                    {this.state.body.seq.length}
                  </span>
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.CreateCustomTask}
                >
                  {this.state.loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    t("buttons.save")
                  )}
                </Button>
              </div>
            ) : null}
          </div>
        </Box>
        <ComponentTable
          source={{
            getData: (page, size, sort, filter) =>
              CustomTaskService.CustomTasksList(false, {
                page: page || 0,
                size: size || 10,
                orderBy: (sort && sort.orderBy) || null,
                order: (sort && sort.order) || null,
                filter: filter,
              }),
            getModel: () => ModelService.CustomTasks(false, { role_id: 5 }),
          }}
          actions={actions}
          selectable={true}
          enableFilter
          title={t("sidebar.students")}
          enableSettings
          toolbarvisible={true}
        />
      </Fragment>
    );
  }
}

const widthConnect = connect(
  (state) => ({}),
  (dispatch) => bindActionCreators({ openRequestModal, reloadTable }, dispatch)
);

export default withTranslation()(widthConnect(MyTemplates));
