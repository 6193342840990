import { Box, Skeleton, Stack } from '@mui/material';

const CustomLoadingSkeleton = () => {
    return (
        <>
            <Stack spacing={1} alignItems={'flex-start'}>
                <Stack
                    spacing={3}
                    alignItems={'center'}
                    direction={'row'}
                    display={'flex'}
                    height={'0px'}
                >
                    <Skeleton
                        width={'90px'}
                        height={'25px'}
                        animation={'wave'}
                    />
                    <Skeleton
                        width={'90px'}
                        height={'25px'}
                        animation={'wave'}
                    />
                    <Skeleton
                        width={'30px'}
                        height={'30px'}
                        animation={'wave'}
                    />
                </Stack>
                <Box height={'47px'}>
                    <Skeleton
                        width={'200px'}
                        height={'60px'}
                        animation={'wave'}
                    />
                </Box>

                <Box height={'21px'}>
                    <Skeleton
                        width={'200px'}
                        height={'25px'}
                        animation={'wave'}
                    />
                </Box>
                <Stack
                    spacing={3}
                    alignItems={'center'}
                    direction={'row'}
                    display={'flex'}
                    height={'40px'}
                >
                    <Skeleton
                        width={'150px'}
                        height={'30px'}
                        animation={'wave'}
                    />

                    <Skeleton
                        width={'150px'}
                        height={'30px'}
                        animation={'wave'}
                    />
                </Stack>
                <Stack
                    spacing={3}
                    alignItems={'center'}
                    direction={'row'}
                    display={'flex'}
                    height={'40px'}
                >
                    <Skeleton
                        width={'200px'}
                        height={'60px'}
                        animation={'wave'}
                    />

                    <Skeleton
                        width={'200px'}
                        height={'60px'}
                        animation={'wave'}
                    />
                </Stack>
            </Stack>
        </>
    );
};

export default CustomLoadingSkeleton;
