import React from 'react';
import i18n from 'i18next';
import { AllHWTopic } from '../../../../constants/hw';

export default ({ data, value, code }) => {
    if (code === 'discount') {
        return value;
    }

    if (code === 'amount_rate' && value !== 0) {
        return `${value} %`;
    }

    if (code === 'topic') {
        return AllHWTopic.find((el) => +el.value === +value).label.replace(
            /\s/g,
            ''
        );
    }

    if (code === 'user' && !value)
        value = `${data.first_name} ${data.last_name}`;

    let type = {
        cash: i18n.t('payments.cash'),
        cashless: i18n.t('payments.cashless'),
        online: i18n.t('payments.cashless'),
    };

    const processLang = (data) => {
        let save = null;
        if (data && Object.keys(data).length) {
            for (let item in data) {
                if (data[item]) {
                    save = { text: data[item], lang: item };
                    break;
                }
            }
        }
        if (save && save.text)
            return `${save.text} (${i18n.t('modals.no_transation')})`;
        return `--${i18n.t('modals.no_transation')}--`;
    };

    const processString = (item, code) => {
        if (code === 'geo_names') item = JSON.parse(item);

        let lang = { ru: 0, ua: 1, en: 2, ro: 5, by: 0, ee: 2 };

        if (typeof item == 'string' && item.includes('|||')) {
            let text = item.split('|||')[lang[i18n.language]];
            if (!text) {
                for (let _item of item.split('|||')) {
                    if (_item) {
                        text = _item;
                        break;
                    }
                }
            }
            return text
                ? text.toString()
                : `--${i18n.t('modals.no_transation')}--`;
        }

        if (item && typeof item === 'object') {
            return item[i18n.language]
                ? item[i18n.language]
                : processLang(item);
        }

        return item.toString();
    };

    const width = code === 'key' ? '30px' : '150px';
    return value ? (
        <div
            style={{
                display: 'block',
                whiteSpace: 'nowrap',
                maxWidth: width,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            title={
                code === 'pay_type'
                    ? type[value.toString()]
                    : processString(value, code)
            }
            children={
                code === 'pay_type'
                    ? type[value.toString()]
                    : processString(value, code)
            }
        />
    ) : code === 'balance' ? (
        value.toLocaleString()
    ) : (
        '-'
    );
};
