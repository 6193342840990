import { ApiConnector, GET } from './request.service';

export default (() => {
    const API = ApiConnector('/finance');
    return {
        getShortFinanceData: async (data) =>
            await API(GET, '/franch/short', false, data).call(),
        getAllListCity: async (data, query) =>
            await API(GET, '', false, query).call(),
        citiesReport: async (data, query) =>
            await API(GET, '/long', false, query).call(),
        getBossFinanceData: async (data) =>
            await API(GET, '/boss/long', false, {
                ...data,
                size: 999999999,
            }).call(),
        getBossShortFinanceData: async (data) =>
            await API(GET, '/boss/short', false, {
                ...data,
                size: 999999999,
            }).call(),
        getFranchOutflow: async (data) =>
            await API(GET, '/outflow', false, {
                ...data,
                size: 999999999,
            }).call(),
        getKPITeacher: async (data) =>
            await API(GET, '/kpi-teacher', false, {
                ...data,
                size: 999999999,
            }).call(),
        getBossOutflow: async (data) =>
            await API(GET, '/outflow-franch', false, {
                ...data,
                size: 999999999,
            }).call(),
        getKPIBoss: async (data) =>
            await API(GET, '/kpi-franch', false, {
                ...data,
                size: 999999999,
            }).call(),
    };
})();
