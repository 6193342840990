import {ApiConnector, GET, POST, PUT, DELETE} from "./request.service";

export default (() => {
    const API = ApiConnector("/custom-task");
    return {
        CreateCustomTask: async (data) => await API(POST, "").call(data),
        CustomTasksList: async () => await API(GET, "", false).call(),
        GetCustomTaskById: async (data) => await API(GET, `${data}`).call(),
        GetCustomTaskInfo: async (id, query) => await API(GET, `/${id}/info`, false, query).call(),
        DeleteCustomTemplate:  async (query) => await API(DELETE, "", false, query).call(),
        EditCustomTemplate: async (data) => await API(PUT, "").call(data),
    };
})();
