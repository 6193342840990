import React from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import NativeSelect from "@material-ui/core/NativeSelect";
// import { changeStudentGroup } from "./../../../../../actions/getUsers";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
const renderTextField = ({
  input,
  meta: { touched, error },
  i18n,
  ...custom
}) => {
  // eslint-disable-next-line
  const days_schema = {
    "0": "mon",
    "1": "tue",
    "2": "wed",
    "3": "thu",
    "4": "fri",
    "5": "sat",
    "6": "sun",
  };
  return (
    <NativeSelect {...input} {...custom}>
      <option value="">{i18n.t("filter.choose_day")}</option>
      {Object.keys(days_schema).map((item, key) => {
        return (
          <option key={key} value={item}>
            {i18n.t(`groups.min.${days_schema[item]}`)}
          </option>
        );
      })}
    </NativeSelect>
  );
  // ChangeStudentGroup
};

export default ({ label, code }) => {
  const { i18n } = useTranslation();
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="h2">
          {i18n.t(label)}
        </Typography>
        <Field
          name="lesson_day"
          i18n={i18n}
          component={renderTextField}
          type="text"
        />
      </CardContent>
    </Card>
  );
};
