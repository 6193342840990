import { useState, useEffect } from 'react';
import { detectPhone } from '../actions/phone';
import { setUserDevice } from '../actions/user';
import { useDispatch, useSelector } from 'react-redux';

const usePhoneCheck = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();
    const { device } = useSelector((state) => state.user);

    useEffect(() => {
        let timeoutId;

        const handleResize = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                const newWidth = window.innerWidth;
                if (newWidth !== windowWidth) {
                    setWindowWidth(newWidth);
                    const isDeviceMobile = detectPhone();
                    if (device !== isDeviceMobile) {
                        dispatch(setUserDevice(isDeviceMobile));
                    }
                }
            }, 50);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(timeoutId);
        };
    }, [windowWidth]);

    useEffect(() => {
        const isDeviceMobile = detectPhone();
        if (device !== isDeviceMobile) {
            dispatch(setUserDevice(isDeviceMobile));
        }
    }, []);

    return device;
};

export default usePhoneCheck;
