import { ApiConnector, GET, POST, DELETE } from "./request.service";

export default (() => {
  const API = ApiConnector("/homework");
  return {
    HomeworkList: async (data, query) =>
      await API(GET, "", false, query).call(false),
    HomeworkListTeacher: async (data, query) =>
      await API(GET, "/teacher", false, query).call(false),
    HomeworkListTeacherCalendar: async (data, query) =>
      await API(GET, "/teacher/calendar", false, query).call(false),
    getTemplatesName: async (data) => await API(GET, `/templates`).call(false),
    saveModification: async (data) => await API(POST, `/templates`).call(data),
    saveGroupHW: async (data, query) =>
      await API(POST, ``, false, query).call(data),
    anull: async (data, query) =>
      await API(DELETE, ``, false, query).call(data),
    deleteHw: async (data, query) =>
      await API(DELETE, `/templates`, false, query).call(data),
    deleteHwTeacher: async (data, query) =>
      await API(DELETE, `/teacher`, false, query).call(data),
    copyLastDay: async (data) =>
      await API(POST, `/templates/copyDay`).call(data),
    copyTemplate: async (data) =>
      await API(POST, `/templates/copyTemplate`).call(data),
    createNewHw: async (data, query) =>
      await API(POST, `/week`, false, query).call(data),
    callAllStat: async (data, query) =>
      await API(GET, "/timestat/student", false, query).call(false),
    notification: async (data, query) =>
      await API(POST, `/notification`, false, query).call(data),
    copyHw: async (data) =>
      await API(POST, `/teacher/copyhw`, false, false).call(data),
    copyHwOtherGroup: async (data) =>
      await API(POST, `/teacher/copyhw/othergroup`, false, false).call(data),
    GetAvailibleDates: async (query) =>
      await API(GET, `/student/getdaysnotactive`, false, query).call(),
    CopyStudentHw: async (data) =>
      await API(POST, `/teacher/copyhw/student`, false, false).call(data),
    GetSequance: async (data) =>
      await API(POST, `/getSequance`, false, false).call(data),
    GetMultStat: async (query) =>
      await API(GET, `/timestat/student/mult`, false, query).call(),
    GetPointStat: async (query) =>
      await API(GET, `/timestat/student/point`, false, query).call(),
    GetHwProgress: async (query) =>
      await API(GET, `/timestat/student/progress`, false, query).call(),
    CopyOtherStudents: async (data) =>
      await API(POST, `/teacher/copyhw/otherstudents`, false, false).call(data),
  };
})();
