import Types from "./../constants/books";

const defaultState = {
    type_book: "K",
    count_book: 1,
    uid: null
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.CHANGE:
            return { ...state, ...action.books };
        default:
            return state;
    }
};
