import { ApiConnector, GET, POST } from './bank-request.service';

export default (() => {
    const API = ApiConnector('/customers');
    return {
        GetCheckExist: async () => await API(GET, '/check-exist').call(),
        GetProfile: async () => await API(GET, '/get-profile').call(),
        GetPaymentInfo: async (query) =>
            await API(GET, `/get-payment-info?id=${query}`).call(),
        Registration: async () => await API(POST, '/registration').call(),
        // Все зарегистрированные студенты группы
        // RegisteredGroupStudents: async (query) =>
        //     await API(GET, `/group?courseId=${query}`).call(),
        GetGroupActiveStudents: async (query) =>
            await API(GET, '/group', false, query).call(false, true),
        GetGroupVipStudents: async (query) =>
            await API(GET, '/group/vip', false, query).call(false, true),
        GetInformationStudentsBalances: async () => await API(GET, '/students/balances').call(),
        GetInformationTeachersBalances: async () => await API(GET, '/teachers/balances').call(),
        GetInformationCitiesBalances: async () => await API(GET, '/cities/balances').call(),
        GetVipDiscount: async () => await API(GET, '/vip').call(),
        AddVipDiscount: async (data) => await API(POST, '/vip').call(data),
        SetVipStatus: async (data) => await API(POST, '/vip/student').call(data),
        // VipGroupStudents: async (query) => await API(GET, `/group/vip?courseId=${query}`).call(),
    };
})();


