import React from "react";
import Button from "@material-ui/core/Button";
import { openRequestModal } from "./../../../../actions/modals";
import store from "./../../../../store";
import i18n from "i18next";

export default ({ id, value }) => {
  let title = "";
  for (let i in value.dataSet) {
    const d = new Date(i);
    const _month =
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
    const _date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    title += `${_date}.${_month}.${d.getFullYear()} - ${value.dataSet[i]}%\n`;
  }
  const table =
    store.getState().AsyncTable &&
    store.getState().AsyncTable.table &&
    store.getState().AsyncTable.table.data;
  const user = table.find((el) => el.id === id);
  return value ? (
    <Button
      title={title}
      color="inherit"
      className="single_group_btn"
      onClick={() => {
        openRequestModal({
          opened: true,
          data: {
            id: id,
            header: `${i18n.t("groups.stat")} | ${user.first_name} ${
              user.last_name
            }`,
            modaltype: "statisticsData",
          },
        })(store.dispatch, store.getState);
      }}
      variant="contained"
    >
      {value.value}
    </Button>
  ) : (
    "-"
  );
};
