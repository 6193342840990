import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import i18n from "i18next";
import { Button, IconButton, Menu, Typography } from "@mui/material";
import "flag-icon-css/css/flag-icons.css";

const LanguageChange = () => {
  const handeLangSwitch = (value) => {
    i18n.changeLanguage(value);
    localStorage.setItem("lang", value);
    setAnchorEl(null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="language_item">
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ padding: "8px" }}
      >
        <Typography>{i18n.language.toUpperCase()}</Typography>
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {Object.keys(i18n.store.data).map((el, index) => {
          return (
            <MenuItem
              key={index}
              value={el}
              onClick={() => handeLangSwitch(el)}
            >
              <Typography>{i18n.t(`offices.${el}`)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default LanguageChange;
