import React, { Fragment } from 'react';
import DiscountsService from './../../services/discounts.service';
import ModelService from './../../services/models.service';
import { connect } from 'react-redux';
import { closeRequestModal, openRequestModal } from '../../actions/modals';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Delete } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ComponentTable from '../../components/ComponentTable';
import { editUser } from './../../actions/getUsers';
import { discountStatus, deleteDiscount } from './../../actions/discounts';
import StudentsService from './../../services/students.service';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class SalesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            refer: [0],
            family: [0],
        };
    }
    componentDidMount() {
        this.getDiscounts();
    }

    async getDiscounts() {
        const refer = await StudentsService.GetReferDiscounts();
        const family = await StudentsService.GetFamilyDiscount();
        this.setState({
            refer: refer.list,
            family: family.list,
            loading: false,
        });
    }

    renderTableIssued = (item, data) => {
        const { t } = this.props;
        return item.map((item, index) => {
            return (
                <TableRow key={index + 1}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{item.first_name}</TableCell>
                    <TableCell>{item.last_name}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>
                        <Tooltip placement="top" title={t('sales.accept')}>
                            <IconButton
                                onClick={() =>
                                    this.handleDiscount(true, {
                                        item,
                                        to: data,
                                    })
                                }
                            >
                                <CheckCircleIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip placement="top" title={t('sales.delete')}>
                            <IconButton
                                onClick={() =>
                                    this.handleDiscount(false, {
                                        item,
                                        to: data,
                                    })
                                }
                            >
                                <Delete fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            );
        });
    };

    handleDiscount(type, data) {
        const schema = {
            refer_discounts: {
                true: 'referer_approved',
                false: 'referer',
            },
            family_discounts: {
                true: 'family_approved',
                false: 'family_id',
            },
        };
        this.props.editUser({
            id: data.item.id,
            [schema[data.to][JSON.stringify(type)]]: type
                ? 1
                : data.to === 'refer_discounts'
                ? 0
                : +data.item.id,
        });
        this.getDiscounts();
    }
    render() {
        const { t } = this.props;
        const role_id = this.props.user.emulation.length
            ? this.props.user.emulation[this.props.user.emulation.length - 1]
                  .info.role_id
            : this.props.user.info.role_id;
        const actions = [
            {
                title: t('sales.accept'),
                onClick: (id) => {
                    this.props.discountStatus({ approved: true, id });
                },
                Icon: CheckCircleIcon,
            },
            {
                title: t('sales.delete'),
                onClick: (id) => {
                    this.props.deleteDiscount(false, { discount_id: id });
                },
                Icon: Delete,
            },
        ];
        const discounts_card = [
            { list: this.state.refer, text: 'refer_discounts' },
            { list: this.state.family, text: 'family_discounts' },
        ];
        return (
            <Fragment>
                <h2 className="container-header">{t('sales.title')} </h2>
                <ComponentTable
                    source={{
                        getData: (page, size, sort, filter) =>
                            DiscountsService.DiscountsList(false, {
                                role_id: +role_id,
                                page: page || 0,
                                size: size || 10,
                                orderBy: (sort && sort.orderBy) || null,
                                order: (sort && sort.order) || null,
                                filter,
                            }),
                        getModel: () => ModelService.DiscountsList(),
                    }}
                    actions={actions}
                    selectable={true}
                    title={t('sales.title')}
                    toolbarvisible={true}
                    enableFilter={true}
                />
                {discounts_card.map((el, index) => {
                    return (
                        <Card
                            className="not_usual_dis"
                            key={index}
                            variant="outlined"
                        >
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    {t(`sales.${el.text}`)}
                                </Typography>
                                <TableContainer>
                                    <Table style={{ width: '100%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>№</TableCell>
                                                <TableCell>
                                                    {t('table.first_name')}
                                                </TableCell>
                                                <TableCell>
                                                    {t('table.last_name')}
                                                </TableCell>
                                                <TableCell
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    {t('table.actions')}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.renderTableIssued(
                                                el.list,
                                                el.text
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Card>
                    );
                })}
            </Fragment>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        user: state.user,
        modalWindow: state.modalWindow,
        AsyncTable: state.AsyncTable.table.data,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                openRequestModal,
                closeRequestModal,
                discountStatus,
                deleteDiscount,
                editUser,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(SalesPage));
