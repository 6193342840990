import React, { useEffect, useRef } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

export default ({ action, input, type, meta: { touched, error }, label }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        // Focus on the input field when it's initially rendered
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <FormControl
            error={Boolean(touched && error)}
            variant="filled"
            fullWidth
        >
            <InputLabel>{label}</InputLabel>
            <Input
                {...input}
                variant="filled"
                type={type}
                endAdornment={action}
                ref={inputRef}
            />
            {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};
