import React from "react";
import Downshift from "downshift";
import classnames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styled = withStyles((theme) => ({
  container: {
    position: "absolute",
    zIndex: 1,
    width: "100%",
  },
  containerTop: {
    bottom: theme.spacing(8),
  },
  item: {
    textTransform: "capitalize",
  },
  zIndex: {
    position: "relative",
    zIndex: 1,
  },
}));

class AutocomplateView extends React.Component {
  onChange = (data) => {
    const { onChange } = this.props.input;
    onChange(data && JSON.stringify(data));
  };

  itemToString = (item) => (item ? item.name : "");

  inputFilter = (val) => (item) =>
    !val || item.name.match(new RegExp(val, "gi"));

  AutocompleteBody = (props) => {
    const {
      classes,
      data,
      meta: { touched, error },
      required,
      fullWidth,
      disabled,
      positionTop,
      action,
    } = this.props;
    const { getInputProps, getItemProps, isOpen, inputValue } = props;
    const filteredItems = data.filter(this.inputFilter(inputValue)).slice(0, 5);

    return (
      <section className={classes.zIndex}>
        <FormControl
          required={required}
          error={touched && !!error}
          fullWidth={fullWidth}
          disabled={disabled}
        >
          <Input {...getInputProps()} endAdornment={action} />
          {touched && error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>

        {isOpen ? (
          <Paper
            className={classnames(classes.container, {
              [classes.containerTop]: positionTop,
            })}
          >
            <List component="nav">
              {filteredItems.length ? (
                filteredItems.map((item, key) => (
                  <ListItem button {...getItemProps({ key, item })}>
                    <ListItemText
                      className={classes.item}
                      primary={item.name}
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText secondary="Не найдено" />
                </ListItem>
              )}
            </List>
          </Paper>
        ) : null}
      </section>
    );
  };

  render() {
    const { value } = this.props.input;

    return (
      <Downshift
        onChange={this.onChange}
        itemToString={this.itemToString}
        selectedItem={value && JSON.parse(value)}
        children={this.AutocompleteBody}
      />
    );
  }
}

export default styled(AutocomplateView);
