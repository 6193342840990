import Types from './../constants/getList';
import TypesGroups from '../constants/groups';

const defaultState = {
    list: null,
    filtration: {
        index: null,
        teacher: null,
        city: null,
        office: null,
    },
    officeGroups: null,
    loading: false,
    lastTopic: null,
    acceptExitHwChange: false,
    single: null,
    select: null,
    teacher_select: {
        teacher: null,
    },
    priceError: null,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case Types.FILTRATION:
        case Types.GROUPS:
            return { ...state, ...action.groups };
        case TypesGroups.SET_PRICE_ERROR:
            return { ...state, priceError: action.payload };
        default:
            return state;
    }
};
