import React from 'react';
import NativeSelect from '@material-ui/core/NativeSelect';
import store from './../../../../store';
import { changeStudentStatus } from '../../../../actions/getUsers';
import { useTranslation } from 'react-i18next';
import {
    RESTRICTED_STATUSES_SCHEMA,
    USER_STATUSES,
    USER_STATUSES_TEACHER,
} from '../../../../constants/statuses';
import { Field } from 'redux-form';
import { openRequestModal } from '../../../../actions/modals';
import { getPaymentsHistory } from '../../../../actions/payments';
import { getErrorNotification } from '../../../../actions/toast';
import { reloadTable } from '../../../../actions/AsyncTable';

const processStatuses = (status, roleId, row) => {
    const { role_id: rowRoleId } = row;
    if (rowRoleId === 4) {
        return USER_STATUSES_TEACHER;
    }
    if (roleId <= 2) {
        return USER_STATUSES;
    }
    return RESTRICTED_STATUSES_SCHEMA[status];
};

const renderTextField = React.memo(({ input, i18n, roleId, ...custom }) => {
    const value = Number(input.value);
    const array = processStatuses(value, roleId, custom.row);
    return (
        <NativeSelect {...input} {...custom} style={{ width: '100px' }}>
            {array.map((item) => (
                <option
                    key={item.id}
                    value={item.id}
                    disabled={
                        (value === 2 || value === 3) && item.id === 6
                            ? false
                            : item.disabled
                    }
                >
                    {i18n.t('users.' + item.title)}
                </option>
            ))}
        </NativeSelect>
    );
});

const handleChangeStatus = (code, id, event) => {
    changeStudentStatus(JSON.stringify({ code, id }), event.target.value)(
        store.dispatch,
        store.getState
    );
};

export default React.memo(({ code, id, type, roleId, data }) => {
    const { i18n } = useTranslation();
    return (
        <Field
            name={JSON.stringify({ code, id })}
            onChange={async (event) => {
                const { role_id, status, balance } = data;
                const numberValue = Number(event.target.value);
                if (role_id === 5 && numberValue === 4) {
                    const payments = await getPaymentsHistory(false, {
                        user_id: id,
                        size: 9999,
                    })(store.dispatch, store.getState);
                    const isChargeReg = payments.findIndex(
                        (el) => el.tran_type === 'charge_reg'
                    );
                    if (isChargeReg === -1) {
                        getErrorNotification(
                            i18n.t('error.ChargeRegIsNotPresented')
                        );
                        reloadTable()(store.dispatch, store.getState);
                        return;
                    }
                }
                if (
                    role_id === 5 &&
                    status === 1 &&
                    balance < 0 &&
                    numberValue === 0
                ) {
                    event.preventDefault();
                    const { first_name, last_name } = data;
                    openRequestModal({
                        opened: true,
                        data: {
                            id,
                            header: `${i18n.t(
                                'payments.student_activation'
                            )} | ${first_name} ${last_name}`,
                            user: data,
                            modaltype: 'ConfirmUnblockModal',
                        },
                    })(store.dispatch, store.getState);
                    return;
                }
                handleChangeStatus(code, id, event);
            }}
            i18n={i18n}
            component={renderTextField}
            data={type}
            type="text"
            roleId={roleId}
            row={data}
        />
    );
});
