import { compose, combineReducers, createStore, applyMiddleware } from "redux";
import rootReducer from "./reducers";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import history from "./history";

const loadState = () => {
  try {
    const serializedState = localStorage.getItem("globalStore");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (ex) {
    return undefined;
  }
};
const persistedState = loadState();
if (persistedState && persistedState.user && !persistedState.user.theme) {
  Object.assign(persistedState.user, { theme: "white" });
} else if (persistedState && persistedState.user) {
  Object.assign(persistedState.user, {
    isRefreshTokenUpdateProcess: false,
    isEmulationTokenUpdateProcess: false,
  });
}

const _compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    ...rootReducer,
    router: connectRouter(history),
    form: formReducer,
  }),
  persistedState,
  _compose(applyMiddleware(routerMiddleware(history)), applyMiddleware(thunk))
);

export default store;
