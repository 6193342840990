import React from "react";
import i18n from "i18next";
export default ({ value }) => {
  const schema = {
    ee: "ru",
    ro: "md",
  };
  if (typeof value === "string") value = JSON.parse(value);
  let language = value[i18n.language];
  if (!language) language = value[schema[i18n.language]];
  if (!language) language = Object.values(value).filter(Boolean)[0];


  return <span>{language || `(${i18n.t("modals.no_transation")})`}</span>;
};
