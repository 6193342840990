import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MenuItem from '@material-ui/core/MenuItem';
import NativeSelect from '@material-ui/core/NativeSelect';
import TextField from '@material-ui/core/TextField';
import store from './../../../../../store';
import { useTranslation } from 'react-i18next';
import { Field } from 'redux-form';
import {
    getTeachersForSupport,
} from '../../../../../actions/groups';
import {
    Box,
    Button,
    CircularProgress,
    Collapse,
    FormControl,
    IconButton,
    Input,
    InputLabel,
    Select,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ClearIcon from '@material-ui/icons/Clear';

const renderTextField = ({
    input,
    meta: { touched, error },
    i18n,
    teacher,
    loading,
    search,
    selectRef,
    clearSearch,
    ...custom
}) => {
    if (!teacher || !teacher.length) return null;
    // eslint-disable-next-line
    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
                Оберіть вчителя
            </InputLabel>
            <Select
                {...input}
                {...custom}
                labelId="teacher_select"
                id="teacher_select"
                endAdornment={
                    <IconButton onClick={clearSearch}>
                        <ClearIcon style={{ marginRight: '20px' }} />
                    </IconButton>
                }
                disabled={loading}
                variant={'outlined'}
                ref={selectRef} // Assign the ref to the Select component
            >
                {(teacher &&
                    teacher.map((item, key) => {
                        return (
                            <MenuItem key={key} value={`${item.id}`}>
                                {`${item.last_name} ${item.first_name}`}
                            </MenuItem>
                        );
                    })) ||
                    null}
            </Select>
        </FormControl>
    );
    // ChangeStudentGroup
};

export default ({ label }) => {
    return <>123</>;

    const selectRef = useRef(null);

    const { i18n } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [searching, setSearching] = useState(false);

    const [search, setSearch] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [noTeacherFound, setNoTeacherFound] = useState(false);
    const selectedTeacher = useSelector(
        (state) => state.groups.teacher_select.teacher
    );

    const fetchData = useCallback(async () => {
        setLoading(true);
        await getTeachersForSupport()(store.dispatch, store.getState);
        setLoading(false);
    }, [store.dispatch, store.getState, setLoading]);

    const teachersSearch = useMemo(() => {
        if (!selectedTeacher || !searchValue) {
            return [];
        }
        const data = selectedTeacher.filter((item) => {
            const toSearched = searchValue.toLowerCase().replaceAll(' ', '');
            const teacherName = `${item.first_name}${item.last_name}`
                .toLowerCase()
                .replaceAll(' ', '');
            return teacherName.includes(toSearched);
        });
        if (!data.length) {
            setNoTeacherFound(true);
        }
        return data;
    }, [searchValue, selectedTeacher]);

    const handleSearchInput = useCallback(
        (e) => {
            setSearch(e.target.value);
        },
        [setSearch]
    );

    const handleSearch = useCallback(
        async (event) => {
            setSearchValue('');
            setNoTeacherFound(false);
            setSearching(true);
            event.preventDefault();
            await fetchData();
            setSearchValue(search);
            setSearching(false);
        },
        [search, setSearchValue, fetchData]
    );

    const clearSearch = () => {
        setSearchValue('');
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('handleSubmit');
    };

    return (
        <Card>
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <Typography gutterBottom variant="h6" component="h2">
                        {i18n.t(label)}
                    </Typography>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <TextField
                            label={'Пошук вчителя'}
                            value={search}
                            onChange={handleSearchInput}
                            variant={'outlined'}
                            helperText={
                                (noTeacherFound && 'Вчителя не знайдено') ||
                                (Boolean(teachersSearch.length) &&
                                    `Було знайдено ${teachersSearch.length} вчителів`)
                            }
                        />
                        <Button
                            variant={'outlined'}
                            type="submit"
                            onClick={handleSearch}
                            fullWidth
                            style={{ margin: '20px 0px' }}
                            endIcon={
                                searching ? (
                                    <CircularProgress size={15} />
                                ) : null
                            }
                        >
                            Пошук
                        </Button>
                    </div>
                </form>
                <Field
                    name="teacher"
                    i18n={i18n}
                    component={renderTextField}
                    type="text"
                    teacher={teachersSearch}
                    loading={loading}
                    selectRef={selectRef}
                    clearSearch={clearSearch}
                />
            </CardContent>
        </Card>
    );
};
