import React from "react";
import NativeSelect from "@material-ui/core/NativeSelect";
import { Field } from "redux-form";
import { useTranslation } from "react-i18next";

let schemaGame = [
  {
    label: "no",
    value: -1,
  },
  {
    label: "groups.junior",
    value: 0,
  },
  {
    label: "groups.senior",
    value: 1,
  },
];

const renderSelectField = ({
  input,
  meta: { touched, error },
  i18n,
  value,
  ...custom
}) => {
  if (typeof value === "number") {
    delete schemaGame[0];
  }
  return (
    <NativeSelect {...input} {...custom} defaultValue={value || -1} style={{ minWidth: "50px" }}>
      {schemaGame.filter(Boolean).map((item, key) => {
        return (
          <option key={key} value={item.value}>
            {i18n.t(item.label === "no" ? "table.no" : item.label)}
          </option>
        );
      })}
    </NativeSelect>
  );
};

export default ({ value, code, id, temporarily }) => {
  const { i18n } = useTranslation();
  return (
    <Field
      name={JSON.stringify({ code, id, temporarily })}
      i18n={i18n}
      value={value}
      component={renderSelectField}
      type="text"
    />
  );
};
