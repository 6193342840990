import React from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";

export default ({ value, id }) => {
  const { i18n } = useTranslation();
  let notTranslation = "";
  if (value && typeof value == "object" && !value[i18n.language]) {
    for (let i in value) {
      if (!!value[i]) notTranslation = value[i] + " (no translation)";
      break;
    }
  }
  const title =
    value && typeof value == "object"
      ? value[i18n.language]
        ? value[i18n.language]
        : notTranslation
      : value;
  return (
    <Link to={"/groups/" + id} name={title}>
      <Button
        variant="contained"
        color="inherit"
        style={{ width: "100px", textTransform: "unset" }}
        className="single_group_btn"
      >
        {title}
      </Button>
    </Link>
  );
};
