import {ApiConnector, GET, POST, DELETE} from "./requestV2.service";

export default (() => {
    const API = ApiConnector("/offices");
    return {
        OfficesList: async (data, query) => await API(GET, "", false, query).call(data),
        AddOffice: async data => await API(POST, "").call(data),
        SingleOffice: async (data, query) => await API(GET, "/single", false, query).call(data),
        EditOffice: async (data, query) => await API(POST, "/edit", false, query).call(data),
        DeleteOffice: async (query) => await API(DELETE, "", false, query).call(query),
    };
})();
