import React, {Fragment} from 'react';
import {connect} from "react-redux";
import {closeRequestModal, openRequestModal} from "../../actions/modals";
import {bindActionCreators} from "redux";



class RegionsPage extends React.Component{

    render() {



        return (
            <Fragment>
                <h2 className="container-header"> Областi </h2>

            </Fragment>
        )



    }

}

const widthConnect = connect(state => ({ user: state.user, modalWindow:state.modalWindow}),
    dispatch => bindActionCreators({ openRequestModal, closeRequestModal }, dispatch));

export default widthConnect(RegionsPage);


