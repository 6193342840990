import React, { useMemo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Tooltip,
  Button,
  Stack,
  Typography,
  IconButton,
} from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { showCurrentStatus } from '../../../actions/user';
import DecimalInput from '../Table/TableBodyCells/DecimalInput'
import IntegerInput from './TableBodyCells/IntegerInput';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';


function renderTableCell({
  row,
  headCell,
  handleQuantityChange,
  handleBankAccountClick,
  emulation,
  roleId,
  isSelected,
  roundToTwoDecimals,
  labelId,
  t,
  i18n,
  dataSetTitle,
  handleStudentStatistics,
  studentStatId,
  setStudentStatId,
  renderAccruedForInput,
  handleAccruedForChange,
  handleClick,
  handleOpenEditQuantityModal,
  handleOpenEditProductModal,
  onDeleteProductClick,
  isRowHidden,
  onVisibilityOfProductClick,
}) {

  if (
    headCell.id === 'teacher' &&
    ((roleId === 2 && emulation.length === 1) || (roleId === 3 && !emulation.length))
  ) {
    return (
      <TableCell align="left">
        {row.teacher}
      </TableCell>
    );
  } else if (headCell.id === 'teacher') {
    return null;
  };

  if (headCell.type === 'displayOrder') {
    if (roleId === 3 && !emulation.length) {
      return (
        <TableCell
          key={headCell.id}
          align="left"
          padding={headCell.disablePadding ? 'none' : 'normal'}
        >
          <Typography>{t(row.promo)}</Typography>
        </TableCell>
      );
    }
    return null; 
  };

  if (headCell.type === 'options') {
    if (roleId === 3 && !emulation.length) {
      return (
        <TableCell padding="checkbox">
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mr: '10px',
            }}
          >
            <Tooltip
              title={t(
                'storefrontComponent.editProductQuantity'
              )}
            >
              <IconButton
                onClick={() =>
                  handleOpenEditQuantityModal(
                    row.id
                  )
                }
              >
                <ProductionQuantityLimitsIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(
                'storefrontComponent.editProduct'
              )}
            >
              <IconButton
                onClick={() =>
                  handleOpenEditProductModal(
                    row.id
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t(
                'storefrontComponent.deleteProduct'
              )}
            >
              <IconButton
                onClick={() =>
                  onDeleteProductClick(
                    row.id
                  )
                }
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                isRowHidden
                  ? t(
                    'storefrontComponent.productHidden'
                  )
                  : t(
                    'storefrontComponent.visibleProduct'
                  )
              }
            >
              <IconButton
                onClick={() =>
                  onVisibilityOfProductClick(
                    row.id
                  )
                }
              >
                {isRowHidden ? (
                  <VisibilityOffIcon
                    style={{
                      color: 'red',
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    style={{
                      color: 'green',
                    }}
                  />
                )}
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      );
    }
    return null; 
  };

  switch (headCell.type) {
    case 'string':
      return (
        <TableCell align="left">
          {row[headCell.id]}
        </TableCell>
      );
    case 'group':
      return (
        <TableCell align="left">
          {typeof row.group === 'object'
            ? (Object.values(row.group).find(value => value !== null && value !== undefined && value !== '') || '')
            : (row.group || '')}
        </TableCell>
      );
    case 'status':
      return (
        <TableCell align="left">
          {showCurrentStatus(row.status, { t, i18n })}
        </TableCell>
      );
    case 'decimalInput':
      return (
        <TableCell align="left">     
          <DecimalInput
            id={row.rowId}
            quantity={row.quantity}
            onChange={handleQuantityChange}
            isSelected={isSelected}
            t={t}
          />
        </TableCell>
      );
    case 'integerInput':
      return (
        <TableCell align="left">   
          <IntegerInput
            id={row.rowId}
            quantity={row.quantity}
            onChange={handleQuantityChange}
            isSelected={isSelected}
            t={t}
          />
        </TableCell>
      );
    case 'comment':
      return (
        <TableCell align="left">
          {renderAccruedForInput(
            row.rowId,
            row.accruedFor,
            handleAccruedForChange
          )}
        </TableCell>
      );
    case 'statistics':
      return (
        <TableCell align="left">
          <Button
            onClick={() => {
              setStudentStatId(
                null
              );
              setTimeout(() => {
                handleStudentStatistics(
                  row.id,
                  `${row.name} ${row.surname}`
                );
              }, 200);
            }}
            title={dataSetTitle}
            color="inherit"
            style={{
              backgroundColor:
                studentStatId &&
                  studentStatId.id === row.id
                  ? '#00a10c'
                  : 'inherit',
              color:
                studentStatId &&
                  studentStatId.id === row.id
                  ? '#fff'
                  : 'inherit',
            }}
            className="single_group_btn"
            variant="contained"
          >
            {row.days_stat.value}
          </Button>
        </TableCell>
      );
    case 'image':
      return (
        <TableCell
          id={labelId}
          scope="row"
          padding="none"
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ margin: '5px' }}
          >
            <img
              width="50"
              src={row.img}
              alt={row.productName}
            ></img>
            <Typography
              variant="body2"
              color="text.secondary"
            >
              {row.productName}
            </Typography>
          </Stack>
        </TableCell>
      );
    case 'img':
      return (
        <TableCell
          align="left"
          style={{
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <img
            src={row.img}
            alt="Product Image"
            style={{
              width: '70px',
              height: '70px',
              borderRadius: '7px',
              objectFit: 'cover',
            }}
          />
        </TableCell>
      );
    case 'sum':
      return (
        <TableCell align="left">
          {`${roundToTwoDecimals(row.price * row.quantity)} SBK`}
        </TableCell>
      );    
    case 'link':
      return (
        <TableCell align="left">
          <Tooltip title="SOROBANK" placement="bottom">
            <Button
              onClick={() => handleBankAccountClick(row.rowId)}
              style={{ height: '40px', borderRadius: '20px', fontSize: '14px', color: '#58a315' }}
              aria-label="SOROBANK"
              startIcon={<AccountBalanceIcon style={{ color: '#58a315' }} />}
            >
              SOROBANK
            </Button>
          </Tooltip>
        </TableCell>
      );
    case 'checkbox':
      return (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            onClick={(event) => handleClick(event, row.rowId)}
            checked={isSelected(row.rowId)}
            inputProps={{ 'aria-labelledby': labelId }}
          />
        </TableCell>
      );
    case 'amount':
      return (
        <TableCell align="left">
          {row.amount || 0}
        </TableCell>
      );
    case 'vip':
      return (
        <TableCell align="left">
          {+row.price !== +row.vip ? row.vip : null}
        </TableCell>
      );
    default:
      return (
        <TableCell align="left">
          {row[headCell.id]}
        </TableCell>
      );
  }
};


export default function BankTableBody(props) {
  const {
    rows,
    headCells,
    isSelected,
    handleClick,
    handleQuantityChange,
    handleBankAccountClick,
    emulation,
    roleId,
    roundToTwoDecimals,
    studentStatId,
    setStudentStatId,
    handleStudentStatistics,
    renderAccruedForInput,
    handleAccruedForChange,
    handleOpenEditQuantityModal,
    handleOpenEditProductModal,
    onDeleteProductClick,
    hiddenRows,
    onVisibilityOfProductClick,
    order,  
    orderBy, 
  } = props; 

  const { t, i18n } = useTranslation();

  const [visibleRows, setVisibleRows] = useState(rows);

  useEffect(() => {
    setVisibleRows(rows);
  }, [rows]);

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => {
        const valA = a[orderBy];
        const valB = b[orderBy];

        // Якщо сортуємо за статусом, використовуємо функцію showCurrentStatus
        if (orderBy === 'status') {
          const numA = showCurrentStatus(parseFloat(valA), { t, i18n });
          const numB = showCurrentStatus(parseFloat(valB), { t, i18n });
          return numB.localeCompare(numA); // Сортування по спаданню
        }
      
        // Якщо сортуємо за групою, використовуємо обчислення для group
        if (orderBy === 'group') {
          const groupA = typeof a.group === 'object'
            ? (Object.values(a.group).find(value => value !== null && value !== undefined && value !== '') || '')
            : (a.group || '');
          const groupB = typeof b.group === 'object'
            ? (Object.values(b.group).find(value => value !== null && value !== undefined && value !== '') || '')
            : (b.group || '');

          // Порівнюємо рядки, ігноруючи регістр і неалфавітні символи
          const cleanGroupA = groupA.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').toLowerCase();
          const cleanGroupB = groupB.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').toLowerCase();

          return cleanGroupB.localeCompare(cleanGroupA);
        }
      
        // Якщо сортуємо за сумою покупки в кошику, обчислюємо загальну суму
        if (orderBy === 'sum') {
          const sumA = roundToTwoDecimals(a.price * a.quantity);
          const sumB = roundToTwoDecimals(b.price * b.quantity);
          return sumB - sumA;
        }

        const numA = parseFloat(valA);
        const numB = parseFloat(valB);

        if (!isNaN(numA) && !isNaN(numB)) {
          return numB - numA;
        }

        return valB.localeCompare(valA, undefined, { sensitivity: 'base', numeric: true });
      }
      : (a, b) => {
        const valA = a[orderBy];
        const valB = b[orderBy];

        if (orderBy === 'status') {
          const numA = showCurrentStatus(parseFloat(valA), { t, i18n });
          const numB = showCurrentStatus(parseFloat(valB), { t, i18n });
          return numA.localeCompare(numB); 
        }
      
        if (orderBy === 'group') {
          const groupA = typeof a.group === 'object'
            ? (Object.values(a.group).find(value => value !== null && value !== undefined && value !== '') || '')
            : (a.group || '');
          const groupB = typeof b.group === 'object'
            ? (Object.values(b.group).find(value => value !== null && value !== undefined && value !== '') || '')
            : (b.group || '');

          const cleanGroupA = groupA.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').toLowerCase();
          const cleanGroupB = groupB.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').toLowerCase();

          return cleanGroupA.localeCompare(cleanGroupB);
        }
      
        if (orderBy === 'sum') {
          const sumA = roundToTwoDecimals(a.price * a.quantity);
          const sumB = roundToTwoDecimals(b.price * b.quantity);
          return sumA - sumB;
        }

        const numA = parseFloat(valA);
        const numB = parseFloat(valB);

        if (!isNaN(numA) && !isNaN(numB)) {
          return numA - numB;
        }

        return valA.localeCompare(valB, undefined, { sensitivity: 'base', numeric: true });
      };
  };
  
  // Сортуємо рядки, якщо сортування активне
  const sortedRows = useMemo(() => {
    if (order && orderBy) {
      return [...visibleRows].sort(getComparator(order, orderBy));
    }
    return visibleRows; // Якщо сортування неактивне, повертаємо просто rows
  }, [visibleRows, order, orderBy]);


  return (
    <TableBody>
      {sortedRows.map((row, index) => {
        const isRowHidden = hiddenRows
          ? hiddenRows.map((item) => item.id).includes(row.id)
          : null;
        const isItemSelected = isSelected ? isSelected(row.rowId) : null;
        const labelId = `enhanced-table-checkbox-${index}`;
        let dataSetTitle = '';

        if (row.days_stat) {
          for (let i in row.days_stat.dataSet) {
            const d = new Date(i);
            const _month =
              d.getMonth() + 1 < 10
                ? '0' + (d.getMonth() + 1)
                : d.getMonth() + 1;
            const _date =
              d.getDate() < 10
                ? '0' + d.getDate()
                : d.getDate();
            dataSetTitle += `${_date}.${_month}.${d.getFullYear()} - ${row.days_stat.dataSet[i]}%\n`;
          }
        };

        return (
          <TableRow
            hover
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.rowId || row.id}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
            style={{
              transition: 'all 0.2s',
              backgroundColor: row.error ? '#ff000033' : 'transparent',
            }}
          >
            {headCells.map((headCell) => (
              <React.Fragment key={headCell.id}>
                {renderTableCell({
                  row,
                  headCell,
                  handleQuantityChange,
                  handleBankAccountClick,
                  emulation,
                  roleId,
                  isSelected,
                  roundToTwoDecimals,
                  labelId,
                  t,
                  i18n,
                  dataSetTitle,
                  handleStudentStatistics,
                  studentStatId,
                  setStudentStatId,
                  renderAccruedForInput,
                  handleAccruedForChange,
                  handleClick,
                  handleOpenEditQuantityModal,
                  handleOpenEditProductModal,
                  onDeleteProductClick,
                  isRowHidden,
                  onVisibilityOfProductClick,
                })}
              </React.Fragment>
            ))}
          </TableRow>
        );
      })}
    </TableBody>
  );
};


