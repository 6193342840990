import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useMediaQuery } from '@mui/material';
import usePhoneCheck from '../../../hooks/usePhoneCheck';

const useToolbarStyles = withStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        flex: '0 0 auto',
    },
}));

const ActionsItem = ({ Icon, onClick, title, color, newEqualizer }) => {
    if (typeof newEqualizer === 'boolean') {
        return (
            <Tooltip placement="top" title={title}>
                <Switch
                    onClick={onClick}
                    checked={newEqualizer}
                    color="primary"
                />
            </Tooltip>
        );
    }

    return (
        <Tooltip placement="top" title={title}>
            <IconButton onClick={onClick} color={color}>
                <Icon />
            </IconButton>
        </Tooltip>
    );
};

const EnhancedTableHeadView = ({ classes, title, actions }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const mobile = usePhoneCheck();

    if (mobile) {
        return (
            <>
                {actions.length || title ? (
                    <Toolbar className={classes.root}>
                        <Typography variant="body1">{title}</Typography>
                        <div className={classes.spacer} />
                        <div className={classes.actions}>
                            {actions
                                .filter((el) => el.outOfMenu)
                                .map(
                                    (
                                        {
                                            Icon,
                                            onClick,
                                            title,
                                            color,
                                            newEqualizer,
                                            outOfMenu,
                                        },
                                        index
                                    ) => {
                                        return (
                                            <ActionsItem
                                                key={index}
                                                Icon={Icon}
                                                onClick={onClick}
                                                title={title}
                                                color={color}
                                                newEqualizer={newEqualizer}
                                            />
                                        );
                                    }
                                )}
                            <Tooltip placement="top" title="Actions">
                                <IconButton
                                    aria-controls="actions-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                id="actions-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                {actions
                                    .filter((el) => !el.outOfMenu)
                                    .map(
                                        (
                                            {
                                                Icon,
                                                onClick,
                                                title,
                                                color,
                                                newEqualizer,
                                                outOfMenu,
                                            },
                                            index
                                        ) => {
                                            if (
                                                typeof newEqualizer ===
                                                'boolean'
                                            ) {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        onClick={onClick}
                                                        style={{ padding: '0' }}
                                                    >
                                                        <ListItemIcon>
                                                            <Switch
                                                                onClick={
                                                                    onClick
                                                                }
                                                                checked={
                                                                    newEqualizer
                                                                }
                                                                color="primary"
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            <Typography
                                                                variant="body2"
                                                                color="text.secondary"
                                                            >
                                                                {title}
                                                            </Typography>
                                                        </ListItemText>
                                                    </MenuItem>
                                                );
                                            }

                                            return (
                                                <MenuItem
                                                    key={index}
                                                    onClick={onClick}
                                                    style={{ padding: '0' }}
                                                >
                                                    <ListItemIcon>
                                                        <Icon />
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        <Typography
                                                            variant="body2"
                                                            color="text.secondary"
                                                        >
                                                            {title}
                                                        </Typography>
                                                    </ListItemText>
                                                </MenuItem>
                                            );
                                        }
                                    )}
                            </Menu>
                        </div>
                    </Toolbar>
                ) : null}
            </>
        );
    }

    return actions.length || title ? (
        <Toolbar className={classes.root}>
            <Typography variant="body1" className={classes.title}>
                {title}
            </Typography>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {actions.map(
                    ({ Icon, onClick, title, color, newEqualizer }, index) => {
                        return (
                            <ActionsItem
                                key={index}
                                Icon={Icon}
                                onClick={onClick}
                                title={title}
                                color={color}
                                newEqualizer={newEqualizer}
                            />
                        );
                    }
                )}
            </div>
        </Toolbar>
    ) : (
        <></>
    );
};

export default useToolbarStyles(EnhancedTableHeadView);
