export default {
  SUPERADMIN: "Супер админ",
  BOSS: "Босс",
  FRANCHISE: "Франчайзи",
  TEACHER: "Учитель",
  STUDENT: "Ученик",
  DEVELOPER: "",
  CALLCENTER: "Тех поддержка",
};

//
// export default {
//     SUPERADMIN: 'superadmin',
//     BOSS: "boss",
//     FRANCHISE: "",
//     TEACHER: "",
//     PUPIL: "",
//     DEVELOPER: "",
//     CALLCENTER: "",
// }
