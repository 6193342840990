import { func } from 'prop-types';

export function processCountryPhone() {
    const domainLast =
        document.location.hostname.split('.')[
            document.location.hostname.split('.').length - 1
        ];
    return domainLast === 'com' ? 'ca' : domainLast;
}

export function detectPhone() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    let value = false;
    if (/android/i.test(userAgent)) {
        value = true;
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        value = true;
    } else if (window.innerWidth <= 768) {
        value = true;
    }
    return value;
}
