import React from 'react';
import { Switch } from 'react-router-dom';

import LoginContainer from '../containers/Login';

import ProtectedRoute from './ProtectedRoute';
import ProtectedRedirectRoute from './ProtectedRedirectRoute';
import ProtectedLoginRoute from './ProtectedLoginRoute';
import DashboardPage from '../containers/DashboardPage';
import UsersPage from '../containers/UsersPage';
import CitiesPage from '../containers/CitiesPage';
import RequestsPage from '../containers/RequestsPage';
import GroupsPage from '../containers/GroupsPage';
import GroupSinlePage from '../containers/GroupSinlePage';
import GroupSinlePageHW from '../containers/GroupSinlePageHw';
import PlannerPage from '../containers/PlannerPage';
import CountriesPage from '../containers/CountriesPage';
import OfficesPage from '../containers/OfficesPage';
import RegionsPage from '../containers/RegionsPage';
import RolesPage from '../containers/RolesPage';
import StatisticsPage from '../containers/StatisticsPage';
import StudentsPage from '../containers/StudentsPage';
import PaymentsPage from '../containers/PaymentsPage';
import ServicesPage from '../containers/ServicesPage';
import SalesPage from '../containers/SalesPage';
import HWSettings from '../containers/HWSettings';
import Journal from './../containers/JournalPage';
import BooksPage from './../containers/BooksPage';
import RelocatePage from '../containers/RelocatePage';
import DebtComponent from '../containers/DebtPage';
import AnalyticsPage from './../containers/AnalyticsPage';
import OlympiadPage from './../containers/OlympiadPage';
import HelpPage from './../containers/HelpPage';
import FinancePage from './../containers/FinancePage';

import ExercisePage from '../containers/ExercisePage';
import ARPage from '../containers/ARPage';
import MyTemplates from '../containers/MyTemplates';
import FranchBooksPage from '../containers/FranchBooksPage';

import StudentBankSorobanPage from '../containers/StudentBankSorobanPage';
import UserBankSorobanPage from '../containers/UserBankSorobanPage';
import ShopPage from '../containers/ShopPage';
import BankStudentsPage from '../containers/BankStudentsPage';
import BankTeachersPage from '../containers/BankTeachersPage';
import BankFranchPage from '../containers/BankFranchPage';
import MyTemplatesPage from '../containers/MyTemplatesPage';


export class RootRoute extends React.Component {
    render() {
        return (
            <Switch>
                <ProtectedLoginRoute path="/login" component={LoginContainer} />
                <ProtectedRedirectRoute exact path="/" />

                <ProtectedRoute
                    exact
                    path="/dashboard"
                    component={DashboardPage}
                />
                <ProtectedRoute
                    exact
                    path="/dashboard/hidden"
                    component={DashboardPage}
                />
                <ProtectedRoute exact path="/users" component={UsersPage} />
                <ProtectedRoute
                    exact
                    path="/users/bank/:id/:role"
                    component={UserBankSorobanPage}
                />
                <ProtectedRoute exact path="/cities" component={CitiesPage} />
                <ProtectedRoute
                    exact
                    path="/requests"
                    component={RequestsPage}
                />
                <ProtectedRoute exact path="/groups" component={GroupsPage} />
                <ProtectedRoute
                    exact
                    path="/groups/:id"
                    component={GroupSinlePage}
                />
                <ProtectedRoute
                    exact
                    path="/groups/:id/hw/:date"
                    component={GroupSinlePageHW}
                />
                <ProtectedRoute exact path="/planner" component={PlannerPage} />
                <ProtectedRoute
                    exact
                    path="/countries"
                    component={CountriesPage}
                />
                <ProtectedRoute exact path="/offices" component={OfficesPage} />
                <ProtectedRoute exact path="/regions" component={RegionsPage} />
                <ProtectedRoute exact path="/roles" component={RolesPage} />
                <ProtectedRoute
                    exact
                    path="/finances_analitics"
                    component={FinancePage}
                />

                <ProtectedRoute
                    exact
                    path="/book_generate_codes"
                    component={BooksPage}
                />
                <ProtectedRoute
                    exact
                    path="/statistics"
                    component={StatisticsPage}
                />
                <ProtectedRoute
                    exact
                    path="/students"
                    component={StudentsPage}
                />
                <ProtectedRoute
                    exact
                    path="/students/bank/:id/:role"
                    component={StudentBankSorobanPage}
                />
                <ProtectedRoute
                    exact
                    path="/dashboard/balances/students"
                    component={BankStudentsPage}
                />
                <ProtectedRoute
                    exact
                    path="/dashboard/balances/teachers"
                    component={BankTeachersPage}
                />
                <ProtectedRoute
                    exact
                    path="/dashboard/balances/franch"
                    component={BankFranchPage}
                />                
                <ProtectedRoute
                    exact
                    path="/books-stat"
                    component={FranchBooksPage}
                />

                <ProtectedRoute
                    exact
                    path="/students/:type"
                    component={StudentsPage}
                />

                <ProtectedRoute
                    exact
                    path="/payments"
                    component={PaymentsPage}
                />
                <ProtectedRoute
                    exact
                    path="/services"
                    component={ServicesPage}
                />
                <ProtectedRoute exact path="/sales" component={SalesPage} />
                <ProtectedRoute
                    exact
                    path="/HWSettings"
                    component={HWSettings}
                />

                <ProtectedRoute
                    exact
                    path="/exercise"
                    component={ExercisePage}
                />
                <ProtectedRoute exact path="/ar" component={ARPage} />
                <ProtectedRoute exact path="/journal" component={Journal} />
                <ProtectedRoute
                    exact
                    path="/relocate"
                    component={RelocatePage}
                />
                <ProtectedRoute exact path="/debt" component={DebtComponent} />
                <ProtectedRoute
                    exact
                    path="/analytics"
                    component={AnalyticsPage}
                />
                <ProtectedRoute
                    exact
                    path="/olympiad"
                    component={OlympiadPage}
                />
                <ProtectedRoute exact path="/help" component={HelpPage} />
                <ProtectedRoute
                    exact
                    path="/templates"
                    component={MyTemplates}
                />
                <ProtectedRoute exact path="/shop" component={ShopPage} />
                <ProtectedRoute exact path="/template" component={MyTemplatesPage} />
            </Switch>
        );
    }
}
