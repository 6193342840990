import React from 'react';
import '../../layout/Dash/Dash.css';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getBooks, getBooksColorsCount } from '../../actions/books';
import Fade from '@material-ui/core/Fade';

import {
    getStatuses,
    GetArCards,
    getDiscountsCount,
    countCreatedStudent,
} from '../../actions/user';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { withTranslation } from 'react-i18next';
import { getUsers } from '../../actions/getUsers';

import Box from '@material-ui/core/Box';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CountUp from 'react-countup';
import base64url from 'base64url';
import { customFilterValue } from '../../actions/AsyncTable';
import AR_CARDS from '../../constants/ar_cards';
import { openRequestModal } from '../../actions/modals';
import { booksSchema, colorsSchema } from '../../constants/booksConstant';
import { Link } from 'react-router-dom';
import BalanceComponent from '../BalanceComponent';
import LTV from '../LTV';

class DashFranchiseView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [0, 1, 2, 3, 4, 5, 6],
            linkToCopy: ' ',
            copied: false,
            loading: true,
            studs: [0, 1, 2, 3, 4, 5, 6, 7],
            ar: [],
            loading_ar: true,
            discounts: null,
            studentsCount: null,
            loading_prices: false,
        };
    }

    componentDidMount() {
        this.fetch();
    }

    async fetch() {
        this.processStudentsCount();
        this.processStatuses();
        this.processBooks();
        this.processArCards();
        this.processDiscountsCount();
    }

    async processStudentsCount() {
        const studentsCount = await countCreatedStudent();
        if (!studentsCount) return;
        this.setState({ studentsCount });
        return true;
    }

    async processDiscountsCount() {
        const res = await this.props.getDiscountsCount();
        if (!res) return;
        this.setState({ discounts: res.count });
        return true;
    }

    async processBooks() {
        const getBooksResponse = await this.props.getBooks();
        let res = structuredClone(getBooksResponse);
        if (!res) return;
        const colors = await getBooksColorsCount();
        if (!colors) return;
        delete colors.green;
        res = { ...res, ...colors };
        const data = booksSchema;

        let items = Object.keys(res)
            .map((el, i) =>
                data[el]
                    ? {
                          lang: data[Object.keys(res)[i]],
                          label:
                              /\d/.test(`${Object.keys(res)[i]}`) && `${i + 1}`,
                          count: res[Object.keys(res)[i]],
                          code: Object.keys(res)[i],
                          color: colorsSchema[Object.keys(res)[i]] || '',
                      }
                    : null
            )
            .filter(Boolean);
        this.setState({ items });
        return true;
    }

    async processStatuses() {
        let students = await this.props.getStatuses();
        const statuses = {
            0: 'ACTIVE',
            1: 'BLOCKED',
            2: 'RESERVED',
            3: 'ARCHIVED',
            4: 'ON_VACATION',
            5: 'NOT_CONFIRMED',
            6: 'CANDIDATE',
            7: 'GRADUATE',
        };

        if (!students) return;
        const studs = Object.keys(students).map((item, index) => ({
            title: statuses[item],
            value: students[index],
        }));
        this.setState({ studs });
        return true;
    }

    async processArCards() {
        let item = await this.props.GetArCards();
        if (!item) return;
        let counter = 0;
        let main = [];
        let output = [];
        if (!Object.keys(item).length) {
            this.setState({ loading_ar: false });
            return;
        }

        const ar = Object.keys(item.cards).map((title) => ({
            title,
            value: item.cards[title],
        }));
        if (ar.length > 8) {
            for (let i = 0; i < ar.length; i++) {
                output.push(ar[i]);
                if (counter === 7) {
                    main.push(output);
                    output = [];
                    counter = 0;
                } else {
                    counter += +1;
                }
            }
        }

        this.setState({ ar: main.length ? main : [ar], loading_ar: false });
    }

    encodeFilter(data) {
        const encodedFilter = data.reduce((result, item) => {
            return item.values
                ? { ...result, [item.code]: item.values }
                : result;
        }, {});
        return base64url.encode(
            encodeURIComponent(JSON.stringify(encodedFilter))
        );
    }

    handleNewStudents(item) {
        this.props.history.push(`/students/${item}`);
    }

    processFilterStudents(item, value) {
        if (value) {
            const { user } = this.props;
            let custom_filter = this.props.custom_filter;
            let user_id = user.emulation.length
                ? user.emulation[user.emulation.length - 1].info.user_id
                : user.info.user_id;
            if (!!user.emulation.length) user_id = `${user_id}_emul`;
            if (!custom_filter) {
                custom_filter = { [user_id]: { '/students': [] } };
            } else if (custom_filter && !custom_filter[user_id]) {
                custom_filter[user_id] = { '/students': [] };
            } else if (
                custom_filter &&
                custom_filter[user_id] &&
                !custom_filter[user_id]['/students']
            ) {
                custom_filter[user_id] = { '/students': [] };
            }

            const index = custom_filter[user_id]['/students'].findIndex(
                (el) => el.code === 'status'
            );
            if (index !== -1) delete custom_filter[user_id]['/students'][index];
            custom_filter[user_id]['/students'] =
                custom_filter[user_id]['/students'].filter(Boolean);

            custom_filter[user_id]['/students'] = [
                ...custom_filter[user_id]['/students'],
                {
                    code: 'status',
                    label: 'table.status',
                    type: 'status_select',
                    values: JSON.stringify(item),
                },
            ];
            this.props.customFilterValue(custom_filter, true);

            const filter = this.encodeFilter([
                {
                    code: 'status',
                    label: 'table.status',
                    type: 'status_select',
                    values: JSON.stringify(item),
                },
            ]);
            this.props.history.push(`/students?filter=${filter}`);
        }
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <LTV />

                <BalanceComponent />
                <Fade in={true}>
                    <Paper elevation={0}>
                        <Box m={2} p={2}>
                            <Typography component="div">
                                {t('dashboard.new_students')}:
                            </Typography>
                            {this.state.studentsCount ? (
                                <ListItem
                                    button
                                    style={{ width: 250 }}
                                    divider
                                    onClick={() =>
                                        this.handleNewStudents('day')
                                    }
                                >
                                    <ListItemText
                                        primary={t(`dashboard.per_day`)}
                                    />
                                    <div className="counter">
                                        {!+this.state.studentsCount
                                            .studentsDay ? (
                                            0
                                        ) : (
                                            <CountUp
                                                duration={2.75}
                                                end={
                                                    this.state.studentsCount
                                                        .studentsDay
                                                }
                                            />
                                        )}
                                    </div>
                                </ListItem>
                            ) : (
                                <ListItem style={{ width: 250 }}>
                                    <Skeleton
                                        width={'100%'}
                                        height={'40px'}
                                        variant="text"
                                    />
                                </ListItem>
                            )}
                            {this.state.studentsCount ? (
                                <ListItem
                                    button
                                    divider
                                    style={{ width: 250 }}
                                    onClick={() =>
                                        this.handleNewStudents('week')
                                    }
                                >
                                    <ListItemText
                                        primary={t(`dashboard.per_week`)}
                                    />
                                    <div className="counter">
                                        {!+this.state.studentsCount
                                            .studentsWeek ? (
                                            0
                                        ) : (
                                            <CountUp
                                                duration={2.75}
                                                end={
                                                    this.state.studentsCount
                                                        .studentsWeek
                                                }
                                            />
                                        )}
                                    </div>
                                </ListItem>
                            ) : (
                                <ListItem style={{ width: 250 }}>
                                    <Skeleton
                                        width={'100%'}
                                        height={'40px'}
                                        variant="text"
                                    />
                                </ListItem>
                            )}
                        </Box>
                    </Paper>
                </Fade>
                <Fade in={true} timeout={500}>
                    <Accordion
                        className={
                            this.state.loading_ar && this.state.ar[0] === 0
                                ? 'discounts_accordion no_data'
                                : 'discounts_accordion'
                        }
                        style={{ borderRadius: '4px' }}
                        elevation={0}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography
                                variant="subtitle1"
                                style={{
                                    margin: '0',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                gutterBottom
                            >
                                {t('dashboard.issued_arcards')}
                                {this.state.loading_ar &&
                                this.state.ar[0] === 0 ? (
                                    <Skeleton
                                        width={'250px'}
                                        height={'40px'}
                                        variant="text"
                                        style={{ marginLeft: '10px' }}
                                    />
                                ) : null}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography component="div" className="ar_cards">
                                <>
                                    {this.state.loading_ar
                                        ? this.state.ar.map((el, index) => {
                                              return (
                                                  <ListItem key={index}>
                                                      <Skeleton
                                                          width={'100%'}
                                                          height={'40px'}
                                                          variant="text"
                                                      />
                                                  </ListItem>
                                              );
                                          })
                                        : null}
                                    {!this.state.loading_ar &&
                                    this.state.ar.length
                                        ? this.state.ar.map(
                                              (element, index) => {
                                                  return (
                                                      <List
                                                          key={index}
                                                          component="nav"
                                                          aria-label="mailbox folders"
                                                          elevation={0}
                                                      >
                                                          {element.map(
                                                              (el, index) => {
                                                                  return (
                                                                      <ListItem
                                                                          divider
                                                                          key={
                                                                              index
                                                                          }
                                                                      >
                                                                          <ListItem>
                                                                              <ListItemText
                                                                                  className="ar_count_text"
                                                                                  primary={`${t(
                                                                                      `cards.${
                                                                                          AR_CARDS[
                                                                                              el
                                                                                                  .title
                                                                                          ]
                                                                                      }`
                                                                                  )}  ${
                                                                                      AR_CARDS[
                                                                                          el
                                                                                              .title
                                                                                      ]
                                                                                  }`}
                                                                              />
                                                                              <div className="counter">
                                                                                  <CountUp
                                                                                      duration={
                                                                                          2.75
                                                                                      }
                                                                                      end={
                                                                                          el.value
                                                                                      }
                                                                                  />
                                                                              </div>
                                                                          </ListItem>
                                                                      </ListItem>
                                                                  );
                                                              }
                                                          )}
                                                      </List>
                                                  );
                                              }
                                          )
                                        : null}
                                </>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Fade>
                <section className="dashSection">
                    <Fade in={true} timeout={1000}>
                        <Paper elevation={0}>
                            <List component="nav" aria-label="mailbox folders">
                                <Typography
                                    variant="subtitle1"
                                    className="subtitle"
                                    gutterBottom
                                >
                                    {t('dashboard.stud_count')}
                                </Typography>
                                {!this.state.studs[0]
                                    ? this.state.studs.map((el, index) => {
                                          return (
                                              <ListItem divider key={index}>
                                                  <ListItem button>
                                                      <Skeleton
                                                          width={'100%'}
                                                          height={'40px'}
                                                          variant="text"
                                                      />
                                                  </ListItem>
                                              </ListItem>
                                          );
                                      })
                                    : this.state.studs.map((el, index) => {
                                          return (
                                              <ListItem
                                                  onClick={() =>
                                                      this.processFilterStudents(
                                                          index,
                                                          el.value
                                                      )
                                                  }
                                                  divider
                                                  key={index}
                                              >
                                                  <ListItem button>
                                                      <ListItemText
                                                          primary={t(
                                                              `users.${el.title}`
                                                          )}
                                                      />
                                                      <div className="counter">
                                                          {!el.value ? (
                                                              0
                                                          ) : (
                                                              <CountUp
                                                                  duration={
                                                                      2.75
                                                                  }
                                                                  end={el.value}
                                                              />
                                                          )}
                                                      </div>
                                                  </ListItem>
                                              </ListItem>
                                          );
                                      })}
                            </List>
                        </Paper>
                    </Fade>
                    <Fade in={true} timeout={1500}>
                        <Paper elevation={0}>
                            <List component="nav" aria-label="mailbox folders">
                                <Typography
                                    variant="subtitle1"
                                    className="subtitle"
                                    gutterBottom
                                >
                                    {t('dashboard.books_stat')}
                                </Typography>
                                {!this.state.items[0]
                                    ? this.state.items.map((el, index) => {
                                          return (
                                              <ListItem divider key={index}>
                                                  <ListItem button>
                                                      <Skeleton
                                                          width={'100%'}
                                                          height={'40px'}
                                                          variant="text"
                                                      />
                                                  </ListItem>
                                              </ListItem>
                                          );
                                      })
                                    : this.state.items
                                          .filter((el) => el.code !== 'WITHOUT')
                                          .map((el, index) => {
                                              let text = t(`books.${el.lang}`);
                                              const label = el.label
                                                  ? el.label
                                                  : '';
                                              text = text + '  ' + label;
                                              return (
                                                  <ListItem divider key={index}>
                                                      <ListItem
                                                          button
                                                          component={Link}
                                                          to={`/books-stat?id=${el.code}`}
                                                      >
                                                          <ListItemText
                                                              primary={text}
                                                          />
                                                          <div
                                                              className="counter"
                                                              style={{
                                                                  backgroundColor:
                                                                      el.color ||
                                                                      '',
                                                              }}
                                                          >
                                                              {!el.count ? (
                                                                  0
                                                              ) : (
                                                                  <CountUp
                                                                      duration={
                                                                          2.75
                                                                      }
                                                                      end={
                                                                          el.count
                                                                      }
                                                                  />
                                                              )}
                                                          </div>
                                                      </ListItem>
                                                  </ListItem>
                                              );
                                          })}
                            </List>
                        </Paper>
                    </Fade>
                    <Fade in={true} timeout={2000}>
                        <Paper elevation={0}>
                            <List component="nav" aria-label="mailbox folders">
                                <Typography
                                    variant="subtitle1"
                                    className="subtitle"
                                    gutterBottom
                                >
                                    {t('sidebar.discounts')}
                                </Typography>
                                {this.state.discounts == null ? (
                                    <ListItem button>
                                        <Skeleton
                                            width={'200px'}
                                            height={'40px'}
                                            variant="text"
                                        />
                                    </ListItem>
                                ) : (
                                    <ListItem
                                        button
                                        component={Link}
                                        to={`/students/discounts`}
                                    >
                                        <ListItemText
                                            primary={t(
                                                'dashboard.discounts_count'
                                            )}
                                        />
                                        <div className="counter">
                                            <CountUp
                                                duration={2.75}
                                                end={this.state.discounts}
                                            />
                                        </div>
                                    </ListItem>
                                )}
                            </List>
                        </Paper>
                    </Fade>
                </section>
            </>
        );
    }
}

const widthConnect = connect(
    (state) => ({
        users: state.users,
        user: state.user,
        cities: state.cities,
        custom_filter:
            state.AsyncTable.table && state.AsyncTable.table.custom_filter,
    }),
    (dispatch) =>
        bindActionCreators(
            {
                getUsers,
                getBooks,
                getStatuses,
                GetArCards,
                getDiscountsCount,
                customFilterValue,
                openRequestModal,
            },
            dispatch
        )
);

export default withTranslation()(widthConnect(DashFranchiseView));
