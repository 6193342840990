import Types from "./../constants/AsyncTable";

const getRowsPerPage = () => {
  try {
    const serializedState = localStorage.getItem("rowsPerPage");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (ex) {
    return undefined;
  }
};
const rowsPerPage = getRowsPerPage();

const defaultState = {
  default: {
    settingsOpen: false,
    multiply: false,
    filterOpen: false,
    expanded: false,
    source: {},
    fetching: true,
    isEmpty: false,
    modelIdHash: "",
    flagUpdate: false,
    model: [],
    filter: [],
    custom_filter: false,
    sort: {
      order: "asc",
      orderBy: "",
    },
    data: [],
    subdivisions: [],
    counterparties: [],
    ordersStatusList: [],
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    count: 0,
    rowsPerPage: rowsPerPage || 100,
    page: 0,
    isTouched: false,
  },
  table: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case Types.DESTROY:
      return {
        ...state,
        table: {
          rowsPerPage:
            state.table && state.table.rowsPerPage
              ? state.table.rowsPerPage
              : state.default.rowsPerPage,
          custom_filter: state.table.custom_filter,
        },
        HW: {},
      };
    case Types.PAY_TYPE:
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            // eslint-disable-next-line
            if (item.id == action.payload.id)
              item.payment_type = action.payload.payment_type;
            return item;
          }),
        },
      };
    case Types.PAY_SUM:
      return {
        ...state,
        table: {
          ...state.table,
          data: state.table.data.map((item) => {
            // eslint-disable-next-line
            if (item.id == action.payload.id)
              item.payment_sum = action.payload.payment_sum;
            return item;
          }),
        },
      };
    case Types.INIT_DATA_HW_ROW:
      return {
        ...state,
        HW: {
          ...state.HW,
          ...action.payload,
        },
      };
    case Types.ADD_DATA_HW_ROW:
      return {
        ...state,
        table: {
          ...state.table,
          data: [...action.payload],
        },
      };
    case Types.ROWS_PER_PAGE:
    case Types.ACTIVATE_FETCHING:
    case Types.CHANGE_PAGE:
    case Types.MODEL:
    case Types.MODEL_UPDATE:
    case Types.DATA:
    case Types.TOOGLE_SETTINGS:
    case Types.TOOGLE_FILTER:
    case Types.SET_FILTER:
    case Types.CITIES:
    case Types.SUBDIVISIONS:
    case Types.COUNTERPARTIES:
    case Types.ORDERS_STATUS_LIST:
    case Types.TOOGLE_FILTER_VIEW:
    case Types.SORT:
    case Types.SETSOURCE:
    case Types.SELECTMULT:
      return {
        ...state,
        table: { ...state.table, ...action.payload },
        HW: {},
      };

    default:
      return state;
  }
};
