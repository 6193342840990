import Types from '../constants/discounts';
import DiscountsService from './../services/discounts.service';
import { getError } from './toast';
import { reloadTable } from './AsyncTable';
import { closeRequestModal } from './modals';

function getSingleDiscounts(...args) {
    return async (dispatch) => {
        try {
            dispatch({
                type: Types.CLEAR_DISCOUNTS,
            });
            const response = await DiscountsService.Single(...args);
            const list = response.discounts;
            dispatch({
                type: Types.SINGLE,
                discounts: {
                    list: list,
                    price: response.price,
                    discountAmount: response.discountAmount,
                    family: response.family || null,
                },
            });
            return true;
        } catch (error) {
            // throw 'ERROR => GET SINGLE DISCOUNTS!';
            getError(error);
        }
    };
}

async function recountDiscount(student_id) {
    return await DiscountsService.RecountDiscount({ student_id });
}

function setDiscountsLoading(state) {
    return (dispatch, getState) => {
        const { discounts } = getState();
        try {
            dispatch({
                type: Types.SINGLE,
                discounts: {
                    ...discounts,
                    loading: state,
                },
            });
        } catch (error) {
            getError(error);
        }
    };
}
function updateDiscounts(data) {
    return (dispatch, getState) => {
        const { discounts } = getState();
        try {
            // const { discounts } = getState();
            dispatch({
                type: Types.SINGLE,
                discounts: {
                    ...discounts,
                    list: data,
                },
            });
        } catch (error) {
            // throw 'ERROR => UPDATE DISCOUNTS!';
            getError(error);
        }
    };
}
function discountStatus(data) {
    return (dispatch, getState) => {
        DiscountsService.discountStatus(data).then((response) => {
            reloadTable()(dispatch, getState);
        });
    };
}

function deleteDiscountModal(data, item, id) {
    return async () => {
        return await DiscountsService.DeleteDiscount(data, item);
    };
}

function deleteDiscount(...arg) {
    return (dispatch, getState) => {
        DiscountsService.DeleteDiscount(...arg).then((res) => {
            reloadTable()(dispatch, getState);
        });
    };
}

function sendRequest(data) {
    return async (dispatch, getState) => {
        setDiscountsLoading(true)(dispatch, getState);
        let items = structuredClone(data);
        if (items.discounts_group === '4') {
            let days = Math.ceil(
                (new Date(items.days).getTime() - new Date().getTime()) /
                    (1000 * 60 * 60 * 24)
            );
            items.days = JSON.stringify(days);
        }
        if (
            +items.discounts_group === 6 &&
            Object.keys(items).find((el) => el === 'referer_type')
        ) {
            delete items.referer_type;
        }
        if (items.amount_rate) {
            items.amount_rate = Number(items.amount_rate);
        }
        if (items.amount_fix) {
            items.amount_fix = Number(items.amount_fix);
        }
        const res = await DiscountsService.Create(items);
        if (res) {
            const { user, modalWindow } = getState();
            const { requestmodal } = modalWindow;
            const { data } = requestmodal;
            const role_id = user.emulation.length
                ? user.emulation[user.emulation.length - 1].info.role_id
                : user.info.role_id;
            closeRequestModal()(dispatch, getState);

            if (data && data.id) {
                await getSingleDiscounts(false, { id: data.id })(
                    dispatch,
                    getState
                );
            }
        }
        setDiscountsLoading(false)(dispatch, getState);

        return true;
    };
}

function calculateDiscounts(totalBalance, data) {
    if (!totalBalance) return;
    let availableDiscount = totalBalance * 0.5;
    let total = 0;

    for (const item of data) {
        const hasAmountFix = item.amount_fix;
        const hasAmountRate = item.amount_rate;

        if (hasAmountFix && hasAmountRate) {
            console.error(
                'Ошибка: одновременно заданы amount_fix и amount_rate.'
            );
        } else if (hasAmountRate) {
            const discountPercentage = item.amount_rate;
            const discountAmount = (discountPercentage / 100) * totalBalance;
            total += discountAmount;
            if (discountAmount <= availableDiscount) {
                availableDiscount -= discountAmount;
            } else {
                availableDiscount = -1;
            }
        } else if (hasAmountFix) {
            const fixedDiscount = item.amount_fix;
            total += fixedDiscount;
            if (fixedDiscount <= availableDiscount) {
                availableDiscount -= fixedDiscount;
            } else {
                availableDiscount = -1;
            }
        }
    }

    return { availableDiscount, total };
}

export {
    calculateDiscounts,
    getSingleDiscounts,
    sendRequest,
    discountStatus,
    deleteDiscount,
    deleteDiscountModal,
    updateDiscounts,
    recountDiscount,
};
