import React from 'react';
import { Autocomplete, TextField, Popper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';

// Стилізований Popper для темної теми
const CustomPopper = styled(Popper)({
  '& .MuiAutocomplete-paper': {
    backgroundColor: '#424242',
    color: '#ffffff',
  },
  '& .MuiAutocomplete-option': {
    '&.Mui-selected': {
      backgroundColor: '#616161',
    },
    '&:hover': {
      backgroundColor: '#757575',
    },
  },
});


const CustomAutocomplete = ({ options, label, inputValue, onInputChange, onChange, ...props }) => {

  const themeMode = useSelector((state) => state.user.theme);
  const popperComponent = themeMode === 'dark' ? (props) => <CustomPopper {...props} /> : undefined;

  return (
    <Autocomplete
      sx={{ width: 260, marginBottom: '10px' }}
      PopperComponent={popperComponent}
      options={options}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <TextField {...params} label={label} />
      )}
      {...props}
    />
  );
};

export default CustomAutocomplete;


