export const regularAnalytics = [
    [
        'ID студента',
        'Населённый пункт',
        'Учитель',
        'Группа',
        'День недели занятия группы',
        'Фамилия ученика',
        'Имя ученика',
        'Количество дней со статусом ACTIVE',
        'Всего активных дней в системе',
        'Количество выполненных ДЗ',
        'Цена группы',
        'Скидки',
        'Описание скидки',
        'Баланс до выставления счета',
        'Счёт к оплате',
        'Баланс после списания',
        'Пополнения',
        'Возврат',
        'Списание',
        'Причина списания',
        'Баланс на дату формирования отчётa',
        'Тип оплаты',
        'Дата формирования отчёта',
    ],
];

export const reportAnalytics = [
    [
        'Адрес офиса',
        'ФИО тренера',
        'Название группы',
        'День занятий',
        'Имя студента',
        'Фамилия студента',
        'Телефон студента',
        'Почта студента',
        'День рождения',
        'Родитель',
        'Текущий статус',
        'Дата смены статуса на текущий',
    ],
];

export const reportSeparateCell = ['', '', '', '', '', '', '', '', '', ''];

export const cityReport = [
    { key: 'period', label: 'Період' },
    { key: 'country', label: 'Країна' },
    { key: 'city', label: 'Місто' },
    { key: 'franch', label: 'Франчайзи' },
    { key: 'balance_in_charge', label: 'Баланс до виставлення рахунку' },
    { key: 'amount_in_charge', label: 'Сума до сплати' },
    { key: 'write_off', label: 'Списання боргу' },
    { key: 'income', label: 'Поповнення балансу за період' },
    { key: 'refund', label: 'Повернення за період' },
    { key: 'balance_after_charge', label: 'Баланс після списання' },
    { key: 'balance_current_day', label: 'Баланс на дату формування звіту' },
    { key: 'init_amount', label: 'Сума первинного нарахування' },
    { key: 'royalty_summ', label: 'Сума для роялті' },
    { key: 'royalty', label: 'Роялті' },
    { key: 'it', label: 'IT' },
    { key: 'total', label: 'Разом' },
];

export const kpiReport = [
    { key: 'period', label: 'Період звіту' },
    { key: 'country', label: 'Країна' },
    { key: 'country_id', label: 'Країна ID' },
    { key: 'city', label: 'Місто' },
    { key: 'city_id', label: 'Місто ID' },
    { key: 'franch_id', label: 'Франч ID' },
    { key: 'teacher_id', label: 'Учитель ID' },
    { key: 'teacher', label: 'Учитель' },
    { key: 'cnt_b0', label: 'Кількість статус Актив на початок періоду' },
    { key: 'cnt_a0', label: 'Кількість статус Актив на закінчення періоду' },
    { key: 'cnt_a7', label: 'Кількість статус Випуск за період' },
    { key: 'kpi', label: 'КПІ' },
];

export const outflowReport = [
    { key: 'period', label: 'Період звіту' },
    { key: 'country', label: 'Країна' },
    { key: 'country_id', label: 'Країна ID' },
    { key: 'city', label: 'Місто' },
    { key: 'city_id', label: 'Місто ID' },
    { key: 'franch_id', label: 'Франч ID' },
    { key: 'cnt_b0', label: 'Кількість статус Актив на початок періоду' },
    { key: 'cnt_f0', label: 'Кількість статус Актив на закінчення періоду' },
    { key: 'cnt_f7', label: 'Кількість статус Випуск за період' },
    { key: 'outflow', label: 'Відтік %' },
];

export const cityReportCell = [''];

export const cityReportDetailed = [
    { key: 'period', label: 'Період' },
    { key: 'country', label: 'Країна' },
    { key: 'city', label: 'Місто' },
    { key: 'franch', label: 'Франчайзи' },
    { key: 'student_id', label: 'Код учня' },
    { key: 'first_name', label: "Ім'я учня" },
    { key: 'last_name', label: 'Прізвище учня' },
    { key: 'course', label: 'Група' },
    { key: 'active_day', label: 'Днів активності звітного періоду' },
    { key: 'active_day_all', label: 'Всього днів активності' },
    { key: 'teacher', label: 'Вчитель' },
    { key: 'lesson_day', label: 'День заняття' },
    { key: 'hw_done_days', label: 'Днів домашніх завдань звітного періоду' },
    { key: 'balance_in_charge', label: 'Баланс до виставлення рахунку' },
    { key: 'price', label: 'Ціна групи' },
    { key: 'discount', label: 'Знижка' },
    { key: 'discount_description', label: 'Опис знижки' },
    { key: 'amount_in_charge', label: 'Рахунок до сплати' },
    { key: 'write_off', label: 'Списання боргу' },
    { key: 'write_off_desc', label: 'Опис списання боргу' },
    { key: 'income', label: 'Поповнення баланса за період' },
    { key: 'refund', label: 'Возврати за період' },
    { key: 'balance_after_charge', label: 'Баланс після списання' },
    { key: 'balance_current_day', label: 'Баланс на дату формування звіту' },
    { key: 'init_amount', label: 'Сума первинного нарахування' },
];

export const cityReportDetailedCell = [''];
