import { ApiConnector, GET, POST, PUT, DELETE } from './requestV2.service';

export default (() => {
    const API = ApiConnector('/countries');
    return {
        CountriesList: async (data, query) =>
            await API(GET, '', false, query).call(data),
        AddCountry: async (data) => await API(POST, '').call(data),
        EditCountry: async (data) => await API(PUT, '').call(data),
        SingleCountry: async (data, query) =>
            await API(GET, '/single', false, query).call(data),
        DeleteCountry: async (data) => await API(DELETE, '').call(data),
        GetMaxCycles: async (data, query) =>
            await API(GET, '/max-cycles', false, query).call(data),
        PostMaxCycles: async (data) =>
            await API(POST, '/max-cycles').call(data),
        SetBossPrices: async (data) => await API(POST, '/prices').call(data),
        GetBossPrices: async () => await API(GET, '/prices').call(),
    };
})();

//TO DO:

// import {ApiConnector, GET, POST, PUT} from "./request.service";
//
// export default (() => {
//     const API = ApiConnector("/countr");
//     return {
//         CountriesList: async (data, query) => await API(GET, "ies", false, query).call(data),
//         AddCountry: async data => await API(POST, "y").call(data),
//         EditCountry: async data => await API(PUT, "y").call(data),
//         GetCountryToAddCity:  async data => await API(GET, "y").call(data),
//     };
// })();
